import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import services from '../../services/services'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions';
import { Button, Modal } from 'react-bootstrap'
import NavAdmission from './NavAdmission'

function Admission() {
    const sesstoken = JSON.parse(sessionStorage.getItem('token'));
    const sess_id_user = JSON.parse(sessionStorage.getItem('id_user'));

    useEffect(() => {
        redirect();
        getAuth(sesstoken);
        GetListClass()

    }, [sesstoken])

    function getAuth(token) {
        
        services.get_auth(token)
        .then((res) =>{
            sessionStorage.setItem('id_user', JSON.stringify(res.data[0].id))
            // console.log(res.data)
        });
    }

    const redirect = () => {
        if (!sesstoken) {
            window.location.replace('/Login')
        }
    }

   

    const [showModal, setShowModal] = useState(false);

    function ShowModal() {
        setShowModal(true);

    }
    const handleCloseModal = () => setShowModal(false)


    const [dataClass, setDataClass] = useState({
        year: '',
        classes: '',
    })

    const handle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataClass, [name]: value }
        setDataClass(data);
        console.log(data)


    }


    function handleSubmit() {
        sessionStorage.setItem('academic_year', JSON.stringify('2023-2024'))
        sessionStorage.setItem('class_choose', JSON.stringify(dataClass.classes))

        window.location.replace("/admission/0")
    }










    const [classList, setClassList] = useState([])

    function GetListClass() {
        services.get_Classe_Open()
            .then((res) => {
                setClassList(res.data)
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }






    const [dataList, setDataList] = useState([])
   


    useEffect(() => {
        GetStudentAdmission(sess_id_user)
    }, [sess_id_user]);

    function GetStudentAdmission(iduser) {
        services.get_data_stud_admission(iduser)
            .then((res) => {
                setDataList(res.data)
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


   

      const columns = [

       
        {
            name: "Picture",
            cell: (rows) => (
                rows.file === "NaN" ? (

                    rows.sexe === 'Male' ? (
                        <div className="table-avatar">
                            <Link to={"/detail-student/" + rows.StudentId} className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src={"assets/img/male.png"} alt="UserImage" /></Link>
                        </div>

                    ) : (
                        <div className="table-avatar">
                            <Link to={"/detail-student/" + rows.StudentId} className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src={"assets/img/female.png"} alt="UserImage" /></Link>
                        </div>

                    )

                ) : (
                    <div className="table-avatar">
                        <Link to={"/detail-student/" + rows.StudentId} className="avatar avatar-sm me-2">
                            <img className="avatar-img rounded-circle" src={"https://scholarsapp.iesaciv.com/" + rows.file} alt="?" />
                        </Link>

                    </div>

                )

            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Full name",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.nom} {rows.prenom} </b>
            ),
            sortable: true,
            reorder: true,
            width: "20rem",                       // added line here
            headerStyle: (selector, id) => {
              return { textAlign: "center" };   // removed partial line here
            },
        },

        
        
        {
            name: "DOB",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.birthday} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Nationality",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.nationality} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Class current",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.class_current} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.academicyear} </b>
            ),
            sortable: true,
            reorder: true
        },
        
       

        
        {
            name: " Process not complet",
            cell: (rows) => (
                
                rows.step1 === '0' && rows.step2 === '0' && rows.step3 === '0' && rows.step4 === '0' && rows.step5 === '0' && rows.step6 === '0' ? (
                    <div className="actions"></div>
                    ) : (
                        <div className="actions">
                    
                            <button onClick={(e) => EndProcess(e, rows.code_student)} className="btn btn-sm bg-danger text-white m-4">
                               <i className='fas fa-arrow-right'></i>
                            </button>

                            
                        </div>
                     )
                     


            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">
                    
                    <Link to="#" className="btn btn-sm bg-danger-light" onClick={(e) => DeleteStudent(e, rows.code_student)}>
                        <i className="fas fa-trash"></i>
                    </Link>

                    
                </div>


            ),
            sortable: true,
            reorder: true
        },


    ]


    function EndProcess(e, code) {
            services.get_data_stud_admission_step1(code)
                .then((res) => {
                    sessionStorage.setItem('class_choose', JSON.stringify(res.data[0].class_current))
                    sessionStorage.setItem('FirstName', JSON.stringify(res.data[0].prenom))
                    sessionStorage.setItem('LastName', JSON.stringify(res.data[0].nom))
                    sessionStorage.setItem('Gender', JSON.stringify(res.data[0].sexe))
                    sessionStorage.setItem('nationality', JSON.stringify(res.data[0].nationality))
                    sessionStorage.setItem('DateBirth', JSON.stringify(res.data[0].birthday))
                    sessionStorage.setItem('BirthCity', JSON.stringify(res.data[0].birth_city))
                    sessionStorage.setItem('BirthCountry', JSON.stringify(res.data[0].birth_country))
                    sessionStorage.setItem('id_user', JSON.stringify(res.data[0].id_userS))
                    sessionStorage.setItem('code_stud', JSON.stringify(res.data[0].code_student))
    
                    window.location.replace("/admission/"+code)
                    console.log(res.data)
    
                })
                .catch((error) => {
                    console.log(error.response);
                })
       
    }



    function DeleteStudent(e, id) {
        Swal.fire({
            title: 'Es-tu sûr?',
            html: "You can't go back! The student will be deleted <br> Vous ne pourrez pas revenir en arrière! l'éléve sera supprimé",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprime-le!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.delete_data_stud_admission(id)
                    .then(response => {
                        // console.log(response.data);
                        GetStudentAdmission()
                        Swal.fire(
                            'Supprimé!',
                            'Votre fichier a été supprimé.',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    return (
        <>
            <NavAdmission />
            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Admission Table</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item active">table Students</li>
                                </ul>
                            </div>
                            <div className="col-auto text-end float-end ms-auto">
                                <button type="button" className="btn btn-primary" onClick={() => ShowModal()} ><i className="fas fa-plus"></i> add new student</button>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-table">
                                <div className="card-body">
                                    <div className="table-responsive">
                                       
                                            <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                columns={columns}
                                                data={dataList}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                // selectableRows
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Select Class </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="GET">
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Year</label>
                                    <input type="text" readOnly name="year" value="2023-2024" className="form-control" />
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Select Class Admission</label>
                                    <select name="classes" id="" className="form-control" onChange={(e) => handle(e)}>
                                        <option value="">--class--</option>
                                        {classList.map((res) =>(
                                            <option key={res.id} value={res.id}>{res.libelle}</option>

                                        ))}
                                    </select>

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>


                        <Button type="button" onClick={(e) => handleSubmit(e)} variant='primary'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default Admission
