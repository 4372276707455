import React from "react";
import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Services from "../services/services";
import Swal from "sweetalert2";

function ModelInvoice() {

    const { type, classe } = useParams()

    const [inputFields, setInputFields] = useState([
        {
            item: '',
            category: '',
            qtty: '',
            price: '',
            amount: '',
            discount: '',
            type_invoice: type,
            classes: classe,
        }
    ])


    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    }

    const addFields = () => {
        let newfield = { item: '', category: '', qtty: '', price: '', amount: '', discount: '',  type_invoice: type,  classes: classe, }
    
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }



    useEffect(()=>{
        getItems()
    }, [])

    const [items, setItems] = useState([])

    function getItems() {
        Services.GetItems()
        .then((response) => {
            setItems(response.data)
            console.log(response.data)
        })
        .catch((error) =>{
            console.log(error)
        })
    }


    const submit = (e) => {
        e.preventDefault();

        Services.AddModel(inputFields).then((response) =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000
            })

            setTimeout(() =>{
                window.location.replace('/Simple-invoice')
            }, 5000);
        })
        .catch((error) =>{
            console.log(error)
        })
        console.log(inputFields)
    }

    return (
        <>
            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="page-title">Model Invoice proforma <b style={{ color: '#fcba38' }}>{type} {classe}</b> </h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/Simple-invoice"}>Simple Invoice  </Link>
                                    </li>
                                    <li className="breadcrumb-item active">General Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="invoice-add-table">
                        <h4>Item Details</h4>

                        <button className="btn btn-info mb-3"  onClick={addFields} style={{color:'#fff'}}>Add line <i className="fas fa-plus-circle"></i></button>

                        <form action="" onSubmit={submit}>
                            
                        <div className="table-responsive">
                            <table className="table table-center add-table-items">
                                <thead>
                                    <tr>
                                        <th>Items</th>
                                        <th>Category</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Amount</th>
                                        <th>Discount</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {inputFields.map((input, index) => {
                                        return (
                                            <>
                                            {/* {
                                                function() {
                                                    let input = []
                                                    for (let i = parseInt(1); i < parseInt(number) + 1; i++) {
                                                        input.push( */}
                                                            <tr className="add-row" key={index}>
                                                                <td>
                                                                <input type="hidden" name="type_invoice" value={type} onChange={event => handleFormChange(index, event)} />
                                                                <input type="hidden" name="classes" value={classe} onChange={event => handleFormChange(index, event)} />

                                                                    <select name="item" id="" onChange={event => handleFormChange(index, event)} className="form-control">
                                                                        <option value="">Select Item...</option>
                                                                        {
                                                                            items.map((row) =>(
                                                                                <option value={row.id_item}>{row.libelle}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="text" name="category" value={input.category} onChange={event => handleFormChange(index, event)} className="form-control" />
                                                                </td>
                                                                <td>
                                                                    <input type="number" name="price" value={input.price} onChange={event => handleFormChange(index, event)} className="form-control" />
                                                                </td>
                                                                <td>
                                                                    {/* <input type="text" name="qtty" value={input.qtty} onChange={event => handleFormChange(index, event)} className="form-control" /> */}
                                                                    <select id="" name="qtty" onChange={event => handleFormChange(index, event)} className="form-control">
                                                                        
                                                                    {[...Array(101)].map((elementInArray, index) => ( 
                                                                        <option value={index}>{index}</option>
                                                                        ) 
                                                                    )}  
                                                                    </select>
                                                                </td>
                                                                
                                                                <td>
                                                                    <input type="number" name="amount" value={input.amount} onChange={event => handleFormChange(index, event)} className="form-control" />
                                                                </td>
                                                                <td>
                                                                    <input type="number" name="discount" value={input.discount} onChange={event => handleFormChange(index, event)} className="form-control" />
                                                                </td>
                                                                <td className="add-remove text-end">
                                                                    
                                                                    <a href="#" onClick={() => removeFields(index)} className="remove-btn"><i className="fas fa-trash fa-2x"></i></a>
                                                                </td>
                                                            </tr>
                                                        {/* )
                                                    }
                                                    return input
                                                }()
                                               
                                             } */}
                                              </>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>

                        <div className="col-auto text-end float-left ms-auto">
                                        <button type="submit" className="btn btn-outline-success me-2 mt-3" >Save Model</button>
                                    </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ModelInvoice;