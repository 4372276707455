import React, { useEffect, useState } from "react";
import Menu from "../../components/Menus";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import services from "../../services/services";
import Swal from "sweetalert2";


function FinancesParameters() {


    ///////////////////////////////////////////////////////////////////////////// Sub Level /////////////////////////////////////////

    /** Activation modal d'insertion Sub Level */
    const [showModalSublevel, setShowModalSublevel] = useState(false)

    function ShowModalSubl() {
        setShowModalSublevel(true); //open modal

    }
    const handleCloseModalSublevel = () => setShowModalSublevel(false) ///close modal
    /** Activation modal d'insertion Sub Level */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSubl, setSubl] = useState({
        id_level: '',
        description: '',
        code: '',
    })

    const handleSublevel = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSubl, [name]: value }
        setSubl(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitSublevel(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataSubl.id_level);
        bodyFormData.append('description', dataSubl.description);
        bodyFormData.append('code', dataSubl.code);

        services.InsertSublevel(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalSublevel();
                getallSublevel();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallSublevel()
        getFirstlevelOpen()
        getSublevelOpen()
    }, [])


    const [dataFirstlevelOpen, setDataFirstlevelOpen] = useState([]);

    function getFirstlevelOpen() {
        services.get_Firstlevel_Open()
            .then((response) => {

                setDataFirstlevelOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataSublevelOpen, setDataSublevelOpen] = useState([]);

    function getSublevelOpen() {
        services.get_Sublevel_Open()
            .then((response) => {

                setDataSublevelOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataSublevel, setDataSublevel] = useState([]);

    function getallSublevel() {
        services.getSublevel()
            .then((response) => {

                setDataSublevel(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsSublevel = [
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateSubl(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSublevel(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteSublevel(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Sub Level",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSublevel(id)
                    .then(response => {
                        console.log(response.data);
                        getallSublevel()
                        Swal.fire(
                            'Data Sub Level has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditSublevel, setShowEditModalSublevel] = useState(false)

    function ShowModalUpdateSubl(id) {
        setShowEditModalSublevel(true);
        dataSublEdit(id)

    }
    const handleEditCloseModalSublevel = () => setShowEditModalSublevel(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditSubl, setDataEditSubl] = useState([])

    function dataSublEdit(id) {
        services.get_Sublevel_by(id)
            .then((response) => {

                setDataEditSubl(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateSublevel(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Subl', id);
        bodyFormData.append('libelle', dataSubl.libelle);

        services.updateSublevel(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallSublevel()
                handleEditCloseModalSublevel()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Sub Level //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Classes /////////////////////////////////////////

    /** Activation modal d'insertion Classes */
    const [showModalClasse, setShowModalClasse] = useState(false)

    function ShowModalClas() {
        setShowModalClasse(true); //open modal

    }
    const handleCloseModalClasse = () => setShowModalClasse(false) ///close modal
    /** Activation modal d'insertion Classes */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataClas, setClas] = useState({
        id_sublevel: '',
        description: '',
    })

    const handleClasse = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataClas, [name]: value }
        setClas(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitClasse(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataClas.id_sublevel);
        bodyFormData.append('libelle', dataClas.libelle);

        services.InsertClasse(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalClasse();
                getallClasse();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallClasse()
        getClasseOpen()

    }, [])

    const [dataClasseOpen, setDataClasseOpen] = useState([]);

        function getClasseOpen() {
            services.get_Classe_Open()
                .then((response) => {

                    setDataClasseOpen(response.data)
                    console.log(response.data);

                })
                .catch((error) => {
                    console.log(error.response);
                })
        }

    const [dataClasse, setDataClasse] = useState([]);

    function getallClasse() {
        services.getClasse()
            .then((response) => {

                setDataClasse(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsClasse = [

        {
            name: "Sub Level",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateClas(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteClasse(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteClasse(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Classes",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteClasse(id)
                    .then(response => {
                        // console.log(response.data);
                        getallClasse()
                        Swal.fire(
                            'Data Classes has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditClasse, setShowEditModalClasse] = useState(false)

    function ShowModalUpdateClas(id) {
        setShowEditModalClasse(true);
        dataClasEdit(id)

    }
    const handleEditCloseModalClasse = () => setShowEditModalClasse(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditClas, setDataEditClas] = useState([])

    function dataClasEdit(id) {
        services.get_Classe_by(id)
            .then((response) => {

                setDataEditClas(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateClasse(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Clas', id);
        bodyFormData.append('libelle', dataClas.libelle);

        services.updateClasse(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallClasse()
                handleEditCloseModalClasse()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Classes //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Section /////////////////////////////////////////

    /** Activation modal d'insertion Section */
    const [showModalSection, setShowModalSection] = useState(false)

    function ShowModalSect() {
        setShowModalSection(true); //open modal

    }
    const handleCloseModalSection = () => setShowModalSection(false) ///close modal
    /** Activation modal d'insertion Section */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSect, setSect] = useState({
        description: '',
    })

    const handleSection = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSect, [name]: value }
        setSect(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitSection(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataSect.description);

        services.InsertSection(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalSection();
                getallSection();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallSection()
    }, [])


    const [dataSection, setDataSection] = useState([]);

    function getallSection() {
        services.getSection()
            .then((response) => {

                setDataSection(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsSection = [

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateSect(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSection(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteSection(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Sections",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSection(id)
                    .then(response => {
                        // console.log(response.data);
                        getallSection()
                        Swal.fire(
                            'Data Sections has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditSection, setShowEditModalSection] = useState(false)

    function ShowModalUpdateSect(id) {
        setShowEditModalSection(true);
        dataSectEdit(id)

    }
    const handleEditCloseModalSection = () => setShowEditModalSection(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditSect, setDataEditSect] = useState([])

    function dataSectEdit(id) {
        services.get_Section_by(id)
            .then((response) => {

                setDataEditSect(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateSection(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Sect', id);
        bodyFormData.append('description', dataSect.description);

        services.updateSection(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallSection()
                handleEditCloseModalSection()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Sections //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Subject /////////////////////////////////////////

    /** Activation modal d'insertion Subject */
    const [showModalSubject, setShowModalSubject] = useState(false)

    function ShowModalSub() {
        setShowModalSubject(true); //open modal

    }
    const handleCloseModalSubject = () => setShowModalSubject(false) ///close modal
    /** Activation modal d'insertion Subject */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSub, setSub] = useState({
        subject: '',
        description: '',
    })

    const handleSubject = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSub, [name]: value }
        setSub(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitSubject(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('subject', dataSub.subject);
        bodyFormData.append('description', dataSub.description);

        services.InsertSubject(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalSubject();
                getallSubject();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallSubject()
        getSubjectOpen()
    }, [])

    const [dataSubjectOpen, setDataSubjectOpen] = useState([]);

    function getSubjectOpen() {
        services.get_Subject_Open()
            .then((response) => {

                setDataSubjectOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataSubject, setDataSubject] = useState([]);

    function getallSubject() {
        services.getSubject()
            .then((response) => {

                setDataSubject(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsSubject = [

        {
            name: "Subject",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.subject} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateSub(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSubject(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteSubject(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Subject",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSubject(id)
                    .then(response => {
                        // console.log(response.data);
                        getallSubject()
                        Swal.fire(
                            'Data Subject has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditSubject, setShowEditModalSubject] = useState(false)

    function ShowModalUpdateSub(id) {
        setShowEditModalSubject(true);
        dataSubEdit(id)

    }
    const handleEditCloseModalSubject = () => setShowEditModalSubject(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditSub, setDataEditSub] = useState([])

    function dataSubEdit(id) {
        services.get_Subject_by(id)
            .then((response) => {

                setDataEditSub(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateSubject(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Sub', id);
        bodyFormData.append('subject', dataSub.subject);
        bodyFormData.append('description', dataSub.description);

        services.updateSubject(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallSubject()
                handleEditCloseModalSubject()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Subject //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// File /////////////////////////////////////////

    /** Activation modal d'insertion File */
    const [showModalFile, setShowModalFile] = useState(false)

    function ShowModalFil() {
        setShowModalFile(true); //open modal

    }
    const handleCloseModalFile = () => setShowModalFile(false) ///close modal
    /** Activation modal d'insertion File */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataFil, setFil] = useState({
        description: '',
    })

    const handleFile = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataFil, [name]: value }
        setFil(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitFile(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataFil.description);

        services.InsertFile(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalFile();
                getallFile();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallFile()
        getFileOpen()
    }, [])

    const [dataFileOpen, setDataFileOpen] = useState([]);

    function getFileOpen() {
        services.get_File_Open()
            .then((response) => {

                setDataFileOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataFile, setDataFile] = useState([]);

    function getallFile() {
        services.getFile()
            .then((response) => {

                setDataFile(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsFile = [

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateFil(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteFile(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteFile(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Files",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteFile(id)
                    .then(response => {
                        // console.log(response.data);
                        getallFile()
                        Swal.fire(
                            'Data Files has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditFile, setShowEditModalFile] = useState(false)

    function ShowModalUpdateFil(id) {
        setShowEditModalFile(true);
        dataFilEdit(id)

    }
    const handleEditCloseModalFile = () => setShowEditModalFile(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditFil, setDataEditFil] = useState([])

    function dataFilEdit(id) {
        services.get_File_by(id)
            .then((response) => {

                setDataEditFil(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateFile(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Fil', id);
        bodyFormData.append('description', dataFil.description);

        services.updateFile(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallFile()
                handleEditCloseModalFile()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Files //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Required File /////////////////////////////////////////

    /** Activation modal d'insertion Required File */
    const [showModalRequired, setShowModalRequired] = useState(false)

    function ShowModalReq() {
        setShowModalRequired(true); //open modal

    }
    const handleCloseModalRequired = () => setShowModalRequired(false) ///close modal
    /** Activation modal d'insertion Required File */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataReq, setReq] = useState({
        id_aca_year: '',
        id_first_level: '',
        id_file: '',
    })

    const handleRequired = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataReq, [name]: value }
        setReq(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitRequired(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataReq.id_aca_year);
        bodyFormData.append('libelle', dataReq.id_first_level);
        bodyFormData.append('description', dataReq.id_file);

        services.InsertRequired(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalRequired();
                getallRequired();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallRequired()
        getAcademicOpen()
    }, [])

    const [dataAcademicOpen, setDataAcademicOpen] = useState([]);

    function getAcademicOpen() {
        services.get_Academic_Open()
            .then((response) => {

                setDataAcademicOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataRequired, setDataRequired] = useState([]);

    function getallRequired() {
        services.getRequired()
            .then((response) => {

                setDataRequired(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsRequired = [

        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                {/* <Link to={"#"} onClick={(e) => ShowModalUpdateReq(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to="#" onClick={(e) => DeleteRequired(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteRequired(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Required File",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteRequired(id)
                    .then(response => {
                        console.log(response.data);
                        getallRequired()
                        Swal.fire(
                            'Data Required File has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    // const [showModalEditRequired, setShowEditModalRequired] = useState(false)

    // function ShowModalUpdateReq(id) {
    //     setShowEditModalRequired(true);
    //     dataReqEdit(id)

    // }
    // const handleEditCloseModalRequired = () => setShowEditModalRequired(false)
    // /**Activation du modale pour l'update */


    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    // const [dataEditReq, setDataEditReq] = useState([])

    // function dataReqEdit(id) {
    //     services.get_Required_by(id)
    //         .then((response) => {

    //             setDataEditReq(response.data[0])
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    // /**function de modification des donnée recuperé en fonction de l'ID */
    // function handleUpdateRequired(e, id) {
    //     e.preventDefault();

    //     const bodyFormData = new FormData();

    //     bodyFormData.append('id_Req', id);

    //     services.updateRequired(bodyFormData)
    //         .then((response) => {
    //             Swal.fire({
    //                 position: 'top-end',
    //                 icon: 'success',
    //                 title: response.data.message,
    //                 showConfirmButton: false,
    //                 timer: 5000
    //             })
    //             console.log(response.data)
    //             getallRequired()
    //             handleEditCloseModalRequired()

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }
    // /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Required File //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Placement Test Subject /////////////////////////////////////////

    /** Activation modal d'insertion Placement Test Subject */
    const [showModalPlacement, setShowModalPlacement] = useState(false)

    function ShowModalPlace() {
        setShowModalPlacement(true); //open modal

    }
    const handleCloseModalPlacement = () => setShowModalPlacement(false) ///close modal
    /** Activation modal d'insertion Placement Test Subject */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataPlace, setPlace] = useState({
        id_aca_year: '',
        id_first_level: '',
        id_subject: '',
    })

    const handlePlacement = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataPlace, [name]: value }
        setPlace(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitPlacement(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataPlace.id_aca_year);
        bodyFormData.append('libelle', dataPlace.id_first_level);
        bodyFormData.append('description', dataPlace.id_subject);

        services.InsertPlacement(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalPlacement();
                getallPlacement();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallPlacement()
    }, [])


    const [dataPlacement, setDataPlacement] = useState([]);

    function getallPlacement() {
        services.getPlacement()
            .then((response) => {

                setDataPlacement(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsPlacement = [

        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                {/* <Link to={"#"} onClick={(e) => ShowModalUpdatePlace(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to="#" onClick={(e) => DeletePlacement(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeletePlacement(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Placement Test Subject",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deletePlacement(id)
                    .then(response => {
                        // console.log(response.data);
                        getallPlacement()
                        Swal.fire(
                            'Data Placement Test Subject has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    // const [showModalEditPlacement, setShowEditModalPlacement] = useState(false)

    // function ShowModalUpdatePlace(id) {
    //     setShowEditModalPlacement(true);
    //     dataPlaceEdit(id)

    // }
    // const handleEditCloseModalPlacement = () => setShowEditModalPlacement(false)
    // /**Activation du modale pour l'update */


    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    // const [dataEditPlace, setDataEditPlace] = useState([])

    // function dataPlaceEdit(id) {
    //     services.get_Placement_by(id)
    //         .then((response) => {

    //             setDataEditPlace(response.data[0])
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    // /**function de modification des donnée recuperé en fonction de l'ID */
    // function handleUpdatePlacement(e, id) {
    //     e.preventDefault();

    //     const bodyFormData = new FormData();

    //     bodyFormData.append('id_Place', id);

    //     services.updatePlacement(bodyFormData)
    //         .then((response) => {
    //             Swal.fire({
    //                 position: 'top-end',
    //                 icon: 'success',
    //                 title: response.data.message,
    //                 showConfirmButton: false,
    //                 timer: 5000
    //             })
    //             console.log(response.data)
    //             getallPlacement()
    //             handleEditCloseModalPlacement()

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }
    // /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Placement Test Subject //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Name Behaviours /////////////////////////////////////////

    /** Activation modal d'insertion Name Behaviours */
    const [showModalNameBehaviour, setShowModalNameBehaviour] = useState(false)

    function ShowModalNameB() {
        setShowModalNameBehaviour(true); //open modal

    }
    const handleCloseModalNameBehaviour = () => setShowModalNameBehaviour(false) ///close modal
    /** Activation modal d'insertion Name Behaviours */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataNameB, setNameB] = useState({
        description: '',
    })

    const handleNameBehaviour = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataNameB, [name]: value }
        setNameB(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitNameBehaviour(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataNameB.description);

        services.InsertNameBehaviour(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalNameBehaviour();
                getallNameBehaviour();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallNameBehaviour()
        getNameBehaviourOpen()
    }, [])

    const [dataNameBehaviourOpen, setDataNameBehaviourOpen] = useState([]);

    function getNameBehaviourOpen() {
        services.get_NameBehaviour_Open()
            .then((response) => {

                setDataNameBehaviourOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataNameBehaviour, setDataNameBehaviour] = useState([]);

    function getallNameBehaviour() {
        services.getNameBehaviour()
            .then((response) => {

                setDataNameBehaviour(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsNameBehaviour = [

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateNameB(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteNameBehaviour(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteNameBehaviour(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Name Behaviours",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteNameBehaviour(id)
                    .then(response => {
                        console.log(response.data);
                        getallNameBehaviour()
                        Swal.fire(
                            'Data Name Behaviours has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE //////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditNameBehaviour, setShowEditModalNameBehaviour] = useState(false)

    function ShowModalUpdateNameB(id) {
        setShowEditModalNameBehaviour(true);
        dataNameBehaviourdit(id)

    }
    const handleEditCloseModalNameBehaviour = () => setShowEditModalNameBehaviour(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditNameB, setDataEditNameB] = useState([])

    function dataNameBehaviourdit(id) {
        services.get_NameBehaviour_by(id)
            .then((response) => {

                setDataEditNameB(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateNameBehaviour(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_NameB', id);
        bodyFormData.append('description', dataNameB.description);

        services.updateNameBehaviour(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallNameBehaviour()
                handleEditCloseModalNameBehaviour()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Name Behaviours //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Behaviours //////////////////////////////////////////

    /** Activation modal d'insertion Behaviours  */
    const [showModalBehaviour, setShowModalBehaviour] = useState(false)

    function ShowModalBeha() {
        getNameBehaviourOpen()
        setShowModalBehaviour(true); //open modal

    }
    const handleCloseModalBehaviour = () => setShowModalBehaviour(false) ///close modal
    /** Activation modal d'insertion Behaviours  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataBeha, setBeha] = useState({
        id_aca_year: '',
        id_first_level: '',
    })

    

    const handleBehaviour = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataBeha, [name]: value }
        setBeha(data);
        // console.log(data);

    }

    const [dataNABeha, setNABeha] = useState({
        id_namebeha: [],
    })

    const handleNABehaviour = (e) => {
        const { value, checked } = e.target;
        const { id_namebeha } = dataNABeha;

        // const name = e.target.name;
        // const data = { ...dataSiz, [name]: value }
        // setBeha(data);
        // console.log(`${value} is ${checked}`);
        // console.log(value);

        if (checked) {
            setNABeha({
                id_namebeha: [...id_namebeha, value],
            });
        }
        else {
            setNABeha({
                // id_size: id_size.filter((e) => e !== value),
                id_namebeha: "",
            });
          }
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitBehaviour(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataBeha.id_aca_year);
        bodyFormData.append('libelle', dataBeha.id_first_level);
        bodyFormData.append('description', dataNABeha.id_namebeha);

        services.InsertBehaviour(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalBehaviour();
                getallBehaviour();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallBehaviour()
        // getBehaviourOpen()
    }, [])

    // const [dataBehaviourOpen, setDataBehaviourOpen] = useState([]);

    // function getBehaviourOpen() {
    //     services.get_Behaviour_Open()
    //         .then((response) => {

    //             setDataBehaviourOpen(response.data)
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    const [dataBehaviour, setDataBehaviour] = useState([]);

    function getallBehaviour() {
        services.getBehaviour()
            .then((response) => {

                setDataBehaviour(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsBehaviour = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                {/* <Link to={"#"} onClick={(e) => ShowModalUpdateBeha(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to="#" onClick={(e) => DeleteBehaviour(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteBehaviour(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Behaviours setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteBehaviour(id)
                    .then(response => {
                        // console.log(response.data);
                        getallBehaviour()
                        Swal.fire(
                            'Data Items Behaviours setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    // /**Activation du modale pour l'update */
    // const [showModalEditBehaviour, setShowEditModalBehaviour] = useState(false)

    // function ShowModalUpdateBeha(id) {
    //     setShowEditModalBehaviour(true);
    //     dataBehaviourdit(id)

    // }
    // const handleEditCloseModalBehaviour = () => setShowEditModalBehaviour(false)
    // /**Activation du modale pour l'update */


    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    // const [dataEditBeha, setDataEditBeha] = useState([])

    // function dataBehaviourdit(id) {
    //     services.get_Behaviour_by(id)
    //         .then((response) => {

    //             setDataEditBeha(response.data[0])
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    // /**function de modification des donnée recuperé en fonction de l'ID */
    // function handleUpdateBehaviour(e, id) {
    //     e.preventDefault();

    //     const bodyFormData = new FormData();

    //     bodyFormData.append('id_Beha', id);
    //     bodyFormData.append('year', dataBeha.id_aca_year);
    //     bodyFormData.append('libelle', dataBeha.id_first_level);
    //     bodyFormData.append('description', dataNABeha.id_namebeha);

    //     services.updateBehaviour(bodyFormData)
    //         .then((response) => {
    //             Swal.fire({
    //                 position: 'top-end',
    //                 icon: 'success',
    //                 title: response.data.message,
    //                 showConfirmButton: false,
    //                 timer: 5000
    //             })
                // console.log(response.data)
    //             getallBehaviour()
    //             handleEditCloseModalBehaviour()

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }
    // /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Behaviours //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Name Effort Levels /////////////////////////////////////////

    /** Activation modal d'insertion Name Effort Levels */
    const [showModalNameEffort, setShowModalNameEffort] = useState(false)

    function ShowModalNameEf() {
        getNameEffortOpen()
        setShowModalNameEffort(true); //open modal

    }
    const handleCloseModalNameEffort = () => setShowModalNameEffort(false) ///close modal
    /** Activation modal d'insertion Name Effort Levels */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataNameEf, setNameEf] = useState({
        description: '',
        code: '',
        mark: '',
    })

    const handleNameEffort = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataNameEf, [name]: value }
        setNameEf(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitNameEffort(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataNameEf.description);
        bodyFormData.append('code', dataNameEf.code);
        bodyFormData.append('mark', dataNameEf.mark);

        services.InsertNameEffort(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalNameEffort();
                getallNameEffort();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallNameEffort()
        getNameEffortOpen()
    }, [])

    const [dataNameEffortOpen, setDataNameEffortOpen] = useState([]);

    function getNameEffortOpen() {
        services.get_NameEffort_Open()
            .then((response) => {

                setDataNameEffortOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataNameEffort, setDataNameEffort] = useState([]);

    function getallNameEffort() {
        services.getNameEffort()
            .then((response) => {

                setDataNameEffort(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsNameEffort = [

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Code",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Mark",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.mark} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateNameEf(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteNameEffort(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteNameEffort(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Name Effort Levels",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteNameEffort(id)
                    .then(response => {
                        // console.log(response.data);
                        getallNameEffort()
                        Swal.fire(
                            'Data Name Effort Levels has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE //////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditNameEffort, setShowEditModalNameEffort] = useState(false)

    function ShowModalUpdateNameEf(id) {
        setShowEditModalNameEffort(true);
        dataNameEffortdit(id)

    }
    const handleEditCloseModalNameEffort = () => setShowEditModalNameEffort(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditNameEf, setDataEditNameEf] = useState([])

    function dataNameEffortdit(id) {
        services.get_NameEffort_by(id)
            .then((response) => {

                setDataEditNameEf(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateNameEffort(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_NameEf', id);
        bodyFormData.append('description', dataNameEf.description);
        bodyFormData.append('code', dataNameEf.code);
        bodyFormData.append('mark', dataNameEf.mark);

        services.updateNameEffort(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallNameEffort()
                handleEditCloseModalNameEffort()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Name Effort Levels //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Effort Levels //////////////////////////////////////////

    /** Activation modal d'insertion Effort Levels  */
    const [showModalEffortLevel, setShowModalEffortLevel] = useState(false)

    function ShowModalEffort() {
        getNameEffortOpen()
        setShowModalEffortLevel(true); //open modal

    }
    const handleCloseModalEffortLevel = () => setShowModalEffortLevel(false) ///close modal
    /** Activation modal d'insertion Effort Levels  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataEffort, setEffort] = useState({
        id_aca_year: '',
        id_first_level: '',
    })

    

    const handleEffortLevel = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataEffort, [name]: value }
        setEffort(data);
        // console.log(data);

    }

    const [dataDEEffort, setNAEffort] = useState({
        id_nameef: [],
    })

    const handleDEEffortLevel = (e) => {
        const { value, checked } = e.target;
        const { id_nameef } = dataDEEffort;

        // const name = e.target.name;
        // const data = { ...dataSiz, [name]: value }
        // setEffort(data);
        // console.log(`${value} is ${checked}`);
        // console.log(value);

        if (checked) {
            setNAEffort({
                id_nameef: [...id_nameef, value],
            });
        }
        else {
            setNAEffort({
                // id_size: id_size.filter((e) => e !== value),
                id_nameef: "",
            });
          }
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitEffortLevel(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataEffort.id_aca_year);
        bodyFormData.append('libelle', dataEffort.id_first_level);
        bodyFormData.append('description', dataDEEffort.id_nameef);

        services.InsertEffortLevel(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalEffortLevel();
                getallEffortLevel();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallEffortLevel()
        // getEffortLevelOpen()
    }, [])

    // const [dataEffortLevelOpen, setDataEffortLevelOpen] = useState([]);

    // function getEffortLevelOpen() {
    //     services.get_EffortLevel_Open()
    //         .then((response) => {

    //             setDataEffortLevelOpen(response.data)
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    const [dataEffortLevel, setDataEffortLevel] = useState([]);

    function getallEffortLevel() {
        services.getEffortLevel()
            .then((response) => {

                setDataEffortLevel(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsEffortLevel = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateEffort(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteEffortLevel(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteEffortLevel(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Effort Levels setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteEffortLevel(id)
                    .then(response => {
                        // console.log(response.data);
                        getallEffortLevel()
                        Swal.fire(
                            'Data Items Effort Levels setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditEffortLevel, setShowEditModalEffortLevel] = useState(false)

    function ShowModalUpdateEffort(id) {
        setShowEditModalEffortLevel(true);
        dataEffortLeveldit(id)

    }
    const handleEditCloseModalEffortLevel = () => setShowEditModalEffortLevel(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditEffort, setDataEditEffort] = useState([])

    function dataEffortLeveldit(id) {
        services.get_EffortLevel_by(id)
            .then((response) => {

                setDataEditEffort(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateEffortLevel(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Effort', id);
        bodyFormData.append('libelle', dataEffort.id_first_level);
        bodyFormData.append('description', dataDEEffort.id_nameef);

        services.updateEffortLevel(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallEffortLevel()
                handleEditCloseModalEffortLevel()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Effort Levels //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Name Grading Scale /////////////////////////////////////////

    /** Activation modal d'insertion Name Grading Scale */
    const [showModalNameGrading, setShowModalNameGrading] = useState(false)

    function ShowModalGrading() {
        setShowModalNameGrading(true); //open modal

    }
    const handleCloseModalNameGrading = () => setShowModalNameGrading(false) ///close modal
    /** Activation modal d'insertion Name Grading Scale */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataGrading, setGrading] = useState({
        grade: '',
        remark: '',
        maximun: '',
        minimun: '',
    })

    const handleNameGrading = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataGrading, [name]: value }
        setGrading(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitNameGrading(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('grade', dataGrading.grade);
        bodyFormData.append('remark', dataGrading.remark);
        bodyFormData.append('maximun', dataGrading.maximun);
        bodyFormData.append('minimun', dataGrading.minimun);

        services.InsertNameGrading(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalNameGrading();
                getallNameGrading();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */
 

    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallNameGrading()
        getNameGradingOpen()
    }, [])

    const [dataNameGradingOpen, setDataNameGradingOpen] = useState([]);

    function getNameGradingOpen() {
        services.get_NameGrading_Open()
            .then((response) => {

                setDataNameGradingOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataNameGrading, setDataNameGrading] = useState([]);

    function getallNameGrading() {
        services.getNameGrading()
            .then((response) => {

                setDataNameGrading(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsNameGrading = [

        {
            name: "Grade",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.grade} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Remark",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.remark} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Max",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.maximun} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Min",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.minimun} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateGrading(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteNameGrading(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteNameGrading(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Name Grading Scale",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteNameGrading(id)
                    .then(response => {
                        // console.log(response.data);
                        getallNameGrading()
                        Swal.fire(
                            'Data Name Grading Scale has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE //////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditNameGrading, setShowEditModalNameGrading] = useState(false)

    function ShowModalUpdateGrading(id) {
        setShowEditModalNameGrading(true);
        dataNameGradingdit(id)

    }
    const handleEditCloseModalNameGrading = () => setShowEditModalNameGrading(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditGrading, setDataEditGrading] = useState([])

    function dataNameGradingdit(id) {
        services.get_NameGrading_by(id)
            .then((response) => {

                setDataEditGrading(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateNameGrading(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Grading', id);
        bodyFormData.append('grade', dataGrading.grade);
        bodyFormData.append('remark', dataGrading.remark);
        bodyFormData.append('maximun', dataGrading.maximun);
        bodyFormData.append('minimun', dataGrading.minimun);

        services.updateNameGrading(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallNameGrading()
                handleEditCloseModalNameGrading()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Name Grading Scale //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Grading Scale //////////////////////////////////////////

    /** Activation modal d'insertion Grading Scale  */
    const [showModalGradingScale, setShowModalGradingScale] = useState(false)

    function ShowModalScale() {
        getNameGradingOpen()
        setShowModalGradingScale(true); //open modal

    }
    const handleCloseModalGradingScale = () => setShowModalGradingScale(false) ///close modal
    /** Activation modal d'insertion Grading Scale  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataScale, setScale] = useState({
        id_aca_year: '',
        id_first_level: '',
    })

    

    const handleGradingScale = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataScale, [name]: value }
        setScale(data);
        console.log(data);

    }

    const [dataDEScale, setNAScale] = useState({
        id_name_grading: [],
    })

    const handleDEGradingScale = (e) => {
        const { value, checked } = e.target;
        const { id_name_grading } = dataDEScale;

        // const name = e.target.name;
        // const data = { ...dataSiz, [name]: value }
        // setScale(data);
        // console.log(`${value} is ${checked}`);
        // console.log(value);

        if (checked) {
            setNAScale({
                id_name_grading: [...id_name_grading, value],
            });
        }
        else {
            setNAScale({
                // id_size: id_size.filter((e) => e !== value),
                id_name_grading: "",
            });
          }
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitGradingScale(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataScale.id_aca_year);
        bodyFormData.append('libelle', dataScale.id_first_level);
        bodyFormData.append('remark', dataDEScale.id_name_grading);

        services.InsertGradingScale(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalGradingScale();
                getallGradingScale();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallGradingScale()
        // getGradingScaleOpen()
    }, [])

    // const [dataGradingScaleOpen, setDataGradingScaleOpen] = useState([]);

    // function getGradingScaleOpen() {
    //     services.get_GradingScale_Open()
    //         .then((response) => {

    //             setDataGradingScaleOpen(response.data)
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    const [dataGradingScale, setDataGradingScale] = useState([]);

    function getallGradingScale() {
        services.getGradingScale()
            .then((response) => {

                setDataGradingScale(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsGradingScale = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Remark",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.remark} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateScale(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteGradingScale(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteGradingScale(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Grading Scale setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteGradingScale(id)
                    .then(response => {
                        // console.log(response.data);
                        getallGradingScale()
                        Swal.fire(
                            'Data Items Grading Scale setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditGradingScale, setShowEditModalGradingScale] = useState(false)

    function ShowModalUpdateScale(id) {
        setShowEditModalGradingScale(true);
        dataGradingScaledit(id)

    }
    const handleEditCloseModalGradingScale = () => setShowEditModalGradingScale(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditScale, setDataEditScale] = useState([])

    function dataGradingScaledit(id) {
        services.get_GradingScale_by(id)
            .then((response) => {

                setDataEditScale(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateGradingScale(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Scale', id);
        bodyFormData.append('libelle', dataScale.id_first_level);
        bodyFormData.append('remark', dataDEScale.id_name_grading);

        services.updateGradingScale(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallGradingScale()
                handleEditCloseModalGradingScale()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Grading Scale //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Nursery Theme /////////////////////////////////////////

        /** Activation modal d'insertion Nursery Theme */
        const [showModalNurseryTheme, setShowModalNurseryTheme] = useState(false)

        function ShowModalNursery() {
            setShowModalNurseryTheme(true); //open modal

        }
        const handleCloseModalNurseryTheme = () => setShowModalNurseryTheme(false) ///close modal
        /** Activation modal d'insertion NurseryTheme */


        /** variable de recuperation des donner entrant dans les cahmp input */
        const [inputFields, setinputFields] = useState([{
            theme: '',
        }])

        const addInputField = (e) =>{
            setinputFields ([ ...inputFields, {
                theme : '' ,
            } ]) 
          
        }

        const removeInputinputFields = (index)=>{
            const rows = [...inputFields];
            rows.splice(index, 1);
            setinputFields(rows);
       }

        const handleNurseryTheme = (index, e) => {
            
            const { name, value } = e.target;
            const list = [...inputFields];
            list[index][name] = value;
            setinputFields(list);

            console.log(list)
        }

      
        /** variable de recuperation des donner entrant dans les cahmp input */


        /** fonction d'insertion des données dans la base de donnée */
        function handleSubmitNurseryTheme(e) {
            e.preventDefault();

            const bodyFormData = new FormData();

            bodyFormData.append('themes', JSON.stringify(inputFields));

            services.InsertNurseryTheme(bodyFormData)
                    .then((response) => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000
                        })
                        // console.log(response.data)
                        handleCloseModalNurseryTheme();
                        getallNurseryTheme();
    
                    })
                    .catch((error) => {
                        // console.log(error.response);
                    })

        }

        /** fonction d'insertion des donné dans la base de donnée */


        /** Affichage des donnée enregistré */
        useEffect(() => {
            getallNurseryTheme()
            getNurseryThemeOpen()
        }, [])

        const [dataNurseryThemeOpen, setDataNurseryThemeOpen] = useState([]);

        function getNurseryThemeOpen() {
            services.get_NurseryTheme_Open()
                .then((response) => {

                    setDataNurseryThemeOpen(response.data)
                    // console.log(response.data);

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }

        const [dataNurseryTheme, setDataNurseryTheme] = useState([]);

        function getallNurseryTheme() {
            services.getNurseryTheme()
                .then((response) => {

                    setDataNurseryTheme(response.data)
                    // console.log(response.data);

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }


        const columnsNurseryTheme = [

            {
                name: "Description",
                cell: (rows) => (
                    <b style={{ color: "black" }}> {rows.theme} </b>
                ),
                sortable: true,
                reorder: true
            },

            {
                name: "Actions",
                cell: (rows) => (
                    <div className="actions">
                        {(() => {
                            return (
                                <>
                                    <Link to={"#"} onClick={(e) => ShowModalUpdateNursery(rows.id)} className="btn btn-sm bg-success-light me-2">
                                        <i className="fas fa-edit"></i>
                                    </Link>
                                    <Link to="#" onClick={(e) => DeleteNurseryTheme(rows.id)} className="btn btn-sm bg-danger-light">
                                        <i className="fas fa-trash"></i>
                                    </Link>
                                </>
                            )



                        })()}
 
                    </div>

                ),
                sortable: true,
                reorder: true
            },
        ]
        /** Affichage des donnée enregistré */


        /** Suppression un élement dans la base de donée */

        function DeleteNurseryTheme(id) {
            Swal.fire({
                title: 'Are you sure?',
                html: "to delete data NurseryTheme",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    services.deleteNurseryTheme(id)
                        .then(response => {
                            // console.log(response.data);
                            getallNurseryTheme()
                            Swal.fire(
                                'Data NurseryTheme has been deleted',
                                'success'
                            )
                        })
                        .catch(e => {
                            console.log(e);
                        });

                }
            })
        }

        /** Suppression un élement dans la base de donée */

        ////////////////////////////////////// UPDATE//////////////////////////////

        /**Activation du modale pour l'update */
        const [showModalEditNurseryTheme, setShowEditModalNurseryTheme] = useState(false)

        function ShowModalUpdateNursery(id) {
            setShowEditModalNurseryTheme(true);
            dataNurseryEdit(id)

        }
        const handleEditCloseModalNurseryTheme = () => setShowEditModalNurseryTheme(false)
        /**Activation du modale pour l'update */


        /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
        const [dataEditNursery, setDataEditNursery] = useState([])

        function dataNurseryEdit(id) {
            services.get_NurseryTheme_by(id)
                .then((response) => {

                    setDataEditNursery(response.data[0])
                    // console.log(response.data);

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }

        /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


        /**function de modification des donnée recuperé en fonction de l'ID */
        function handleUpdateNurseryTheme(e, id) {
            e.preventDefault();

            const bodyFormData = new FormData();

            bodyFormData.append('id_Nursery', id);
            bodyFormData.append('theme', inputFields.theme);

            services.updateNurseryTheme(bodyFormData)
                .then((response) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    // console.log(response.data)
                    getallNurseryTheme()
                    handleEditCloseModalNurseryTheme()

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }
        /**function de modification des donnée recuperé en fonction de l'ID */

        
        
    ///////////////////////////////////////////////////////////////////////////// Nursery Theme //////////////////////////////////////////
      
    ///////////////////////////////////////////////////////////////////////////// Nursery Sub Theme /////////////////////////////////////////

        /** Activation modal d'insertion Nursery Sub Theme */
        const [showModalNursSubTheme, setShowModalNursSubTheme] = useState(false)

        function ShowModalNursSub() {
            setShowModalNursSubTheme(true); //open modal

        }
        const handleCloseModalNursSubTheme = () => setShowModalNursSubTheme(false) ///close modal
        /** Activation modal d'insertion Nursery Sub Theme */


        /** variable de recuperation des donner entrant dans les cahmp input */
        const [inputSubFields, setinputSubFields] = useState([{
            subtheme: '',
        }])

        const addInputSubField = () =>{
            setinputSubFields ([ ...inputSubFields, {
                subtheme : '' ,
            } ]) 
          
        }

        const removeInputinputSubFields = (index)=>{
            const rows = [...inputSubFields];
            rows.splice(index, 1);
            setinputSubFields(rows);
       }

        const handleNursSubTheme = (index, e) => {
            
            const { name, value } = e.target;
            const list = [...inputSubFields];
            list[index][name] = value;
            setinputSubFields(list);

            console.log(list)
        }
        

        /** variable de recuperation des donner entrant dans les cahmp input */


        /** fonction d'insertion des données dans la base de donnée */
        function handleSubmitNursSubTheme(e) {
            e.preventDefault();

            const bodyFormData = new FormData();

            bodyFormData.append('subtheme', JSON.stringify(inputSubFields));

            services.InsertNursSubTheme(bodyFormData)
                .then((response) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    // console.log(response.data)
                    handleCloseModalNursSubTheme();
                    getallNursSubTheme();

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }

        /** fonction d'insertion des donné dans la base de donnée */


        /** Affichage des donnée enregistré */
        useEffect(() => {
            getallNursSubTheme()
            getNursSubThemeOpen()
        }, [])

        const [dataNursSubThemeOpen, setDataNursSubThemeOpen] = useState([]);

        function getNursSubThemeOpen() {
            services.get_NursSubTheme_Open()
                .then((response) => {

                    setDataNursSubThemeOpen(response.data)
                    // console.log(response.data);

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }

        const [dataNursSubTheme, setDataNursSubTheme] = useState([]);

        function getallNursSubTheme() {
            services.getNursSubTheme()
                .then((response) => {

                    setDataNursSubTheme(response.data)
                    // console.log(response.data);

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }


        const columnsNursSubTheme = [

            {
                name: "Description",
                cell: (rows) => (
                    <b style={{ color: "black" }}> {rows.subtheme} </b>
                ),
                sortable: true,
                reorder: true
            },

            {
                name: "Actions",
                cell: (rows) => (
                    <div className="actions">
                        {(() => {
                            return (
                                <>
                                    <Link to={"#"} onClick={(e) => ShowModalUpdateNursSub(rows.id)} className="btn btn-sm bg-success-light me-2">
                                        <i className="fas fa-edit"></i>
                                    </Link>
                                    <Link to="#" onClick={(e) => DeleteNursSubTheme(rows.id)} className="btn btn-sm bg-danger-light">
                                        <i className="fas fa-trash"></i>
                                    </Link>
                                </>
                            )



                        })()}
 
                    </div>

                ),
                sortable: true,
                reorder: true
            },
        ]
        /** Affichage des donnée enregistré */


        /** Suppression un élement dans la base de donée */

        function DeleteNursSubTheme(id) {
            Swal.fire({
                title: 'Are you sure?',
                html: "to delete data Nursery Sub Theme",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    services.deleteNursSubTheme(id)
                        .then(response => {
                            // console.log(response.data);
                            getallNursSubTheme()
                            Swal.fire(
                                'Data Nursery Sub Theme has been deleted',
                                'success'
                            )
                        })
                        .catch(e => {
                            console.log(e);
                        });

                }
            })
        }

        /** Suppression un élement dans la base de donée */

        ////////////////////////////////////// UPDATE//////////////////////////////

        /**Activation du modale pour l'update */
        const [showModalEditNursSubTheme, setShowEditModalNursSubTheme] = useState(false)

        function ShowModalUpdateNursSub(id) {
            setShowEditModalNursSubTheme(true);
            dataNursSubEdit(id)

        }
        const handleEditCloseModalNursSubTheme = () => setShowEditModalNursSubTheme(false)
        /**Activation du modale pour l'update */


        /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
        const [dataEditNursSub, setDataEditNursSub] = useState([])

        function dataNursSubEdit(id) {
            services.get_NursSubTheme_by(id)
                .then((response) => {

                    setDataEditNursSub(response.data[0])
                    // console.log(response.data);

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }

        /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


        /**function de modification des donnée recuperé en fonction de l'ID */
        function handleUpdateNursSubTheme(e, id) {
            e.preventDefault();

            const bodyFormData = new FormData();

            bodyFormData.append('id_NursSub', id);
            bodyFormData.append('subtheme', inputSubFields.subtheme);

            services.updateNursSubTheme(bodyFormData)
                .then((response) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    // console.log(response.data)
                    getallNursSubTheme()
                    handleEditCloseModalNursSubTheme()

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }
        /**function de modification des donnée recuperé en fonction de l'ID */

        
        
    ///////////////////////////////////////////////////////////////////////////// Nursery Sub Theme //////////////////////////////////////////
    
    ///////////////////////////////////////////////////////////////////////////// Nursery Report //////////////////////////////////////////

    /** Activation modal d'insertion Nursery Report  */
    const [showModalNurseryReport, setShowModalNurseryReport] = useState(false)

    function ShowModalNursReport() {
        getNursSubThemeOpen()
        getNurseryThemeOpen()
        setShowModalNurseryReport(true); //open modal

    }
    const handleCloseModalNurseryReport = () => setShowModalNurseryReport(false) ///close modal
    /** Activation modal d'insertion Nursery Report  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataNursReport, setNursReport] = useState({
        id_nurs_theme: '',
        id_nurs_sub: '',
    })

    

    const handleNurseryReport = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataNursReport, [name]: value }
        setNursReport(data);
        // console.log(data);
    }

    const [dataNRNursReport, setNRNursReport] = useState({
        id_namebeha: [],
    })

    const handleNRNurseryReport = (e) => {
        const { value, checked } = e.target;
        const { id_namebeha } = dataNRNursReport;

        // const name = e.target.name;
        // const data = { ...dataSiz, [name]: value }
        // setScale(data);
        // console.log(`${value} is ${checked}`);
        // console.log(value);

        if (checked) {
            setNRNursReport({
                id_namebeha: [...id_namebeha, value],
            });
        }
        else {
            setNRNursReport({
                // id_size: id_size.filter((e) => e !== value),
                id_namebeha: "",
            });
          }
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitNurseryReport(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('therm', dataNursReport.id_nurs_theme);
        bodyFormData.append('subtherm', dataNursReport.id_nurs_sub);
        bodyFormData.append('behaviour', dataNRNursReport.id_namebeha);

        services.InsertNurseryReport(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalNurseryReport();
                getallNurseryReport();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallNurseryReport()
        // getNurseryReportOpen()
    }, [])

    // const [dataNurseryReportOpen, setDataNurseryReportOpen] = useState([]);

    // function getNurseryReportOpen() {
    //     services.get_NurseryReport_Open()
    //         .then((response) => {

    //             setDataNurseryReportOpen(response.data)
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    const [dataNurseryReport, setDataNurseryReport] = useState([]);

    function getallNurseryReport() {
        services.getNurseryReport()
            .then((response) => {

                setDataNurseryReport(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsNurseryReport = [
        {
            name: "Term",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.theme} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Subterm",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.subtheme} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Behaviour",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateNursReport(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteNurseryReport(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteNurseryReport(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Nursery Report setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteNurseryReport(id)
                    .then(response => {
                        // console.log(response.data);
                        getallNurseryReport()
                        Swal.fire(
                            'Data Items Nursery Report setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditNurseryReport, setShowEditModalNurseryReport] = useState(false)

    function ShowModalUpdateNursReport(id) {
        setShowEditModalNurseryReport(true);
        dataNurseryReportdit(id)

    }
    const handleEditCloseModalNurseryReport = () => setShowEditModalNurseryReport(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditNursReport, setDataEditNursReport] = useState([])

    function dataNurseryReportdit(id) {
        services.get_NurseryReport_by(id)
            .then((response) => {

                setDataEditNursReport(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateNurseryReport(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_NursReport', id);
        bodyFormData.append('therm', dataNursReport.id_nurs_theme);
        bodyFormData.append('subtherm', dataNursReport.id_nurs_sub);
        bodyFormData.append('behaviour', dataNursReport.id_namebeha);

        services.updateNurseryReport(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallNurseryReport()
                handleEditCloseModalNurseryReport()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Nursery Report //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Evaluation Name //////////////////////////////////////////

    /** Activation modal d'insertion Evaluation Name  */
    const [showModalEvaluation, setShowModalEvaluation] = useState(false)

    function ShowModalEval() {
        setShowModalEvaluation(true); //open modal

    }
    const handleCloseModalEvaluation = () => setShowModalEvaluation(false) ///close modal
    /** Activation modal d'insertion Evaluation Name  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataEval, setEval] = useState({
        description: '',
    })

    
    const handleEvaluation = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataEval, [name]: value }
        setEval(data);
        console.log(data);

    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitEvaluation(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataEval.description);

        services.InsertEvaluation(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalEvaluation();
                getallEvaluation();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallEvaluation()
        getEvaluationOpen()
    }, [])

    const [dataEvaluationOpen, setDataEvaluationOpen] = useState([]);

    function getEvaluationOpen() {
        services.get_Evaluation_Open()
            .then((response) => {

                setDataEvaluationOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataEvaluation, setDataEvaluation] = useState([]);

    function getallEvaluation() {
        services.getEvaluation()
            .then((response) => {

                setDataEvaluation(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsEvaluation = [
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateEval(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteEvaluation(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteEvaluation(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Evaluation Name setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteEvaluation(id)
                    .then(response => {
                        // console.log(response.data);
                        getallEvaluation()
                        Swal.fire(
                            'Data Items Evaluation Name setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditEvaluation, setShowEditModalEvaluation] = useState(false)

    function ShowModalUpdateEval(id) {
        setShowEditModalEvaluation(true);
        dataEvaluationdit(id)

    }
    const handleEditCloseModalEvaluation = () => setShowEditModalEvaluation(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditEval, setDataEditEval] = useState([])

    function dataEvaluationdit(id) {
        services.get_Evaluation_by(id)
            .then((response) => {

                setDataEditEval(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateEvaluation(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Eval', id);
        bodyFormData.append('description', dataEval.description);

        services.updateEvaluation(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallEvaluation()
                handleEditCloseModalEvaluation()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Evaluation Name //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Weighting //////////////////////////////////////////

    /** Activation modal d'insertion Weighting  */
    const [showModalWeighting, setShowModalWeighting] = useState(false)

    function ShowModalWeig() {
        getEvaluationOpen()
        setShowModalWeighting(true); //open modal

    }
    const handleCloseModalWeighting = () => setShowModalWeighting(false) ///close modal
    /** Activation modal d'insertion Weighting  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataWeig, setWeig] = useState({
        id_aca_year: '',
        id_first_level: '',
        id_evaluation: '',
        weight: '',
    })


    const handleWeighting = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataWeig, [name]: value }
        setWeig(data);
        console.log(data);

    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitWeighting(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataWeig.id_aca_year);
        bodyFormData.append('libelle', dataWeig.id_first_level);
        bodyFormData.append('description', dataWeig.id_evaluation);
        bodyFormData.append('weight', dataWeig.weight);

        services.InsertWeighting(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalWeighting();
                getallWeighting();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallWeighting()
        // getWeightingOpen()
    }, [])

    // const [dataWeightingOpen, setDataWeightingOpen] = useState([]);

    // function getWeightingOpen() {
    //     services.get_Weighting_Open()
    //         .then((response) => {

    //             setDataWeightingOpen(response.data)
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    const [dataWeighting, setDataWeighting] = useState([]);

    function getallWeighting() {
        services.getWeighting()
            .then((response) => {

                setDataWeighting(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsWeighting = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Weights",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.weight} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateWeig(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteWeighting(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteWeighting(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Weighting setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteWeighting(id)
                    .then(response => {
                        // console.log(response.data);
                        getallWeighting()
                        Swal.fire(
                            'Data Items Weighting setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditWeighting, setShowEditModalWeighting] = useState(false)

    function ShowModalUpdateWeig(id) {
        setShowEditModalWeighting(true);
        dataWeightingdit(id)

    }
    const handleEditCloseModalWeighting = () => setShowEditModalWeighting(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditWeig, setDataEditWeig] = useState([])

    function dataWeightingdit(id) {
        services.get_Weighting_by(id)
            .then((response) => {

                setDataEditWeig(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateWeighting(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Weig', id);
        bodyFormData.append('libelle', dataWeig.id_first_level);
        bodyFormData.append('description', dataWeig.id_evaluation);
        bodyFormData.append('weight', dataWeig.weight);

        services.updateWeighting(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallWeighting()
                handleEditCloseModalWeighting()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Weighting //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Exams Name //////////////////////////////////////////

    /** Activation modal d'insertion Exams Name  */
    const [showModalExamsName, setShowModalExamsName] = useState(false)

    function ShowModalExamN() {
        setShowModalExamsName(true); //open modal

    }
    const handleCloseModalExamsName = () => setShowModalExamsName(false) ///close modal
    /** Activation modal d'insertion Exams Name  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataExamN, setExamN] = useState({
        description: '',
    })

    
    const handleExamsName = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataExamN, [name]: value }
        setExamN(data);
        console.log(data);

    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitExamsName(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataExamN.description);

        services.InsertExamsName(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalExamsName();
                getallExamsName();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallExamsName()
        getExamsNameOpen()
    }, [])

    const [dataExamsNameOpen, setDataExamsNameOpen] = useState([]);

    function getExamsNameOpen() {
        services.get_ExamsName_Open()
            .then((response) => {

                setDataExamsNameOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataExamsName, setDataExamsName] = useState([]);

    function getallExamsName() {
        services.getExamsName()
            .then((response) => {

                setDataExamsName(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsExamsName = [
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateExamN(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteExamsName(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteExamsName(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Exams Name setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteExamsName(id)
                    .then(response => {
                        // console.log(response.data);
                        getallExamsName()
                        Swal.fire(
                            'Data Items Exams Name setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditExamsName, setShowEditModalExamsName] = useState(false)

    function ShowModalUpdateExamN(id) {
        setShowEditModalExamsName(true);
        dataExamsNamedit(id)

    }
    const handleEditCloseModalExamsName = () => setShowEditModalExamsName(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditExamN, setDataEditExamN] = useState([])

    function dataExamsNamedit(id) {
        services.get_ExamsName_by(id)
            .then((response) => {

                setDataEditExamN(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateExamsName(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_ExamN', id);
        bodyFormData.append('description', dataExamN.description);

        services.updateExamsName(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallExamsName()
                handleEditCloseModalExamsName()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Exams Name //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Exams //////////////////////////////////////////

    /** Activation modal d'insertion Exams  */
    const [showModalExams, setShowModalExams] = useState(false)

    function ShowModalExa() {
        getExamsNameOpen()
        setShowModalExams(true); //open modal

    }
    const handleCloseModalExams = () => setShowModalExams(false) ///close modal
    /** Activation modal d'insertion Exams  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataExa, setExa] = useState({
        id_exam_name: '',
        id_first_level: '',
        id_classe: '',
        start: '',
        end: '',
    })


    const handleExams = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataExa, [name]: value }
        setExa(data);
        console.log(data);

    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitExams(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataExa.id_exam_name);
        bodyFormData.append('libelle', dataExa.id_first_level);
        bodyFormData.append('class', dataExa.id_classe);
        bodyFormData.append('start', dataExa.start);
        bodyFormData.append('end', dataExa.end);

        services.InsertExams(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalExams();
                getallExams();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallExams()
        // getExamsOpen()
    }, [])

    // const [dataExamsOpen, setDataExamsOpen] = useState([]);

    // function getExamsOpen() {
    //     services.get_Exams_Open()
    //         .then((response) => {

    //             setDataExamsOpen(response.data)
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    const [dataExams, setDataExams] = useState([]);

    function getallExams() {
        services.getExams()
            .then((response) => {

                setDataExams(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsExams = [
        {
            name: "Exam",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Class",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Start",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.start} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "End",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.end} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateExa(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteExams(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteExams(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Exams setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteExams(id)
                    .then(response => {
                        // console.log(response.data);
                        getallExams()
                        Swal.fire(
                            'Data Items Exams setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditExams, setShowEditModalExams] = useState(false)

    function ShowModalUpdateExa(id) {
        setShowEditModalExams(true);
        dataExamsdit(id)

    }
    const handleEditCloseModalExams = () => setShowEditModalExams(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditExa, setDataEditExa] = useState([])

    function dataExamsdit(id) {
        services.get_Exams_by(id)
            .then((response) => {

                setDataEditExa(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateExams(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Exa', id);
        bodyFormData.append('description', dataExa.id_exam_name);
        bodyFormData.append('libelle', dataExa.id_first_level);
        bodyFormData.append('class', dataExa.id_classe);
        bodyFormData.append('start', dataExa.start);
        bodyFormData.append('end', dataExa.end);

        services.updateExams(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallExams()
                handleEditCloseModalExams()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Exams //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Annuel Average //////////////////////////////////////////

    /** Activation modal d'insertion Annuel Average  */
    const [showModalAnnuelAverage, setShowModalAnnuelAverage] = useState(false)

    function ShowModalAnnuel() {
        getExamsNameOpen()
        setShowModalAnnuelAverage(true); //open modal

    }
    const handleCloseModalAnnuelAverage = () => setShowModalAnnuelAverage(false) ///close modal
    /** Activation modal d'insertion Annuel Average  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataAnnuel, setAnnuel] = useState({
        id_classe: '',
        average: '',
    })


    const handleAnnuelAverage = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataAnnuel, [name]: value }
        setAnnuel(data);
        console.log(data);

    }

    const [dataAAAnnuel, setAAAnnuel] = useState({
        id_exam_name: [],
    })

    const handleAAAnnuelAverage = (e) => {
        const { value, checked } = e.target;
        const { id_exam_name } = dataAAAnnuel;

        // const name = e.target.name;
        // const data = { ...dataSiz, [name]: value }
        // setClassSlot(data);
        // console.log(`${value} is ${checked}`);
        console.log(value);

        if (checked) {
            setAAAnnuel({
                id_exam_name: [...id_exam_name, value],
            });
        }
        else {
            setAAAnnuel({
                // id_size: id_size.filter((e) => e !== value),
                id_exam_name: "",
            });
        }
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitAnnuelAverage(e) {
        e.preventDefault();

        const bodyFormData = new FormData();
        
        bodyFormData.append('libelle', dataAnnuel.id_classe);
        bodyFormData.append('description', dataAAAnnuel.id_exam_name);
        bodyFormData.append('average', dataAnnuel.average);

        services.InsertAnnuelAverage(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalAnnuelAverage();
                getallAnnuelAverage();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallAnnuelAverage()
        // getAnnuelAverageOpen()
    }, [])

    // const [dataAnnuelAverageOpen, setDataAnnuelAverageOpen] = useState([]);

    // function getAnnuelAverageOpen() {
    //     services.get_AnnuelAverage_Open()
    //         .then((response) => {

    //             setDataAnnuelAverageOpen(response.data)
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    const [dataAnnuelAverage, setDataAnnuelAverage] = useState([]);

    function getallAnnuelAverage() {
        services.getAnnuelAverage()
            .then((response) => {

                setDataAnnuelAverage(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsAnnuelAverage = [
        {
            name: "Class",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Exams",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Average",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.average} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateAnnuel(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteAnnuelAverage(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteAnnuelAverage(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Annuel Average setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteAnnuelAverage(id)
                    .then(response => {
                        // console.log(response.data);
                        getallAnnuelAverage()
                        Swal.fire(
                            'Data Items Annuel Average setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditAnnuelAverage, setShowEditModalAnnuelAverage] = useState(false)

    function ShowModalUpdateAnnuel(id) {
        setShowEditModalAnnuelAverage(true);
        dataAnnuelAveragedit(id)

    }
    const handleEditCloseModalAnnuelAverage = () => setShowEditModalAnnuelAverage(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditAnnuel, setDataEditAnnuel] = useState([])

    function dataAnnuelAveragedit(id) {
        services.get_AnnuelAverage_by(id)
            .then((response) => {

                setDataEditAnnuel(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateAnnuelAverage(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Annuel', id);
        bodyFormData.append('libelle', dataAnnuel.id_classe);
        bodyFormData.append('description', dataAAAnnuel.id_exam_name);
        bodyFormData.append('average', dataAnnuel.average);

        services.updateAnnuelAverage(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallAnnuelAverage()
                handleEditCloseModalAnnuelAverage()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Annuel Average //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Name Promotion /////////////////////////////////////////

    /** Activation modal d'insertion Name Promotion */
    const [showModalNamePromotion, setShowModalNamePromotion] = useState(false)

    function ShowModalNamePro() {
        setShowModalNamePromotion(true); //open modal

    }
    const handleCloseModalNamePromotion = () => setShowModalNamePromotion(false) ///close modal
    /** Activation modal d'insertion Name Promotion */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataNamePro, setNamePro] = useState({
        description: '',
        code: '',
    })

    const handleNamePromotion = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataNamePro, [name]: value }
        setNamePro(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitNamePromotion(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataNamePro.description);
        bodyFormData.append('code', dataNamePro.code);

        services.InsertNamePromotion(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalNamePromotion();
                getallNamePromotion();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallNamePromotion()
    }, [])


    const [dataNamePromotion, setDataNamePromotion] = useState([]);

    function getallNamePromotion() {
        services.getNamePromotion()
            .then((response) => {

                setDataNamePromotion(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsNamePromotion = [

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Code",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateNamePro(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteNamePromotion(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteNamePromotion(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Name Promotion",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteNamePromotion(id)
                    .then(response => {
                        // console.log(response.data);
                        getallNamePromotion()
                        Swal.fire(
                            'Data Name Promotion has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditNamePromotion, setShowEditModalNamePromotion] = useState(false)

    function ShowModalUpdateNamePro(id) {
        setShowEditModalNamePromotion(true);
        dataNameProEdit(id)

    }
    const handleEditCloseModalNamePromotion = () => setShowEditModalNamePromotion(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditNamePro, setDataEditNamePro] = useState([])

    function dataNameProEdit(id) {
        services.get_NamePromotion_by(id)
            .then((response) => {

                setDataEditNamePro(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateNamePromotion(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_NamePro', id);
        bodyFormData.append('description', dataNamePro.description);
        bodyFormData.append('code', dataNamePro.code);

        services.updateNamePromotion(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallNamePromotion()
                handleEditCloseModalNamePromotion()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Name Promotion //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Promotion //////////////////////////////////////////

    /** Activation modal d'insertion Promotion  */
    const [showModalPromotion, setShowModalPromotion] = useState(false)

    function ShowModalPro() {
        getEvaluationOpen()
        setShowModalPromotion(true); //open modal

    }
    const handleCloseModalPromotion = () => setShowModalPromotion(false) ///close modal
    /** Activation modal d'insertion Promotion  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataPro, setPro] = useState({
        id_aca_year: '',
        id_sublevel: '',
        promotion: '',
        max: '',
        min: '',
    })


    const handlePromotion = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataPro, [name]: value }
        setPro(data);
        console.log(data);

    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitPromotion(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataPro.id_aca_year);
        bodyFormData.append('description', dataPro.id_sublevel);
        bodyFormData.append('promotion', dataPro.promotion);
        bodyFormData.append('max', dataPro.max);
        bodyFormData.append('min', dataPro.min);

        services.InsertPromotion(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalPromotion();
                getallPromotion();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallPromotion()
        // getPromotionOpen()
    }, [])

    // const [dataPromotionOpen, setDataPromotionOpen] = useState([]);

    // function getPromotionOpen() {
    //     services.get_Promotion_Open()
    //         .then((response) => {

    //             setDataPromotionOpen(response.data)
                // console.log(response.data);

    //         })
    //         .catch((error) => {
                // console.log(error.response);
    //         })
    // }

    const [dataPromotion, setDataPromotion] = useState([]);

    function getallPromotion() {
        services.getPromotion()
            .then((response) => {

                setDataPromotion(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsPromotion = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Promotion",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.promotion} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Max",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.max} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Min",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.min} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdatePro(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeletePromotion(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeletePromotion(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Promotion setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deletePromotion(id)
                    .then(response => {
                        // console.log(response.data);
                        getallPromotion()
                        Swal.fire(
                            'Data Items Promotion setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditPromotion, setShowEditModalPromotion] = useState(false)

    function ShowModalUpdatePro(id) {
        setShowEditModalPromotion(true);
        dataPromotiondit(id)

    }
    const handleEditCloseModalPromotion = () => setShowEditModalPromotion(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditPro, setDataEditPro] = useState([])

    function dataPromotiondit(id) {
        services.get_Promotion_by(id)
            .then((response) => {

                setDataEditPro(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdatePromotion(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Pro', id);
        bodyFormData.append('description', dataPro.id_sublevel);
        bodyFormData.append('promotion', dataPro.promotion);
        bodyFormData.append('max', dataPro.max);
        bodyFormData.append('min', dataPro.min);

        services.updatePromotion(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallPromotion()
                handleEditCloseModalPromotion()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Promotion //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Id Card Validity /////////////////////////////////////////

    /** Activation modal d'insertion Card Validity */
    const [showModalCardValidity, setShowModalCardValidity] = useState(false)

    function ShowModalCard() {
        setShowModalCardValidity(true); //open modal

    }
    const handleCloseModalCardValidity = () => setShowModalCardValidity(false) ///close modal
    /** Activation modal d'insertion Card Validity */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataCard, setCard] = useState({
        validity: '',
    })

    const handleCardValidity = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataCard, [name]: value }
        setCard(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitCardValidity(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('validity', dataCard.validity);

        services.InsertCardValidity(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalCardValidity();
                getallCardValidity();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallCardValidity()
    }, [])


    const [dataCardValidity, setDataCardValidity] = useState([]);

    function getallCardValidity() {
        services.getCardValidity()
            .then((response) => {

                setDataCardValidity(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsCardValidity = [

        {
            name: "Validity",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.validity} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateCard(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteCardValidity(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteCardValidity(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Card Validity",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteCardValidity(id)
                    .then(response => {
                        // console.log(response.data);
                        getallCardValidity()
                        Swal.fire(
                            'Data Card Validity has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditCardValidity, setShowEditModalCardValidity] = useState(false)

    function ShowModalUpdateCard(id) {
        setShowEditModalCardValidity(true);
        dataCardEdit(id)

    }
    const handleEditCloseModalCardValidity = () => setShowEditModalCardValidity(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditCard, setDataEditCard] = useState([])

    function dataCardEdit(id) {
        services.get_CardValidity_by(id)
            .then((response) => {

                setDataEditCard(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateCardValidity(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Card', id);
        bodyFormData.append('validity', dataCard.validity);

        services.updateCardValidity(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallCardValidity()
                handleEditCloseModalCardValidity()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Id Card Validity //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Class Call /////////////////////////////////////////

    /** Activation modal d'insertion Class Call */
    const [showModalClassCall, setShowModalClassCall] = useState(false)

    function ShowModalClassC() {
        setShowModalClassCall(true); //open modal

    }
    const handleCloseModalClassCall = () => setShowModalClassCall(false) ///close modal
    /** Activation modal d'insertion Class Call */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataClassC, setClassC] = useState({
        code: '',
        description: '',
    })

    const handleClassCall = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataClassC, [name]: value }
        setClassC(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitClassCall(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('code', dataClassC.code);
        bodyFormData.append('description', dataClassC.description);

        services.InsertClassCall(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalClassCall();
                getallClassCall();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallClassCall()
        // getClassCallOpen()
    }, [])

    // const [dataClassCallOpen, setDataClassCallOpen] = useState([]);

    //     function getClassCallOpen() {
    //         services.get_ClassCall_Open()
    //             .then((response) => {

    //                 setDataClassCallOpen(response.data)
                    // console.log(response.data);

    //             })
    //             .catch((error) => {
                    // console.log(error.response);
    //             })
    //     }

    const [dataClassCall, setDataClassCall] = useState([]);

    function getallClassCall() {
        services.getClassCall()
            .then((response) => {

                setDataClassCall(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsClassCall = [

        {
            name: "Code",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateClassC(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteClassCall(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteClassCall(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Class Call",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteClassCall(id)
                    .then(response => {
                        // console.log(response.data);
                        getallClassCall()
                        Swal.fire(
                            'Data Class Call has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditClassCall, setShowEditModalClassCall] = useState(false)

    function ShowModalUpdateClassC(id) {
        setShowEditModalClassCall(true);
        dataClassCEdit(id)

    }
    const handleEditCloseModalClassCall = () => setShowEditModalClassCall(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditClassC, setDataEditClassC] = useState([])

    function dataClassCEdit(id) {
        services.get_ClassCall_by(id)
            .then((response) => {

                setDataEditClassC(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateClassCall(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_ClassC', id);
        bodyFormData.append('code', dataClassC.code);
        bodyFormData.append('description', dataClassC.description);

        services.updateClassCall(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallClassCall()
                handleEditCloseModalClassCall()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Class Call //////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Reason Of Leaving /////////////////////////////////////////

    /** Activation modal d'insertion Reason Of Leaving */
    const [showModalReasonLeaving, setShowModalReasonLeaving] = useState(false)

    function ShowModalLeaving() {
        setShowModalReasonLeaving(true); //open modal

    }
    const handleCloseModalReasonLeaving = () => setShowModalReasonLeaving(false) ///close modal
    /** Activation modal d'insertion Reason Of Leaving */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataLeaving, setLeaving] = useState({
        reason: '',
        group: '',
    })

    const handleReasonLeaving = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataLeaving, [name]: value }
        setLeaving(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitReasonLeaving(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('reason', dataLeaving.reason);
        bodyFormData.append('group', dataLeaving.group);

        services.InsertReasonLeaving(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalReasonLeaving();
                getallReasonLeaving();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallReasonLeaving()
        // getReasonLeavingOpen()
    }, [])

    // const [dataReasonLeavingOpen, setDataReasonLeavingOpen] = useState([]);

    //     function getReasonLeavingOpen() {
    //         services.get_ReasonLeaving_Open()
    //             .then((response) => {

    //                 setDataReasonLeavingOpen(response.data)
                    // console.log(response.data);

    //             })
    //             .catch((error) => {
                    // console.log(error.response);
    //             })
    //     }

    const [dataReasonLeaving, setDataReasonLeaving] = useState([]);

    function getallReasonLeaving() {
        services.getReasonLeaving()
            .then((response) => {

                setDataReasonLeaving(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsReasonLeaving = [

        {
            name: "Reason",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.reason} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Group",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.group} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateLeaving(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteReasonLeaving(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteReasonLeaving(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Reason Of Leaving",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteReasonLeaving(id)
                    .then(response => {
                        // console.log(response.data);
                        getallReasonLeaving()
                        Swal.fire(
                            'Data Reason Of Leaving has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditReasonLeaving, setShowEditModalReasonLeaving] = useState(false)

    function ShowModalUpdateLeaving(id) {
        setShowEditModalReasonLeaving(true);
        dataLeavingEdit(id)

    }
    const handleEditCloseModalReasonLeaving = () => setShowEditModalReasonLeaving(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditLeaving, setDataEditLeaving] = useState([])

    function dataLeavingEdit(id) {
        services.get_ReasonLeaving_by(id)
            .then((response) => {

                setDataEditLeaving(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateReasonLeaving(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Leaving', id);
        bodyFormData.append('reason', dataLeaving.reason);
        bodyFormData.append('group', dataLeaving.group);

        services.updateReasonLeaving(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallReasonLeaving()
                handleEditCloseModalReasonLeaving()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Reason Of Leaving //////////////////////////////////////////

     ///////////////////////////////////////////////////////////////////////////// Classes Timetables /////////////////////////////////////////

    /** Activation modal d'insertion Classes Timetables */
    const [showModalClasseTime, setShowModalClasseTime] = useState(false)

    function ShowModalClasseTi() {
        getClasseTimeOpen()
        setShowModalClasseTime(true); //open modal

    }
    const handleCloseModalClasseTime = () => setShowModalClasseTime(false) ///close modal
    /** Activation modal d'insertion Classes Timetables */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataClasseTi, setClasseTi] = useState({
        start_time: '',
        end_time: '',
    })

    const handleClasseTime = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataClasseTi, [name]: value }
        setClasseTi(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitClasseTime(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('start_time', dataClasseTi.start_time);
        bodyFormData.append('end_time', dataClasseTi.end_time);

        services.InsertClasseTime(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalClasseTime();
                getallClasseTime();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallClasseTime()
        getClasseTimeOpen()
    }, [])

    const [dataClasseTimeOpen, setDataClasseTimeOpen] = useState([]);

        function getClasseTimeOpen() {
            services.get_ClasseTime_Open()
                .then((response) => {

                    setDataClasseTimeOpen(response.data)
                    // console.log(response.data);

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }

    const [dataClasseTime, setDataClasseTime] = useState([]);

    function getallClasseTime() {
        services.getClasseTime()
            .then((response) => {

                setDataClasseTime(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsClasseTime = [

        {
            name: "Start Time",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.start_time} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "End Time",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.end_time} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateClasseTi(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteClasseTime(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteClasseTime(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Classes Timetables",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteClasseTime(id)
                    .then(response => {
                        // console.log(response.data);
                        getallClasseTime()
                        Swal.fire(
                            'Data Classes Timetables has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditClasseTime, setShowEditModalClasseTime] = useState(false)

    function ShowModalUpdateClasseTi(id) {
        setShowEditModalClasseTime(true);
        dataClasseTiEdit(id)

    }
    const handleEditCloseModalClasseTime = () => setShowEditModalClasseTime(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditClasseTi, setDataEditClasseTi] = useState([])

    function dataClasseTiEdit(id) {
        services.get_ClasseTime_by(id)
            .then((response) => {

                setDataEditClasseTi(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateClasseTime(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_ClasseTi', id);
        bodyFormData.append('start_time', dataClasseTi.start_time);
        bodyFormData.append('end_time', dataClasseTi.end_time);

        services.updateClasseTime(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallClasseTime()
                handleEditCloseModalClasseTime()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Classes Timetables //////////////////////////////////////////

     ///////////////////////////////////////////////////////////////////////////// Classe Time Slots /////////////////////////////////////////

    /** Activation modal d'insertion Classe Time Slots */
    const [showModalClassTimeSlot, setShowModalClassTimeSlot] = useState(false)

    function ShowModalClassSlot() {
        getClasseTimeOpen()
        setShowModalClassTimeSlot(true); //open modal

    }
    const handleCloseModalClassTimeSlot = () => setShowModalClassTimeSlot(false) ///close modal
    /** Activation modal d'insertion Classe Time Slots */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataClassSlot, setClassSlot] = useState({
        id_classe: '',
        
    })

    const handleClassTimeSlot = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataClassSlot, [name]: value }
        setClassSlot(data);
        console.log(data)
    }

    const [dataDETClassSlot, setCHTClassSlot] = useState({
        id_time: [],
    })

    const [dataDEClassSlot, setCHClassSlot] = useState({
        days: [],
    })

    const handleCHTClassTimeSlot = (e) => {
        const { value, checked } = e.target;
        const { id_time } = dataDETClassSlot;

        // const name = e.target.name;
        // const data = { ...dataSiz, [name]: value }
        // setClassSlot(data);
        // console.log(`${value} is ${checked}`);
        // console.log(value);

        if (checked) {
            setCHTClassSlot({
                id_time: [...id_time, value],
            });
        }
        else {
            setCHTClassSlot({
                // id_size: id_size.filter((e) => e !== value),
                id_time: "",
            });
        }
    }

    const handleCHDClassTimeSlot = (e) => {
        const { value, checked } = e.target;
        const { days } = dataDEClassSlot;

        // const name = e.target.name;
        // const data = { ...dataSiz, [name]: value }
        // setClassSlot(data);
        // console.log(`${value} is ${checked}`);
        console.log(value);

        if (checked) {
            setCHClassSlot({
                days: [...days, value],
            });
        }
        else {
            setCHClassSlot({
                // id_size: id_size.filter((e) => e !== value),
                days: "",
            });
        }
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitClassTimeSlot(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataClassSlot.id_classe);
        bodyFormData.append('days', dataDEClassSlot.days);
        bodyFormData.append('hourtime', dataDETClassSlot.id_time);

        services.InsertClassTimeSlot(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalClassTimeSlot();
                getallClassTimeSlot();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallClassTimeSlot()
        // getClassTimeSlotOpen()
    }, [])

    // const [dataClassTimeSlotOpen, setDataClassTimeSlotOpen] = useState([]);

    //     function getClassTimeSlotOpen() {
    //         services.get_ClassTimeSlot_Open()
    //             .then((response) => {

    //                 setDataClassTimeSlotOpen(response.data)
                    // console.log(response.data);

    //             })
    //             .catch((error) => {
    //                 console.log(error.response);
    //             })
    //     }

    const [dataClassTimeSlot, setDataClassTimeSlot] = useState([]);

    function getallClassTimeSlot() {
        services.getClassTimeSlot()
            .then((response) => {

                setDataClassTimeSlot(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsClassTimeSlot = [

        {
            name: "Classes",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Days",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.days} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Time Slots",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.start_time} - {rows.end_time} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                {/* <Link to={"#"} onClick={(e) => ShowModalUpdateClassSlot(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to="#" onClick={(e) => DeleteClassTimeSlot(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteClassTimeSlot(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Classe Time Slots",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteClassTimeSlot(id)
                    .then(response => {
                        // console.log(response.data);
                        getallClassTimeSlot()
                        Swal.fire(
                            'Data Classe Time Slots has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    // /**Activation du modale pour l'update */
    // const [showModalEditClassTimeSlot, setShowEditModalClassTimeSlot] = useState(false)

    // function ShowModalUpdateClassSlot(id) {
    //     setShowEditModalClassTimeSlot(true);
    //     dataClassSlotEdit(id)

    // }
    // const handleEditCloseModalClassTimeSlot = () => setShowEditModalClassTimeSlot(false)
    // /**Activation du modale pour l'update */


    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    // const [dataEditClassSlot, setDataEditClassSlot] = useState([])

    // function dataClassSlotEdit(id) {
    //     services.get_ClassTimeSlot_by(id)
    //         .then((response) => {

    //             setDataEditClassSlot(response.data[0])
                // console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    // /**function de modification des donnée recuperé en fonction de l'ID */
    // function handleUpdateClassTimeSlot(e, id) {
    //     e.preventDefault();

    //     const bodyFormData = new FormData();

    //     bodyFormData.append('id_ClassSlot', id);
    //     bodyFormData.append('start_time', dataClassSlot.start_time);
    //     bodyFormData.append('end_time', dataClassSlot.end_time);

    //     services.updateClassTimeSlot(bodyFormData)
    //         .then((response) => {
    //             Swal.fire({
    //                 position: 'top-end',
    //                 icon: 'success',
    //                 title: response.data.message,
    //                 showConfirmButton: false,
    //                 timer: 5000
    //             })
                // console.log(response.data)
    //             getallClassTimeSlot()
    //             handleEditCloseModalClassTimeSlot()

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }
    // /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Classe Time Slots //////////////////////////////////////////


    return(
        <>
        <Menu />
        <Header />

        <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Academic Parameters</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Setting</a></li>
                                    <li className="breadcrumb-item active">Academic Parameters</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="card bg-white">
                                <div className="card-header">
                                    <h5 className="card-title">Academic Parameters</h5>
                                </div>
                                <div className="card-body">
                                    <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                        <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab1" data-bs-toggle="tab">Levels</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab2" data-bs-toggle="tab">Subjets</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab3" data-bs-toggle="tab">Admission</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4" data-bs-toggle="tab">Report</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5" data-bs-toggle="tab">Evaluation</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab6" data-bs-toggle="tab">ID card Validity</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab7" data-bs-toggle="tab">Class Call</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab8" data-bs-toggle="tab">Reason of leaving</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab9" data-bs-toggle="tab">Class Timetables</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab10" data-bs-toggle="tab">Class Time Slots</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="solid-justified-tab1">
                                            <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                                <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab1-1" data-bs-toggle="tab">Sub Levels</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab1-2" data-bs-toggle="tab">Classes</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab1-3" data-bs-toggle="tab">Section</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="solid-justified-tab1-1">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalSubl()} ><i className="fas fa-plus"></i> Add Sub Level </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsSublevel}
                                                                            data={dataSublevel}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab1-2">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalClas()} ><i className="fas fa-plus"></i> Add Classes </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsClasse}
                                                                            data={dataClasse}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab1-3">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalSect()} ><i className="fas fa-plus"></i> Add Section </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsSection}
                                                                            data={dataSection}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab2">
                                        <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalSub()} ><i className="fas fa-plus"></i> Add Subjects </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsSubject}
                                                                                data={dataSubject}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab3">
                                             <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                                <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab2-1" data-bs-toggle="tab">File</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab2-2" data-bs-toggle="tab">Required File</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab2-3" data-bs-toggle="tab">Placement Test Subject</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="solid-justified-tab2-1">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalFil()} ><i className="fas fa-plus"></i> Add File </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsFile}
                                                                                data={dataFile}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab2-2">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalReq()} ><i className="fas fa-plus"></i> Add Required File </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsRequired}
                                                                                data={dataRequired}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab2-3">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalPlace()} ><i className="fas fa-plus"></i> Add Placement Test Subject </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsPlacement}
                                                                                data={dataPlacement}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                        </div>
                                       
                                        <div className="tab-pane" id="solid-justified-tab4">
                                            <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                                <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab4-1" data-bs-toggle="tab">Name Behaviours</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4-2" data-bs-toggle="tab">Behaviours</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4-3" data-bs-toggle="tab">Name Effort Levels</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4-4" data-bs-toggle="tab">Effort Levels</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4-5" data-bs-toggle="tab">Name Grading Scale</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4-6" data-bs-toggle="tab">Grading Scale</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4-7" data-bs-toggle="tab">Nursery Therm</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4-8" data-bs-toggle="tab">Nursery Sub Therm</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4-9" data-bs-toggle="tab">Nursery Report</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="solid-justified-tab4-1">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalNameB()} ><i className="fas fa-plus"></i> Add Name Behaviours </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsNameBehaviour}
                                                                            data={dataNameBehaviour}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab4-2">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalBeha()} ><i className="fas fa-plus"></i> Add Behaviours </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsBehaviour}
                                                                            data={dataBehaviour}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab4-3">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalNameEf()} ><i className="fas fa-plus"></i> Add Name Effort Levels </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsNameEffort}
                                                                            data={dataNameEffort}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab4-4">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalEffort()} ><i className="fas fa-plus"></i> Add Effort Levels </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsEffortLevel}
                                                                            data={dataEffortLevel}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab4-5">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalGrading()} ><i className="fas fa-plus"></i> Add Name Grading Scale </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsNameGrading}
                                                                            data={dataNameGrading}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab4-6">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalScale()} ><i className="fas fa-plus"></i> Add Grading Scale </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsGradingScale}
                                                                            data={dataGradingScale}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab4-7">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        {/* <Link to={"../nursery_theme"} target="blank"><button type="button" className="btn btn-info mb-3" onClick={() => ShowModalNursery()} ><i className="fas fa-plus"></i> Add Nursery Therm </button></Link> */}
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalNursery()} ><i className="fas fa-plus"></i> Add Nursery Term </button>
                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsNurseryTheme}
                                                                            data={dataNurseryTheme}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab4-8">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                    {/* <Link to={"../nursery_sub_theme"} target="blank"><button type="button" className="btn btn-info mb-3"  ><i className="fas fa-plus"></i> Add Nursery Sub Theme </button></Link> */}
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalNursSub()} ><i className="fas fa-plus"></i> Add Nursery Subterm </button>
                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsNursSubTheme}
                                                                            data={dataNursSubTheme}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="tab-pane " id="solid-justified-tab4-9">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalNursReport()} ><i className="fas fa-plus"></i> Add Nursery Report </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsNurseryReport}
                                                                            data={dataNurseryReport}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            pagination
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab5">
                                            <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                                <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab5-1" data-bs-toggle="tab">Evaluation Name</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5-2" data-bs-toggle="tab">Weighting</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5-3" data-bs-toggle="tab">Exams Name</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5-4" data-bs-toggle="tab">Exams</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5-5" data-bs-toggle="tab">Annual Average</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5-6" data-bs-toggle="tab">Name Promotion</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5-7" data-bs-toggle="tab">Promotion</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="solid-justified-tab5-1">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalEval()} ><i className="fas fa-plus"></i> Add Evaluation Name </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsEvaluation}
                                                                            data={dataEvaluation}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab5-2">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalWeig()} ><i className="fas fa-plus"></i> Add Weighting </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsWeighting}
                                                                            data={dataWeighting}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab5-3">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalExamN()} ><i className="fas fa-plus"></i> Add Exams Name </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsExamsName}
                                                                            data={dataExamsName}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab5-4">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalExa()} ><i className="fas fa-plus"></i> Add Exams </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsExams}
                                                                            data={dataExams}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="tab-pane " id="solid-justified-tab5-5">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalAnnuel()} ><i className="fas fa-plus"></i> Add Annual Average </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsAnnuelAverage}
                                                                            data={dataAnnuelAverage}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab5-6">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalNamePro()} ><i className="fas fa-plus"></i> Add Name Promotion </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsNamePromotion}
                                                                            data={dataNamePromotion}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane " id="solid-justified-tab5-7">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalPro()} ><i className="fas fa-plus"></i> Add Promotion </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsPromotion}
                                                                            data={dataPromotion}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="tab-pane" id="solid-justified-tab6">
                                        <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalCard()} ><i className="fas fa-plus"></i> Add ID Cart Validity </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsCardValidity}
                                                                                data={dataCardValidity}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab7">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalClassC()} ><i className="fas fa-plus"></i> Add Class Call </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsClassCall}
                                                                                data={dataClassCall}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab8">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalLeaving()} ><i className="fas fa-plus"></i> Add Reason of Leaving </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsReasonLeaving}
                                                                                data={dataReasonLeaving}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab9">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalClasseTi()} ><i className="fas fa-plus"></i> Add Classes Timetables </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsClasseTime}
                                                                                data={dataClasseTime}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab10">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalClassSlot()} ><i className="fas fa-plus"></i> New Assignation </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsClassTimeSlot}
                                                                                data={dataClassTimeSlot}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>


            {/* formulaire Sub Level */}
            <Modal show={showModalSublevel} onHide={handleCloseModalSublevel} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Sub Level Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitSublevel(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_level" onChange={(e) => handleSublevel(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleSublevel(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditSublevel} onHide={handleEditCloseModalSublevel} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Sublevel Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateSublevel(e, dataEditSubl.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditSubl.description} onChange={(e) => handleSublevel(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Sub Level */}


             {/* formulaire Classes */}
             <Modal show={showModalClasse} onHide={handleCloseModalClasse} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Classes Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitClasse(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Sub Level</label>
                                    <select name="id_sublevel" onChange={(e) => handleClasse(e)} id=""  className="form-control">
                                    <option value="">Select Sub Level</option>{
                                    dataSublevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Libelle</label>
                                    <input type="text" name="libelle" onChange={(e) => handleClasse(e)} placeholder="libelle..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditClasse} onHide={handleEditCloseModalClasse} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Classes Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateClasse(e, dataEditClas.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Libelle</label>
                                    <input type="text" name="libelle" defaultValue={dataEditClas.libelle} onChange={(e) => handleClasse(e)} placeholder="libelle..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Classes */}


             {/* formulaire Section */}
             <Modal show={showModalSection} onHide={handleCloseModalSection} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Section Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitSection(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleSection(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditSection} onHide={handleEditCloseModalSection} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Section Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateSection(e, dataEditSect.id)}>
                    <Modal.Body>

                        <div SectsName="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditSect.libelle} onChange={(e) => handleSection(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Section */}


            {/* formulaire Subject */}
            <Modal show={showModalSubject} onHide={handleCloseModalSubject} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitSubject(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Subject</label>
                                    <input type="text" name="subject" onChange={(e) => handleSubject(e)} placeholder="subject..." className="form-control" />
                                </div>         
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleSubject(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditSubject} onHide={handleEditCloseModalSubject} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateSubject(e, dataEditSub.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Subject</label>
                                    <input type="text" name="subject" defaultValue={dataEditSub.subject} onChange={(e) => handleSubject(e)} placeholder="subject..." className="form-control" />
                                </div>          
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditSub.description} onChange={(e) => handleSubject(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Subject */}


            {/* formulaire File */}
            <Modal show={showModalFile} onHide={handleCloseModalFile} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add File Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitFile(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleFile(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditFile} onHide={handleEditCloseModalFile} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update File Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateFile(e, dataEditFil.id)}>
                    <Modal.Body>

                        <div FilsName="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditFil.libelle} onChange={(e) => handleFile(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire File */}


            {/* formulaire Required File */}
            <Modal show={showModalRequired} onHide={handleCloseModalRequired} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Required File Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitRequired(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleRequired(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" onChange={(e) => handleRequired(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select File</label>
                                    <select name="id_file" onChange={(e) => handleRequired(e)} id=""  className="form-control">
                                    <option value="">Select description</option>{
                                    dataFileOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* <Modal show={showModalEditRequired} onHide={handleEditCloseModalRequired} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Required File Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateRequired(e, dataEditReq.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditReq.description} onChange={(e) => handleRequired(e)} placeholder="libelle..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> */}
            {/* formulaire Required File */}


            {/* formulaire Placement Test subject */}
            <Modal show={showModalPlacement} onHide={handleCloseModalPlacement} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Placement Test subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitPlacement(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handlePlacement(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" onChange={(e) => handlePlacement(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Subject</label>
                                    <select name="id_subject" onChange={(e) => handlePlacement(e)} id=""  className="form-control">
                                    <option value="">Select description</option>{
                                    dataSubjectOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* <Modal show={showModalEditPlacement} onHide={handleEditCloseModalPlacement} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Placement Test subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdatePlacement(e, dataEditPlace.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditPlace.description} onChange={(e) => handlePlacement(e)} placeholder="libelle..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> */}
            {/* formulaire Placement Test subject */}


            {/* formulaire Name Behaviours */}
            <Modal show={showModalNameBehaviour} onHide={handleCloseModalNameBehaviour} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Name Behaviours Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitNameBehaviour(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleNameBehaviour(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditNameBehaviour} onHide={handleEditCloseModalNameBehaviour} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Name Behaviours Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateNameBehaviour(e, dataEditNameB.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditNameB.description} onChange={(e) => handleNameBehaviour(e)} placeholder="libelle..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Name Behaviours */}


            {/* formulaire Behaviours */}
            <Modal show={showModalBehaviour} onHide={handleCloseModalBehaviour} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Behaviours subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitBehaviour(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleBehaviour(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" onChange={(e) => handleBehaviour(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Name</label>{
                                        dataNameBehaviourOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_namebeha" 
                                                id="id_namebeha"  
                                                className="form-check-input"  
                                                onChange={(e) => handleNABehaviour(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.description}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* <Modal show={showModalEditBehaviour} onHide={handleEditCloseModalBehaviour} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Behaviours Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateBehaviour(e, dataEditPlace.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditPlace.description} onChange={(e) => handleBehaviour(e)} placeholder="libelle..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>  */}
            {/* formulaire Behaviours */}


            {/* formulaire Name Effort Levels */}
            <Modal show={showModalNameEffort} onHide={handleCloseModalNameEffort} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Name Effort Levels Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitNameEffort(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleNameEffort(e)} placeholder="description..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" onChange={(e) => handleNameEffort(e)} placeholder="code..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Mark</label>
                                    <input type="text" name="mark" onChange={(e) => handleNameEffort(e)} placeholder="mark..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditNameEffort} onHide={handleEditCloseModalNameEffort} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Name Effort Levels Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateNameEffort(e, dataEditNameEf.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditNameEf.description} onChange={(e) => handleNameEffort(e)} placeholder="description..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" defaultValue={dataEditNameEf.code} onChange={(e) => handleNameEffort(e)} placeholder="code..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Mark</label>
                                    <input type="text" name="mark" defaultValue={dataEditNameEf.mark} onChange={(e) => handleNameEffort(e)} placeholder="mark..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Name Effort Levels */}


             {/* formulaire Effort Levels */}
             <Modal show={showModalEffortLevel} onHide={handleCloseModalEffortLevel} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Effort Levels subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitEffortLevel(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleEffortLevel(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" onChange={(e) => handleEffortLevel(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Description</label>{
                                        dataNameEffortOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_nameef" 
                                                id="id_nameef"  
                                                className="form-check-input"  
                                                onChange={(e) => handleDEEffortLevel(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label"  defaultValue={result.description}>{result.description}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditEffortLevel} onHide={handleEditCloseModalEffortLevel} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Effort Levels Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateEffortLevel(e, dataEditEffort.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" defaultValue={dataEditEffort.libelle} onChange={(e) => handleEffortLevel(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Description</label>{
                                        dataNameEffortOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_nameef" 
                                                id="id_nameef"  
                                                className="form-check-input"  
                                                defaultValue={dataEditEffort.description}
                                                onChange={(e) => handleDEEffortLevel(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label"  defaultValue={result.description}>{result.description}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> 
            {/* formulaire Effort Levels */}


            {/* formulaire Name Grading Scale */}
            <Modal show={showModalNameGrading} onHide={handleCloseModalNameGrading} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Name Grading Scale Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitNameGrading(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Grade</label>
                                    <input type="text" name="grade" onChange={(e) => handleNameGrading(e)} placeholder="grade..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Remark</label>
                                    <input type="text" name="remark" onChange={(e) => handleNameGrading(e)} placeholder="remark..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Maximun</label>
                                    <input type="text" name="maximun" onChange={(e) => handleNameGrading(e)} placeholder="maximun..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Minimun</label>
                                    <input type="text" name="minimun" onChange={(e) => handleNameGrading(e)} placeholder="minimun..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditNameGrading} onHide={handleEditCloseModalNameGrading} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Name Grading Scale Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateNameGrading(e, dataEditGrading.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Grade</label>
                                    <input type="text" name="grade" defaultValue={dataEditGrading.grade} onChange={(e) => handleNameGrading(e)} placeholder="grade..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Remark</label>
                                    <input type="text" name="remark" defaultValue={dataEditGrading.remark} onChange={(e) => handleNameGrading(e)} placeholder="remark..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Maximun</label>
                                    <input type="text" name="maximun" defaultValue={dataEditGrading.maximun} onChange={(e) => handleNameGrading(e)} placeholder="maximun..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Minimun</label>
                                    <input type="text" name="minimun" defaultValue={dataEditGrading.minimun} onChange={(e) => handleNameGrading(e)} placeholder="minimun..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Name Grading Scale */}



            {/* formulaire Grading scale */}
             <Modal show={showModalGradingScale} onHide={handleCloseModalGradingScale} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Grading scale subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitGradingScale(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleGradingScale(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" onChange={(e) => handleGradingScale(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Remark</label>{
                                        dataNameGradingOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_name_grading" 
                                                id="id_name_grading"  
                                                className="form-check-input"  
                                                onChange={(e) => handleDEGradingScale(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.remark}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditGradingScale} onHide={handleEditCloseModalGradingScale} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Grading scale Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateGradingScale(e, dataEditScale.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" defaultValue={dataEditScale.libelle} onChange={(e) => handleGradingScale(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Remark</label>{
                                        dataNameGradingOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_name_grading" 
                                                id="id_name_grading"  
                                                className="form-check-input"  
                                                defaultValue={dataEditScale.remark}
                                                onChange={(e) => handleDEGradingScale(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label"  defaultValue={result.remark}>{result.remark}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> 
            {/* formulaire Grading scale */}

            
            {/* formulaire Nursery Theme */}
            <Modal show={showModalNurseryTheme} onHide={handleCloseModalNurseryTheme} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Nursery Term Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitNurseryTheme(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                    {
                                        inputFields.map((data, index) => {
                                            const { theme, } = data;
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <input type="text" onChange={(e) => handleNurseryTheme(index, e)} value={theme} name="theme" className="form-control" placeholder="therm" />
                                                        </div>
                                                    </div>

                                                    <div className="col">


                                                        {(inputFields.length !== 1) ? <button className="btn btn-outline-danger" onClick={removeInputinputFields}>x</button> : ''}


                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <button type="button" className="btn btn-outline-success " onClick={(e) => addInputField(e)}>Add New</button>
                                        </div>
                                    </div>
                                    {/* {JSON.stringify(inputFields)} */}
                            </div>
                            
                         </div>

                            

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditNurseryTheme} onHide={handleEditCloseModalNurseryTheme} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Nursery Term Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateNurseryTheme(e, dataEditNursery.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Term</label>
                                    <input type="text" name="Theme" defaultValue={dataEditNursery.Theme} onChange={(e) => handleNurseryTheme(e)} placeholder="Theme..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Nursery Theme */}
            

            {/* formulaire Nursery Sub Theme */}
            <Modal show={showModalNursSubTheme} onHide={handleCloseModalNursSubTheme} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Nursery Subterm Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitNursSubTheme(e)}>
                    <Modal.Body>

                         <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                    {
                                        inputSubFields.map((data, index) => {
                                            const { subtheme, } = data;
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <input type="text" onChange={(e) => handleNursSubTheme(index, e)} value={subtheme} name="subtheme" className="form-control" placeholder="subtheme" />
                                                        </div>
                                                    </div>

                                                    <div className="col">


                                                        {(inputSubFields.length !== 1) ? <button className="btn btn-outline-danger" onClick={removeInputinputSubFields}>x</button> : ''}


                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <button className="btn btn-outline-success " onClick={(e) => addInputSubField(e)}>Add New</button>
                                        </div>
                                    </div>
                            </div>
                            
                         </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditNursSubTheme} onHide={handleEditCloseModalNursSubTheme} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Nursery Subterm Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateNursSubTheme(e, dataEditNursSub.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">subterm</label>
                                    <input type="text" name="subtherm" defaultValue={dataEditNursSub.subtherm} onChange={(e) => handleUpdateNursSubTheme(e)} placeholder="subtherm..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Nursery Sub Theme */}


            {/* formulaire Nursery Report */}
            <Modal show={showModalNurseryReport} onHide={handleCloseModalNurseryReport} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Nursery Report subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitNurseryReport(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Nursery Term</label>
                                    <select name="id_nurs_theme" onChange={(e) => handleNurseryReport(e)} id=""  className="form-control">
                                    <option value="">Select term</option>{
                                    dataNurseryThemeOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.theme}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Nursery Subterm</label>
                                    <select name="id_nurs_sub" onChange={(e) => handleNurseryReport(e)} id=""  className="form-control">
                                    <option value="">Select subtherm</option>{
                                    dataNursSubThemeOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.subtheme}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Behaviours</label>{
                                        dataNameBehaviourOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_namebeha" 
                                                id="id_namebeha"  
                                                className="form-check-input"  
                                                onChange={(e) => handleNRNurseryReport(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.description}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditNurseryReport} onHide={handleEditCloseModalNurseryReport} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Nursery Report Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateNurseryReport(e, dataEditNursReport.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Nursery Term</label>
                                    <select name="id_nurs_theme" defaultValue={dataEditNursReport.theme} onChange={(e) => handleNurseryReport(e)} id=""  className="form-control">
                                    <option value="">Select Term</option>{
                                    dataNurseryThemeOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.theme}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Nursery Subterm</label>
                                    <select name="id_nurs_sub" defaultValue={dataEditNursReport.subtheme} onChange={(e) => handleNurseryReport(e)} id=""  className="form-control">
                                    <option value="">Select Subterm</option>{
                                    dataNursSubThemeOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.subtheme}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Behaviours</label>
                                    <select name="id_namebeha" defaultValue={dataEditNursReport.behaviour} onChange={(e) => handleNurseryReport(e)} id=""  className="form-control">
                                    <option value="">Select Behaviours</option>{
                                    dataNameBehaviourOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> 
            {/* formulaire Nursery Report */}


            {/* formulaire Evaluation Name */}
            <Modal show={showModalEvaluation} onHide={handleCloseModalEvaluation} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Evaluation Name Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitEvaluation(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleEvaluation(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditEvaluation} onHide={handleEditCloseModalEvaluation} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Evaluation Name Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateEvaluation(e, dataEditEval.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditEval.description} onChange={(e) => handleEvaluation(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Evaluation Name */}


            {/* formulaire Weighting */}
             <Modal show={showModalWeighting} onHide={handleCloseModalWeighting} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Weighting subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitWeighting(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleWeighting(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" onChange={(e) => handleWeighting(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Evaluation</label>
                                    <select name="id_evaluation" onChange={(e) => handleWeighting(e)} id=""  className="form-control">
                                    <option value="">Select evaluation</option>{
                                    dataEvaluationOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Weight</label>
                                    <input type="text" name="weight"  onChange={(e) => handleWeighting(e)}  className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditWeighting} onHide={handleEditCloseModalWeighting} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Weighting Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateWeighting(e, dataEditWeig.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" defaultValue={dataEditWeig.libelle} onChange={(e) => handleWeighting(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Evaluation</label>
                                    <select name="id_evaluation" defaultValue={dataEditWeig.libelle} onChange={(e) => handleWeighting(e)} id=""  className="form-control">
                                    <option value="">Select evaluation</option>{
                                    dataEvaluationOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Weight</label>
                                    <input type="text" name="weight" defaultValue={dataEditWeig.weight} onChange={(e) => handleWeighting(e)} placeholder="Weight..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> 
            {/* formulaire Weighting */}


            {/* formulaire Exams Name */}
            <Modal show={showModalExamsName} onHide={handleCloseModalExamsName} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Exams Name Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitExamsName(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleExamsName(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditExamsName} onHide={handleEditCloseModalExamsName} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Exams Name Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateExamsName(e, dataEditExamN.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditExamN.description} onChange={(e) => handleExamsName(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Exams Name */}                    


            {/* formulaire Exams */}
             <Modal show={showModalExams} onHide={handleCloseModalExams} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Exams subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitExams(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Exams Name</label>
                                    <select name="id_exam_name" onChange={(e) => handleExams(e)} id=""  className="form-control">
                                    <option value="">Select Exams</option>{
                                    dataExamsNameOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" onChange={(e) => handleExams(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_classe" onChange={(e) => handleExams(e)} id=""  className="form-control">
                                    <option value="">Select classe</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Start</label>
                                    <input type="date" name="start" onChange={(e) => handleExams(e)} className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">End</label>
                                    <input type="date" name="end" onChange={(e) => handleExams(e)} className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditExams} onHide={handleEditCloseModalExams} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Exams Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateExams(e, dataEditExa.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Exams Name</label>
                                    <select name="id_exam_name" defaultValue={dataEditExa.libelle} onChange={(e) => handleExams(e)} id=""  className="form-control">
                                    <option value="">Select Exams</option>{
                                    dataExamsNameOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_first_level" defaultValue={dataEditExa.libelle} onChange={(e) => handleExams(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class</label>
                                    <select name="id_classe" defaultValue={dataEditExa.libelle} onChange={(e) => handleExams(e)} id=""  className="form-control">
                                    <option value="">Select classe</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Start</label>
                                    <input type="date" name="start" defaultValue={dataEditExa.start} onChange={(e) => handleExams(e)} placeholder="start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">End</label>
                                    <input type="date" name="end" defaultValue={dataEditExa.end} onChange={(e) => handleExams(e)} placeholder="end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> 
            {/* formulaire Exams */}


            {/* formulaire Annuel Average */}
             <Modal show={showModalAnnuelAverage} onHide={handleCloseModalAnnuelAverage} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add AnnuelAverage subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitAnnuelAverage(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class</label>
                                    <select name="id_classe" onChange={(e) => handleAnnuelAverage(e)} id=""  className="form-control">
                                    <option value="">Select classe</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Exams Name</label>{
                                        dataExamsNameOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_exam_name" 
                                                id="id_exam_name"  
                                                className="form-check-input"
                                                onChange={(e) => handleAAAnnuelAverage(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.description}</label>
                                            </div>
                                            ))
                                        }
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Average</label>
                                    <select name="average" onChange={(e) => handleAnnuelAverage(e)} id="" placeholder="Select Average....." className="form-control">
                                        <option value="Average">Average</option>
                                        <option value="Ratio">Ratio</option>
                                        <option value="Average & Ratio">Average & Ratio</option>
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditAnnuelAverage} onHide={handleEditCloseModalAnnuelAverage} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update AnnuelAverage Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateAnnuelAverage(e, dataEditAnnuel.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class</label>
                                    <select name="id_classe" defaultValue={dataEditAnnuel.libelle} onChange={(e) => handleAnnuelAverage(e)} id=""  className="form-control">
                                    <option value="">Select classe</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Exams Name</label>{
                                        dataExamsNameOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_exam_name" 
                                                id="id_exam_name"  
                                                className="form-check-input"
                                                defaultValue={dataEditAnnuel.description}
                                                onChange={(e) => handleAAAnnuelAverage(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.description}</label>
                                            </div>
                                            ))
                                        }
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Average</label>
                                    <select name="average" defaultValue={dataEditAnnuel.average} onChange={(e) => handleAnnuelAverage(e)} id="" placeholder="Select Average....." className="form-control">
                                        <option value="Average">Average</option>
                                        <option value="Ratio">Ratio</option>
                                        <option value="Average & Ratio">Average & Ratio</option>
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> 
            {/* formulaire Annuel Average */}


            {/* formulaire Name Promotion */}
            <Modal show={showModalNamePromotion} onHide={handleCloseModalNamePromotion} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Name Promotion Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitNamePromotion(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleNamePromotion(e)} placeholder="description..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" onChange={(e) => handleNamePromotion(e)} placeholder="code..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditNamePromotion} onHide={handleEditCloseModalNamePromotion} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Name Promotion Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateNamePromotion(e, dataEditNamePro.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditNamePro.description} onChange={(e) => handleNamePromotion(e)} placeholder="description..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" defaultValue={dataEditNamePro.code} onChange={(e) => handleNamePromotion(e)} placeholder="code..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Name Promotion */}

            
            {/* formulaire Promotion */}
            <Modal show={showModalPromotion} onHide={handleCloseModalPromotion} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Promotion subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitPromotion(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handlePromotion(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Sub Level</label>
                                    <select name="id_sublevel" onChange={(e) => handlePromotion(e)} id=""  className="form-control">
                                    <option value="">Select description</option>{
                                    dataSublevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Promotion</label>
                                    <input type="text" name="promotion"  onChange={(e) => handlePromotion(e)}  className="form-control" />
                                </div>
                                <div className="col-12 col-sm-3">
                                    <label htmlFor="">Max</label>
                                    <input type="text" name="max"  onChange={(e) => handlePromotion(e)}  className="form-control" />
                                </div>
                                <div className="col-12 col-sm-3">
                                    <label htmlFor="">Min</label>
                                    <input type="text" name="min"  onChange={(e) => handlePromotion(e)}  className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditPromotion} onHide={handleEditCloseModalPromotion} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Promotion Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdatePromotion(e, dataEditPro.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontweight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_sublevel" defaultValue={dataEditPro.libelle} onChange={(e) => handlePromotion(e)} id=""  className="form-control">
                                    <option value="">Select description</option>{
                                    dataSublevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Promotion</label>
                                    <input type="text" name="promotion" defaultValue={dataEditPro.promotion} onChange={(e) => handlePromotion(e)} placeholder="promotion..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Max</label>
                                    <input type="text" name="max" defaultValue={dataEditPro.max} onChange={(e) => handlePromotion(e)} placeholder="max..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Min</label>
                                    <input type="text" name="min" defaultValue={dataEditPro.min} onChange={(e) => handlePromotion(e)} placeholder="min..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> 
            {/* formulaire Promotion */}


            {/* formulaire Id Card Validity */}
            <Modal show={showModalCardValidity} onHide={handleCloseModalCardValidity} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Id Card Validity Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitCardValidity(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Validity</label>
                                    <input type="date" name="validity" onChange={(e) => handleCardValidity(e)} placeholder="validity..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditCardValidity} onHide={handleEditCloseModalCardValidity} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Id Card Validity Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateCardValidity(e, dataEditCard.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Validity</label>
                                    <input type="date" name="validity" defaultValue={dataEditCard.validity} onChange={(e) => handleCardValidity(e)} placeholder="validity..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Id Card Validity */}


            {/* formulaire Class Call */}
            <Modal show={showModalClassCall} onHide={handleCloseModalClassCall} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Class Call Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitClassCall(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="
                                    " onChange={(e) => handleClassCall(e)} placeholder="code..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleClassCall(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditClassCall} onHide={handleEditCloseModalClassCall} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Class Call Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateClassCall(e, dataEditClassC.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" defaultValue={dataEditClassC.code} onChange={(e) => handleClassCall(e)} placeholder="code..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditClassC.description} onChange={(e) => handleClassCall(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Class Call */}


            {/* formulaire Reason Of Leaving */}
            <Modal show={showModalReasonLeaving} onHide={handleCloseModalReasonLeaving} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Reason Of Leaving Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitReasonLeaving(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">reason</label>
                                    <input type="text" name="reason" onChange={(e) => handleReasonLeaving(e)} placeholder="reason..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Group</label>
                                    <input type="text" name="group" onChange={(e) => handleReasonLeaving(e)} placeholder="group..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditReasonLeaving} onHide={handleEditCloseModalReasonLeaving} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Reason Of Leaving Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateReasonLeaving(e, dataEditLeaving.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">reason</label>
                                    <input type="text" name="reason" defaultValue={dataEditLeaving.reason} onChange={(e) => handleReasonLeaving(e)} placeholder="reason..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Group</label>
                                    <input type="text" name="group" defaultValue={dataEditLeaving.group} onChange={(e) => handleReasonLeaving(e)} placeholder="group..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Reason Of Leaving */}


            {/* formulaire Classes Timetables */}
            <Modal show={showModalClasseTime} onHide={handleCloseModalClasseTime} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Classes Timetables Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitClasseTime(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Start Time</label>
                                    <input type="time" name="start_time" onChange={(e) => handleClasseTime(e)} placeholder="start_time..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">End Time</label>
                                    <input type="time" name="end_time" onChange={(e) => handleClasseTime(e)} placeholder="end_time..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditClasseTime} onHide={handleEditCloseModalClasseTime} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Classes Timetables Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateClasseTime(e, dataEditClasseTi.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Start Time</label>
                                    <input type="time" name="start_time" defaultValue={dataEditClasseTi.start_time} onChange={(e) => handleClasseTime(e)} placeholder="start_time..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">End Time</label>
                                    <input type="time" name="end_time" defaultValue={dataEditClasseTi.end_time} onChange={(e) => handleClasseTime(e)} placeholder="end_time..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Classes Timetables */}


            {/* formulaire Class Time Slots */}
            <Modal show={showModalClassTimeSlot} onHide={handleCloseModalClassTimeSlot} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Class Time Slots Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitClassTimeSlot(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 

                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">libelle</label>
                                    <select name="id_classe" onChange={(e) => handleClassTimeSlot(e)} id=""  className="form-control">
                                    <option value="">Choose class</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">choose Days</label> <br />
                                    <input type="checkbox" id="monday" name="days" value="monday" onChange={(e) => handleCHDClassTimeSlot(e)} className="form-check-label" /><label >&ensp; Monday </label> <br />
                                    <input type="checkbox" id="tuesday" name="days" value="tuesday" onChange={(e) => handleCHDClassTimeSlot(e)} className="form-check-label" /><label >&ensp; Tuesday </label> <br />
                                    <input type="checkbox" id="wednesday" name="days" value="wednesday" onChange={(e) => handleCHDClassTimeSlot(e)} className="form-check-label" /><label >&ensp; Wednesday </label> <br />
                                    <input type="checkbox" id="thurday" name="days" value="thurday" onChange={(e) => handleCHDClassTimeSlot(e)} className="form-check-label" /><label >&ensp; Thurday </label> <br />
                                    <input type="checkbox" id="friday" name="days" value="friday" onChange={(e) => handleCHDClassTimeSlot(e)} className="form-check-label" /><label >&ensp; Friday </label> <br />
                                    <input type="checkbox" id="saturday" name="days" value="saturday" onChange={(e) => handleCHDClassTimeSlot(e)} className="form-check-label" /><label >&ensp; Saturday </label> <br />
                                    <input type="checkbox" id="sunday" name="days" value="sunday" onChange={(e) => handleCHDClassTimeSlot(e)} className="form-check-label" /><label >&ensp; Sunday </label> <br />
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Choose Time Slots</label>{
                                        dataClasseTimeOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_time" 
                                                id="id_time"  
                                                className="form-check-input"
                                                onChange={(e) => handleCHTClassTimeSlot(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.start_time} - {result.end_time}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* <Modal show={showModalEditClassTimeSlot} onHide={handleEditCloseModalClassTimeSlot} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Class Time Slots Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateClassTimeSlot(e, dataEditClassSlot.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle</label>
                                    <select name="id_classe" defaultValue={dataEditClassSlot.libelle} onChange={(e) => handleClassTimeSlot(e)} id=""  className="form-control">
                                    <option value="">Choose class</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Start Time</label>
                                    <input type="time" name="start_time" defaultValue={dataEditClassSlot.start_time} onChange={(e) => handleClassTimeSlot(e)} placeholder="start_time..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">End Time</label>
                                    <input type="time" name="end_time" defaultValue={dataEditClassSlot.end_time} onChange={(e) => handleClassTimeSlot(e)} placeholder="end_time..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> */}
            {/* formulaire Class Time Slots */}


        </>
    )
    
}
export default FinancesParameters;