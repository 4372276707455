import React, { useEffect, useState } from "react";
import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link } from 'react-router-dom'

import DataTable from 'react-data-table-component';
import Services from '../services/services';
import Swal from 'sweetalert2';

import { Modal, Button } from 'react-bootstrap';


function AcademicYear() {

    const [showModal, setShowModal] = useState(false);

    function ShowModal() {
        setShowModal(true);

    }
    const handleCloseModal = () => setShowModal(false)


    const [dataYear, setDataYear] = useState({
        year: '',
        debut: '',
        fin: '',
    })

    const handle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataYear, [name]: value }
        setDataYear(data);
        console.log(data)


    }

    function handleSubmit(e) {

        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataYear.year);
        bodyFormData.append('debut', dataYear.debut);
        bodyFormData.append('fin', dataYear.fin);

        Services.InsertAcaYear(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModal()
                getallAcaYear()

            })
            .catch((error) => {
                console.log(error.response);
            })

    }

    useEffect(() => {
        getallAcaYear()
    }, [])

    const [datagetYear, setDatagetYear] = useState([])

    function getallAcaYear() {
        Services.GetAllAcaYear()
            .then((response) => {

                setDatagetYear(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const columns = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Date Start",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.start} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Date End",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.end} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Etat",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.etat === 0) {
                            return (
                                <Link className="me-2">
                                    <i className="fas fa-toggle-off fa-2x" onClick={() => OpenAcaYear(rows.year, rows.id_year)} style={{ color: 'red' }}></i>
                                </Link>
                            )
                        } else {
                            return (
                                <Link className="e-2">
                                    <i className="fas fa-toggle-on fa-2x" onClick={() => CloseAcaYear(rows.year, rows.id_year)} style={{ color: 'green' }}></i>
                                </Link>
                            )
                        }
                    })()}



                </div>


            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                {(() => {
                    if (rows.etat === 0) {
                        return (
                            <>
                           
        
                            <Link to={"#"}  onClick={() => ShowModalEdit(rows.id_year)} className="btn btn-sm bg-success-light me-2">
                                <i className="fas fa-pen"></i>
                            </Link>
                            <Link to="#"  onClick={(e)=> DeleteYear(e, rows.id_year, rows.year)} className="btn btn-sm bg-danger-light">
                                <i className="fas fa-trash"></i>
                            </Link>
                            </>
                        )
                    } else {
                        return (
                            <></>
                        )
                    }

                    
                })()}
                
                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]


    function OpenAcaYear(years, id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "Opening of the "+years+" academic year",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, !'
          }).then((result) => {
            if (result.isConfirmed) {
              Services.openYear(id)
                .then(response => {
                    getallAcaYear()
                  console.log(response.data);
                  Swal.fire(
                    
                    'Success',
                    'The academic year 2022-2023 is open',
                  )
                })
                .catch(e => {
                  console.log(e);
                });
      
            }
          })
    }


    function CloseAcaYear(years, id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "Closed of the "+years+" academic year",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, !'
          }).then((result) => {
            if (result.isConfirmed) {
              Services.closeYear(id)
                .then(response => {
                    getallAcaYear()
                  console.log(response.data);
                  Swal.fire(
                    
                    'Success',
                    'The academic year 2022-2023 is closed',
                  )
                })
                .catch(e => {
                  console.log(e);
                });
      
            }
          })
    }


    const [showModalEdit, setShowModalEdit] = useState(false);

    function ShowModalEdit(id) {
        setShowModalEdit(true);
        dataAcaYearEdit(id)

    }
    const handleCloseModalEdit = () => setShowModalEdit(false);

    const [dataEditYear, setDataEditYear] = useState([]);
    const [idYear, setIdYear] = useState([]);


    function dataAcaYearEdit(id) {
        Services.getYearBy(id)
            .then((response) => {

                setDataEditYear(response.data[0])
                setIdYear(response.data[0].id_year)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    function handleSubmitEdit(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('idYear', idYear);
        bodyFormData.append('year', dataYear.year);
        bodyFormData.append('year', dataYear.year);
        bodyFormData.append('debut', dataYear.debut);
        bodyFormData.append('fin', dataYear.fin);

        Services.UpdateAcaYear(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalEdit()
                getallAcaYear()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    function DeleteYear(e, id, year) {
        Swal.fire({
            title: 'Are you sure?',
            html: "You can't go back! The academic year "+year+" will be deleted <br>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              Services.removeAcaYear(id)
                .then(response => {
                  console.log(response.data);
                  getallAcaYear()
                  Swal.fire(
                    'Academic year has been deleted',
                    'success'
                  )
                })
                .catch(e => {
                  console.log(e);
                });
      
            }
          })
    }
    return (

        <>
            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Academic Year</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Academic Year</li>
                                </ul>
                            </div>
                            <div className="col-auto text-end float-end ms-auto">
                                <button type="button" className="btn btn-primary" onClick={() => ShowModal()} ><i className="fas fa-plus"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-table">
                                <div className="card-body">
                                    <div className="table-responsive">

                                        <DataTable
                                            title="DataTable"
                                            // customStyles={customStyles}
                                            columns={columns}
                                            data={datagetYear}
                                            responsive
                                            noHeader
                                            defaultSortFieldId={1}
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            //pagination
                                            // selectableRows
                                            persistTableHead
                                            highlightOnHover
                                            exportHeaders={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <footer>
                    <p>Copyright © 2020 Dreamguys.</p>
                </footer> */}

            </div>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add academic year </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Year</label>

                                    <select name="year" id="" className="form-control" onChange={(e) => handle(e)}>
                                        <option value="">Select Year</option>
                                        <option value="2022-2023">2022-2023</option>
                                        <option value="2023-2024">2023-2024</option>
                                        <option value="2024-2025">2024-2025</option>
                                        <option value="2025-2026">2025-2026</option>
                                        <option value="2026-2027">2026-2027</option>
                                        <option value="2027-2028">2027-2028</option>
                                        <option value="2028-2029">2028-2029</option>
                                        <option value="2029-2030">2029-2030</option>
                                    </select>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Debut</label>
                                    <input type="date" name="debut" onChange={(e) => handle(e)} className="form-control" />
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Fin</label>
                                    <input type="date" name="fin" onChange={(e) => handle(e)} className="form-control" />

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>


                        <Button type="submit" variant='primary'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>



            <Modal show={showModalEdit} onHide={handleCloseModalEdit} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Edit academic year </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitEdit(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Year</label>
                                   
                                    <select name="year" readOnly id="" className="form-control" onChange={(e) => handle(e)}>
                                        <option defaultValue={dataEditYear.year}>{dataEditYear.year}</option>
                                        <option defaultValue="2022-2023">2022-2023</option>
                                        <option defaultValue="2023-2024">2023-2024</option>
                                        <option defaultValue="2024-2025">2024-2025</option>
                                        <option defaultValue="2025-2026">2025-2026</option>
                                        <option defaultValue="2026-2027">2026-2027</option>
                                        <option defaultValue="2027-2028">2027-2028</option>
                                        <option defaultValue="2028-2029">2028-2029</option>
                                        <option defaultValue="2029-2030">2029-2030</option>
                                    </select>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Debut</label>
                                    <input type="date" name="debut" defaultValue={dataEditYear.start} onChange={(e) => handle(e)} className="form-control" />
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Fin</label>
                                    <input type="date" name="fin" defaultValue={dataEditYear.end} onChange={(e) => handle(e)} className="form-control" />

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>


                        <Button type="submit" variant='primary'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AcademicYear;