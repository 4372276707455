import React from "react";
import Menus from '../components/Menus';
import Header from '../components/Header';
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';

import { Link } from "react-router-dom";
import Services from "../services/services";
import Swal from "sweetalert2";

function ItemsSettings() {

    const [showModalItems, setShowModalItems] = useState(false)
    function ShowModalItems() {
        setShowModalItems(true)
    }
    const HideModalItems = () => setShowModalItems(false)


    useEffect(()=>{
        getItemsGroups()
    }, [])

    const [itemGroup, setItemGroup] = useState([])

    function getItemsGroups() {
        Services.GetItemsGroup()
        .then((response) => {
            setItemGroup(response.data)
            console.log(response.data)
        })
        .catch((error) =>{
            console.log(error)
        })
    }

    const [dataItems, setDataItems] = useState({
        name_item: '',
        description: '',
        iditemGroup:''
    })


    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = {...dataItems, [name]: value}
        setDataItems(data)
        console.log(data)

    }


    function handleSubmit(e) {
            e.preventDefault()

            const dataItem = new FormData()

            dataItem.append('name_item', dataItems.name_item)
            dataItem.append('description', dataItems.description)
            dataItem.append('iditemGroup', dataItems.iditemGroup)

            Services.AddItems(dataItem)
            .then((response)=>{
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                HideModalItems()
                getItems()

            })
            .catch((error)=>{
                console.log(error)
            })
    }
    
    
    useEffect(()=>{
        getItems()
    }, [])

    const [items, setItems] = useState([])

    function getItems() {
        Services.GetItems()
        .then((response) => {
            setItems(response.data)
            console.log(response.data)
        })
        .catch((error) =>{
            console.log(error)
        })
    }

    const columnsItems = [
        {
            name: "Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Groupe",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.title} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">
                    <button className="btn btn-sm bg-primary-light me-3" onClick={(e) => UpdateItems(rows.id_item)}>
                        <i className="fas fa-edit"></i>
                    </button>
                    <Link to="#" className="btn btn-sm bg-danger-light me-3" onClick={(e) => DeleteItems(rows.id_item)}>
                        <i className="fas fa-trash"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },
    ]

    
    function DeleteItems(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "You can't go back! The <b>Item</b> name will be deleted",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Services.removeItems(id)
                    .then(response => {
                        console.log(response.data);
                        getItems()
                        Swal.fire(
                            'Section name has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }


    const [showUpdateModalItems, setShowUpdateModalItems] = useState(false)
   
    const HideUpdateModalItems = () => setShowUpdateModalItems(false)

   
    const [dataUpdateItems, setDataUpdateItems] = useState([])
    const [idItems, setIdItems] = useState([])

    function UpdateItems(id) {
        setIdItems(id)

        Services.GetItemsId(id)
        .then((response) => {
            
            setDataUpdateItems(response.data[0])
            console.log(response.data)
            setShowUpdateModalItems(true)
            
        })
        .catch((error) =>{
            console.log(error)
        })
    }


    function handleUpdate(e) {
        e.preventDefault()

        const dataItem = new FormData()

        dataItem.append('id', idItems)
        dataItem.append('name_item', dataItems.name_item)
        dataItem.append('description', dataItems.description)
        dataItem.append('iditemGroup', dataItems.iditemGroup)

        Services.UpdateItems(dataItem)
        .then((response)=>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000
            })
            console.log(response.data)
            HideUpdateModalItems()
            getItems()

        })
        .catch((error)=>{
            console.log(error)
        })
}

    return (
        <>
            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="page-title">Invoice settings</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/Dashboard"}>Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">General Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-xl-3 col-md-4">
                            <div className="widget settings-menu">
                                <ul>
                                    <li className="nav-item">
                                        <Link to={"/Invoice-settings"} className="nav-link">
                                            <i className="fa fa-book"></i> <span>Items Groups</span>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to={"/Items-settings"} className="nav-link active">
                                            <i className="fa fa-book"></i> <span>Items</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/Simple-invoice"} className="nav-link">
                                            <i className="fas fa-file-invoice"></i> <span>Simple invoice</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/bank-settings"} className="nav-link">
                                            <i className="fas fa-university"></i> <span>Bank Settings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-9 col-md-8">
                            <div className="card invoices-settings-card">
                                <div className="card-header">
                                    <h5 className="card-title">Items</h5>
                                </div>
                                <div className="card-body">
                                    <div className="col-auto text-end float-left ms-auto">
                                        <button type="button" className="btn btn-outline-primary me-2 mt-3" onClick={() => ShowModalItems()}>Add Item <i className="fas fa-plus"></i></button>
                                    </div>
                                    <div className="table-responsive mt-5">
                                        <DataTable
                                            title="DataTable"
                                            columns={columnsItems}
                                            data={items}
                                            // customStyles={customStyles}
                                            responsive
                                            noHeader
                                            defaultSortFieldId={1}
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            // selectableRows
                                            persistTableHead
                                            highlightOnHover
                                            exportHeaders={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={showModalItems} onHide={HideModalItems} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h3 style={{ color: 'orange' }}>Add Item </h3>
                            </Modal.Title>
                        </Modal.Header>
                        <form action="#" className="invoices-settings-form" onSubmit={(e) => handleSubmit(e)}>
                            <Modal.Body>


                               
                                <div className="row align-items-center form-group">
                                    <label htmlFor="Name" className="col-sm-3 col-form-label input-label">Name</label>
                                    <div className="col-sm-9">
                                        <input type="text" name="name_item" value={dataItems.name_item} onChange={(e) => handle(e)} className="form-control" placeholder="Name..." />
                                    </div>
                                </div>

                                <div className="row align-items-center form-group">
                                    <label htmlFor="Description" className="col-sm-3 col-form-label input-label">Description</label>
                                    <div className="col-sm-9">
                                        <input type="text" name="description" value={dataItems.description} onChange={(e) => handle(e)} className="form-control" placeholder="Description..." />
                                    </div>
                                </div>

                                <div className="row align-items-center form-group">
                                    <label htmlFor="Items groupe" className="col-sm-3 col-form-label input-label">Items Groups</label>
                                    <div className="col-sm-9">
                                        <select name="iditemGroup" onChange={(e) => handle(e)} id=""  className="form-control">
                                            <option value="">Select item group</option>
                                            {
                                                itemGroup.map((res)=>(
                                                    <option value={res.id_itg} key={res.id_itg}>{res.title}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                               
                               
                               
                               
                               


                            </Modal.Body>
                            <Modal.Footer>
                           

                                <Button type="submit"  className="btn btn-primary-save-bg">
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>


                    <Modal show={showUpdateModalItems} onHide={HideUpdateModalItems} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h3 style={{ color: 'orange' }}>Add Item </h3>
                            </Modal.Title>
                        </Modal.Header>
                        <form action="#" className="invoices-settings-form" onSubmit={(e) => handleUpdate(e)}>
                            <Modal.Body>


                               
                                <div className="row align-items-center form-group">
                                    <label htmlFor="Name" className="col-sm-3 col-form-label input-label">Name</label>
                                    <div className="col-sm-9">
                                        <input type="text" name="name_item" defaultValue={dataUpdateItems.libelle} onChange={(e) => handle(e)} className="form-control" placeholder="Name..." />
                                    </div>
                                </div>

                                <div className="row align-items-center form-group">
                                    <label htmlFor="Description" className="col-sm-3 col-form-label input-label">Description</label>
                                    <div className="col-sm-9">
                                        <input type="text" name="description" defaultValue={dataUpdateItems.description} onChange={(e) => handle(e)} className="form-control" placeholder="Description..." />
                                    </div>
                                </div>

                                <div className="row align-items-center form-group">
                                    <label htmlFor="Items groupe" className="col-sm-3 col-form-label input-label">Items Groups</label>
                                    <div className="col-sm-9">
                                        <select name="iditemGroup" onChange={(e) => handle(e)} id=""  className="form-control">
                                            <option defaultValue={dataUpdateItems.id_item_group}>{dataUpdateItems.title}</option>
                                            {
                                                itemGroup.map((res)=>(
                                                    <option value={res.id_itg} key={res.id_itg}>{res.title}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                               
                               
                               
                               
                               


                            </Modal.Body>
                            <Modal.Footer>
                           

                                <Button type="submit"  className="btn btn-primary-save-bg">
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                </div>
            </div>
        </>
    )
}

export default ItemsSettings;