import { useEffect, useState } from 'react';
import logo from '../logo-white.png';
import services from '../services/services';
import Swal from 'sweetalert2';


function Login() {



    useEffect(()=>{
        redirect();

        // services.get_auth(JSON.parse(sessionStorage.getItem('token'))).then((res) =>{
        //     sessionStorage.setItem('id_user', JSON.stringify(res.data.id))
        // });
    }, [JSON.parse(sessionStorage.getItem('token'))])
    



const redirect = () =>{
    if(JSON.parse(sessionStorage.getItem('token'))){
        if (JSON.parse(sessionStorage.getItem('role_us')) === '909') {
                
            window.location.replace('/admission-table') 
        }
    }
}

const [username, setUsername] = useState('')
const [password, setPassword] = useState('')

const usernameInput = document.getElementById("username");
const passwordInput = document.getElementById("password");


const handlerSubmit = (e) => {
    if (username !== '' && password !== '') {

        e.preventDefault();
         const bodyFormData = new FormData();
         bodyFormData.append('username', username);
         bodyFormData.append('password', password);

        services.log_auth(bodyFormData)
        .then((response) => {
            sessionStorage.setItem('role_us', JSON.stringify(response.data.role))
            sessionStorage.setItem('token', JSON.stringify(response.data.token))
            sessionStorage.setItem('id_user', JSON.stringify(response.data.id_user))
            console.log(response.data)

            if (response.data.role === '909') {
                
                window.location.replace('/admission-table') 
            }

            if (response.data.role === '1') {
                
                window.location.replace('/Dashboard') 
            }

        }).catch((error) => {
            console.error(error.response);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 5000
              })

            usernameInput.value = ''
            passwordInput.value = ''
        });


    }else
            if(username === '' && password !== ''){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'The Username field is required',
            text: '',
          })
         

        }else
          if(username !== '' && password === ''){
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'The Password field is required',
                text: '',
              })
             
          }else{
             Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Fields are required',
                text: '',
              })
             
          }
}



    return (
        <div className="main-wrapper login-body">
            <div className="login-wrapper">
                <div className="container">
                    <div className="loginbox">
                        <div className="login-left">
                            <img className="img-fluid" src={logo} alt="Logo" />
                        </div>
                        <div className="login-right">
                            <div className="login-right-wrap">
                                <h1>Login</h1>
                                <p className="account-subtitle">Access to our dashboard</p>

                                <form action="/Dashboard">
                                    <div className="form-group">
                                        <input className="form-control" type="text" id="username" placeholder="Username"  onChange={e => setUsername(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control" type="text" id="password" placeholder="Password"  onChange={e => setPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block" type="button"  onClick={(e)=> handlerSubmit(e)}>Login</button>
                                    </div>
                                </form>

                                <div className="text-center forgotpass"><a href="forgot-password.html">Forgot Password?</a></div>
                                <div className="login-or">
                                    <span className="or-line"></span>
                                    <span className="span-or">or</span>
                                </div>

                                {/* <div className="social-login">
                                    <span>Login with</span>
                                    <a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a><a href="#" className="google"><i className="fab fa-google"></i></a>
                                </div> */}

                                <div className="text-center dont-have">Online registration create account <a href="/register">Register</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Login;