import { Link } from "react-router-dom";
import Header from "../components/Header";
import Menus from "../components/Menus";
import services from "../services/services";
import Swal from "sweetalert2";
import { useState } from "react";






function TeacherAdd(){

    const [data, setData] = useState({
        It_teacher:'',
        Council_mem:'',
        Civility:'',
        FirstName:'',
        LastName:'',
        Email:'',
        Sms_phone:'',
        Phone_2:'',
        Departemnt:'',
        Job_descrip:'',
        Enter_date:'',
        Contract:'',
        Supervisor:'',
        Main_lang:'',

    })

    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const newdata = { ...data, [name]: value }
        setData(newdata);
        console.log(newdata)
    }


    function handleSubmitData(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('It_teacher', data.It_teacher);
        bodyFormData.append('Council_mem', data.Council_mem);
        bodyFormData.append('Civility', data.Civility);
        bodyFormData.append('FirstName', data.FirstName);
        bodyFormData.append('LastName', data.LastName);
        bodyFormData.append('Email', data.Email);
        bodyFormData.append('Sms_phone', data.Sms_phone);
        bodyFormData.append('Phone_2', data.Phone_2);
        bodyFormData.append('Departemnt', data.Departemnt);
        bodyFormData.append('Job_descrip', data.Job_descrip);
        bodyFormData.append('Enter_date', data.Enter_date);
        bodyFormData.append('Contract', data.Contract);
        bodyFormData.append('Supervisor', data.Supervisor);
        bodyFormData.append('Main_lang', data.Main_lang);

        services.createTeacher(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            })
            .catch((error) => {
                console.log(error.response);
            })

    }

    return (
        <>
            <Header />
            <Menus />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Add Staffs</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/staffs">Staffs</Link></li>
                                    <li className="breadcrumb-item active">Add Staffs</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    
                                            <form onSubmit={(e) => handleSubmitData(e)} encType="multipart/form-data" id="formStudent">
                                                <div className="row">

                                                    <div className="col-12">
                                                        <h5 className="form-title"><span>Staff Information</span></h5>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="row">

                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>It Teacher</label>
                                                                        <select className="form-control" name='It_teacher' id='It_teacher' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                                            <option value={data.It_teacher}>Select It_teacher</option>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Council Member</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Council_mem'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Council_mem}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Civility</label>
                                                                        <select className="form-control" name='Civility' id='Civility' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                                            <option value={data.Civility}>Select Civility</option>
                                                                            <option value="Mister">Mister</option>
                                                                            <option value="Madam">Madam</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>First Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='FirstName'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.FirstName}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Last Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='LastName'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.LastName}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Email</label>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            name='Email'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Email}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>SMS Phone</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Sms_phone'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Sms_phone}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Phone 2</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Phone_2'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Phone_2}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Departements</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Departemnt'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Departemnt}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Job Description</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Job_descrip'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Job_descrip}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Enter date</label>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            name='Enter_date'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Enter_date}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Contract</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Contract'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Contract}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Supervisor</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Supervisor'
                                                                            onChange={(e) => handle(e)}
                                                                            value={data.Supervisor}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Main Language</label>
                                                                        <select className="form-control" name='Main_lang' id='Main_lang' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                                            <option value={data.Main_lang}>Select Main Language</option>
                                                                            <option value="English">English</option>
                                                                            <option value="French">Madam</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mt-5">
                                                        <button type="submit" className="btn btn-success btn-lg">Submit</button>
                                                    </div>

                                                </div>
                                            </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TeacherAdd;



