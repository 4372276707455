import React from "react";
import Menus from '../components/Menus';
import Header from '../components/Header';
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';

import { Link } from "react-router-dom";

import Services from "../services/services";
import Swal from "sweetalert2";

function SimpleInvoice() {

    const [showSimpleInvoice, setShowSimpleInvoice] = useState(false)
    function ShowSimpleInvoices() {
        setShowSimpleInvoice(true)
    }
    const HideSimpleInvoices = () => {
        setShowSimpleInvoice(false)
    }


    useEffect(() => {
        GetClassName()
    }, [])

    const [datagetClass, setDatagetClass] = useState([])
    function GetClassName() {
        Services.getAllClass()
            .then((response) => {

                setDatagetClass(response.data)
                console.log(response.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const [lineInvoice, setLineInvoice] = useState({
        type_invoice : '',
        classes:'',
        number_item:'',

    })

    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = {...lineInvoice, [name]: value}
        setLineInvoice(data)
        console.log(data)

    }
   

    const handleSubmit = () => {
       

        // window.location.replace('/Add-model-invoice/'+lineInvoice.type_invoice+'/'+lineInvoice.classes+'/'+lineInvoice.number_item)
        window.location.replace('/Add-model-invoice/'+lineInvoice.type_invoice+'/'+lineInvoice.classes)
    }


    useEffect(() => {
        GetProformaClass()
    }, [])

    const [dataProforma, setDataProforma] = useState([])
    function GetProformaClass() {
        Services.GetModel()
            .then((response) => {

                setDataProforma(response.data)
                console.log(response.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsModel = [
        {
            name: "Type Invoice",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.type_invoice} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Class",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.classe} </b>
            ),
            sortable: true,
            reorder: true
        },

       


        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">
                    <Link to={"/Detail-model-invoice/"+rows.code_model} className="btn btn-sm bg-info-light me-3">
                        <i className="fas fa-eye"></i>
                    </Link>

                    <button className="btn btn-sm bg-warning-light me-3">
                        <i className="fas fa-edit"></i>
                    </button>

                    <Link to="#" className="btn btn-sm bg-danger-light me-3">
                        <i className="fas fa-trash"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },
    ]

   
   
    

    return (
        <>
            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="page-title">Invoice settings</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/Dashboard"}>Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">General Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-xl-3 col-md-4">
                            <div className="widget settings-menu">
                                <ul>
                                    <li className="nav-item">
                                        <Link to={"/Invoice-settings"} className="nav-link">
                                            <i className="fa fa-book"></i> <span>Items Groups</span>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to={"/Items-settings"} className="nav-link">
                                            <i className="fa fa-book"></i> <span>Items</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/Simple-invoice"} className="nav-link active">
                                            <i className="fas fa-file-invoice"></i> <span>Simple invoice</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/bank-settings"} className="nav-link">
                                            <i className="fas fa-university"></i> <span>Bank Settings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-9 col-md-8">
                            <div className="card invoices-settings-card">
                                <div className="card-header">
                                    <h5 className="card-title">Simple Invoice</h5>
                                </div>
                                <div className="card-body">
                                    <div className="col-auto text-end float-left ms-auto">
                                        <button type="button" className="btn btn-outline-primary me-2 mt-3" onClick={() => ShowSimpleInvoices()}>Add Simple Invoice <i className="fas fa-plus"></i></button>
                                    </div>
                                    <div className="table-responsive mt-5">
                                        <DataTable
                                            title="DataTable"
                                            columns={columnsModel}
                                            data={dataProforma}
                                            // customStyles={customStyles}
                                            responsive
                                            noHeader
                                            defaultSortFieldId={1}
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            // selectableRows
                                            persistTableHead
                                            highlightOnHover
                                            exportHeaders={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={showSimpleInvoice} onHide={HideSimpleInvoices} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h3 style={{ color: 'orange' }}>Invoices model - new</h3>
                            </Modal.Title>
                        </Modal.Header>
                        <form action="#" className="invoices-settings-form">
                            <Modal.Body>


                               
                            <div className="row align-items-center form-group">
                                    <label htmlFor="Type" className="col-sm-3 col-form-label input-label">Type of invoice</label>
                                    <div className="col-sm-9">
                                        <select name="type_invoice" onChange={(e)=>handle(e)} id=""  className="form-control">
                                            <option value="">Select Type of invoice</option>
                                            <option value="ADMISSION">ADMISSION</option>
                                            <option value="RE-REGISTRATION">RE-REGISTRATION</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="row align-items-center form-group">
                                    <label htmlFor="Class" className="col-sm-3 col-form-label input-label">Class</label>
                                    <div className="col-sm-9">
                                        <select name="classes" onChange={(e)=>handle(e)} id=""  className="form-control">
                                            <option value="">Select Class</option>
                                            {
                                                datagetClass.map((res)=>(
                                                    <option value={res.libelle} key={res.id_class}>{res.libelle}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                {/* <div className="row align-items-center form-group">
                                    <label htmlFor="Article" className="col-sm-3 col-form-label input-label">Article number :</label>
                                    <div className="col-sm-9">
                                        <select name="number_item" onChange={(e)=>handle(e)} id=""  className="form-control">
                                            <option value="">Select article number</option>
                                           
                                                {[...Array(101)].map((elementInArray, index) => ( 
                                                    <option value={index}>{index}</option>
                                                    ) 
                                                )}   
                                        </select>
                                        
                                    </div>
                                </div> */}
                               
                               
                               
                               
                               


                            </Modal.Body>
                            <Modal.Footer>
                           

                                <Button type="button" onClick={handleSubmit}  className="btn btn-primary-save-bg">
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>



                </div>
            </div>
        </>
    )
}

export default SimpleInvoice;