

import React, { useState } from 'react';
import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link } from 'react-router-dom'
import services from '../services/services';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';


function StaffAdd() {

    const [dataUserAccess, setDataUserAccess] = useState([]);

    function getallUserAccess() {
        services.getUserAccess()
            .then((response) => {

                setDataUserAccess(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }



    useEffect(() => {
        getallUserAccess()
    }, [])

   


    const columnsStaff = [
        {
            name: "First and LastName",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.firstname} {rows.lastname}</b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "User Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.username} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Role",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.id_usergroup} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Email",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.email} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Etat",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.status === 0) {
                            return (
                                <Link className="me-2">
                                    <i className="fas fa-toggle-off fa-2x" onClick={() => OpenAccessStaff(rows.id)} style={{ color: 'red' }}></i>
                                </Link>
                            )
                        } else {
                            return (
                                <Link className="e-2">
                                    <i className="fas fa-toggle-on fa-2x" onClick={() => CloseAccessStaff(rows.id)} style={{ color: 'green' }}></i>
                                </Link>
                            )
                        }
                    })()}
                    
                </div>

            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateAccessStaff(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteAccessStaff(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
        
    ]   

    function OpenAccessStaff(id) {
        services.openAccessStaff(id)
                    .then(response => {
                        console.log(response.data);
                        getallUserAccess()
                        
                    })
                    .catch(e => {
                        console.log(e);
                    }); 
    }


    /** Fermerture des etats */
    
    function CloseAccessStaff(id) {
        services.closeAccessStaff(id)
                    .then(response => {
                        console.log(response.data);
                        getallUserAccess()
                        
                    })
                    .catch(e => {
                        console.log(e);
                    });    
    }

    /**recuperation de la ligne dans la base de donnees en fonction de l'id */
    const [dataEditAccessStaff, setDataEditAccessStaff] = useState([])

    function dataAccessStaffEdit(id) {
        services.get_AccessStaff_by(id)
        .then((response) => {
            setDataEditAccessStaff(response.data[0])
            console.log(response.data);

        })
        .catch((error) => {
            console.log(error.response);
        })
        
    }
    /**recuperation de la ligne dans la base de donnees en fonction de l'id */

    
    /**Activation du modal pour la modification */
    const [showModalEditAccessStaff, setShowModalEditAccessStaff] = useState(false)

    function ShowModalUpdateAccessStaff(id) {
        setShowModalEditAccessStaff(true);
        dataAccessStaffEdit(id)

    }
    /**Activation du modal pour la modification */


    const handleEditCloseModalAccessStaff = () => setShowModalEditAccessStaff(false)
    /**Activation du modal pour la modification */


    const [data, setData] = useState({
        username:'',
        password:'',

    })

    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const newdata = { ...data, [name]: value }
        setData(newdata);
        // console.log(newdata)
    }


    /**Fonction de modification en fonction de l'id */
    function handleUpdateAccessStaff(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Access', id);
        bodyFormData.append('username', data.username);
        bodyFormData.append('password', data.password);

        services.UpdateAccessStaff(bodyFormData)
        .then((response) => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000
            })
            console.log(response.data)
            handleEditCloseModalAccessStaff()
            getallUserAccess()
        })
        .catch((error) => {
            console.log(error.response);
        })
    }
    /**Fonction de modification en fonction de l'id */


    /**Fonction de Suppression de Suppression en fonction de l'id */

    function DeleteAccessStaff(id) {
        Swal.fire({
            title: 'Are you Sure?',
            html: 'to delete data Access Staff',
            icon:'warning',
            showCancelButton:true,
            confirmButtonColor:'#3085d6',
            cancelButtonColor:'#d33',
            confirmButtonText: 'Yes, delete '
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteAccessStaff(id)
                .then(response => {
                    console.log(response.data);
                    getallUserAccess()
                    Swal.fire(
                        'data Access Staff has been delate',
                        'success'
                    )
                })
                .catch(e => {
                    console.log(e);
                });

            }
        })
        
    }
    /**Fonction de Suppression de Suppression en fonction de l'id */
     


    return (
        <>
            <Header />
            <Menus />


            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Staff</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp</a></li>
                                    <li className="breadcrumb-item active">Access</li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {/* <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalAdmin()} ><i className="fas fa-plus"></i> Add/VIEW Pragram Event </button> */}
                                                
                                                <DataTableExtensions
                                                    columns={columnsStaff}
                                                    data={dataUserAccess}
                                                    export={false}
                                                    print={false}
                                                >
                                                    <DataTable
                                                        title="DataTable"
                                                        // customStyles={customStyles}
                                                        responsive
                                                        noHeader
                                                        defaultSortFieldId={1}
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        persistTableHead
                                                        highlightOnHover
                                                        exportHeaders={false}
                                                    />
                                                </DataTableExtensions>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModalEditAccessStaff} onHide={handleEditCloseModalAccessStaff} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update User Access</h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateAccessStaff(e,dataEditAccessStaff.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold'}}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='username'
                                            onChange={(e) => handle(e)}
                                            defaultValue={dataEditAccessStaff.username}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name='password'
                                            onChange={(e) => handle(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button Button type="Update" variant='warning'>
                            Update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
           
        </>
    )


}

export default StaffAdd;