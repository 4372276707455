import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Menus from "../../components/Menus";
import services from "../../services/services";
import DataTable from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import { Link } from "react-router-dom";


function ManagProgramEvent(){

    const{term_manag} = useParams()

     /** Activation modal d'insertion academic yaer */
     const [showModalProgramEvent, setShowModalProgramEvent] = useState(false)

     function ShowModalProgram() {
         getallProgramEvent()
         setShowModalProgramEvent(true); //open modal
 
     }
     const handleCloseModalProgramEvent = () => setShowModalProgramEvent(false) ///close modal
     /** Activation modal d'insertion academic yaer */
 
 
     /** variable de recuperation des donner entrant dans les cahmp input */
     const [dataProgram, setProgram] = useState({
         term_manag: '',
         id_event: '',
         event_date_start: '',
         event_date_end: '',
         no_school: '',
         no_calendar: '',
         meet: '',
         exam: '',
         event: '',
     })
 
     const handleProgramEvent = (e) => {
         const name = e.target.name;
         const value = e.target.value;
         const data = { ...dataProgram, [name]: value }
         setProgram(data);
         // console.log(data)
     }
 
 
     /** fonction d'insertion des données dans la base de donnée */
     function handleSubmitProgramEvent(e) {
         e.preventDefault();
 
         const bodyFormData = new FormData();
 
         bodyFormData.append('id_term_manag', term_manag);
         bodyFormData.append('id_event', dataProgram.id_event);
         bodyFormData.append('event_date_start', dataProgram.event_date_start);
         bodyFormData.append('event_date_end', dataProgram.event_date_end);
         bodyFormData.append('no_school', dataProgram.no_school);
         bodyFormData.append('no_calendar', dataProgram.no_calendar);
         bodyFormData.append('meet', dataProgram.meet);
         bodyFormData.append('exam', dataProgram.exam);
         bodyFormData.append('event', dataProgram.event);
 
         services.InsertProgramEvent(bodyFormData)
             .then((response) => {
                 Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: response.data.message,
                     showConfirmButton: false,
                     timer: 5000
                 })
                 // console.log(response.data)
                 handleCloseModalProgramEvent();
                 // getallProgramEvent();
 
             })
             .catch((error) => {
                 console.log(error.response);
             })
     }


    useEffect(() => {
        getallProgramEvent()
        getEventNameOpen()
    },[])

    const [dataEventNameOpen, setDataEventNameOpen] = useState([]);

    function getEventNameOpen() {
        services.get_EventName_Open()
            .then((response) => {

                setDataEventNameOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const [dataProgramEvent, setDataProgramEvent] = useState([]);

    function getallProgramEvent() {
        services.getProgramEvent()
            .then((response) => {

                setDataProgramEvent(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsProgramEvent = [
        {
            name: "Term",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.id_level} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Event Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Event Start Time",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_start} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Event End Time",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_end} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Event duration",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.event_dur} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "No School",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.no_school} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "No Calendar",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.no_calendar} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Meet",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.meet} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Exam",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.exam} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Event",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.event} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Academic Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        // {
        //     name: "Update",
        //     cell: (rows) => (
        //         <div className="actions">
        //             {(() => {
                    
        //                 return (
        //                     <>
        //                         <Link to={"#"} onClick={(e) => ShowModalUpdateProgram(rows.id)} className="btn btn-sm bg-success-light me-2">
        //                             <i className="fas fa-edit"></i>
        //                         </Link>
        //                     </>
        //                 )



        //             })()}

        //         </div>

        //     ),
        //     sortable: true,
        //     reorder: true
        // },
        
    ]


    

    return(

        <>
            <Header/>
            <Menus/>

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Management Program Event</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="">Scholarsapp / Mapping</a></li>
                                    <li className="breadcrumb-item active">Management Program Event</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <button type="button" className="btn btn-info mb-3 me-3" onClick={() => ShowModalProgram()} ><i className="fas fa-plus"></i> Program Event </button>
                                                <Link to={"/manag-program-event"}>
                                                    <button type="#" className="btn btn-info mb-3" ><i className="fas fa-plus"></i> View Program Event </button>                                                
                                                </Link>

                                                <DataTable
                                                    title="DataTable"
                                                    // customStyles={customStyles}
                                                    columns={columnsProgramEvent}
                                                    data={dataProgramEvent}
                                                    responsive
                                                    noHeader
                                                    defaultSortFieldId={1}
                                                    defaultSortField="id"
                                                    defaultSortAsc={false}
                                                    pagination
                                                    persistTableHead
                                                    highlightOnHover
                                                    exportHeaders={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Modal show={showModalProgramEvent} onHide={handleCloseModalProgramEvent} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Pragram Event</h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitProgramEvent(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    {/* <label htmlFor="">Select Event Name</label> */}
                                    <select name="id_event" onChange={(e) => handleProgramEvent(e)} id=""  className="form-control">
                                    <option value="">Select Event Name</option>{
                                    dataEventNameOpen.map((result) => (
                                         <option key={result.id} value={result.libelle}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-3">
                                    {/* <label htmlFor="">date_start</label> */}
                                    <input type="date" name="event_date_start" onChange={(e) => handleProgramEvent(e)} placeholder="date_start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-3">
                                    {/* <label htmlFor="">date_end</label> */}
                                    <input type="date" name="event_date_end" onChange={(e) => handleProgramEvent(e)} placeholder="date_end..." className="form-control" />
                                </div> <br /><br /><br />
                                <div className="col-12 col-sm-12" >
                                    {/* <label htmlFor="">date_end</label> */}
                                    <input type="checkbox" name="no_school" id="no_school" value="Yes" onChange={(e) => handleProgramEvent(e)}/>&ensp;
                                    <label for="no_school">No school</label> &emsp;&emsp;
                                    <input type="checkbox" name="no_calendar" id="no_calendar" value="Yes" onChange={(e) => handleProgramEvent(e)}/>&ensp;
                                    <label for="no_calendar">No calendar</label> &emsp;&emsp;
                                    <input type="checkbox" name="meet" id="meet" value="Yes" onChange={(e) => handleProgramEvent(e)}/>&ensp;
                                    <label for="meet">Meet</label> &emsp;&emsp;&ensp;
                                    <input type="checkbox" name="exam" id="exam" value="Yes" onChange={(e) => handleProgramEvent(e)}/>&ensp;
                                    <label for="exam">Exam</label>&emsp;&emsp;
                                    <input type="checkbox" name="event" id="event" value="Yes" onChange={(e) => handleProgramEvent(e)}/>&ensp;
                                    <label for="event">Event</label>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

}
export default ManagProgramEvent;