import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from 'react-router-dom'
import Header from "../../components/Header";
import Menus from "../../components/Menus";
import { Button, Modal } from "react-bootstrap";
import services from "../../services/services";
import Swal from "sweetalert2";



function TeacherSubject() {

    /** Activation modal d'insertion Teacher Subject  */
    const [showModalTeacherSubject, setShowModalTeacherSubject] = useState(false)

    function ShowModalTeachSub() {
        getClasseSubjectOpen()
        getTeacherOpen()
        setShowModalTeacherSubject(true); //open modal

    }
    const handleCloseModalTeacherSubject = () => setShowModalTeacherSubject(false) ///close modal
    /** Activation modal d'insertion Teacher Subject  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataTeachSub, setTeachSub] = useState({
        id_teacher: '',
        id_class_sub: '',
    })


    const handleTeacherSubject = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataTeachSub, [name]: value }
        setTeachSub(data);
        console.log(data);

    }



    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitTeacherSubject(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_teacher', dataTeachSub.id_teacher);
        bodyFormData.append('id_class_sub', dataTeachSub.id_class_sub);

        services.InsertTeacherSubject(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalTeacherSubject();
                getallTeacherSubject();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */

    useEffect(() => {
        getClasseSubjectOpen()
        getTeacherOpen()
        getallTeacherSubject()
    }, [])

    const { term } = useParams()


    const [dataClasseSubjectOpen, setDataClasseSubjectOpen] = useState([]);

    function getClasseSubjectOpen() {
        services.get_ClasseSubject_Open()
            .then((response) => {

                setDataClasseSubjectOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataTeacherOpen, setDataTeacherOpen] = useState([]);

    function getTeacherOpen() {
        services.get_Teacher_Open()
            .then((response) => {

                setDataTeacherOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const [dataTeacherSubject, setDataTeacherSubject] = useState([]);

    function getallTeacherSubject() {
        services.getTeacherSubject()
            .then((response) => {

                setDataTeacherSubject(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsTeacherSubject = [
        {
            name: "Class",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.class_section} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Teacher",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.firstname} {rows.lastname}</b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Academic Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year}</b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                {/* <Link to={"#"} onClick={(e) => ShowModalUpdateTeachSub(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSubject(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link> */}
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>

            <Menus />
            <Header />
            {term}


            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Teacher Subject {term}</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Mapping</a></li>
                                    <li className="breadcrumb-item active">Teacher Subject</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalTeachSub()} ><i className="fas fa-plus"></i> Add Teacher Subject </button>

                                                <DataTable
                                                    title="DataTable"
                                                    // customStyles={customStyles}
                                                    columns={columnsTeacherSubject}
                                                    data={dataTeacherSubject}
                                                    responsive
                                                    noHeader
                                                    defaultSortFieldId={1}
                                                    defaultSortField="id"
                                                    defaultSortAsc={false}
                                                    pagination
                                                    persistTableHead
                                                    highlightOnHover
                                                    exportHeaders={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* formulaire Teacher Subject */}

            <Modal show={showModalTeacherSubject} onHide={handleCloseModalTeacherSubject} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Teacher Subject </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitTeacherSubject(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Teacher</label>
                                    <select name="id_teacher" onChange={(e) => handleTeacherSubject(e)} id="" className="form-control">
                                        <option value="">Select Teacher</option>{
                                            dataTeacherOpen.map((result) => (
                                                <option key={result.id} value={result.id}>{result.firstname} {result.lastname}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class Subject</label>
                                    <select name="id_class_sub" onChange={(e) => handleTeacherSubject(e)} id="" className="form-control">
                                        <option value="">Select Class Subject</option>{
                                            dataClasseSubjectOpen.map((result) => (
                                                <option key={result.id} value={result.id}>{result.class_section}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* formulaire Teacher Subject */}


        </>

    )
}
export default TeacherSubject;