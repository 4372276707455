import React, { useEffect, useState } from "react";
import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link } from 'react-router-dom'

import DataTable from 'react-data-table-component';
import Services from '../services/services';
import Swal from 'sweetalert2';

import { Modal, Button } from 'react-bootstrap';


function ClassSection() {

    const [showClassModal, setShowClassModal] = useState(false);
    const [showSectionModal, setShowSectionModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);

    function ShowModalClass() {
        setShowClassModal(true);

    }

    function ShowModalSection() {
        setShowSectionModal(true);

    }

    function ShowModalAssign() {
        setShowAssignModal(true);

    }
    const handleCloseClassModal = () => setShowClassModal(false)
    const handleCloseSectionModal = () => setShowSectionModal(false)
    const handleCloseAssignModal = () => setShowAssignModal(false)


    const [dataClass, setDataClass] = useState({
        name: '',
        code: '',
    })

    const handleClass = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataClass, [name]: value }
        setDataClass(data);
        console.log(data)


    }

    function handleSubmitClass(e) {

        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataClass.name);
        bodyFormData.append('code', dataClass.code);

        Services.InsertNameClass(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                GetClassName()
                handleCloseClassModal()

            })
            .catch((error) => {
                console.log(error.response);
            })

    }

    useEffect(() => {
        GetClassName()
    }, [])

    const [datagetClass, setDatagetClass] = useState([])
    function GetClassName() {
        Services.getAllClass()
            .then((response) => {

                setDatagetClass(response.data)
                console.log(response.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const columsClass = [
        {
            name: "Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Code",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    <Link to="#" onClick={() => DeleteClass(rows.libelle, rows.id_class)} className="btn btn-sm bg-danger-light">
                        <i className="fas fa-trash"></i>
                    </Link>

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]



    function DeleteClass(clas, id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "You can't go back! The <b>" + clas + "</b> name will be deleted",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Services.removeClass(id)
                    .then(response => {
                        console.log(response.data);
                        GetClassName()
                        Swal.fire(
                            'Class name has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }







    const [dataSection, setDataSection] = useState({
        name: '',
    })

    const handleSection = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSection, [name]: value }
        setDataSection(data);
        console.log(data)


    }

    function handleSubmitSection(e) {

        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataSection.name);

        Services.InsertNameSection(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                GetSectionName()
                handleCloseSectionModal()

            })
            .catch((error) => {
                console.log(error.response);
            })

    }

    useEffect(() => {
        GetSectionName()
    }, [])

    const [datagetSection, setDatagetSection] = useState([])

    function GetSectionName() {
        Services.getAllSection()
            .then((response) => {

                setDatagetSection(response.data)
                console.log(response.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const columsSection = [
        {
            name: "Section",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.title} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">

                    <Link to="#" className="btn btn-sm bg-danger-light" onClick={(e) => DeleteSection(rows.title, rows.id_sect)}>
                        <i className="fas fa-trash"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },
    ]



    function DeleteSection(sect, id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "You can't go back! The <b>Section " + sect + "</b> name will be deleted",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Services.removeSection(id)
                    .then(response => {
                        console.log(response.data);
                        GetSectionName()
                        Swal.fire(
                            'Section name has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }




    const [assigned, setAssigned] = useState([])

    function handleSectionAssign(e) {
        const { name, value } = e.target
        setAssigned({
            ...assigned,
            [name]: value
        })

        console.log(e.target.value)

    }


    const [classes, setClasses] = useState({
            classeSelect :'',
        })

    function handleClasses(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...classes, [name]: value }
        setClasses(data);
        console.log(data)



    }


    function handleSubmitAssign(e) {
        e.preventDefault()
        const bodyFormData = new FormData();

        bodyFormData.append('classe', classes.classeSelect);

        Object.keys(assigned).map(function (cles) {
            bodyFormData.append(cles, assigned[cles]);
            return [cles, assigned[cles]];

            // console.log(cles)
        });


        Services.AssignSection(bodyFormData)
        .then((response) => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000
            })
            console.log(response.data)
            handleCloseAssignModal();

        })
        .catch((error) => {
            console.log(error.response);
        })

    }


    return (

        <>
            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Class and Section</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Assign Class Section</li>
                                </ul>
                            </div>
                            <div className="col-auto text-end float-end ms-auto">

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="col-md-12">
                                <div className="card bg-white">
                                    <div className="card-header">
                                        <h5 className="card-title">Tabs</h5>
                                    </div>
                                    <div className="card-body">
                                        <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                                            <li className="nav-item"><a className="nav-link active" href="#solid-rounded-justified-tab1" data-bs-toggle="tab">Class</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#solid-rounded-justified-tab2" data-bs-toggle="tab">Section</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#solid-rounded-justified-tab3" data-bs-toggle="tab">Assign Class and Section</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane show active" id="solid-rounded-justified-tab1">
                                                <button type="button" className="btn btn-info mt-3" onClick={() => ShowModalClass()} style={{ color: '#fff' }}>Add Class <i className="fas fa-plus"></i></button>
                                                <div className="table-responsive mt-5">

                                                    <DataTable
                                                        title="DataTable"
                                                        columns={columsClass}
                                                        data={datagetClass}
                                                        // customStyles={customStyles}
                                                        responsive
                                                        noHeader
                                                        defaultSortFieldId={1}
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        // selectableRows
                                                        persistTableHead
                                                        highlightOnHover
                                                        exportHeaders={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="solid-rounded-justified-tab2">
                                                <button type="button" className="btn btn-info mt-3" onClick={() => ShowModalSection()} style={{ color: '#fff' }}>Add Section <i className="fas fa-plus"></i></button>
                                                <div className="table-responsive mt-5">
                                                    <DataTable
                                                        title="DataTable"
                                                        columns={columsSection}
                                                        data={datagetSection}
                                                        // customStyles={customStyles}
                                                        responsive
                                                        noHeader
                                                        defaultSortFieldId={1}
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        // selectableRows
                                                        persistTableHead
                                                        highlightOnHover
                                                        exportHeaders={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="solid-rounded-justified-tab3">
                                                <button type="button" className="btn btn-info mt-3" onClick={() => ShowModalAssign()} style={{ color: '#fff' }} >Assigned <i className="fas fa-plus"></i></button>

                                                <div className="table-responsive mt-5">
                                                    <DataTable
                                                        title="DataTable"
                                                        //columns={columns}
                                                        //data={dataList}
                                                        // customStyles={customStyles}
                                                        responsive
                                                        noHeader
                                                        defaultSortFieldId={1}
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        // selectableRows
                                                        persistTableHead
                                                        highlightOnHover
                                                        exportHeaders={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <footer>
                    <p>Copyright © 2020 Dreamguys.</p>
                </footer> */}

            </div>

            <Modal show={showClassModal} onHide={handleCloseClassModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Class Name </h3>
                    </Modal.Title>
                </Modal.Header>
                <form action="" onSubmit={(e) => handleSubmitClass(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name" onChange={(e) => handleClass(e)} className="form-control" />
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" onChange={(e) => handleClass(e)} className="form-control" />

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='primary'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>



            <Modal show={showSectionModal} onHide={handleCloseSectionModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Section Name </h3>
                    </Modal.Title>
                </Modal.Header>
                <form action="" onSubmit={(e) => handleSubmitSection(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name" onChange={(e) => handleSection(e)} className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='primary'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showAssignModal} onHide={handleCloseAssignModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Assign Class Section Name </h3>
                    </Modal.Title>
                </Modal.Header>
                <form action="" onSubmit={(e) => handleSubmitAssign(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Class</label>

                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div>

                                                <select name="classeSelect"  onChange={(e) => handleClasses(e)} id="" className="form-control">
                                                    <option defaultValue="">Select Class</option>
                                                    {
                                                        datagetClass.map((result) => (
                                                            <option key={result.id_class} value={result.id_class}>{result.libelle}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                        <div className="col-12 col-sm-6">

                                            {
                                                datagetSection.map((res) => (
                                                    <div key={res.id_sect}>
                                                        <div className="form-group mb-0">
                                                            <label className="custom_check w-100">
                                                                <input
                                                                    type="checkbox"
                                                                    id=""
                                                                    name={'section'+res.id_sect}
                                                                    defaultValue={res.id_sect}
                                                                    onChange={(e) => handleSectionAssign(e)}
                                                                />
                                                                <span className="checkmark"></span><h5>Section {res.title}</h5>
                                                            </label>

                                                        </div>

                                                    </div>
                                                ))
                                            }

                                        </div>
                                        <hr />
                                    </div>


                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='primary'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ClassSection;