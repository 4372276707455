import axios from '../apiRest/api';
// import  axiosJson from '../apiRest/apiJson';

////////////////////////////// GLOBAL PARAMETERS ////////////////////////////

////////////////////////////// main setting ////////////////////////////
const InsertMainSetting = data => {
    return axios.post("/insert_main_setting", data);
}

const getMainSetting = () => {
    return axios.get("/get_all_main_setting");
}

const deleteMainSetting = (id) => {
    return axios.get(`/delete_main_setting/${id}`);
}

const updateMainSetting = data => {
    return axios.post("/update_main_setting", data);
}

const get_main_by = id => {
    return axios.get(`/get_main_setting_by/${id}`);
}



/////////////////////////////// countires /////////////////////////////////
const InsertCountrie = data => {
    return axios.post("/insert_countrie", data);
}

const getCountrie = () => {
    return axios.get("/get_all_countrie");
}

const deleteCountrie = (id) => {
    return axios.get(`/delete_countrie/${id}`);
}

const updateCountrie = data => {
    return axios.post("/update_countrie", data);
}

const get_countrie_by = id => {
    return axios.get(`/get_countrie_by/${id}`);
}

//////////////////////////// Nationalities ////////////////////////////////

const InsertNation = data => {
    return axios.post("/insert_Nation", data);
}

const getNation = () => {
    return axios.get("/get_all_Nation");
}

const deleteNation = (id) => {
    return axios.get(`/delete_Nation/${id}`);
}

const updateNation = data => {
    return axios.post("/update_Nation", data);
}

const get_Nation_by = id => {
    return axios.get(`/get_Nation_by/${id}`);
}


//////////////////////////// Users Group ////////////////////////////////

const InsertUsersGroups = data => {
    return axios.post("/insert_usersgroup", data);
}

const getUsersGroups = () => {
    return axios.get("/get_all_usersgroup");
}

const get_UsersGroups_Open = () => {
    return axios.get("/get_usersgroup_open");
}

const deleteUsersGroups = (id) => {
    return axios.get(`/delete_usersgroup/${id}`);
}

const updateUsersGroups = data => {
    return axios.post("/update_usersgroup", data);
}

const get_UsersGroups_by = id => {
    return axios.get(`/get_usersgroup_by/${id}`);
}



//////////////////////////// Relationship ////////////////////////////////

const InsertRelation = data => {
    return axios.post("/insert_Relation", data);
}

const getRelation = () => {
    return axios.get("/get_all_Relation");
}

const deleteRelation = (id) => {
    return axios.get(`/delete_Relation/${id}`);
}

const updateRelation = data => {
    return axios.post("/update_Relation", data);
}

const get_Relation_by = id => {
    return axios.get(`/get_Relation_by/${id}`);
}



//////////////////////////// Jobs Description ////////////////////////////////

const InsertJob = data => {
    return axios.post("/insert_Job", data);
}

const getJob = () => {
    return axios.get("/get_all_Job");
}

const deleteJob = (id) => {
    return axios.get(`/delete_Job/${id}`);
}

const updateJob = data => {
    return axios.post("/update_Job", data);
}

const get_Job_by = id => {
    return axios.get(`/get_Job_by/${id}`);
}

////////////////////////////// GLOBAL PARAMETERS ////////////////////////////



////////////////////////////// SCHOOL YEAR ////////////////////////////

////////////////////////////// Academic Year ////////////////////////////
const InsertAcademic = data => {
    return axios.post("/insert_Academic", data);
}

const getAcademic = () => {
    return axios.get("/get_all_Academic");
}

const get_Academic_Open = () => {
    return axios.get("/get_Academic_open");
}

const deleteAcademic = (id) => {
    return axios.get(`/delete_Academic/${id}`);
}

const updateAcademic = data => {
    return axios.post("/update_Academic", data);
}

const get_Academic_by = id => {
    return axios.get(`/get_Academic_by/${id}`);
}

const openAcademic = id => {
    return axios.get(`/open_academic/${id}`);
}

const closeAcademic = id => {
    return axios.get(`/close_academic/${id}`);
}


// /////////////////////////////// Admission Period /////////////////////////////////
const InsertAdmission = data => {
    return axios.post("/insert_Admission", data);
}

const getAdmission = () => {
    return axios.get("/get_all_Admission");
}

const deleteAdmission = (id) => {
    return axios.get(`/delete_Admission/${id}`);
}

const updateAdmission = data => {
    return axios.post("/update_Admission", data);
}

const get_Admission_by = id => {
    return axios.get(`/get_Admission_by/${id}`);
}

const openAdmission = id => {
    return axios.get(`/open_admission/${id}`);
}

const closeAdmission = id => {
    return axios.get(`/close_admission/${id}`);
}

// //////////////////////////// Registration Period ////////////////////////////////

const InsertRegistration = data =>{
    return axios.post("/insert_Registration", data);
}

const getRegistration = () =>{
    return axios.get("/get_all_Registration");
}

const deleteRegistration = (id) =>{
    return axios.get(`/delete_Registration/${id}`);
}

const updateRegistration = data =>{
    return axios.post("/update_Registration", data);
}

const get_Registration_by = id =>{
    return axios.get(`/get_Registration_by/${id}`);
}

const openRegistration = id => {
    return axios.get(`/open_registration/${id}`);
}

const closeRegistration = id => {
    return axios.get(`/close_registration/${id}`);
}


// //////////////////////////// First Levels ////////////////////////////////

const InsertFirstlevel = data =>{
    return axios.post("/insert_Firstlevel", data);
}

const getFirstlevel = () =>{
    return axios.get("/get_all_Firstlevel");
}

const get_Firstlevel_Open = () => {
    return axios.get("/get_Firstlevel_open");
}

const deleteFirstlevel = (id) =>{
    return axios.get(`/delete_Firstlevel/${id}`);
}

const updateFirstlevel = data =>{
    return axios.post("/update_Firstlevel", data);
}

const get_Firstlevel_by = id =>{
    return axios.get(`/get_Firstlevel_by/${id}`);
}



// //////////////////////////// School Years ////////////////////////////////

const InsertSchoolYear = data =>{
    return axios.post("/insert_SchoolYear", data);
}

const getSchoolYear = () =>{
    return axios.get("/get_all_SchoolYear");
}

const deleteSchoolYear = (id) =>{
    return axios.get(`/delete_SchoolYear/${id}`);
}

const updateSchoolYear = data =>{
    return axios.post("/update_SchoolYear", data);
}

const get_SchoolYear_by = id =>{
    return axios.get(`/get_SchoolYear_by/${id}`);
}



// //////////////////////////// Term Name ////////////////////////////////

const InsertTermName = data =>{
    return axios.post("/insert_TermName", data);
}

const getTermName = () =>{
    return axios.get("/get_all_TermName");
}

const get_TermName_Open = () => {
    return axios.get("/get_TermName_open");
}

const deleteTermName = (id) =>{
    return axios.get(`/delete_TermName/${id}`);
}

const updateTermName = data =>{
    return axios.post("/update_TermName", data);
}

const get_TermName_by = id =>{
    return axios.get(`/get_TermName_by/${id}`);
}



// //////////////////////////// Event Name ////////////////////////////////

const InsertEventName = data =>{
    return axios.post("/insert_EventName", data);
}

const getEventName = () =>{
    return axios.get("/get_all_EventName");
}

const deleteEventName = (id) =>{
    return axios.get(`/delete_EventName/${id}`);
}

const updateEventName = data =>{
    return axios.post("/update_EventName", data);
}

const get_EventName_by = id =>{
    return axios.get(`/get_EventName_by/${id}`);
}

const get_EventName_Open = () => {
    return axios.get(`/get_EventName_Open`);
}

// //////////////////////////// Term Management ////////////////////////////////

const InsertManagement = data =>{
    return axios.post("/insert_Management", data);
}

const getManagement = () =>{
    return axios.get("/get_all_Management");
}

const get_Management_Open = () => {
    return axios.get(`/get_Management_open`);
}

const deleteManagement = (id) =>{
    return axios.get(`/delete_Management/${id}`);
}

const updateManagement = data =>{
    return axios.post("/update_Management", data);
}

const get_Management_by = id =>{
    return axios.get(`/get_Management_by/${id}`);
}

const openManagement = id => {
    return axios.get(`/open_Management/${id}`);
}

const closeManagement = id => {
    return axios.get(`/close_Management/${id}`);
}


// //////////////////////////// Program Event //////////////////////////////// 

const InsertProgramEvent = data => {
    return axios.post('/insert_ProgramEvent/', data);
}

const getProgramEvent = () => {
    return axios.get('get_all_ProgramEvent');
}

const deleteProgramEvent = (id) => {
    return axios.get(`/delete_ProgramEvent/${id}`);
}

const get_ProgramEvent_by = id => {
    return axios.get(`/get_ProgramEvent_by/${id}`);
}

const updateProgramEvent = data => {
    return axios.post("/update_ProgramEvent", data);
}
////////////////////////////////// SCHOOL YEAR /////////////////////////////// 





////////////////////////////// Services Parameters ////////////////////////////


////////////////////////////// Cars Brands ////////////////////////////
const InsertCarsBrand = data =>{
    return axios.post("/insert_CarsBrand", data);
}

const getCarsBrand = () =>{
    return axios.get("/get_all_CarsBrand");
}

const get_CarsBrand_Open = () => {
    return axios.get("/get_CarsBrand_open");
}

const deleteCarsBrand = (id) =>{
    return axios.get(`/delete_CarsBrand/${id}`);
}

const updateCarsBrand = data =>{
    return axios.post("/update_CarsBrand", data);
}

const get_CarsBrand_by = id =>{
    return axios.get(`/get_CarsBrand_by/${id}`);
}



////////////////////////////// Cars ////////////////////////////
const InsertCars = data =>{
    return axios.post("/insert_Cars", data);
}

const getCars = () =>{
    return axios.get("/get_all_Cars");
}

const deleteCars = (id) =>{
    return axios.get(`/delete_Cars/${id}`);
}

const updateCars = data =>{
    return axios.post("/update_Cars", data);
}

const get_Cars_by = id =>{
    return axios.get(`/get_Cars_by/${id}`);
}


////////////////////////////// Cars ////////////////////////////
const InsertTrips = data =>{
    return axios.post("/insert_Trips", data);
}

const getTrips = () =>{
    return axios.get("/get_all_Trips");
}

const get_Trips_Open = () => {
    return axios.get("/get_Trips_open");
}

const deleteTrips = (id) =>{
    return axios.get(`/delete_Trips/${id}`);
}

const updateTrips = data =>{
    return axios.post("/update_Trips", data);
}

const get_Trips_by = id =>{
    return axios.get(`/get_Trips_by/${id}`);
}


////////////////////////////// Assign Trips ////////////////////////////
const InsertAssignTrip = data =>{
    return axios.post("/insert_AssignTrip", data);
}

const getAssignTrip = () =>{
    return axios.get("/get_all_AssignTrip");
}

const deleteAssignTrip = (id) =>{
    return axios.get(`/delete_AssignTrip/${id}`);
}

const updateAssignTrip = data =>{
    return axios.post("/update_AssignTrip", data);
}

const get_AssignTrip_by = id =>{
    return axios.get(`/get_AssignTrip_by/${id}`);
}


////////////////////////////// Reasons for Absence ////////////////////////////
const InsertReasons = data =>{
    return axios.post("/insert_Reasons", data);
}

const getReasons = () =>{
    return axios.get("/get_all_Reasons");
}

const deleteReasons = (id) =>{
    return axios.get(`/delete_Reasons/${id}`);
}

const updateReasons = data =>{
    return axios.post("/update_Reasons", data);
}

const get_Reasons_by = id =>{
    return axios.get(`/get_Reasons_by/${id}`);
}


//////////////////////////////////////////// Coachs //////////////////////////////////////
const InsertCoachs = data =>{
    return axios.post("/insert_Coachs", data);
}

const getCoachs = () =>{
    return axios.get("/get_all_Coachs");
}

const get_Coachs_Open = () => {
    return axios.get("/get_Coachs_open");
}

const deleteCoachs = (id) =>{
    return axios.get(`/delete_Coachs/${id}`);
}

const updateCoachs = data =>{
    return axios.post("/update_Coachs", data);
}

const get_Coachs_by = id =>{
    return axios.get(`/get_Coachs_by/${id}`);
}


//////////////////////////////////////////// Activities //////////////////////////////////////
const InsertActivitie = data =>{
    return axios.post("/insert_Activitie", data);
}

const getActivitie = () =>{
    return axios.get("/get_all_Activitie");
}

const deleteActivitie = (id) =>{
    return axios.get(`/delete_Activitie/${id}`);
}

const updateActivitie = data =>{
    return axios.post("/update_Activitie", data);
}

const get_Activitie_by = id =>{
    return axios.get(`/get_Activitie_by/${id}`);
}


//////////////////////////////////////////// Provider //////////////////////////////////////
const InsertProvider = data =>{
    return axios.post("/insert_Provider", data);
}

const getProvider = () =>{
    return axios.get("/get_all_Provider");
}

const deleteProvider = (id) =>{
    return axios.get(`/delete_Provider/${id}`);
}

const updateProvider = data =>{
    return axios.post("/update_Provider", data);
}

const get_Provider_by = id =>{
    return axios.get(`/get_Provider_by/${id}`);
}


//////////////////////////////////////////// Monthly //////////////////////////////////////
const InsertMonthly = data =>{
    return axios.post("/insert_Monthly", data);
}

const getMonthly = () =>{
    return axios.get("/get_all_Monthly");
}

const deleteMonthly = (id) =>{
    return axios.get(`/delete_Monthly/${id}`);
}

const updateMonthly = data =>{
    return axios.post("/update_Monthly", data);
}

const get_Monthly_by = id =>{
    return axios.get(`/get_Monthly_by/${id}`);
}


//////////////////////////////////////////// Subjects //////////////////////////////////////
const InsertSubjects = data =>{
    return axios.post("/insert_Subjects", data);
}

const getSubjects = () =>{
    return axios.get("/get_all_Subjects");
}

const deleteSubjects = (id) =>{
    return axios.get(`/delete_Subjects/${id}`);
}

const updateSubjects = data =>{
    return axios.post("/update_Subjects", data);
}

const get_Subjects_by = id =>{
    return axios.get(`/get_Subjects_by/${id}`);
}

////////////////////////////// Services Parameters ////////////////////////////



//////////////////////////////// Academic Parameters ////////////////////////////

//////////////////////////////////////////// Sub Level //////////////////////////////////////
const InsertSublevel = data =>{
    return axios.post("/insert_Sublevel", data);
}

const getSublevel = () =>{
    return axios.get("/get_all_Sublevel");
}

const get_Sublevel_Open = () => {
    return axios.get("/get_Sublevel_open");
}

const deleteSublevel = (id) =>{
    return axios.get(`/delete_Sublevel/${id}`);
}

const updateSublevel = data =>{
    return axios.post("/update_Sublevel", data);
}

const get_Sublevel_by = id =>{
    return axios.get(`/get_Sublevel_by/${id}`);
}


//////////////////////////////////////////// Classes //////////////////////////////////////
const InsertClasse = data =>{
    return axios.post("/insert_Classe", data);
}

const getClasse = () =>{
    return axios.get("/get_all_Classe");
}

const get_Classe_Open = () => {
    return axios.get("/get_Classe_open");
}

const deleteClasse = (id) =>{
    return axios.get(`/delete_Classe/${id}`);
}

const updateClasse = data =>{
    return axios.post("/update_Classe", data);
}

const get_Classe_by = id =>{
    return axios.get(`/get_Classe_by/${id}`);
}


//////////////////////////////////////////// Section //////////////////////////////////////
const InsertSection = data =>{
    return axios.post("/insert_Section", data);
}

const getSection = () =>{
    return axios.get("/get_all_Section");
}

const get_Section_Open = () => {
    return axios.get("/get_Section_open");
}

const deleteSection = (id) =>{
    return axios.get(`/delete_Section/${id}`);
}

const updateSection = data =>{
    return axios.post("/update_Section", data);
}

const get_Section_by = id =>{
    return axios.get(`/get_Section_by/${id}`);
}

//////////////////////////////////////////// Subject //////////////////////////////////////
const InsertSubject = data =>{
    return axios.post("/insert_Subject", data);
}

const getSubject = () =>{
    return axios.get("/get_all_Subject");
}

const get_Subject_Open = () => {
    return axios.get("/get_Subject_open");
}

const deleteSubject = (id) =>{
    return axios.get(`/delete_Subject/${id}`);
}

const updateSubject = data =>{
    return axios.post("/update_Subject", data);
}

const get_Subject_by = id =>{
    return axios.get(`/get_Subject_by/${id}`);
}

//////////////////////////////////////////// File //////////////////////////////////////
const InsertFile = data =>{
    return axios.post("/insert_File", data);
}

const getFile = () =>{
    return axios.get("/get_all_File");
}

const get_File_Open = () => {
    return axios.get("/get_File_open");
}

const deleteFile = (id) =>{
    return axios.get(`/delete_File/${id}`);
}

const updateFile = data =>{
    return axios.post("/update_File", data);
}

const get_File_by = id =>{
    return axios.get(`/get_File_by/${id}`);
}

//////////////////////////////////////////// Required File //////////////////////////////////////
const InsertRequired = data =>{
    return axios.post("/insert_Required", data);
}

const getRequired = () =>{
    return axios.get("/get_all_Required");
}

const deleteRequired = (id) =>{
    return axios.get(`/delete_Required/${id}`);
}

// const updateRequired = data =>{
//     return axios.post("/update_Required", data);
// }

const get_Required_by = id =>{
    return axios.get(`/get_Required_by/${id}`);
}

//////////////////////////////////////////// Placement Test //////////////////////////////////////
const InsertPlacement = data =>{
    return axios.post("/insert_Placement", data);
}

const getPlacement = () =>{
    return axios.get("/get_all_Placement");
}

const deletePlacement = (id) =>{
    return axios.get(`/delete_Placement/${id}`);
}

// const updatePlacement = data =>{
//     return axios.post("/update_Placement", data);
// }

const get_Placement_by = id =>{
    return axios.get(`/get_Placement_by/${id}`);
}


//////////////////////////////////////////// Name Behaviours //////////////////////////////////////
const InsertNameBehaviour = data =>{
    return axios.post("/insert_NameBehaviour", data);
}

const getNameBehaviour = () =>{
    return axios.get("/get_all_NameBehaviour");
}

const get_NameBehaviour_Open = () => {
    return axios.get("/get_NameBehaviour_open");
}

const deleteNameBehaviour = (id) =>{
    return axios.get(`/delete_NameBehaviour/${id}`);
}

const updateNameBehaviour = data =>{
    return axios.post("/update_NameBehaviour", data);
}

const get_NameBehaviour_by = id =>{
    return axios.get(`/get_NameBehaviour_by/${id}`);
}


//////////////////////////////////////////// Behaviours //////////////////////////////////////
const InsertBehaviour = data =>{
    return axios.post("/insert_Behaviour", data);
}

const getBehaviour = () =>{
    return axios.get("/get_all_Behaviour");
}

// const get_Behaviour_Open = () => {
//     return axios.get("/get_Behaviour_open");
// }

const deleteBehaviour = (id) =>{
    return axios.get(`/delete_Behaviour/${id}`);
}

const updateBehaviour = data =>{
    return axios.post("/update_Behaviour", data);
}

const get_Behaviour_by = id =>{
    return axios.get(`/get_Behaviour_by/${id}`);
}


//////////////////////////////////////////// Name Effort Levels //////////////////////////////////////
const InsertNameEffort = data =>{
    return axios.post("/insert_NameEffort", data);
}

const getNameEffort = () =>{
    return axios.get("/get_all_NameEffort");
}

const get_NameEffort_Open = () => {
    return axios.get("/get_NameEffort_open");
}

const deleteNameEffort = (id) =>{
    return axios.get(`/delete_NameEffort/${id}`);
}

const updateNameEffort = data =>{
    return axios.post("/update_NameEffort", data);
}

const get_NameEffort_by = id =>{
    return axios.get(`/get_NameEffort_by/${id}`);
}


//////////////////////////////////////////// Effort Levels //////////////////////////////////////
const InsertEffortLevel = data =>{
    return axios.post("/insert_EffortLevel", data);
}

const getEffortLevel = () =>{
    return axios.get("/get_all_EffortLevel");
}

// const get_EffortLevel_Open = () => {
//     return axios.get("/get_EffortLevel_open");
// }

const deleteEffortLevel = (id) =>{
    return axios.get(`/delete_EffortLevel/${id}`);
}

const updateEffortLevel = data =>{
    return axios.post("/update_EffortLevel", data);
}

const get_EffortLevel_by = id =>{
    return axios.get(`/get_EffortLevel_by/${id}`);
}


//////////////////////////////////////////// Name Grading Scale //////////////////////////////////////
const InsertNameGrading = data =>{
    return axios.post("/insert_NameGrading", data);
}

const getNameGrading = () =>{
    return axios.get("/get_all_NameGrading");
}

const get_NameGrading_Open = () => {
    return axios.get("/get_NameGrading_open");
}

const deleteNameGrading = (id) =>{
    return axios.get(`/delete_NameGrading/${id}`);
}

const updateNameGrading = data =>{
    return axios.post("/update_NameGrading", data);
}

const get_NameGrading_by = id =>{
    return axios.get(`/get_NameGrading_by/${id}`);
}


//////////////////////////////////////////// Grading Scale //////////////////////////////////////
const InsertGradingScale = data =>{
    return axios.post("/insert_GradingScale", data);
}

const getGradingScale = () =>{
    return axios.get("/get_all_GradingScale");
}

// const get_GradingScale_Open = () => {
//     return axios.get("/get_GradingScale_open");
// }

const deleteGradingScale = (id) =>{
    return axios.get(`/delete_GradingScale/${id}`);
}

const updateGradingScale = data =>{
    return axios.post("/update_GradingScale", data);
}

const get_GradingScale_by = id =>{
    return axios.get(`/get_GradingScale_by/${id}`);
}


//////////////////////////////////////////// Nursery Theme //////////////////////////////////////
const InsertNurseryTheme = data =>{
    return axios.post("/insert_NurseryTheme", data);
}

const getNurseryTheme = () =>{
    return axios.get("/get_all_NurseryTheme");
}

const get_NurseryTheme_Open = () => {
    return axios.get("/get_NurseryTheme_open");
}

const deleteNurseryTheme = (id) =>{
    return axios.get(`/delete_NurseryTheme/${id}`);
}

const updateNurseryTheme = data =>{
    return axios.post("/update_NurseryTheme", data);
}

const get_NurseryTheme_by = id =>{
    return axios.get(`/get_NurseryTheme_by/${id}`);
}


//////////////////////////////////////////// Nursery Sub Theme //////////////////////////////////////
const InsertNursSubTheme = data =>{
    return axios.post("/insert_NursSubTheme", data);
}

const getNursSubTheme = () =>{
    return axios.get("/get_all_NursSubTheme");
}

const get_NursSubTheme_Open = () => {
    return axios.get("/get_NursSubTheme_open");
}

const deleteNursSubTheme = (id) =>{
    return axios.get(`/delete_NursSubTheme/${id}`);
}

const updateNursSubTheme = data =>{
    return axios.post("/update_NursSubTheme", data);
}

const get_NursSubTheme_by = id =>{
    return axios.get(`/get_NursSubTheme_by/${id}`);
}


//////////////////////////////////////////// Nursery Report //////////////////////////////////////
const InsertNurseryReport = data =>{
    return axios.post("/insert_NurseryReport", data);
}

const getNurseryReport = () =>{
    return axios.get("/get_all_NurseryReport");
}

// const get_NurseryReport_Open = () => {
//     return axios.get("/get_NurseryReport_open");
// }

const deleteNurseryReport = (id) =>{
    return axios.get(`/delete_NurseryReport/${id}`);
}

const updateNurseryReport = data =>{
    return axios.post("/update_NurseryReport", data);
}

const get_NurseryReport_by = id =>{
    return axios.get(`/get_NurseryReport_by/${id}`);
}


//////////////////////////////////////////// Evaluation Name //////////////////////////////////////
const InsertEvaluation = data =>{
    return axios.post("/insert_Evaluation", data);
}

const getEvaluation = () =>{
    return axios.get("/get_all_Evaluation");
}

const get_Evaluation_Open = () => {
    return axios.get("/get_Evaluation_open");
}

const deleteEvaluation = (id) =>{
    return axios.get(`/delete_Evaluation/${id}`);
}

const updateEvaluation = data =>{
    return axios.post("/update_Evaluation", data);
}

const get_Evaluation_by = id =>{
    return axios.get(`/get_Evaluation_by/${id}`);
}


//////////////////////////////////////////// Weighting //////////////////////////////////////
const InsertWeighting = data =>{
    return axios.post("/insert_Weighting", data);
}

const getWeighting = () =>{
    return axios.get("/get_all_Weighting");
}

// const get_Weighting_Open = () => {
//     return axios.get("/get_Weighting_open");
// }

const deleteWeighting = (id) =>{
    return axios.get(`/delete_Weighting/${id}`);
}

const updateWeighting = data =>{
    return axios.post("/update_Weighting", data);
}

const get_Weighting_by = id =>{
    return axios.get(`/get_Weighting_by/${id}`);
}


//////////////////////////////////////////// Exams Name //////////////////////////////////////
const InsertExamsName = data =>{
    return axios.post("/insert_ExamsName", data);
}

const getExamsName = () =>{
    return axios.get("/get_all_ExamsName");
}

const get_ExamsName_Open = () => {
    return axios.get("/get_ExamsName_open");
}

const deleteExamsName = (id) =>{
    return axios.get(`/delete_ExamsName/${id}`);
}

const updateExamsName = data =>{
    return axios.post("/update_ExamsName", data);
}

const get_ExamsName_by = id =>{
    return axios.get(`/get_ExamsName_by/${id}`);
}


//////////////////////////////////////////// Exams //////////////////////////////////////
const InsertExams = data =>{
    return axios.post("/insert_Exams", data);
}

const getExams = () =>{
    return axios.get("/get_all_Exams");
}

// const get_Exams_Open = () => {
//     return axios.get("/get_Exams_open");
// }

const deleteExams = (id) =>{
    return axios.get(`/delete_Exams/${id}`);
}

const updateExams = data =>{
    return axios.post("/update_Exams", data);
}

const get_Exams_by = id =>{
    return axios.get(`/get_Exams_by/${id}`);
}


//////////////////////////////////////////// Annuel Average//////////////////////////////////////
const InsertAnnuelAverage = data =>{
    return axios.post("/insert_AnnuelAverage", data);
}

const getAnnuelAverage = () =>{
    return axios.get("/get_all_AnnuelAverage");
}

// const get_AnnuelAverage_Open = () => {
//     return axios.get("/get_AnnuelAverage_open");
// }

const deleteAnnuelAverage = (id) =>{
    return axios.get(`/delete_AnnuelAverage/${id}`);
}

const updateAnnuelAverage = data =>{
    return axios.post("/update_AnnuelAverage", data);
}

const get_AnnuelAverage_by = id =>{
    return axios.get(`/get_AnnuelAverage_by/${id}`);
}


//////////////////////////////////////////// Name Promotion //////////////////////////////////////
const InsertNamePromotion = data =>{
    return axios.post("/insert_NamePromotion", data);
}

const getNamePromotion = () =>{
    return axios.get("/get_all_NamePromotion");
}

// const get_NamePromotion_Open = () => {
//     return axios.get("/get_NamePromotion_open");
// }

const deleteNamePromotion = (id) =>{
    return axios.get(`/delete_NamePromotion/${id}`);
}

const updateNamePromotion = data =>{
    return axios.post("/update_NamePromotion", data);
}

const get_NamePromotion_by = id =>{
    return axios.get(`/get_NamePromotion_by/${id}`);
}


//////////////////////////////////////////// Promotion //////////////////////////////////////
const InsertPromotion = data =>{
    return axios.post("/insert_Promotion", data);
}

const getPromotion = () =>{
    return axios.get("/get_all_Promotion");
}

// const get_Promotion_Open = () => {
//     return axios.get("/get_Promotion_open");
// }

const deletePromotion = (id) =>{
    return axios.get(`/delete_Promotion/${id}`);
}

const updatePromotion = data =>{
    return axios.post("/update_Promotion", data);
}

const get_Promotion_by = id =>{
    return axios.get(`/get_Promotion_by/${id}`);
}


//////////////////////////////////////////// Id Card Validity //////////////////////////////////////
const InsertCardValidity = data =>{
    return axios.post("/insert_CardValidity", data);
}

const getCardValidity = () =>{
    return axios.get("/get_all_CardValidity");
}

// const get_CardValidity_Open = () => {
//     return axios.get("/get_CardValidity_open");
// }

const deleteCardValidity = (id) =>{
    return axios.get(`/delete_CardValidity/${id}`);
}

const updateCardValidity = data =>{
    return axios.post("/update_CardValidity", data);
}

const get_CardValidity_by = id =>{
    return axios.get(`/get_CardValidity_by/${id}`);
}


//////////////////////////////////////////// class Call //////////////////////////////////////
const InsertClassCall = data =>{
    return axios.post("/insert_ClassCall", data);
}

const getClassCall = () =>{
    return axios.get("/get_all_ClassCall");
}

// const get_ClassCall_Open = () => {
//     return axios.get("/get_ClassCall_open");
// }

const deleteClassCall = (id) =>{
    return axios.get(`/delete_ClassCall/${id}`);
}

const updateClassCall = data =>{
    return axios.post("/update_ClassCall", data);
}

const get_ClassCall_by = id =>{
    return axios.get(`/get_ClassCall_by/${id}`);
}


//////////////////////////////////////////// Reason Of Leaving //////////////////////////////////////
const InsertReasonLeaving = data =>{
    return axios.post("/insert_ReasonLeaving", data);
}

const getReasonLeaving = () =>{
    return axios.get("/get_all_ReasonLeaving");
}

// const get_ReasonLeaving_Open = () => {
//     return axios.get("/get_ReasonLeaving_open");
// }

const deleteReasonLeaving = (id) =>{
    return axios.get(`/delete_ReasonLeaving/${id}`);
}

const updateReasonLeaving = data =>{
    return axios.post("/update_ReasonLeaving", data);
}

const get_ReasonLeaving_by = id =>{
    return axios.get(`/get_ReasonLeaving_by/${id}`);
}



//////////////////////////////////////////// classe Timetables //////////////////////////////////////
const InsertClasseTime = data =>{
    return axios.post("/insert_ClasseTime", data);
}

const getClasseTime = () =>{
    return axios.get("/get_all_ClasseTime");
}

const get_ClasseTime_Open = () => {
    return axios.get("/get_ClasseTime_open");
}

const deleteClasseTime = (id) =>{
    return axios.get(`/delete_ClasseTime/${id}`);
}

const updateClasseTime = data =>{
    return axios.post("/update_ClasseTime", data);
}

const get_ClasseTime_by = id =>{
    return axios.get(`/get_ClasseTime_by/${id}`);
}


/////////////////////////////////////// Class Time Slot //////////////////////////////////////
const InsertClassTimeSlot = data =>{
    return axios.post("/insert_ClassTimeSlot", data);
}

const getClassTimeSlot = () =>{
    return axios.get("/get_all_ClassTimeSlot");
}

// const get_ClassTimeSlot_Open = () => {
//     return axios.get("/get_ClassTimeSlot_open");
// }

const deleteClassTimeSlot = (id) =>{
    return axios.get(`/delete_ClassTimeSlot/${id}`);
}

const updateClassTimeSlot = data =>{
    return axios.post("/update_ClassTimeSlot", data);
}

const get_ClassTimeSlot_by = id =>{
    return axios.get(`/get_ClassTimeSlot_by/${id}`);
}


///////////////////////////////////// Academic Parameters ////////////////////////////


////////////////////////////////////// Finance Parameters /////////////////////////////////

/////////////////////////////////////// Sample Invoice //////////////////////////////////////
const InsertSampleInvoice = data =>{
    return axios.post("/insert_SampleInvoice", data);
}

const getSampleInvoice = () =>{
    return axios.get("/get_all_SampleInvoice");
}

const deleteSampleInvoice = (id) =>{
    return axios.get(`/delete_SampleInvoice/${id}`);
}

const updateSampleInvoice = data =>{
    return axios.post("/update_SampleInvoice", data);
}

const get_SampleInvoice_by = id =>{
    return axios.get(`/get_SampleInvoice_by/${id}`);
}

/////////////////////////////////////// Sample Invoice //////////////////////////////////////
const InsertBank = data =>{
    return axios.post("/insert_Bank", data);
}

const getBank = () =>{
    return axios.get("/get_all_Bank");
}

const deleteBank = (id) =>{
    return axios.get(`/delete_Bank/${id}`);
}

const updateBank = data =>{
    return axios.post("/update_Bank", data);
}

const get_Bank_by = id =>{
    return axios.get(`/get_Bank_by/${id}`);
}


/////////////////////////////////////// Items Groups //////////////////////////////////////
const InsertGroups = data =>{
    return axios.post("/insert_Groups", data);
}

const getGroups = () =>{
    return axios.get("/get_all_Groups");
}

const get_Groups_Open = () => {
    return axios.get("/get_Groups_open");
}

const deleteGroups = (id) =>{
    return axios.get(`/delete_Groups/${id}`);
}

const updateGroups = data =>{
    return axios.post("/update_Groups", data);
}

const get_Groups_by = id =>{
    return axios.get(`/get_Groups_by/${id}`);
}


/////////////////////////////////////// Types Packages //////////////////////////////////////
const InsertTypePackage = data =>{
    return axios.post("/insert_TypePackage", data);
}

const getTypePackage = () =>{
    return axios.get("/get_all_TypePackage");
}

// const get_TypePackage_Open = () => {
//     return axios.get("/get_TypePackage_open");
// }

const deleteTypePackage = (id) =>{
    return axios.get(`/delete_TypePackage/${id}`);
}

const updateTypePackage = data =>{
    return axios.post("/update_TypePackage", data);
}

const get_TypePackage_by = id =>{
    return axios.get(`/get_TypePackage_by/${id}`);
}


/////////////////////////////////////// Sizes //////////////////////////////////////
const InsertSize = data =>{
    return axios.post("/insert_Size", data);
}

const getSize = () =>{
    return axios.get("/get_all_Size");
}

const get_Size_Open = () => {
    return axios.get("/get_Size_open");
}

const deleteSize = (id) =>{
    return axios.get(`/delete_Size/${id}`);
}

const updateSize = data =>{
    return axios.post("/update_Size", data);
}

const get_Size_by = id =>{
    return axios.get(`/get_Size_by/${id}`);
}


/////////////////////////////////////// Size Charts Names //////////////////////////////////////
const InsertSizeChart = data =>{
    return axios.post("/insert_SizeChart", data);
}

const getSizeChart = () =>{
    return axios.get("/get_all_SizeChart");
}

const get_SizeChart_Open = () => {
    return axios.get("/get_SizeChart_open");
}

const deleteSizeChart = (id) =>{
    return axios.get(`/delete_SizeChart/${id}`);
}

const updateSizeChart = data =>{
    return axios.post("/update_SizeChart", data);
}

const get_SizeChart_by = id =>{
    return axios.get(`/get_SizeChart_by/${id}`);
}


/////////////////////////////////////// Size Charts Determination //////////////////////////////////////
const InsertChartDetermination = data =>{
    return axios.post("/insert_ChartDetermination", data);
}

const getChartDetermination = () =>{
    return axios.get("/get_all_ChartDetermination");
}

// const get_ChartDetermination_Open = () => {
//     return axios.get("/get_ChartDetermination_open");
// }

const deleteChartDetermination = (id) =>{
    return axios.get(`/delete_ChartDetermination/${id}`);
}

const updateChartDetermination = data =>{
    return axios.post("/update_ChartDetermination", data);
}

const get_ChartDetermination_by = id =>{
    return axios.get(`/get_ChartDetermination_by/${id}`);
}


/////////////////////////////////////// Items //////////////////////////////////////
const InsertItem = data =>{
    return axios.post("/insert_Item", data);
}

const getItem = () =>{
    return axios.get("/get_all_Item");
}

const get_Item_Open = () => {
    return axios.get("/get_Item_open");
}

const deleteItem = (id) =>{
    return axios.get(`/delete_Item/${id}`);
}

const updateItem = data =>{
    return axios.post("/update_Item", data);
}

const get_Item_by = id =>{
    return axios.get(`/get_Item_by/${id}`);
}


/////////////////////////////////////// Provider //////////////////////////////////////
const InsertFiProvider = data =>{
    return axios.post("/insert_FiProvider", data);
}

const getFiProvider = () =>{
    return axios.get("/get_all_FiProvider");
}

// const get_FiProvider_Open = () => {
//     return axios.get("/get_FiProvider_open");
// }

const deleteFiProvider = (id) =>{
    return axios.get(`/delete_FiProvider/${id}`);
}

const updateFiProvider = data =>{
    return axios.post("/update_FiProvider", data);
}

const get_FiProvider_by = id =>{
    return axios.get(`/get_FiProvider_by/${id}`);
}


/////////////////////////////////////// Instalment //////////////////////////////////////
const InsertInstalment = data =>{
    return axios.post("/insert_Instalment", data);
}

const getInstalment = () =>{
    return axios.get("/get_all_Instalment");
}

// const get_Instalment_Open = () => {
//     return axios.get("/get_Instalment_open");
// }

const deleteInstalment = (id) =>{
    return axios.get(`/delete_Instalment/${id}`);
}

const updateInstalment = data =>{
    return axios.post("/update_Instalment", data);
}

const get_Instalment_by = id =>{
    return axios.get(`/get_Instalment_by/${id}`);
}

const openInstalment = id => {
    return axios.get(`/open_Instalment/${id}`);
}

const closeInstalment = id => {
    return axios.get(`/close_Instalment/${id}`);
}


/////////////////////////////////////// Date First Instal //////////////////////////////////////
const InsertDateFirst = data =>{
    return axios.post("/insert_DateFirst", data);
}

const getDateFirst = () =>{
    return axios.get("/get_all_DateFirst");
}

// const get_DateFirst_Open = () => {
//     return axios.get("/get_DateFirst_open");
// }

const deleteDateFirst = (id) =>{
    return axios.get(`/delete_DateFirst/${id}`);
}

const updateDateFirst = data =>{
    return axios.post("/update_DateFirst", data);
}

const get_DateFirst_by = id =>{
    return axios.get(`/get_DateFirst_by/${id}`);
}

///////////////////////////////////// Finance Parameters /////////////////////////////////



/////////////////////////////////////////// Mapping //////////////////////////////////////

/////////////////////////////////////// Classe Section //////////////////////////////////////
const InsertClasseSection = data =>{
    return axios.post("/insert_ClasseSection", data);
}

const getClasseSection = () =>{
    return axios.get("/get_all_ClasseSection");
}

const get_ClasseSection_Open = () => {
    return axios.get("/get_ClasseSection_open");
}

const deleteClasseSection = (id) =>{
    return axios.get(`/delete_ClasseSection/${id}`);
}

const updateClasseSection = data =>{
    return axios.post("/update_ClasseSection", data);
}

const get_ClasseSection_by = id =>{
    return axios.get(`/get_ClasseSection_by/${id}`);
}


/////////////////////////////////////// Student Section //////////////////////////////////////
const InsertStudentSection = data =>{
    return axios.post("/insert_StudentSection", data);
}

const getStudentSection = () =>{
    return axios.get("/get_all_StudentSection");
}

const get_StudentSection_Open = () => {
    return axios.get("/get_StudentSection_open");
}

const deleteStudentSection = (id) =>{
    return axios.get(`/delete_StudentSection/${id}`);
}

const updateStudentSection = data =>{
    return axios.post("/update_StudentSection", data);
}

const get_StudentSection_by = id =>{
    return axios.get(`/get_StudentSection_by/${id}`);
}

const get_StudentSection_by_id_class = id =>{
    return axios.get(`/get_studentSection_by_id_class/${id}`);
}


/////////////////////////////////////// Student Subject //////////////////////////////////////
const InsertStudentSubject = data =>{
    return axios.post("/insert_StudentSubject", data);
}

const getStudentSubject = () =>{
    return axios.get("/get_StudentSubject_byget_StudentSubject_by");
}

const getStudentSubjects = () =>{
    return axios.get("/get_all_StudentSubjects");
}

const get_StudentSubjectAssigned = (code, id_class) => {
    return axios.get(`/get_StudentSubject_assigned/${code}/${id_class}`);
}

const deleteAllSubjectAssigned = (code_academic, idclass) =>{
    return axios.get(`/delete_AllSubjectAssigned/${code_academic}/${idclass}`);
}

const deleteSubjectAssigned = (id) =>{
    return axios.get(`/delete_SubjectAssigned/${id}`);
}

const get_StudentInfo = (code, subject) => {
    return axios.get(`/get_StudentInfo/${code}/${subject}`);
}

const updateStudentSubject = data =>{
    return axios.post("/update_StudentSubject", data);
}

const get_StudentSubject_by = id =>{
    return axios.get(`/get_StudentSubject_by/${id}`);
}


/////////////////////////////////////// Student Subject //////////////////////////////////////
const InsertClasseSubject = data =>{
    return axios.post("/insert_ClasseSubject", data);
}

const getClasseSubject = () =>{
    return axios.get("/get_all_ClasseSubject");
}

const getSubjectByIDCLass = (id) =>{
    return axios.get(`/get_all_Subject/${id}`);
}

const deleteClasseSubject = (id) =>{
    return axios.get(`/delete_ClasseSubject/${id}`);
}

const get_ClasseSubject_Open = () => {
    return axios.get("/get_ClasseSubject_Open");
}


/////////////////////////////////////// Student Subject //////////////////////////////////////

const InsertTeacherSubject = data =>{
    return axios.post("/insert_TeacherSubject", data);
}

const getTeacherSubject = () =>{
    return axios.get("/get_all_TeacherSubject");
}



/////////////////////////////////////////// Mapping //////////////////////////////////////

/////////////////////////////////////////// Student ////////////////////////...............................................................................................................................................................................................................................................................................................................................................................//////////////
const create = data =>{
    return axios.post("/insert_StudentAdd", data);
}

const createParent = data =>{
    return axios.post("/insert_ParentAdd", data);
}

const get_Student_Open = () => {
    return axios.get("/get_Student_Open");
}

const getStaff = data =>{
    return axios.get("/get_Teacher", data);
}

const updateStaffs = data =>{
    return axios.post("/update_Teacher", data);
}

const get_Staffs_by = (id) =>{
    return axios.get(`/get_Teacher_by/${id}`);
}

const openStaff = id => {
    return axios.get(`/open_Staff/${id}`);
}

const closeStaff = id => {
    return axios.get(`/close_Staff/${id}`);
}


/////////////////////////////////////////// Staff ////////////////////////////////////// 

const InsertUserAccess = data =>{
    return axios.post("/insert_UserAccess", data); 
}

const getUserAccess = () => {
    return axios.get("/get_all_UserAccess");
}

const get_Staffs_Open = () => {
    return axios.get("/get_staffs_open");
}


const openAccessStaff = id => {
    return axios.get(`/open_access_staff/${id}`);
}

const closeAccessStaff = id => {
    return axios.get(`/close_access_staff/${id}`);
}

const deleteAccessStaff = (id) =>{
    return axios.get(`/delete_AccessStaff/${id}`);
}

const UpdateAccessStaff = data =>{
    return axios.post("/update_AccessStaff", data);
}

const get_AccessStaff_by = id =>{
    return axios.get(`/get_AccessStaff_by/${id}`);
}


/////////////////////////////////////////// Teacher //////////////////////////////////////

const createTeacher = data =>{
    return axios.post("/insert_TeacherAdd", data);
}

const get_Teacher_Open = () => {
    return axios.get("/get_Teacher_Open");
}

/////////////////////////////////////////// Teacher //////////////////////////////////////


/////////////////////////////////////// REGISTRATION ///////////////////////////////////////

const create_auth = data =>{
    return axios.post("/auth", data);
}

const get_auth = (token) =>{
    return axios.get(`/get_data_auth/${token}`);
}
const log_auth = data =>{
    return axios.post("/login", data);
}

const get_data_stud = () =>{
    return axios.get("/get_student");
}

const get_sudent_by_code_parent = (code) =>{
    return axios.get(`/get_sudent_by_code_parent/${code}`);
}

const get_data_parent = () =>{
    return axios.get("/get_parents");
}

const insert_info_student = data =>{
    return axios.post("/insert_info_student", data);
}

const get_data_stud_admission = (id_user) =>{
    return axios.get(`/get_data_stud_admission/${id_user}`);
}

const delete_data_stud_admission = (code_stud) =>{
    return axios.get(`/delete_data_stud_admission/${code_stud}`);
}

const insert_info_emergency = data =>{
    return axios.post("/insert_info_emergency", data);
}

const get_step_admission = (code_stud) =>{
    return axios.get(`/get_step_admission/${code_stud}`);
}

const get_data_stud_admission_step1= (code) =>{
    return axios.get(`/get_data_stud_admission_step1/${code}`);
}

const get_data_stud_admission_step2= (code) =>{
    return axios.get(`/get_data_stud_admission_step2/${code}`);
}

const get_data_stud_admission_step3= (code) =>{
    return axios.get(`/get_data_stud_admission_step3/${code}`);
}

const get_data_stud_admission_step4= (code) =>{
    return axios.get(`/get_data_stud_admission_step4/${code}`);
}

const insert_info_medical = data =>{
    return axios.post("/insert_info_medical", data);
}

const insert_pickup_chlid = data =>{
    return axios.post("/insert_pickup_chlid", data);
}

const insert_parent_detail = data =>{
    return axios.post("/insert_parent_detail", data);
}



const services = {
    ////////////////////////////// GLOBAL PARAMETERS ////////////////////////////

    InsertMainSetting,
    getMainSetting,
    deleteMainSetting,
    updateMainSetting,
    get_main_by,

    InsertCountrie,
    getCountrie,
    deleteCountrie,
    updateCountrie,
    get_countrie_by,

    InsertNation,
    getNation,
    deleteNation,
    updateNation,
    get_Nation_by,


    InsertUsersGroups,
    getUsersGroups,
    get_UsersGroups_Open,
    deleteUsersGroups,
    updateUsersGroups,
    get_UsersGroups_by,


    InsertRelation,
    getRelation,
    deleteRelation,
    updateRelation,
    get_Relation_by,


    InsertJob,
    getJob,
    deleteJob,
    updateJob,
    get_Job_by,
    ////////////////////////////// GLOBAL PARAMETERS ////////////////////////////

    ///////////////////////////////// School Year ///////////////////////////////
    InsertAcademic,
    getAcademic,
    get_Academic_Open,
    deleteAcademic,
    updateAcademic,
    get_Academic_by,
    openAcademic,
    closeAcademic,

    InsertAdmission,
    getAdmission,
    deleteAdmission,
    updateAdmission,
    get_Admission_by,
    openAdmission,
    closeAdmission,

    InsertRegistration,
    getRegistration,
    deleteRegistration,
    updateRegistration,
    get_Registration_by,
    openRegistration,
    closeRegistration,


    InsertFirstlevel,
    getFirstlevel,
    get_Firstlevel_Open,
    deleteFirstlevel,
    updateFirstlevel,
    get_Firstlevel_by,


    InsertSchoolYear,
    getSchoolYear,
    deleteSchoolYear,
    updateSchoolYear,
    get_SchoolYear_by,


    InsertTermName,
    getTermName,
    get_TermName_Open,
    deleteTermName,
    updateTermName,
    get_TermName_by,


    InsertEventName,
    getEventName,
    deleteEventName,
    updateEventName,
    get_EventName_by,
    get_EventName_Open,



    InsertManagement,
    getManagement,
    deleteManagement,
    updateManagement,
    get_Management_by,
    openManagement,
    closeManagement,
    get_Management_Open,


    InsertProgramEvent,
    getProgramEvent,
    deleteProgramEvent,
    updateProgramEvent,
    get_ProgramEvent_by,

    ////////////////////////////////// School Year //////////////////////////////


    ////////////////////////////////// Services Parameters ///////////////////////

    InsertCarsBrand,
    getCarsBrand,
    get_CarsBrand_Open,
    deleteCarsBrand,
    updateCarsBrand,
    get_CarsBrand_by,


    InsertCars,
    getCars,
    deleteCars,
    updateCars,
    get_Cars_by,


    InsertTrips,
    getTrips,
    get_Trips_Open,
    deleteTrips,
    updateTrips,
    get_Trips_by,


    InsertAssignTrip,
    getAssignTrip,
    deleteAssignTrip,
    updateAssignTrip,
    get_AssignTrip_by,


    InsertReasons,
    getReasons,
    deleteReasons,
    updateReasons,
    get_Reasons_by,


    InsertCoachs,
    getCoachs,
    get_Coachs_Open,
    deleteCoachs,
    updateCoachs,
    get_Coachs_by,


    InsertActivitie,
    getActivitie,
    deleteActivitie,
    updateActivitie,
    get_Activitie_by,


    InsertProvider,
    getProvider,
    deleteProvider,
    updateProvider,
    get_Provider_by,


    InsertMonthly,
    getMonthly,
    deleteMonthly,
    updateMonthly,
    get_Monthly_by,


    InsertSubjects,
    getSubjects,
    deleteSubjects,
    updateSubjects,
    get_Subjects_by,

    ////////////////////////////////// Services Parameters ////////////////////////////// 

    ////////////////////////////////// Academic Parameters //////////////////////////////

    InsertSublevel,
    getSublevel,
    get_Sublevel_Open,
    deleteSublevel,
    updateSublevel,
    get_Sublevel_by,


    InsertClasse,
    getClasse,
    get_Classe_Open,
    deleteClasse,
    updateClasse,
    get_Classe_by,


    InsertSection,
    getSection,
    get_Section_Open,
    deleteSection,
    updateSection,
    get_Section_by,


    InsertSubject,
    getSubject,
    get_Subject_Open,
    deleteSubject,
    updateSubject,
    get_Subject_by,


    InsertFile,
    getFile,
    get_File_Open,
    deleteFile,
    updateFile,
    get_File_by,


    InsertRequired,
    getRequired,
    deleteRequired,
    // updateRequired,
    get_Required_by,


    InsertPlacement,
    getPlacement,
    deletePlacement,
    // updatePlacement,
    get_Placement_by,


    InsertNameBehaviour,
    getNameBehaviour,
    get_NameBehaviour_Open,
    deleteNameBehaviour,
    updateNameBehaviour,
    get_NameBehaviour_by,


    InsertBehaviour,
    getBehaviour,
    // get_Behaviour_Open,
    deleteBehaviour,
    updateBehaviour,
    get_Behaviour_by,


    InsertNameEffort,
    getNameEffort,
    get_NameEffort_Open,
    deleteNameEffort,
    updateNameEffort,
    get_NameEffort_by,


    InsertEffortLevel,
    getEffortLevel,
    // get_EffortLevel_Open,
    deleteEffortLevel,
    updateEffortLevel,
    get_EffortLevel_by,


    InsertNameGrading,
    getNameGrading,
    get_NameGrading_Open,
    deleteNameGrading,
    updateNameGrading,
    get_NameGrading_by,


    InsertGradingScale,
    getGradingScale,
    // get_GradingScale_Open,
    deleteGradingScale,
    updateGradingScale,
    get_GradingScale_by,


    InsertNurseryTheme,
    getNurseryTheme,
    get_NurseryTheme_Open,
    deleteNurseryTheme,
    updateNurseryTheme,
    get_NurseryTheme_by,


    InsertNursSubTheme,
    getNursSubTheme,
    get_NursSubTheme_Open,
    deleteNursSubTheme,
    updateNursSubTheme,
    get_NursSubTheme_by,


    InsertNurseryReport,
    getNurseryReport,
    // get_NurseryReport_Open,
    deleteNurseryReport,
    updateNurseryReport,
    get_NurseryReport_by,


    InsertEvaluation,
    getEvaluation,
    get_Evaluation_Open,
    deleteEvaluation,
    updateEvaluation,
    get_Evaluation_by,


    InsertWeighting,
    getWeighting,
    // get_Weighting_Open,
    deleteWeighting,
    updateWeighting,
    get_Weighting_by,


    InsertExamsName,
    getExamsName,
    get_ExamsName_Open,
    deleteExamsName,
    updateExamsName,
    get_ExamsName_by,


    InsertExams,
    getExams,
    // get_Exams_Open,
    deleteExams,
    updateExams,
    get_Exams_by,


    InsertAnnuelAverage,
    getAnnuelAverage,
    // get_AnnuelAverage_Open,
    deleteAnnuelAverage,
    updateAnnuelAverage,
    get_AnnuelAverage_by,


    InsertNamePromotion,
    getNamePromotion,
    // get_NamePromotion_Open,
    deleteNamePromotion,
    updateNamePromotion,
    get_NamePromotion_by,


    InsertPromotion,
    getPromotion,
    // get_Promotion_Open,
    deletePromotion,
    updatePromotion,
    get_Promotion_by,


    InsertCardValidity,
    getCardValidity,
    // get_CardValidity_Open,
    deleteCardValidity,
    updateCardValidity,
    get_CardValidity_by,


    InsertClassCall,
    getClassCall,
    // get_ClassCall_Open,
    deleteClassCall,
    updateClassCall,
    get_ClassCall_by,


    InsertReasonLeaving,
    getReasonLeaving,
    // get_ReasonLeaving_Open,
    deleteReasonLeaving,
    updateReasonLeaving,
    get_ReasonLeaving_by,



    InsertClasseTime,
    getClasseTime,
    get_ClasseTime_Open,
    deleteClasseTime,
    updateClasseTime,
    get_ClasseTime_by,


    InsertClassTimeSlot,
    getClassTimeSlot,
    // get_ClassTimeSlot_Open,
    deleteClassTimeSlot,
    updateClassTimeSlot,
    get_ClassTimeSlot_by,

    ////////////////////////////////// Academic Parameters //////////////////////////////

    ////////////////////////////////// Finance Parameters //////////////////////////////
 
    InsertSampleInvoice,
    getSampleInvoice,
    deleteSampleInvoice,
    updateSampleInvoice,
    get_SampleInvoice_by,


    InsertBank,
    getBank,
    deleteBank,
    updateBank,
    get_Bank_by,


    InsertGroups,
    get_Groups_Open,
    getGroups,
    deleteGroups,
    updateGroups,
    get_Groups_by,
    


    InsertTypePackage,
    getTypePackage,
    // get_TypePackage_Open,
    deleteTypePackage,
    updateTypePackage,
    get_TypePackage_by,


    InsertSize,
    getSize,
    get_Size_Open,
    deleteSize,
    updateSize,
    get_Size_by,


    InsertSizeChart,
    getSizeChart,
    get_SizeChart_Open,
    deleteSizeChart,
    updateSizeChart,
    get_SizeChart_by,



    InsertChartDetermination,
    getChartDetermination,
    // get_ChartDetermination_Open,
    deleteChartDetermination,
    updateChartDetermination,
    get_ChartDetermination_by,


    InsertItem,
    getItem,
    get_Item_Open,
    deleteItem,
    updateItem,
    get_Item_by,


    InsertFiProvider,
    getFiProvider,
    // get_FiProvider_Open,
    deleteFiProvider,
    updateFiProvider,
    get_FiProvider_by,


    InsertInstalment,
    getInstalment,
    // get_Instalment_Open,
    deleteInstalment,
    updateInstalment,
    get_Instalment_by,
    openInstalment,
    closeInstalment,


    InsertDateFirst,
    getDateFirst,
    // get_DateFirst_Open,
    deleteDateFirst,
    updateDateFirst,
    get_DateFirst_by,

    ////////////////////////////////// Finance Parameters //////////////////////////////  
    
    
    /////////////////////////////////////// Mapping ///////////////////////////////////

    InsertClasseSection,
    getClasseSection,
    get_ClasseSection_Open,
    deleteClasseSection,
    updateClasseSection,
    get_ClasseSection_by,

    InsertStudentSection,
    getStudentSection,
    get_StudentSection_Open,
    deleteStudentSection,
    updateStudentSection,
    get_StudentSection_by,
    get_StudentSection_by_id_class,

    InsertStudentSubject,
    getStudentSubject,
    getStudentSubjects,
    get_StudentSubjectAssigned,
    deleteSubjectAssigned,
    deleteAllSubjectAssigned,
    get_StudentInfo,
    updateStudentSubject,
    get_StudentSubject_by,


    InsertClasseSubject,
    getClasseSubject,
    getSubjectByIDCLass,
    deleteClasseSubject,
    get_ClasseSubject_Open,


    InsertTeacherSubject,
    getTeacherSubject,

    ///////////////////////////////////// Student ////////////////////////////////////

    create,
    createParent,
    get_Student_Open,
    getStaff,
    updateStaffs,
    get_Staffs_by,
    openStaff,
    closeStaff,


    InsertUserAccess,
    getUserAccess,
    get_Staffs_Open,
    openAccessStaff,
    closeAccessStaff,
    deleteAccessStaff,
    UpdateAccessStaff,
    get_AccessStaff_by,


    ///////////////////////////////////// Teacher ////////////////////////////////////

    get_Teacher_Open,
    createTeacher,


    // InsertClasseSection,
    // getClasseSection,
    // get_ClasseSection_Open,
    // deleteClasseSection,
    // updateClasseSection,
    // get_ClasseSection_by,
    
    ////////////////////////////////// Admission //////////////////////////////  

    create_auth,
    get_auth,
    log_auth,

    get_data_stud,
    get_data_parent,
    get_sudent_by_code_parent,
    insert_info_student,
    get_data_stud_admission,
    delete_data_stud_admission,
    insert_info_emergency,
    get_step_admission,
    get_data_stud_admission_step1,
    get_data_stud_admission_step2,
    get_data_stud_admission_step3,
    get_data_stud_admission_step4,
    insert_info_medical,
    insert_pickup_chlid,
    insert_parent_detail,


}

export default services;