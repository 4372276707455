import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../logo.png';
import logosmall from '../../logo-small.png';

import Avatar1 from '../../profiles/avatar-01.jpg';

function  NavAdmission() {

    function logout() {
        sessionStorage.clear()
        window.location.replace("/")
    }


    return (
      <div className="header">

            <div className="header-left">
                <Link to={"/admission-table"} className="logo">
                    <img src={logo} alt="Logo" />
                </Link>
                <Link to={"/admission-table"} className="logo logo-small">
                    <img src={logosmall} alt="Logo" width="30" height="30" />
                </Link>
            </div>

           

            <div className="top-nav-search">
                <form>
                    <input type="text" className="form-control" placeholder="Search here" />
                    <button className="btn" type="submit"><i className="fas fa-search"></i></button>
                </form>
            </div>

           
            <ul className="nav user-menu">

                <li className="nav-item dropdown noti-dropdown">
                   
                </li>

                <li className="nav-item dropdown has-arrow">
                    <Link to={"!#"} className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                        <span className="user-img"><img className="rounded-circle" src={Avatar1} width="31"
                            alt="Ryan Taylor" /></span>
                    </Link>
                    <div className="dropdown-menu">
                        <div className="user-header">
                            <div className="avatar avatar-sm">
                                <img src={Avatar1} alt="User" className="avatar-img rounded-circle" />
                            </div>
                            <div className="user-text">
                                <h6>Ryan Taylor</h6>
                                <p className="text-muted mb-0">Admission</p>
                            </div>
                        </div>
                        <Link className="dropdown-item" onClick={logout}>Logout</Link>
                    </div>
                </li>

            </ul>

        </div>
    )
}

export default NavAdmission
