import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import Services from '../services/services';
import iconMale from '../male.png';
import iconFemale from '../female.png';

import DataTable from 'react-data-table-component';

function StudentDetail() {

    const { code } = useParams()

    useEffect(() => {
        if (code)
            getStudent(code)
            getStudentPickup(code)
            getParentStudent(code)

    }, [code]);


    const [dataStud, setDataStud] = useState('')
    function getStudent(code) {

        Services.get(code)
            .then((res) => {
                setDataStud(res.data[0])
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataStudPickup, setDataStudPickup] = useState([])

    function getStudentPickup(code){
        Services.getPickup(code)
            .then((res) => {
                setDataStudPickup(res.data)
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsPickup = [
        {
            name: "Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.name_pickup} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Contact",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.contact_pickup} </b>
            ),
            sortable: true,
            reorder: true
        },
    ]


    const [dataParents, setDataParents] = useState([])

    function getParentStudent(code){
        Services.getParents(code)
            .then((res) => {
                setDataParents(res.data)
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsParents = [
        {
            name: "Persone",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Persone} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Name} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Occupation",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Occupation} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Mobile",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Mobile} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Email",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Email} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Present Address",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.PresentAddress} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Permanent Address",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.PermanentAddress} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Responsible of school fees",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Responsible_fees} </b>
            ),
            sortable: true,
            reorder: true
        },
    ]


    return (
        <>
            <Header />
            <Menus />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="page-title">Student Details</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Student-List">Student</Link></li>
                                    <li className="breadcrumb-item active">Student Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="about-info">
                                        <h4>About {dataStud.FirstName} {dataStud.LastName}</h4>

                                        <div className="col-auto text-end float-end ms-auto">
                                <Link to={"/edit-student/"+dataStud.StudentId} className="btn btn-primary">Update infos <i className="fas fa-edit"></i></Link>
                            </div>

                                        <div className="media mt-3 d-flex" key={dataStud.id_stud}>
                                            {/* {(() => {
                                                if (dataStud.StudentImage === 'null') {

                                                    if (dataStud.Gender === 'Male') {
                                                        return (
                                                            <img src={iconMale} className="me-3 flex-shrink-0" alt="..." />
                                                        )

                                                    } else {
                                                        return (
                                                            <img src={iconFemale} className="me-3 flex-shrink-0" alt="..." />
                                                        )

                                                    }

                                                } else {
                                                    
                                                }
                                            })()} */}
                                            <img className="avatar-img rounded-circle" src={"http://localhost/eSchool/storage/app/public/profileStudents/" + dataStud.StudentImage} alt="UserImage" />

                                            <div className="media-body flex-grow-1 col-6">
                                                <ul>
                                                    <li>
                                                        <span className="title-span">Full Name : </span>
                                                        <span className="info-span">{dataStud.FirstName} {dataStud.LastName}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title-span">Classeroom : </span>
                                                        <span className="info-span">{dataStud.Class}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title-span">Mobile : </span>
                                                        <span className="info-span">{dataStud.MobileNumber}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title-span">Joining Date : </span>
                                                        <span className="info-span">{dataStud.JoiningDate}</span>
                                                    </li>
                                                    
                                                    <li>
                                                        <span className="title-span">DOB : </span>
                                                        <span className="info-span">{dataStud.DateofBirth}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title-span">Blood Type : </span>
                                                        <span className="info-span">{dataStud.Bloodtype}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title-span">Admission N° : </span>
                                                        <span className="info-span">{dataStud.AdmissionNumber}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title-span">Academicyear : </span>
                                                        <span className="info-span">{dataStud.AcademicYear}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>






                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="skill-info">
                                        <h4>Pickup Child Information</h4>
                                       
                                        <div className="row mt-3">
                                            <div className="col-md-12">

                                            <div className="table-responsive">
                                  
                                            <DataTable
                                                columns={columnsPickup}
                                                data={dataStudPickup}
                                            />
                                    </div>
                                               
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="skill-info">
                                        <h4>Parent Information</h4>
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                            <div className="table-responsive">
                                  
                                                        <DataTable
                                                            columns={columnsParents}
                                                            data={dataParents}
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <footer>
                    <p>Copyright © 2020 Dreamguys.</p>
                </footer>

            </div>
        </>
    )
}
export default StudentDetail;
