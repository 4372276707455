import React, { useEffect, useState } from "react";
import Menu from "../../components/Menus";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import services from "../../services/services";
import Swal from "sweetalert2";


function AcademicParameters() {

    ///////////////////////////////////////////////////////////////////////////// Sample Invoices //////////////////////////////////////////

    /** Activation modal d'insertion Sample Invoices  */
    const [showModalSampleInvoice, setShowModalSampleInvoice] = useState(false)

    function ShowModalSamp() {
        getClasseOpen()
        setShowModalSampleInvoice(true); //open modal

    }
    const handleCloseModalSampleInvoice = () => setShowModalSampleInvoice(false) ///close modal
    /** Activation modal d'insertion Sample Invoices  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSamp, setSamp] = useState({
        type_invoice: '',
        id_classe: '',
        sun_amount: '',
        concession: '',
    })

    const handleSampleInvoice = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSamp, [name]: value }
        setSamp(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitSampleInvoice(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('type_invoice', dataSamp.type_invoice);
        bodyFormData.append('libelle', dataSamp.id_classe);
        bodyFormData.append('sun_amount', dataSamp.sun_amount);
        bodyFormData.append('concession', dataSamp.concession);

        services.InsertSampleInvoice(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalSampleInvoice();
                getallSampleInvoice();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallSampleInvoice()
        getClasseOpen()
    }, [])

    const [dataClasseOpen, setDataClasseOpen] = useState([]);

    function getClasseOpen() {
        services.get_Classe_Open()
            .then((response) => {

                setDataClasseOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataSampleInvoice, setDataSampleInvoice] = useState([]);

    function getallSampleInvoice() {
        services.getSampleInvoice()
            .then((response) => {

                setDataSampleInvoice(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsSampleInvoice = [
        {
            name: "Type Invoice",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.type_invoice} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Class",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Sun Amount",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.sun_amount} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Concession",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.concession} </b>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateSamp(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSampleInvoice(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteSampleInvoice(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Samp setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSampleInvoice(id)
                    .then(response => {
                        console.log(response.data);
                        getallSampleInvoice()
                        Swal.fire(
                            'Data Samp setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditSampleInvoice, setShowEditModalSampleInvoice] = useState(false)

    function ShowModalUpdateSamp(id) {
        setShowEditModalSampleInvoice(true);
        dataSampEdit(id)

    }
    const handleEditCloseModalSampleInvoice = () => setShowEditModalSampleInvoice(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditSamp, setDataEditSamp] = useState([])

    function dataSampEdit(id) {
        services.get_Samp_by(id)
            .then((response) => {

                setDataEditSamp(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateSampleInvoice(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Samp', id);
        bodyFormData.append('type_invoice', dataSamp.type_invoice);
        bodyFormData.append('sun_amount', dataSamp.sun_amount);
        bodyFormData.append('concession', dataSamp.concession);

        services.updateSampleInvoice(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallSampleInvoice()
                handleEditCloseModalSampleInvoice()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Sample Invoices //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Bank //////////////////////////////////////////

    /** Activation modal d'insertion Bank  */
    const [showModalBank, setShowModalBank] = useState(false)

    function ShowModalBan() {
        setShowModalBank(true); //open modal

    }
    const handleCloseModalBank = () => setShowModalBank(false) ///close modal
    /** Activation modal d'insertion Bank  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataBan, setBan] = useState({
        bank: '',
        description: '',
    })

    const handleBank = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataBan, [name]: value }
        setBan(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitBank(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('bank', dataBan.bank);
        bodyFormData.append('description', dataBan.description);

        services.InsertBank(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalBank();
                getallBank();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallBank()
        // getBankOpen()
    }, [])

    // const [dataBankOpen, setDataBankOpen] = useState([]);

    // function getBankOpen() {
    //     services.get_Bank_Open()
    //         .then((response) => {

    //             setDataBankOpen(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    const [dataBank, setDataBank] = useState([]);

    function getallBank() {
        services.getBank()
            .then((response) => {

                setDataBank(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsBank = [
        {
            name: "Bank",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.bank} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateBan(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteBank(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteBank(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Ban setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteBank(id)
                    .then(response => {
                        console.log(response.data);
                        getallBank()
                        Swal.fire(
                            'Data Ban setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditBank, setShowEditModalBank] = useState(false)

    function ShowModalUpdateBan(id) {
        setShowEditModalBank(true);
        dataBanEdit(id)

    }
    const handleEditCloseModalBank = () => setShowEditModalBank(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditBan, setDataEditBan] = useState([])

    function dataBanEdit(id) {
        services.get_Bank_by(id)
            .then((response) => {

                setDataEditBan(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateBank(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Ban', id);
        bodyFormData.append('bank', dataBan.bank);
        bodyFormData.append('description', dataBan.description);

        services.updateBank(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallBank()
                handleEditCloseModalBank()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Bank //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Items Groups //////////////////////////////////////////

    /** Activation modal d'insertion Items Groups  */
    const [showModalGroups, setShowModalGroups] = useState(false)

    function ShowModalGroup() {
        setShowModalGroups(true); //open modal

    }
    const handleCloseModalGroups = () => setShowModalGroups(false) ///close modal
    /** Activation modal d'insertion Items Groups  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataGroup, setGroup] = useState({
        family: '',
        stock: '',
        package: '',
    })

    const handleGroups = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataGroup, [name]: value }
        setGroup(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitGroups(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('family', dataGroup.family);
        bodyFormData.append('stock', dataGroup.stock);
        bodyFormData.append('package', dataGroup.package);

        services.InsertGroups(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalGroups();
                getallGroups();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallGroups()
        getGroupsOpen()
    }, [])

    const [dataGroupsOpen, setDataGroupsOpen] = useState([]);

    function getGroupsOpen() {
        services.get_Groups_Open()
            .then((response) => {

                setDataGroupsOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataGroups, setDataGroups] = useState([]);

    function getallGroups() {
        services.getGroups()
            .then((response) => {

                setDataGroups(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsGroups = [
        {
            name: "Family",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.family} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Stocks",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.stock} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Packages",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.package} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateGroup(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteGroups(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteGroups(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Groups setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteGroups(id)
                    .then(response => {
                        console.log(response.data);
                        getallGroups()
                        Swal.fire(
                            'Data Items Groups setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditGroups, setShowEditModalGroups] = useState(false)

    function ShowModalUpdateGroup(id) {
        setShowEditModalGroups(true);
        dataGroupEdit(id)

    }
    const handleEditCloseModalGroups = () => setShowEditModalGroups(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditGroup, setDataEditGroup] = useState([])

    function dataGroupEdit(id) {
        services.get_Groups_by(id)
            .then((response) => {

                setDataEditGroup(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateGroups(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Group', id);
        bodyFormData.append('family', dataGroup.family);
        bodyFormData.append('stock', dataGroup.stock);
        bodyFormData.append('package', dataGroup.package);

        services.updateGroups(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallGroups()
                handleEditCloseModalGroups()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Items Groups //////////////////////////////////////////


     ///////////////////////////////////////////////////////////////////////////// Types of PAckage //////////////////////////////////////////

    /** Activation modal d'insertion Types of PAckage  */
    const [showModalTypePackage, setShowModalTypePackage] = useState(false)

    function ShowModalTypeP() {
        setShowModalTypePackage(true); //open modal

    }
    const handleCloseModalTypePackage = () => setShowModalTypePackage(false) ///close modal
    /** Activation modal d'insertion Types of PAckage  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataTypeP, setTypeP] = useState({
        type: '',
    })

    const handleTypePackage = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataTypeP, [name]: value }
        setTypeP(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitTypePackage(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('type', dataTypeP.type);

        services.InsertTypePackage(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalTypePackage();
                getallTypePackage();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallTypePackage()
        // getTypePackageOpen()
    }, [])

    // const [dataTypePackageOpen, setDataTypePackageOpen] = useState([]);

    // function getTypePackageOpen() {
    //     services.get_TypePackage_Open()
    //         .then((response) => {

    //             setDataTypePackageOpen(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    const [dataTypePackage, setDataTypePackage] = useState([]);

    function getallTypePackage() {
        services.getTypePackage()
            .then((response) => {

                setDataTypePackage(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsTypePackage = [
        {
            name: "Type",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.type} </b>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateTypeP(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteTypePackage(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteTypePackage(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Types of PAckage setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteTypePackage(id)
                    .then(response => {
                        console.log(response.data);
                        getallTypePackage()
                        Swal.fire(
                            'Data Types of PAckage setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditTypePackage, setShowEditModalTypePackage] = useState(false)

    function ShowModalUpdateTypeP(id) {
        setShowEditModalTypePackage(true);
        dataTypePEdit(id)

    }
    const handleEditCloseModalTypePackage = () => setShowEditModalTypePackage(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditTypeP, setDataEditTypeP] = useState([])

    function dataTypePEdit(id) {
        services.get_TypePackage_by(id)
            .then((response) => {

                setDataEditTypeP(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateTypePackage(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_TypeP', id);
        bodyFormData.append('type', dataTypeP.type);

        services.updateTypePackage(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallTypePackage()
                handleEditCloseModalTypePackage()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Types of PAckage //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Sizes //////////////////////////////////////////

    /** Activation modal d'insertion Sizes  */
    const [showModalSize, setShowModalSize] = useState(false)

    function ShowModalSiz() {
        setShowModalSize(true); //open modal

    }
    const handleCloseModalSize = () => setShowModalSize(false) ///close modal
    /** Activation modal d'insertion Sizes  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSiz, setSiz] = useState({
        size_name: '',
        code: '',
    })

    const handleSize = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSiz, [name]: value }
        setSiz(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitSize(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('size_name', dataSiz.size_name);
        bodyFormData.append('code', dataSiz.code);

        services.InsertSize(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalSize();
                getallSize();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallSize()
        getSizeOpen()
    }, [])

    const [dataSizeOpen, setDataSizeOpen] = useState([]);

    function getSizeOpen() {
        services.get_Size_Open()
            .then((response) => {

                setDataSizeOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataSize, setDataSize] = useState([]);

    function getallSize() {
        services.getSize()
            .then((response) => {

                setDataSize(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsSize = [
        {
            name: "Size Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.size_name} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Code",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateSiz(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSize(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteSize(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Size setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSize(id)
                    .then(response => {
                        console.log(response.data);
                        getallSize()
                        Swal.fire(
                            'Data Items Size setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditSize, setShowEditModalSize] = useState(false)

    function ShowModalUpdateSiz(id) {
        setShowEditModalSize(true);
        dataSizEdit(id)

    }
    const handleEditCloseModalSize = () => setShowEditModalSize(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditSiz, setDataEditSiz] = useState([])

    function dataSizEdit(id) {
        services.get_Size_by(id)
            .then((response) => {

                setDataEditSiz(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateSize(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Siz', id);
        bodyFormData.append('size_name', dataSiz.size_name);
        bodyFormData.append('code', dataSiz.code);

        services.updateSize(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallSize()
                handleEditCloseModalSize()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Sizes //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Size Charts Names //////////////////////////////////////////

    /** Activation modal d'insertion Size Charts Names  */
    const [showModalSizeChart, setShowModalSizeChart] = useState(false)

    function ShowModalSizeC() {
        setShowModalSizeChart(true); //open modal

    }
    const handleCloseModalSizeChart = () => setShowModalSizeChart(false) ///close modal
    /** Activation modal d'insertion Size Charts Names  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSizeC, setSizeC] = useState({
        chart: '',
    })

    const handleSizeChart = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSizeC, [name]: value }
        setSizeC(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitSizeChart(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('chart', dataSizeC.chart);

        services.InsertSizeChart(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalSizeChart();
                getallSizeChart();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallSizeChart()
        getSizeChartOpen()
    }, [])

    const [dataSizeChartOpen, setDataSizeChartOpen] = useState([]);

    function getSizeChartOpen() {
        services.get_SizeChart_Open()
            .then((response) => {

                setDataSizeChartOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataSizeChart, setDataSizeChart] = useState([]);

    function getallSizeChart() {
        services.getSizeChart()
            .then((response) => {

                setDataSizeChart(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsSizeChart = [
        {
            name: "Charts",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.chart} </b>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateSizeC(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSizeChart(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteSizeChart(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Size Charts Names setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSizeChart(id)
                    .then(response => {
                        console.log(response.data);
                        getallSizeChart()
                        Swal.fire(
                            'Data Size Charts Names setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditSizeChart, setShowEditModalSizeChart] = useState(false)

    function ShowModalUpdateSizeC(id) {
        setShowEditModalSizeChart(true);
        dataSizeCEdit(id)

    }
    const handleEditCloseModalSizeChart = () => setShowEditModalSizeChart(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditSizeC, setDataEditSizeC] = useState([])

    function dataSizeCEdit(id) {
        services.get_SizeChart_by(id)
            .then((response) => {

                setDataEditSizeC(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateSizeChart(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_SizeC', id);
        bodyFormData.append('chart', dataSizeC.chart);

        services.updateSizeChart(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallSizeChart()
                handleEditCloseModalSizeChart()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Size Charts Names //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Size Charts Determination //////////////////////////////////////////

    /** Activation modal d'insertion Size Charts Determination  */
    const [showModalChartDetermination, setShowModalChartDetermination] = useState(false)

    function ShowModalChaDeter() {
        getSizeOpen()
        getSizeChartOpen()
        setShowModalChartDetermination(true); //open modal

    }
    const handleCloseModalChartDetermination = () => setShowModalChartDetermination(false) ///close modal
    /** Activation modal d'insertion Size Charts Determination  */

    
    //show size chart Yes or no

    const [showInputSizeChart, setShowInputSizeChart] = useState(false)

    function checkYes() {
        setShowInputSizeChart(!showInputSizeChart)
    }

    function checkNo() {
        setShowInputSizeChart()
    }

    //show Gender Precision Yes or no

    const [showInputGenderPrecision, setshowInputGenderPrecision] = useState(false)

    function checkGEYes() {
        setshowInputGenderPrecision(!showInputGenderPrecision)
    }

    function checkGENo() {
        setshowInputGenderPrecision()
    }


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSNChaDeter, setSNChaDeter] = useState({
        id_sizechart: '',
    })

    

    const handleSizeNameChartDetermination = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataSNChaDeter, [name]: value }
        setSNChaDeter(data);
        // console.log(data);

    }

    const [dataChaDeter, setChaDeter] = useState({
        id_size: [],
    })

    const handleSizeChartDetermination = (e) => {
        const { value, checked } = e.target;
        const { id_size } = dataChaDeter;

        // const name = e.target.name;
        // const data = { ...dataSiz, [name]: value }
        // setChaDeter(data);
        // console.log(`${value} is ${checked}`);
        // console.log(value);

        if (checked) {
            setChaDeter({
                id_size: [...id_size, value],
            });
        }
        else {
            setChaDeter({
                // id_size: id_size.filter((e) => e !== value),
                id_size: "",
            });
          }
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitChartDetermination(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('chart', dataSNChaDeter.id_sizechart);
        bodyFormData.append('code', dataChaDeter.id_size);

        services.InsertChartDetermination(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalChartDetermination();
                getallChartDetermination();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallChartDetermination()
        // getChartDeterminationOpen()
    }, [])

    // const [dataChartDeterminationOpen, setDataChartDeterminationOpen] = useState([]);

    // function getChartDeterminationOpen() {
    //     services.get_ChartDetermination_Open()
    //         .then((response) => {

    //             setDataChartDeterminationOpen(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    const [dataChartDetermination, setDataChartDetermination] = useState([]);

    function getallChartDetermination() {
        services.getChartDetermination()
            .then((response) => {

                setDataChartDetermination(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsChartDetermination = [
        {
            name: "Chart",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.chart} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Code",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                {/* <Link to={"#"} onClick={(e) => ShowModalUpdateChaDeter(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to="#" onClick={(e) => DeleteChartDetermination(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteChartDetermination(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Size Charts Determination setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSize(id)
                    .then(response => {
                        console.log(response.data);
                        getallSize()
                        Swal.fire(
                            'Data Items Size Charts Determination setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    // /**Activation du modale pour l'update */
    // const [showModalEditChartDetermination, setShowEditModalChartDetermination] = useState(false)

    // function ShowModalUpdateChaDeter(id) {
    //     setShowEditModalChartDetermination(true);
    //     dataChartDeterminationdit(id)

    // }
    // const handleEditCloseModalChartDetermination = () => setShowEditModalChartDetermination(false)
    // /**Activation du modale pour l'update */


    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    // const [dataEditChaDeter, setDataEditChaDeter] = useState([])

    // function dataChartDeterminationdit(id) {
    //     services.get_ChartDetermination_by(id)
    //         .then((response) => {

    //             setDataEditChaDeter(response.data[0])
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    // /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    // /**function de modification des donnée recuperé en fonction de l'ID */
    // function handleUpdateChartDetermination(e, id) {
    //     e.preventDefault();

    //     const bodyFormData = new FormData();

    //     bodyFormData.append('id_ChaDeter', id);
    //     bodyFormData.append('code', dataChaDeter.id_size);
    //     bodyFormData.append('chart', dataChaDeter.id_sizechart);

    //     services.updateChartDetermination(bodyFormData)
    //         .then((response) => {
    //             Swal.fire({
    //                 position: 'top-end',
    //                 icon: 'success',
    //                 title: response.data.message,
    //                 showConfirmButton: false,
    //                 timer: 5000
    //             })
    //             console.log(response.data)
    //             getallChartDetermination()
    //             handleEditCloseModalChartDetermination()

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }
    // /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// Size Charts Determination //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Items //////////////////////////////////////////

    /** Activation modal d'insertion Items  */
    const [showModalItem, setShowModalItem] = useState(false)

    function ShowModalIte() {
        getGroupsOpen()
        setShowModalItem(true); //open modal

    }
    const handleCloseModalItem = () => setShowModalItem(false) ///close modal
    /** Activation modal d'insertion Items  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataIte, setIte] = useState({
        name: '',
        description: '',
        id_itemgroup: '',
        size_chart: '',
        id_sizechart: '',
        gender_precision: '',
        gender_desc:'',
        stock_alert: '',
        unit_price: '',
    })

    const handleItem = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataIte, [name]: value }
        setIte(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitItem(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('name', dataIte.name);
        bodyFormData.append('description', dataIte.description);
        bodyFormData.append('family', dataIte.id_itemgroup);
        bodyFormData.append('size_chart', dataIte.size_chart);
        bodyFormData.append('id_sizechart', dataIte.id_sizechart);
        bodyFormData.append('gender_precision', dataIte.gender_precision);
        bodyFormData.append('gender', dataIte.gender_desc);
        bodyFormData.append('stock_alert', dataIte.stock_alert);
        bodyFormData.append('unit_price', dataIte.unit_price);

        services.InsertItem(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalItem();
                getallItem();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallItem()
        // getItemOpen()
    }, [])

    // const [dataItemOpen, setDataItemOpen] = useState([]);

    // function getItemOpen() {
    //     services.get_Item_Open()
    //         .then((response) => {

    //             setDataItemOpen(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    const [dataItem, setDataItem] = useState([]);

    function getallItem() {
        services.getItem()
            .then((response) => {

                setDataItem(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsItem = [
        {
            name: "Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.name} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Family",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.family} </b>
            ),
            sortable: true,
            reorder: true
        },{
            name: "Size Chart",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.size_chart} </b>
            ),
            sortable: true,
            reorder: true
        },{
            name: "Gender Precision",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.gender_precision} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Stock Alert",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.stock_alert} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Unit Price",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.unit_price} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateIte(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteItem(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteItem(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteItem(id)
                    .then(response => {
                        console.log(response.data);
                        getallItem()
                        Swal.fire(
                            'Data Items setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditItem, setShowEditModalItem] = useState(false)

    function ShowModalUpdateIte(id) {
        setShowEditModalItem(true);
        dataIteEdit(id)

    }
    const handleEditCloseModalItem = () => setShowEditModalItem(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditIte, setDataEditIte] = useState([])

    function dataIteEdit(id) {
        services.get_Item_by(id)
            .then((response) => {

                setDataEditIte(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateItem(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Ite', id);
        bodyFormData.append('name', dataIte.name);
        bodyFormData.append('description', dataIte.description);
        bodyFormData.append('size_chart', dataIte.size_chart);
        bodyFormData.append('gender_precision', dataIte.gender_precision);
        bodyFormData.append('stock_alert', dataIte.stock_alert);
        bodyFormData.append('unit_price', dataIte.unit_price);

        services.updateItem(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallItem()
                handleEditCloseModalItem()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Items //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Provider //////////////////////////////////////////

    /** Activation modal d'insertion Provider  */
    const [showModalFiProvider, setShowModalFiProvider] = useState(false)

    function ShowModalFiProv() {
        setShowModalFiProvider(true); //open modal

    }
    const handleCloseModalFiProvider = () => setShowModalFiProvider(false) ///close modal
    /** Activation modal d'insertion Provider  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataFiProv, setFiProv] = useState({
        full_name: '',
        adresse: '',
        city: '',
        country: '',
        office_number: '',
        email: '',
        modele_1: '',
        modele_2: '',
    })

    const handleFiProvider = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataFiProv, [name]: value }
        setFiProv(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitFiProvider(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('full_name', dataFiProv.full_name);
        bodyFormData.append('adresse', dataFiProv.adresse);
        bodyFormData.append('city', dataFiProv.city);
        bodyFormData.append('country', dataFiProv.country);
        bodyFormData.append('office_number', dataFiProv.office_number);
        bodyFormData.append('email', dataFiProv.email);
        bodyFormData.append('modele_1', dataFiProv.modele_1);
        bodyFormData.append('modele_2', dataFiProv.modele_2);

        services.InsertFiProvider(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalFiProvider();
                getallFiProvider();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallFiProvider()
        // getFiProviderOpen()
    }, [])

    // const [dataFiProviderOpen, setDataFiProviderOpen] = useState([]);

    // function getFiProviderOpen() {
    //     services.get_FiProvider_Open()
    //         .then((response) => {

    //             setDataFiProviderOpen(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    const [dataFiProvider, setDataFiProvider] = useState([]);

    function getallFiProvider() {
        services.getFiProvider()
            .then((response) => {

                setDataFiProvider(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsFiProvider = [
        {
            name: "Full Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.full_name} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Adresse",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.adresse} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "City",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.city} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Country",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.country} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Office Number",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.office_number} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Email",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.email} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Modele 1",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.modele_1} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Modele 2",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.modele_2} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateFiProv(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteFiProvider(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteFiProvider(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Provider setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteFiProvider(id)
                    .then(response => {
                        console.log(response.data);
                        getallFiProvider()
                        Swal.fire(
                            'Data Provider setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditFiProvider, setShowEditModalFiProvider] = useState(false)

    function ShowModalUpdateFiProv(id) {
        setShowEditModalFiProvider(true);
        dataFiProvEdit(id)

    }
    const handleEditCloseModalFiProvider = () => setShowEditModalFiProvider(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditFiProv, setDataEditFiProv] = useState([])

    function dataFiProvEdit(id) {
        services.get_FiProvider_by(id)
            .then((response) => {

                setDataEditFiProv(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateFiProvider(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_FiProv', id);
        bodyFormData.append('full_name', dataFiProv.full_name);
        bodyFormData.append('adresse', dataFiProv.adresse);
        bodyFormData.append('city', dataFiProv.city);
        bodyFormData.append('country', dataFiProv.country);
        bodyFormData.append('office_number', dataFiProv.office_number);
        bodyFormData.append('email', dataFiProv.email);
        bodyFormData.append('modele_1', dataFiProv.modele_1);
        bodyFormData.append('modele_2', dataFiProv.modele_2);

        services.updateFiProvider(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallFiProvider()
                handleEditCloseModalFiProvider()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
     ///////////////////////////////////////////////////////////////////////////// Provider //////////////////////////////////////////


     ///////////////////////////////////////////////////////////////////////////// Instalment /////////////////////////////////////////

    /** Activation modal d'insertion Instalment */
    const [showModalInstalment, setShowModalInstalment] = useState(false)

    function ShowModalInst() {
        setShowModalInstalment(true); //open modal

    }
    const handleCloseModalInstalment = () => setShowModalInstalment(false) ///close modal
    /** Activation modal d'insertion Instalment */




    /** variable de recuperation des donnees entrant dans les champ input */
    const [dataInst, setInst] = useState({
        instal_numb: '',
        deadline: '',
    })

    const handleInstalment = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataInst, [name]: value }
        setInst(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitInstalment(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('instal_numb', dataInst.instal_numb);
        bodyFormData.append('deadline', dataInst.deadline);

        services.InsertInstalment(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalInstalment();
                getallInstalment();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallInstalment()
        // getInstalmentOpen()
    }, [])


    const [dataInstalment, setDataInstalment] = useState([]);

    function getallInstalment() {
        services.getInstalment()
            .then((response) => {

                setDataInstalment(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    // const [dataInstalmentOpen, setDataInstalmentOpen] = useState([]);

    // function getInstalmentOpen() {
    //     services.get_Instalment_Open()
    //         .then((response) => {

    //             setDataInstalmentOpen(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    const columnsInstalment = [
        {
            name: "Instalment Number",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.instal_numb} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Deadline",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.deadline} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Etat",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.status === 0) {
                            return (
                                <Link className="me-2">
                                    <i className="fas fa-toggle-off fa-2x" onClick={() => OpenInsta(rows.id)} style={{ color: 'red' }}></i>
                                </Link>
                            )
                        } else {
                            return (
                                <Link className="e-2">
                                    <i className="fas fa-toggle-on fa-2x" onClick={() => CloseInsta(rows.id)} style={{ color: 'green' }}></i>
                                </Link>
                            )
                        }
                    })()}



                </div>


            ),
            sortable: true,
            reorder: true
        },
        


        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdate(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteInstalment(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Ouverture des etats */

    function OpenInsta(id) {
        services.openInstalment(id)
                    .then(response => {
                        console.log(response.data);
                        getallInstalment()
                        
                    })
                    .catch(e => {
                        console.log(e);
                    });
        
            
    }


    /** Fermerture des etats */

    function CloseInsta(id) {
        services.closeInstalment(id)
                    .then(response => {
                        console.log(response.data);
                        getallInstalment()
                        
                    })
                    .catch(e => {
                        console.log(e);
                    });
        
            
    }

    /** Suppression un élement dans la base de donée */

    function DeleteInstalment(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Instalment",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteInstalment(id)
                    .then(response => {
                        console.log(response.data);
                        getallInstalment()
                        Swal.fire(
                            'Data Instalment has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditInstalment, setShowEditModalInstalment] = useState(false)

    function ShowModalUpdate(id) {
        setShowEditModalInstalment(true);
        dataInstEdit(id)

    }
    const handleEditCloseModalInstalment = () => setShowEditModalInstalment(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditInst, setDataEditInst] = useState([])

    function dataInstEdit(id) {
        services.get_Instalment_by(id)
            .then((response) => {

                setDataEditInst(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateInstalment(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Inst', id);
        bodyFormData.append('instal_numb', dataInst.instal_numb);
        bodyFormData.append('deadline', dataInst.deadline);

        services.updateInstalment(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallInstalment()
                handleEditCloseModalInstalment()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Instalment //////////////////////////////////////////



    ///////////////////////////////////////////////////////////////////////////// Date First Instal //////////////////////////////////////////

    /** Activation modal d'insertion Date First Instal  */
    const [showModalDateFirst, setShowModalDateFirst] = useState(false)

    function ShowModalDateF() {
        setShowModalDateFirst(true); //open modal

    }
    const handleCloseModalDateFirst = () => setShowModalDateFirst(false) ///close modal
    /** Activation modal d'insertion Date First Instal  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataDateF, setDateF] = useState({
        deadline: '',
    })

    const handleDateFirst = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataDateF, [name]: value }
        setDateF(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitDateFirst(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('deadline', dataDateF.deadline);

        services.InsertDateFirst(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalDateFirst();
                getallDateFirst();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallDateFirst()
        // getDateFirstOpen()
    }, [])

    // const [dataDateFirstOpen, setDataDateFirstOpen] = useState([]);

    // function getDateFirstOpen() {
    //     services.get_DateFirst_Open()
    //         .then((response) => {

    //             setDataDateFirstOpen(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    const [dataDateFirst, setDataDateFirst] = useState([]);

    function getallDateFirst() {
        services.getDateFirst()
            .then((response) => {

                setDataDateFirst(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsDateFirst = [
        {
            name: "Deadline",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.deadline} </b>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateDateF(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteDateFirst(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteDateFirst(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Date First Instal setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteDateFirst(id)
                    .then(response => {
                        console.log(response.data);
                        getallDateFirst()
                        Swal.fire(
                            'Data Date First Instal setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditDateFirst, setShowEditModalDateFirst] = useState(false)

    function ShowModalUpdateDateF(id) {
        setShowEditModalDateFirst(true);
        dataDateFEdit(id)

    }
    const handleEditCloseModalDateFirst = () => setShowEditModalDateFirst(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditDateF, setDataEditDateF] = useState([])

    function dataDateFEdit(id) {
        services.get_DateFirst_by(id)
            .then((response) => {

                setDataEditDateF(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateDateFirst(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_DateF', id);
        bodyFormData.append('deadline', dataDateF.deadline);

        services.updateDateFirst(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallDateFirst()
                handleEditCloseModalDateFirst()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Date First Instal //////////////////////////////////////////


    

    return(
        <>
        <Menu />
        <Header />

        <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Finances Parameters</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Setting</a></li>
                                    <li className="breadcrumb-item active">Finances Parameters</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="card bg-white">
                                <div className="card-header">
                                    <h5 className="card-title">Finances Parameters</h5>
                                </div>
                                <div className="card-body">
                                    <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                        <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab1" data-bs-toggle="tab">Types of PAckage</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab2" data-bs-toggle="tab">Bank</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab3" data-bs-toggle="tab">Items Groups</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4" data-bs-toggle="tab">Types Of Packages</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5" data-bs-toggle="tab">Sizes</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab6" data-bs-toggle="tab">Size Charts Names</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab7" data-bs-toggle="tab">Size Charts Determination</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab8" data-bs-toggle="tab">Items</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab9" data-bs-toggle="tab">Provider</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab10" data-bs-toggle="tab">Instalment</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab11" data-bs-toggle="tab">Date 1st Instalment</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="solid-justified-tab1">
                                        <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalSamp()} ><i className="fas fa-plus"></i> Add Model </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsSampleInvoice}
                                                                                data={dataSampleInvoice}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                               
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab2">
                                                <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalBan()} ><i className="fas fa-plus"></i> Add Bank </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsBank}
                                                                                data={dataBank}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab3">
                                             
                                                <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalGroup()} ><i className="fas fa-plus"></i> Add Items Groups </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsGroups}
                                                                                data={dataGroups}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>   
                                        </div>
                                       
                                        <div className="tab-pane" id="solid-justified-tab4">
                                             
                                                <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalTypeP()} ><i className="fas fa-plus"></i> Add Type of parkage </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsTypePackage}
                                                                                data={dataTypePackage}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>   
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab5">
                                             
                                                <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalSiz()} ><i className="fas fa-plus"></i> Add Size </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsSize}
                                                                                data={dataSize}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>   
                                        </div>


                                        <div className="tab-pane" id="solid-justified-tab6">
                                        <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalSizeC()} ><i className="fas fa-plus"></i> Add Size Charts Name </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsSizeChart}
                                                                                data={dataSizeChart}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab7">
                                        <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalChaDeter()} ><i className="fas fa-plus"></i> Add Charts Determination </button>

                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsChartDetermination}
                                                                                data={dataChartDetermination}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab8">
                                        <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalIte()} ><i className="fas fa-plus"></i> Add Items </button>

                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsItem}
                                                                                data={dataItem}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab9">
                                            <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalFiProv()} ><i className="fas fa-plus"></i> Add Provider </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsFiProvider}
                                                                                data={dataFiProvider}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab10">
                                            <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalInst()} ><i className="fas fa-plus"></i> Add Instalment Dateline </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsInstalment}
                                                                                data={dataInstalment}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>


                                        <div className="tab-pane" id="solid-justified-tab11">
                                            <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalDateF()} ><i className="fas fa-plus"></i> Add Date 1st Instalment </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsDateFirst}
                                                                                data={dataDateFirst}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                pagination
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>


            {/* formulaire Sample Invoices */}
            <Modal show={showModalSampleInvoice} onHide={handleCloseModalSampleInvoice} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Sample Invoices Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitSampleInvoice(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Type Invoice</label>
                                    <input type="text" name="type_invoice" onChange={(e) => handleSampleInvoice(e)} placeholder="type_invoice..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Classe</label>
                                    <select name="id_classe" onChange={(e) => handleSampleInvoice(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Sun Amount</label>
                                    <input type="text" name="sun_amount" onChange={(e) => handleSampleInvoice(e)} placeholder="sun_amount..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Concession</label>
                                    <input type="text" name="concession" onChange={(e) => handleSampleInvoice(e)} placeholder="concession..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditSampleInvoice} onHide={handleEditCloseModalSampleInvoice} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Sample Invoices Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateSampleInvoice(e, dataEditSamp.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Type Invoice</label>
                                    <input type="text" name="type_invoice" defaultValue={dataEditSamp.type_invoice} onChange={(e) => handleSampleInvoice(e)} placeholder="type_invoice..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Sun Amount</label>
                                    <input type="text" name="sun_amount" defaultValue={dataEditSamp.sun_amount} onChange={(e) => handleSampleInvoice(e)} placeholder="sun_amount..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Concession</label>
                                    <input type="text" name="concession" defaultValue={dataEditSamp.concession} onChange={(e) => handleSampleInvoice(e)} placeholder="concession..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Sample Invoices */}


             {/* formulaire Bank */}
             <Modal show={showModalBank} onHide={handleCloseModalBank} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Bank Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitBank(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Bank</label>
                                    <input type="text" name="bank" onChange={(e) => handleBank(e)} placeholder="bank..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleBank(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditBank} onHide={handleEditCloseModalBank} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Bank Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateBank(e, dataEditBan.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Bank</label>
                                    <input type="text" name="bank" defaultValue={dataEditBan.bank} onChange={(e) => handleBank(e)} placeholder="bank..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditBan.description} onChange={(e) => handleBank(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Bank */}


            {/* formulaire Items Groups */}
            <Modal show={showModalGroups} onHide={handleCloseModalGroups} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Items Groups Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitGroups(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Family</label>
                                    <input type="text" name="family" onChange={(e) => handleGroups(e)} placeholder="family..." className="form-control" />
                                </div> 
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Stocks</label> &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="stock" defaultValue="Yes" onChange={(e) => handleGroups(e)} /> Yes  &emsp;&emsp; 
                                    <input type="radio" name="stock" defaultValue="No" onChange={(e) => handleGroups(e)} /> No  
                                </div>
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Packages</label> &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;
                                    <input type="radio" name="package" defaultValue="Yes" onChange={(e) => handleGroups(e)} /> Yes &emsp;&emsp; 
                                    <input type="radio" name="package" defaultValue="No" onChange={(e) => handleGroups(e)} /> No   
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditGroups} onHide={handleEditCloseModalGroups} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Items Groups Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateGroups(e, dataEditGroup.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Family</label>
                                    <input type="text" name="family" defaultValue={dataEditGroup.family} onChange={(e) => handleGroups(e)} placeholder="family..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Stocks</label> &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="stock" defaultValue="Yes" onChange={(e) => handleGroups(e)} /> Yes  &emsp;&emsp; 
                                    <input type="radio" name="stock" defaultValue="No" onChange={(e) => handleGroups(e)} /> No 
                                </div>
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Packages</label> &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;
                                    <input type="radio" name="package" defaultValue="Yes" onChange={(e) => handleGroups(e)} /> Yes &emsp;&emsp; 
                                    <input type="radio" name="package" defaultValue="No" onChange={(e) => handleGroups(e)} /> No 
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Items Groups */}
                     
            {/* formulaire Types Packages */}
            <Modal show={showModalTypePackage} onHide={handleCloseModalTypePackage} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Types Packages Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitTypePackage(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Type</label>
                                    <input type="text" name="type" onChange={(e) => handleTypePackage(e)} placeholder="type..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditTypePackage} onHide={handleEditCloseModalTypePackage} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Types Packages Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateTypePackage(e, dataEditTypeP.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Type</label>
                                    <input type="text" name="type" defaultValue={dataEditTypeP.type} onChange={(e) => handleTypePackage(e)} placeholder="type..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Types Packages */} 


            {/* formulaire Sizes */}
            <Modal show={showModalSize} onHide={handleCloseModalSize} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Sizes Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitSize(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Size Name</label>
                                    <input type="text" name="size_name" onChange={(e) => handleSize(e)} placeholder="size_name..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" onChange={(e) => handleSize(e)} placeholder="code..." className="form-control" />
                                </div>
                                

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditSize} onHide={handleEditCloseModalSize} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Sizes Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateSize(e, dataEditSiz.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Size Name</label>
                                    <input type="text" name="size_name" defaultValue={dataEditSiz.size_name} onChange={(e) => handleSize(e)} placeholder="size_name..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" defaultValue={dataEditSiz.code} onChange={(e) => handleSize(e)} placeholder="code..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Sizes */}   


            {/* formulaire Size Charts Names */}
            <Modal show={showModalSizeChart} onHide={handleCloseModalSizeChart} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Size Charts Names Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitSizeChart(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Charts</label>
                                    <input type="text" name="chart" onChange={(e) => handleSizeChart(e)} placeholder="chart..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditSizeChart} onHide={handleEditCloseModalSizeChart} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Size Charts Names Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateSizeChart(e, dataEditSizeC.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Charts</label>
                                    <input type="text" name="chart" defaultValue={dataEditSizeC.chart} onChange={(e) => handleSizeChart(e)} placeholder="chart..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Size Charts Names */}    



            {/* formulaire Size Charts Determination */}
            <Modal show={showModalChartDetermination} onHide={handleCloseModalChartDetermination} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Size Charts Determination Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitChartDetermination(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Size Chart</label>
                                    <select name="id_sizechart" onChange={(e) => handleSizeNameChartDetermination(e)} id="id_sizechart"  className="form-control">
                                    <option value="">Select chart</option>{
                                    dataSizeChartOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.chart}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Choose Size</label>
                                    {
                                    dataSizeOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_size" 
                                                id="id_size"  
                                                className="form-check-input"  
                                                onChange={(e) => handleSizeChartDetermination(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label"  defaultValue={result.code}>{result.code}</label>
                                            </div>
                                            ))
                                          }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* <Modal show={showModalEditChartDetermination} onHide={handleEditCloseModalChartDetermination} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Size Charts Determination Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateChartDetermination(e, dataEditSiz.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Size Name</label>
                                    <input type="text" name="size_name" defaultValue={dataEditSiz.size_name} onChange={(e) => handleChartDetermination(e)} placeholder="size_name..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Code</label>
                                    <input type="text" name="code" defaultValue={dataEditSiz.code} onChange={(e) => handleChartDetermination(e)} placeholder="code..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> */}
            {/* formulaire Size Charts Determination */} 


            {/* formulaire Items */}
            <Modal show={showModalItem} onHide={handleCloseModalItem} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Items Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitItem(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name" onChange={(e) => handleItem(e)} placeholder="name..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleItem(e)} placeholder="description..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Select Item Group</label>
                                    <select name="id_itemgroup" onChange={(e) => handleItem(e)} id=""  className="form-control">
                                    <option value="">Select family</option>{
                                    dataGroupsOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.family}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-12">

                                    <label htmlFor="">Size Chart</label> &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
                                    <input type="radio" name="size_chart" defaultValue="Yes" onChange={(e) => handleItem(e)} onClick={checkYes} /> Yes  &emsp;&emsp; 
                                    <input type="radio" name="size_chart" defaultValue="No" onChange={(e) => handleItem(e)} onClick={checkNo} /> No  
                                    <div className="row">
                                        {
                                            showInputSizeChart && 
                                            <>
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-6">
                                                <select name="id_sizechart" onChange={(e) => handleItem(e)} id=""  className="form-control">
                                                <option value="">Select Size Chart</option>{
                                                dataSizeChartOpen.map((result) => (
                                                    <option key={result.id} value={result.id}>{result.chart}</option>
                                                    ))
                                                    }
                                                </select>
                                            </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Gender Precision</label> &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                    <input type="radio" name="gender_precision" defaultValue="Yes" onChange={(e) => handleItem(e)} onClick={checkGEYes} /> Yes  &emsp;&emsp; 
                                    <input type="radio" name="gender_precision" defaultValue="No" onChange={(e) => handleItem(e)} onClick={checkGENo} /> No  
                                    <div className="row">
                                        {
                                            showInputGenderPrecision && 
                                            <>
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-6">
                                                <input type="text" name="gender_desc" onChange={(e) => handleItem(e)} placeholder="gender_precision..." className="form-control" />
                                            </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Stock Alert</label>
                                    <input type="text" name="stock_alert" onChange={(e) => handleItem(e)} placeholder="stock_alert..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Unit Price</label>
                                    <input type="text" name="unit_price" onChange={(e) => handleItem(e)} placeholder="unit_price..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditItem} onHide={handleEditCloseModalItem} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Items Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateItem(e, dataEditIte.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="name" defaultValue={dataEditIte.name} onChange={(e) => handleItem(e)} placeholder="name..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditIte.description} onChange={(e) => handleItem(e)} placeholder="description..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Size Chart</label> &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
                                    <input type="radio" name="size_chart" defaultValue="Yes" onChange={(e) => handleItem(e)} /> Yes  &emsp;&emsp; 
                                    <input type="radio" name="size_chart" defaultValue="No" onChange={(e) => handleItem(e)} /> No  
                                </div>
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Gender Precision</label> &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                    <input type="radio" name="gender_precision" defaultValue="Yes" onChange={(e) => handleItem(e)} /> Yes  &emsp;&emsp; 
                                    <input type="radio" name="gender_precision" defaultValue="No" onChange={(e) => handleItem(e)} /> No  
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Stock Alert</label>
                                    <input type="text" name="stock_alert" defaultValue={dataEditIte.stock_alert} onChange={(e) => handleItem(e)} placeholder="stock_alert..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Unit Price</label>
                                    <input type="text" name="unit_price" defaultValue={dataEditIte.unit_price} onChange={(e) => handleItem(e)} placeholder="unit_price..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Items */}       


            {/* formulaire Provider */}
            <Modal show={showModalFiProvider} onHide={handleCloseModalFiProvider} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Provider Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitFiProvider(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor=""> Full Name</label>
                                    <input type="text" name="full_name" onChange={(e) => handleFiProvider(e)} placeholder="full_name..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Adresse</label>
                                    <input type="text" name="adresse" onChange={(e) => handleFiProvider(e)} placeholder="adresse..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">City</label>
                                    <input type="text" name="city" onChange={(e) => handleFiProvider(e)} placeholder="city..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Country</label>
                                    <input type="text" name="country" onChange={(e) => handleFiProvider(e)} placeholder="country..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Office Number</label>
                                    <input type="text" name="office_number" onChange={(e) => handleFiProvider(e)} placeholder="office_number..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Email</label>
                                    <input type="text" name="email" onChange={(e) => handleFiProvider(e)} placeholder="email..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Modele 1</label>
                                    <input type="text" name="modele_1" onChange={(e) => handleFiProvider(e)} placeholder="modele_1..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Modele 2</label>
                                    <input type="text" name="modele_2" onChange={(e) => handleFiProvider(e)} placeholder="modele_2..." className="form-control" />
                                </div>

                            </div>
                            <hr />
                            
                        </div>
                        {/* <Modal.Title>
                            <h3 style={{ color: 'orange' }}>Persons to contact </h3>
                        </Modal.Title>
                        <Modal.Body>
                                <div className="col-lg-12 " style={{ fontWeight: 'bold' }}>
                                    <div className="row">
                                        <label htmlFor="">First Person</label>
                                        <div className="col-12 col-sm-4">
                                            <input type="text" name="Name" onChange={(e) => handleFiProvider(e)} placeholder="Name..." className="form-control" />
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <input type="text" name="Function" onChange={(e) => handleFiProvider(e)} placeholder="Function..." className="form-control" />
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <input type="text" name="Contact" onChange={(e) => handleFiProvider(e)} placeholder="Contact..." className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 " style={{ fontWeight: 'bold' }}>
                                    <div className="row">
                                        <label htmlFor="">Second Person</label>
                                        <div className="col-12 col-sm-4">
                                            <input type="text" name="Name" onChange={(e) => handleFiProvider(e)} placeholder="Name..." className="form-control" />
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <input type="text" name="Function" onChange={(e) => handleFiProvider(e)} placeholder="Function..." className="form-control" />
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <input type="text" name="Contact" onChange={(e) => handleFiProvider(e)} placeholder="Contact..." className="form-control" />
                                        </div>
                                    </div>
                                </div>
                        </Modal.Body> */}
                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditFiProvider} onHide={handleEditCloseModalFiProvider} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Provider Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateFiProvider(e, dataEditFiProv.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Full Name</label>
                                    <input type="text" name="full_name" defaultValue={dataEditFiProv.full_name} onChange={(e) => handleFiProvider(e)} placeholder="full_name..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Adresse</label>
                                    <input type="text" name="adresse" defaultValue={dataEditFiProv.adresse} onChange={(e) => handleFiProvider(e)} placeholder="adresse..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">City</label>
                                    <input type="text" name="city" defaultValue={dataEditFiProv.city} onChange={(e) => handleFiProvider(e)} placeholder="city..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Country</label>
                                    <input type="text" name="country" defaultValue={dataEditFiProv.country} onChange={(e) => handleFiProvider(e)} placeholder="country..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Office Number</label>
                                    <input type="text" name="office_number" defaultValue={dataEditFiProv.office_number} onChange={(e) => handleFiProvider(e)} placeholder="office_number..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Email</label>
                                    <input type="text" name="email" defaultValue={dataEditFiProv.email} onChange={(e) => handleFiProvider(e)} placeholder="email..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Modele 1</label>
                                    <input type="text" name="modele_1" defaultValue={dataEditFiProv.modele_1} onChange={(e) => handleFiProvider(e)} placeholder="modele_1..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Modele 2</label>
                                    <input type="text" name="modele_2" defaultValue={dataEditFiProv.modele_2} onChange={(e) => handleFiProvider(e)} placeholder="modele_2..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Provider */}     


            {/* formulaire Instalment */}
            <Modal show={showModalInstalment} onHide={handleCloseModalInstalment} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Instalment Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitInstalment(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Instalment Number</label>
                                    <input type="number" name="instal_numb" onChange={(e) => handleInstalment(e)} placeholder="Instalment Number..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Deadline</label>
                                    <input type="date" name="deadline" onChange={(e) => handleInstalment(e)} placeholder="Deadline..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditInstalment} onHide={handleEditCloseModalInstalment} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Instalment Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateInstalment(e, dataEditInst.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Charts</label>
                                    <input type="number" name="instal_numb" defaultValue={dataEditInst.instal_numb} onChange={(e) => handleInstalment(e)} placeholder="Instalment Number..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Deadline</label>
                                    <input type="date" name="deadline" defaultValue={dataEditInst.deadline} onChange={(e) => handleInstalment(e)} placeholder="Deadline..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Instalment */}


            {/* formulaire Date First Instal */}
            <Modal show={showModalDateFirst} onHide={handleCloseModalDateFirst} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Date First Instal Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitDateFirst(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Deadline</label>
                                    <input type="date" name="deadline" onChange={(e) => handleDateFirst(e)} placeholder="deadline..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditDateFirst} onHide={handleEditCloseModalDateFirst} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Date First Instal Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateDateFirst(e, dataEditDateF.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Deadline</label>
                                    <input type="date" name="deadline" defaultValue={dataEditDateF.deadline} onChange={(e) => handleDateFirst(e)} placeholder="Deadline..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Date First Instal */}


        </>
    )
    
}
export default AcademicParameters;