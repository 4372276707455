import DataTable from "react-data-table-component";
import Header from "../../components/Header";
import Menus from "../../components/Menus";
import services from "../../services/services";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";


function StudentSection() {

     ///////////////////////////////////////////////////////////////////////////// Student Section //////////////////////////////////////////

    /** Activation modal d'insertion Student Section  */
    const [showModalStudentSection, setShowModalStudentSection] = useState(false)

    function ShowModalStudeSec() {
        setShowModalStudentSection(true); //open modal

    }
    const handleCloseModalStudentSection = () => setShowModalStudentSection(false) ///close modal
    /** Activation modal d'insertion Student Section  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataStudeSec, setStudeSec] = useState({
        id_student: '',
        id_class_section: '',
    })


    const handleStudentSection = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataStudeSec, [name]: value }
        setStudeSec(data);
        console.log(data);

    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitStudentSection(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('student', dataStudeSec.id_student);
        bodyFormData.append('libelle', dataStudeSec.id_class_section);

        services.InsertStudentSection(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalStudentSection();
                getallStudentSection();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallStudentSection()
        getClasseSectionOpen()
        getStudentOpen()
    }, [])

    const [dataClasseSectionOpen, setDataClasseSectionOpen] = useState([]);

    function getClasseSectionOpen() {
        services.get_ClasseSection_Open()
            .then((response) => {
                setDataClasseSectionOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataStudentOpen, setDataStudentOpen] = useState([]);

    function getStudentOpen() {
        services.get_Student_Open()
            .then((response) => {

                setDataStudentOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const [dataStudentSection, setDataStudentSection] = useState([]);

    function getallStudentSection() {
        services.getStudentSection()
            .then((response) => {

                setDataStudentSection(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsStudentSection = [
        {
            name: "Student",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.prenom} {rows.nom}</b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Class & Section",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.id_classe}{rows.id_section} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Academic Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year}</b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateStudeSec(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteStudentSection(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteStudentSection(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Student Section setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteStudentSection(id)
                    .then(response => {
                        // console.log(response.data);
                        getallStudentSection()
                        Swal.fire(
                            'Data Items Student Section setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditStudentSection, setShowEditModalStudentSection] = useState(false)

    function ShowModalUpdateStudeSec(id) {
        setShowEditModalStudentSection(true);
        dataStudentSectiondit(id)

    }
    const handleEditCloseModalStudentSection = () => setShowEditModalStudentSection(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditStudeSec, setDataEditStudeSec] = useState([])

    function dataStudentSectiondit(id) {
        services.get_StudentSection_by(id)
            .then((response) => {

                setDataEditStudeSec(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateStudentSection(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_StudeSec', id);
        bodyFormData.append('student', dataStudeSec.id_student);
        bodyFormData.append('libelle', dataStudeSec.id_class_section);

        services.updateStudentSection(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallStudentSection()
                handleEditCloseModalStudentSection()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// StudentSection //////////////////////////////////////////

    return (
        <>

            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Student Section</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Mapping</a></li>
                                    <li className="breadcrumb-item active">Student Section</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalStudeSec()} ><i className="fas fa-plus"></i> Add Student Section </button>

                                                <DataTable
                                                    title="DataTable"
                                                    // customStyles={customStyles}
                                                    columns={columnsStudentSection}
                                                    data={dataStudentSection}
                                                    responsive
                                                    noHeader
                                                    defaultSortFieldId={1}
                                                    defaultSortField="id"
                                                    defaultSortAsc={false}
                                                    persistTableHead
                                                    highlightOnHover
                                                    exportHeaders={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* formulaire StudentSection */}

            <Modal show={showModalStudentSection} onHide={handleCloseModalStudentSection} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Student Section </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitStudentSection(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Student</label>
                                    <select name="id_student" onChange={(e) => handleStudentSection(e)} id=""  className="form-control">
                                    <option value="">Select Student</option>{
                                    dataStudentOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.prenom} {result.nom}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class section</label>
                                    <select name="id_class_section" onChange={(e) => handleStudentSection(e)} id=""  className="form-control">
                                    <option value="">Select class Section</option>{
                                    dataClasseSectionOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.id_classe}{result.id_section}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditStudentSection} onHide={handleEditCloseModalStudentSection} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Student Section </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateStudentSection(e, dataEditStudeSec.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Student</label>
                                    <select name="id_student" defaultValue={dataEditStudeSec.libelle} onChange={(e) => handleStudentSection(e)} id=""  className="form-control">
                                    <option value="">Select Student</option>{
                                    dataStudentOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.prenom} {result.nom}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class section</label>
                                    <select name="id_class_section" defaultValue={dataEditStudeSec.libelle} onChange={(e) => handleStudentSection(e)} id=""  className="form-control">
                                    <option value="">Select class Section</option>{
                                    dataClasseSectionOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.id_classe}{result.id_section}</option>
                                          ))
                                          }
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* formulaire StudentSection */}
        </>

    )
}
export default StudentSection;