// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProSidebarProvider } from 'react-pro-sidebar';
import Login from './screen/Login';
import Dashboard from './screen/Dashboard';
import StudentActive from './screen/StudentList';
import StudentAdd from './screen/StudentAdd';
import StudentDetail from './screen/StudentDetail';
import StudentEdit from './screen/StudentEdit';
import AcademicYear from './screen/AcademicYear';
import ClassSection from './screen/ClassSection';
import InvoiceSettings from './screen/InvoiceSettings';
import ItemsSettings from './screen/ItemsSettings';
import SimpleInvoice from './screen/SimpleInvoice';
import ModelInvoice from './screen/ModelInvoice';
import DetailModel from './screen/DetailModel';
import DetailInvoiceStudent from './screen/InvoiceStudent';
import InvoiceList from './screen/InvoiceList';
import InvoiceDetail from './screen/InvoiceDetail';

// SETTING //
import GlobalParameters from "./screen/setting/GlobalParameters";
import SchoolYear from "./screen/setting/SchoolYear";
import AcademicParameters from "./screen/setting/AcademicParameters";
import ServicesParameters from "./screen/setting/ServicesParameters";
import FinancesParameters from "./screen/setting/FinancesParameters";
import Register from './screen/registration/Register';
import Admission from './screen/registration/Admission';
import AdmissionTabs from './screen/registration/AdmissionTabs';
import PaarentList from './screen/ParentList';
// SETTING //

// MAPPING //
import ClassesSection from './screen/mapping/ClassesSection';
import StudentSection from './screen/mapping/StudentSection';
import StudentSubject from "./screen/mapping/StudentSubject";
import ClassStudentSubject from "./screen/mapping/ClassStudentSubject";
import ClassesSubject from "./screen/mapping/ClassesSubject";
import StaffAdd from './screen/StaffActive';
import TeacherAdd from './screen/AddStaff'
import StaffsAccess from './screen/StaffsAccess'
import UserStaffs from './screen/UserStaffs'
import TeacherSubject from './screen/mapping/TeacherSubject';
import ManagProgramEvent from './screen/setting/ManagProgramEvent';
import AddProgramEvent from './screen/setting/AddProgramEvent';
// MAPPING //

function App() {
  return (
    <ProSidebarProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/Dashboard" element={<Dashboard />} />

          <Route path="/student-active" element={<StudentActive />} />
          <Route path="/Add-Student" element={<StudentAdd />} />
          <Route path="/detail-student/:code" element={<StudentDetail />} />
          <Route path="/edit-student/:code" element={<StudentEdit />} />
          <Route path="/staff-active" element={<StaffAdd />} />
          <Route path="/Add-Staff" element={<TeacherAdd />} />
          <Route path="/staffs-access" element={<StaffsAccess/>} />
          <Route path="/users-staff" element={<UserStaffs/>} />
          
          <Route path="/academic-year" element={<AcademicYear />} />
          <Route path="/assign-class-section" element={<ClassSection />} />
          <Route path="/Invoice-settings" element={<InvoiceSettings />} />
          <Route path="/Items-settings" element={<ItemsSettings />} />
          <Route path="/Simple-invoice" element={<SimpleInvoice />} />
          <Route path="/Add-model-invoice/:type/:classe" element={<ModelInvoice />} />
          <Route path="/Detail-model-invoice/:code" element={<DetailModel />} />
          <Route path="/Detail-invoice-stud/:classe/:name/:table" element={<DetailInvoiceStudent />} />
          <Route path="/Invoice-List" element={<InvoiceList />} />
          <Route path="/View-invoice/:invoice" element={<InvoiceDetail />} />
          <Route path="/global-parameters" element={<GlobalParameters />} />
          <Route path="/school-year" element={<SchoolYear />} /> 
          <Route path="/academic-parameters" element={<AcademicParameters />} />
          <Route path="/services-parameters" element={<ServicesParameters />} />
          <Route path="/finances-parameters" element={<FinancesParameters />} />

          <Route path="/classes-section" element={<ClassesSection />} />
          <Route path="/student-subject/:id_class" element={<StudentSubject />} />
          <Route path="/class-student-subject" element={<ClassStudentSubject />} />
          <Route path="/student-section" element={<StudentSection />} />
          <Route path="/classes-subject/:term" element={<ClassesSubject />} />
          <Route path="/teacher-subject/:term" element={<TeacherSubject />} />
          <Route path="/manag-program-event" element={<ManagProgramEvent />} />
          <Route path="/add-program-event/:term_manag" element={<AddProgramEvent />} />

          <Route path="/admission/:code" element={<Admission />} />
          <Route path="/admission-table" element={<AdmissionTabs />} />
          <Route path="/list-parents" element={<PaarentList />} />
        </Routes>
      </BrowserRouter>
    </ProSidebarProvider>

  );
}

export default App;
