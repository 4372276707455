import React, { useState } from 'react';
import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link } from 'react-router-dom'
import services from '../services/services';
import Swal from 'sweetalert2';
import { useEffect } from 'react';

function StudentAdd() {

    const [dataStudent, setDataStudent] = useState(

        {
            id_stud: '',
            id_userS: '',
            Gender: '',
            FirstName: '',
            LastName: '',
            DateBirth: '',
            BirthCity: '',
            BirthCountry: '',
            nationality: '',
            Classroom: '',
            JoiningDate: '',
            Admission_number: '',
            Religion: '',
            Mobile: '',
            BloodType: '',
            // name_pickup1 : '',
            // contact_pickup1 : '',
            // name_pickup2 : '',
            // contact_pickup2 : ''


        }
    );


    const [dataParent, setDataParent] = useState({
        Person: '',
        Civility: '',
        FirstName: '',
        LastName: '',
        Email: '',
        Email_2: '',
        Phone: '',
        Other_phone: '',
        WhatA_phone: '',
        Work_phone: '',
        Home_phone: '',
        Home_adress: '',
        Postal_code: '',
        Country: '',
        Nationality: '',
        City: '',
        Sms_lang: '',
        Mobile: '',
        Occupation: '',
        PresentAddress: '',
        EnterpriseAddress: '',
        ResponsibleFees: ''
    })


    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const newdata = { ...dataStudent, [name]: value }
        setDataStudent(newdata);
        console.log(newdata)
    }

    function handleParent(e) {
        const name = e.target.name;
        const value = e.target.value;
        const Fadata = { ...dataParent, [name]: value }
        setDataParent(Fadata);
        console.log(Fadata)
    }


    function handleSubmitData(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('Gender', dataStudent.Gender);
        bodyFormData.append('FirstName', dataStudent.FirstName);
        bodyFormData.append('LastName', dataStudent.LastName);
        bodyFormData.append('DateBirth', dataStudent.DateBirth);
        bodyFormData.append('BirthCity', dataStudent.BirthCity);
        bodyFormData.append('BirthCountry', dataStudent.BirthCountry);
        bodyFormData.append('nationality', dataStudent.nationality);
        bodyFormData.append('Classroom', dataStudent.Classroom);
        // bodyFormData.append('section', dataStudent.Section);
        bodyFormData.append('JoiningDate', dataStudent.JoiningDate);
        bodyFormData.append('Admission_number', dataStudent.Admission_number);
        bodyFormData.append('Religion', dataStudent.Religion);
        bodyFormData.append('Mobile', dataStudent.Mobile);
        bodyFormData.append('BloodType', dataStudent.BloodType);

        services.create(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            })
            .catch((error) => {
                console.log(error.response);
            })

    }

    function handleSubmitDataParent(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('Person', dataParent.Person);
        bodyFormData.append('Civility', dataParent.Civility);
        bodyFormData.append('FirstName', dataParent.FirstName);
        bodyFormData.append('LastName', dataParent.LastName);
        bodyFormData.append('Email', dataParent.Email);
        bodyFormData.append('Email_2', dataParent.Email_2);
        bodyFormData.append('Phone', dataParent.Phone);
        bodyFormData.append('Other_phone', dataParent.Other_phone);
        bodyFormData.append('WhatA_phone', dataParent.WhatA_phone);
        bodyFormData.append('Work_phone', dataParent.Work_phone);
        bodyFormData.append('Home_phone', dataParent.Home_phone);
        bodyFormData.append('Home_adress', dataParent.Home_adress);
        bodyFormData.append('Postal_code', dataParent.Postal_code);
        bodyFormData.append('Country', dataParent.Country);
        bodyFormData.append('Nationality', dataParent.Nationality);
        bodyFormData.append('City', dataParent.City);
        bodyFormData.append('Sms_lang', dataParent.Sms_lang);
        bodyFormData.append('Occupation', dataParent.Occupation);
        bodyFormData.append('PresentAddress', dataParent.PresentAddress);
        bodyFormData.append('EnterpriseAddress', dataParent.EnterpriseAddress);
        bodyFormData.append('ResponsibleFees', dataParent.ResponsibleFees);

        services.createParent(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            })
            .catch((error) => {
                console.log(error.response);
            })

    }

    useEffect(() => {
        NameSection()
    }, [])


    const [listClasseSection, setListClasseSection] = useState([])

    function NameSection() {

        services.getClasseSection()
            .then((reponse) => {
                setListClasseSection(reponse.data)
                console.log(reponse.data)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    return (
        <>
            <Header />
            <Menus />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Add Students</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Student-List">Students</Link></li>
                                    <li className="breadcrumb-item active">Add Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    
                                        <form onSubmit={(e) => handleSubmitData(e)} encType="multipart/form-data" id="formStudent">
                                            <div className="row">
                                                    <div className="col-12">
                                                        <h5 className="form-title"><span>Student Information</span></h5>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>First Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name='FirstName'
                                                                onChange={(e) => handle(e)}
                                                                value={dataStudent.FirstName}
                                                                style={{ fontWeight: 'bold' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Last Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name='LastName'
                                                                onChange={(e) => handle(e)}
                                                                value={dataStudent.LastName}
                                                                style={{ fontWeight: 'bold' }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Gender</label>
                                                            <select className="form-control" name='Gender' id='Gender' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                                <option value={dataStudent.Gender}>Select Gender</option>
                                                                <option value="Female">Female</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Others">Others</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Date of Birth</label>
                                                            <div>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    name='DateBirth'
                                                                    onChange={(e) => handle(e)}
                                                                    value={dataStudent.DateBirth}
                                                                    style={{ fontWeight: 'bold' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Birth City <code>(*)</code></label>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='BirthCity'
                                                                    id='BirthCity'
                                                                    onChange={(e) => handle(e)}
                                                                    value={dataStudent.BirthCity}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder='Birth City'
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Birth Country <code>(*)</code></label>
                                                            <div>
                                                                <select name="BirthCountry" id="BirthCountry" onChange={(e) => handle(e)} className='form-control'>
                                                                    <option value={dataStudent.BirthCountry}>{dataStudent.BirthCountry}</option>
                                                                    <option value="Albania">Albania</option>
                                                                    <option value="Antarctica">Antarctica</option>
                                                                    <option value="Algeria">Algeria</option>
                                                                    <option value="American Samoa">American Samoa</option>
                                                                    <option value="Andorra">Andorra</option>
                                                                    <option value="Angola">Angola</option>
                                                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                                    <option value="Argentina">Argentina</option>
                                                                    <option value="Australia">Australia</option>
                                                                    <option value="Austria">Austria</option>
                                                                    <option value="Bahamas">Bahamas</option>
                                                                    <option value="Bahrain">Bahrain</option>
                                                                    <option value="Bangladesh">Bangladesh</option>
                                                                    <option value="Armenia">Armenia</option>
                                                                    <option value="Barbados">Barbados</option>
                                                                    <option value="Belgium">Belgium</option>
                                                                    <option value="Bermuda">Bermuda</option>
                                                                    <option value="Bhutan">Bhutan</option>
                                                                    <option value="Bolivia">Bolivia</option>
                                                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                                    <option value="Botswana">Botswana</option>
                                                                    <option value="Bouvet Island">Bouvet Island</option>
                                                                    <option value="Brazil">Brazil</option>
                                                                    <option value="Belize">Belize</option>
                                                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                                    <option value="British Virgin Islands">British Virgin Islands</option>
                                                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                                    <option value="Bulgaria">Bulgaria</option>
                                                                    <option value="Myanmar">Myanmar</option>
                                                                    <option value="Burundi">Burundi</option>
                                                                    <option value="Belarus">Belarus</option>
                                                                    <option value="Cambodia">Cambodia</option>
                                                                    <option value="Cameroon">Cameroon</option>
                                                                    <option value="Canada">Canada</option>
                                                                    <option value="Cape Verde">Cape Verde</option>
                                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                                    <option value="Central African">Central African</option>
                                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                                    <option value="Chad">Chad</option>
                                                                    <option value="Chile">Chile</option>
                                                                    <option value="China">China</option>
                                                                    <option value="Taiwan">Taiwan</option>
                                                                    <option value="Christmas Island">Christmas Island</option>
                                                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                                    <option value="Colombia">Colombia</option>
                                                                    <option value="Comoros">Comoros</option>
                                                                    <option value="Mayotte">Mayotte</option>
                                                                    <option value="Republic of the Congo">Republic of the Congo</option>
                                                                    <option value="The Democratic Republic Of The Congo">The Democratic Republic Of The Congo</option>
                                                                    <option value="Cook Islands">Cook Islands</option>
                                                                    <option value="Costa Rica">Costa Rica</option>
                                                                    <option value="Croatia">Croatia</option>
                                                                    <option value="Cuba">Cuba</option>
                                                                    <option value="Cyprus">Cyprus</option>
                                                                    <option value="Czech Republic">Czech Republic</option>
                                                                    <option value="Benin">Benin</option>
                                                                    <option value="Denmark">Denmark</option>
                                                                    <option value="Dominica">Dominica</option>
                                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                                    <option value="Ecuador">Ecuador</option>
                                                                    <option value="El Salvador">El Salvador</option>
                                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                                    <option value="Ethiopia">Ethiopia</option>
                                                                    <option value="Eritrea">Eritrea</option>
                                                                    <option value="Estonia">Estonia</option>
                                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                                    <option value="Falkland Islands">Falkland Islands</option>
                                                                    <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                                    <option value="Fiji">Fiji</option>
                                                                    <option value="Finland">Finland</option>
                                                                    <option value="Åland Islands">Åland Islands</option>
                                                                    <option value="France">France</option>
                                                                    <option value="French Guiana">French Guiana</option>
                                                                    <option value="French Polynesia">French Polynesia</option>
                                                                    <option value="French Southern Territories">French Southern Territories</option>
                                                                    <option value="Djibouti">Djibouti</option>
                                                                    <option value="Gabon">Gabon</option>
                                                                    <option value="Georgia">Georgia</option>
                                                                    <option value="Gambia">Gambia</option>
                                                                    <option value="Occupied Palestinian Territory">Occupied Palestinian Territory</option>
                                                                    <option value="Germany">Germany</option>
                                                                    <option value="Ghana">Ghana</option>
                                                                    <option value="Gibraltar">Gibraltar</option>
                                                                    <option value="Kiribati">Kiribati</option>
                                                                    <option value="Greece">Greece</option>
                                                                    <option value="Greenland">Greenland</option>
                                                                    <option value="Grenada">Grenada</option>
                                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                                    <option value="Guam">Guam</option>
                                                                    <option value="Guatemala">Guatemala</option>
                                                                    <option value="Guinea">Guinea</option>
                                                                    <option value="Guyana">Guyana</option>
                                                                    <option value="Haiti">Haiti</option>
                                                                    <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                                                                    <option value="Vatican City State">Vatican City State</option>
                                                                    <option value="Honduras">Honduras</option>
                                                                    <option value="Hong Kong">Hong Kong</option>
                                                                    <option value="Hungary">Hungary</option>
                                                                    <option value="Iceland">Iceland</option>
                                                                    <option value="India">India</option>
                                                                    <option value="Indonesia">Indonesia</option>
                                                                    <option value="Islamic Republic of Iran">Islamic Republic of Iran</option>
                                                                    <option value="Iraq">Iraq</option>
                                                                    <option value="Ireland">Ireland</option>
                                                                    <option value="Israel">Israel</option>
                                                                    <option value="Italy">Italy</option>
                                                                    <option value="Ivory Coast">Ivory Coast</option>
                                                                    <option value="Jamaica">Jamaica</option>
                                                                    <option value="Japan">Japan</option>
                                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                                    <option value="Jordan">Jordan</option>
                                                                    <option value="Kenya">Kenya</option>
                                                                    <option value="Democratic People's Republic of Korea">Democratic People's Republic of Korea</option>
                                                                    <option value="Republic of Korea">Republic of Korea</option>
                                                                    <option value="Kuwait">Kuwait</option>
                                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                                    <option value="Lebanon">Lebanon</option>
                                                                    <option value="Lesotho">Lesotho</option>
                                                                    <option value="Latvia">Latvia</option>
                                                                    <option value="Liberia">Liberia</option>
                                                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                                    <option value="Lithuania">Lithuania</option>
                                                                    <option value="Luxembourg">Luxembourg</option>
                                                                    <option value="Macao">Macao</option>
                                                                    <option value="Madagascar">Madagascar</option>
                                                                    <option value="Malawi">Malawi</option>
                                                                    <option value="Malaysia">Malaysia</option>
                                                                    <option value="Maldives">Maldives</option>
                                                                    <option value="Mali">Mali</option>
                                                                    <option value="Malta">Malta</option>
                                                                    <option value="Martinique">Martinique</option>
                                                                    <option value="Mauritania">Mauritania</option>
                                                                    <option value="Mauritius">Mauritius</option>
                                                                    <option value="Mexico">Mexico</option>
                                                                    <option value="Monaco">Monaco</option>
                                                                    <option value="Mongolia">Mongolia</option>
                                                                    <option value="Republic of Moldova">Republic of Moldova</option>
                                                                    <option value="Montserrat">Montserrat</option>
                                                                    <option value="Morocco">Morocco</option>
                                                                    <option value="Mozambique">Mozambique</option>
                                                                    <option value="Oman">Oman</option>
                                                                    <option value="Namibia">Namibia</option>
                                                                    <option value="Nauru">Nauru</option>
                                                                    <option value="Nepal">Nepal</option>
                                                                    <option value="Netherlands">Netherlands</option>
                                                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                                    <option value="Aruba">Aruba</option>
                                                                    <option value="New Caledonia">New Caledonia</option>
                                                                    <option value="Vanuatu">Vanuatu</option>
                                                                    <option value="New Zealand">New Zealand</option>
                                                                    <option value="Nicaragua">Nicaragua</option>
                                                                    <option value="Niger">Niger</option>
                                                                    <option value="Nigeria">Nigeria</option>
                                                                    <option value="Niue">Niue</option>
                                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                                    <option value="Norway">Norway</option>
                                                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                                    <option value="Federated States of Micronesia">Federated States of Micronesia</option>
                                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                                    <option value="Palau">Palau</option>
                                                                    <option value="Pakistan">Pakistan</option>
                                                                    <option value="Panama">Panama</option>
                                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                                    <option value="Paraguay">Paraguay</option>
                                                                    <option value="Peru">Peru</option>
                                                                    <option value="Philippines">Philippines</option>
                                                                    <option value="Pitcairn">Pitcairn</option>
                                                                    <option value="Poland">Poland</option>
                                                                    <option value="Portugal">Portugal</option>
                                                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                                    <option value="Timor-Leste">Timor-Leste</option>
                                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                                    <option value="Qatar">Qatar</option>
                                                                    <option value="Réunion">Réunion</option>
                                                                    <option value="Romania">Romania</option>
                                                                    <option value="Russian Federation">Russian Federation</option>
                                                                    <option value="Rwanda">Rwanda</option>
                                                                    <option value="Saint Helena">Saint Helena</option>
                                                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                                    <option value="Anguilla">Anguilla</option>
                                                                    <option value="Saint Lucia">Saint Lucia</option>
                                                                    <option value="Saint-Pierre and Miquelon">Saint-Pierre and Miquelon</option>
                                                                    <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                                    <option value="San Marino">San Marino</option>
                                                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                                    <option value="Senegal">Senegal</option>
                                                                    <option value="Seychelles">Seychelles</option>
                                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                                    <option value="Singapore">Singapore</option>
                                                                    <option value="Slovakia">Slovakia</option>
                                                                    <option value="Vietnam">Vietnam</option>
                                                                    <option value="Slovenia">Slovenia</option>
                                                                    <option value="Somalia">Somalia</option>
                                                                    <option value="South Africa">South Africa</option>
                                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                                    <option value="Spain">Spain</option>
                                                                    <option value="Western Sahara">Western Sahara</option>
                                                                    <option value="Sudan">Sudan</option>
                                                                    <option value="Suriname">Suriname</option>
                                                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                                    <option value="Swaziland">Swaziland</option>
                                                                    <option value="Sweden">Sweden</option>
                                                                    <option value="Switzerland">Switzerland</option>
                                                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                                    <option value="Tajikistan">Tajikistan</option>
                                                                    <option value="Thailand">Thailand</option>
                                                                    <option value="Togo">Togo</option>
                                                                    <option value="Tokelau">Tokelau</option>
                                                                    <option value="Tonga">Tonga</option>
                                                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                                    <option value="Tunisia">Tunisia</option>
                                                                    <option value="Turkey">Turkey</option>
                                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                                    <option value="Tuvalu">Tuvalu</option>
                                                                    <option value="Uganda">Uganda</option>
                                                                    <option value="Ukraine">Ukraine</option>
                                                                    <option value="The Former Yugoslav Republic of Macedonia">The Former Yugoslav Republic of Macedonia</option>
                                                                    <option value="Egypt">Egypt</option>
                                                                    <option value="United Kingdom">United Kingdom</option>
                                                                    <option value="Isle of Man">Isle of Man</option>
                                                                    <option value="United Republic Of Tanzania">United Republic Of Tanzania</option>
                                                                    <option value="United States">United States</option>
                                                                    <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                                    <option value="Uruguay">Uruguay</option>
                                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                                    <option value="Venezuela">Venezuela</option>
                                                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                                    <option value="Samoa">Samoa</option>
                                                                    <option value="Yemen">Yemen</option>
                                                                    <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                                                                    <option value="Zambia">Zambia</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Nationality <code>(*)</code></label>
                                                            <div>
                                                                <select name="nationality" id="nationality" onChange={(e) => handle(e)} className='form-control'>
                                                                    <option value={dataStudent.nationality}>{dataStudent.nationality}</option>
                                                                    <option value="Afghan">Afghan</option>
                                                                    <option value="Albanian">Albanian</option>
                                                                    <option value="Algerian">Algerian</option>
                                                                    <option value="American">American</option>
                                                                    <option value="Andorran">Andorran</option>
                                                                    <option value="Angolan">Angolan</option>
                                                                    <option value="Argentinian">Argentinian</option>
                                                                    <option value="Armenian">Armenian</option>
                                                                    <option value="Australian">Australian</option>
                                                                    <option value="Austrian">Austrian</option>
                                                                    <option value="Azerbaijani">Azerbaijani</option>
                                                                    <option value="Bahamian">Bahamian</option>
                                                                    <option value="Bangladeshi">Bangladeshi</option>
                                                                    <option value="Barbadian">Barbadian</option>
                                                                    <option value="Belgian">Belgian</option>
                                                                    <option value="Belorussian">Belorussian</option>
                                                                    <option value="Beninese">Beninese</option>
                                                                    <option value="Bhutanese">Bhutanese</option>
                                                                    <option value="Bolivian">Bolivian</option>
                                                                    <option value="Bosnian">Bosnian</option>
                                                                    <option value="Brazilian">Brazilian</option>
                                                                    <option value="British">British</option>
                                                                    <option value="Briton">Briton</option>
                                                                    <option value="Bruneian">Bruneian</option>
                                                                    <option value="Bulgarian">Bulgarian</option>
                                                                    <option value="Burkinabe">Burkinabe</option>
                                                                    <option value="Burmese">Burmese</option>
                                                                    <option value="Burundian">Burundian</option>
                                                                    <option value="Cambodian">Cambodian</option>
                                                                    <option value="Cameroonian">Cameroonian</option>
                                                                    <option value="Canadian">Canadian</option>
                                                                    <option value="Central African">Central African</option>
                                                                    <option value="Chadian">Chadian</option>
                                                                    <option value="Chilean">Chilean</option>
                                                                    <option value="Chinese">Chinese</option>
                                                                    <option value="Colombian">Colombian</option>
                                                                    <option value="Congolese">Congolese</option>
                                                                    <option value="Congolese">Congolese</option>
                                                                    <option value="Croatian">Croatian</option>
                                                                    <option value="Cuban">Cuban</option>
                                                                    <option value="Cypriot">Cypriot</option>
                                                                    <option value="Czech">Czech</option>
                                                                    <option value="Dane">Dane</option>
                                                                    <option value="Dominican">Dominican</option>
                                                                    <option value="Dutchman">Dutchman</option>
                                                                    <option value="Ecuadorean">Ecuadorean</option>
                                                                    <option value="Egyptian">Egyptian</option>
                                                                    <option value="Englishman">Englishman</option>
                                                                    <option value="Eritrean">Eritrean</option>
                                                                    <option value="Estonian">Estonian</option>
                                                                    <option value="Ethiopian">Ethiopian</option>
                                                                    <option value="Fijian">Fijian</option>
                                                                    <option value="Filipino">Filipino</option>
                                                                    <option value="Finn">Finn</option>
                                                                    <option value="Frenchman">Frenchman</option>
                                                                    <option value="Gabonese">Gabonese</option>
                                                                    <option value="Gambian">Gambian</option>
                                                                    <option value="Georgian">Georgian</option>
                                                                    <option value="German">German</option>
                                                                    <option value="Ghanaian">Ghanaian</option>
                                                                    <option value="Greek">Greek</option>
                                                                    <option value="Grenadian">Grenadian</option>
                                                                    <option value="Guatemalan">Guatemalan</option>
                                                                    <option value="Guinean">Guinean</option>
                                                                    <option value="Guyanese">Guyanese</option>
                                                                    <option value="Haitian">Haitian</option>
                                                                    <option value="Honduran">Honduran</option>
                                                                    <option value="Hungarian">Hungarian</option>
                                                                    <option value="Icelander">Icelander</option>
                                                                    <option value="Indian">Indian</option>
                                                                    <option value="Indonesian">Indonesian</option>
                                                                    <option value="Iranian">Iranian</option>
                                                                    <option value="Iraqi">Iraqi</option>
                                                                    <option value="Irishman">Irishman</option>
                                                                    <option value="Israeli">Israeli</option>
                                                                    <option value="Italian">Italian</option>
                                                                    <option value="Ivorian">Ivorian</option>
                                                                    <option value="Jamaican">Jamaican</option>
                                                                    <option value="Japanese">Japanese</option>
                                                                    <option value="Jordanian">Jordanian</option>
                                                                    <option value="Kazakh">Kazakh</option>
                                                                    <option value="Kenyan">Kenyan</option>
                                                                    <option value="Korean">Korean</option>
                                                                    <option value="Kuwaiti">Kuwaiti</option>
                                                                    <option value="Laotian">Laotian</option>
                                                                    <option value="Latvian">Latvian</option>
                                                                    <option value="Lebanese">Lebanese</option>
                                                                    <option value="Liberian">Liberian</option>
                                                                    <option value="Libyan">Libyan</option>
                                                                    <option value="Liechtensteiner">Liechtensteiner</option>
                                                                    <option value="Lithuanian">Lithuanian</option>
                                                                    <option value="Luxembourger">Luxembourger</option>
                                                                    <option value="Macedonian">Macedonian</option>
                                                                    <option value="Madagascan">Madagascan</option>
                                                                    <option value="Malawian">Malawian</option>
                                                                    <option value="Malaysian">Malaysian</option>
                                                                    <option value="Maldivian">Maldivian</option>
                                                                    <option value="Malian">Malian</option>
                                                                    <option value="Maltese">Maltese</option>
                                                                    <option value="Mauritanian">Mauritanian</option>
                                                                    <option value="Mauritian">Mauritian</option>
                                                                    <option value="Mexican">Mexican</option>
                                                                    <option value="Moldovan">Moldovan</option>
                                                                    <option value="Monacan">Monacan</option>
                                                                    <option value="Mongolian">Mongolian</option>
                                                                    <option value="Montenegrin">Montenegrin</option>
                                                                    <option value="Moroccan">Moroccan</option>
                                                                    <option value="Mozambican">Mozambican</option>
                                                                    <option value="Namibian">Namibian</option>
                                                                    <option value="Nepalese">Nepalese</option>
                                                                    <option value="Nicaraguan">Nicaraguan</option>
                                                                    <option value="Nigerian">Nigerian</option>
                                                                    <option value="Nigerien">Nigerien</option>
                                                                    <option value="Norwegian">Norwegian</option>
                                                                    <option value="Pakistani">Pakistani</option>
                                                                    <option value="Panamanian">Panamanian</option>
                                                                    <option value="Paraguayan">Paraguayan</option>
                                                                    <option value="Peruvian">Peruvian</option>
                                                                    <option value="Pole">Pole</option>
                                                                    <option value="Portuguese">Portuguese</option>
                                                                    <option value="Qatari">Qatari</option>
                                                                    <option value="Romanian">Romanian</option>
                                                                    <option value="Russian">Russian</option>
                                                                    <option value="Rwandan">Rwandan</option>
                                                                    <option value="Salvadorean">Salvadorean</option>
                                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                                    <option value="Saudi Arabian">Saudi Arabian</option>
                                                                    <option value="Scot">Scot</option>
                                                                    <option value="Senegalese">Senegalese</option>
                                                                    <option value="Serbian">Serbian</option>
                                                                    <option value="Sierra Leonean">Sierra Leonean</option>
                                                                    <option value="Singaporean">Singaporean</option>
                                                                    <option value="Slovak">Slovak</option>
                                                                    <option value="Slovenian">Slovenian</option>
                                                                    <option value="Somali">Somali</option>
                                                                    <option value="South African">South African</option>
                                                                    <option value="Spaniard">Spaniard</option>
                                                                    <option value="SriLankan">SriLankan</option>
                                                                    <option value="Sudanese">Sudanese</option>
                                                                    <option value="Surinamese">Surinamese</option>
                                                                    <option value="Swazi">Swazi</option>
                                                                    <option value="Swede">Swede</option>
                                                                    <option value="Swiss">Swiss</option>
                                                                    <option value="Syrian">Syrian</option>
                                                                    <option value="Tadzhik">Tadzhik</option>
                                                                    <option value="Taiwanese">Taiwanese</option>
                                                                    <option value="Tanzanian">Tanzanian</option>
                                                                    <option value="Thai">Thai</option>
                                                                    <option value="Togolese">Togolese</option>
                                                                    <option value="Trinidadian">Trinidadian</option>
                                                                    <option value="Tunisian">Tunisian</option>
                                                                    <option value="Turk">Turk</option>
                                                                    <option value="Ugandan">Ugandan</option>
                                                                    <option value="Ukrainian">Ukrainian</option>
                                                                    <option value="Uruguayan">Uruguayan</option>
                                                                    <option value="Uzbek">Uzbek</option>
                                                                    <option value="Venezuelan">Venezuelan</option>
                                                                    <option value="Vietnamese">Vietnamese</option>
                                                                    <option value="Welshman">Welshman</option>
                                                                    <option value="Yemeni">Yemeni</option>
                                                                    <option value="Yugoslav">Yugoslav</option>
                                                                    <option value="Zambian">Zambian</option>
                                                                    <option value="Zimbabwean">Zimbabwean</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Section</label>
                                                            <select className="form-control select" name='Classroom' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                                <option>Select Classroom</option>

                                                                {
                                                                    listClasseSection.map((res) => (

                                                                        <option value= {res.id_class_section} key={res.id}>{res.id_classe}{res.id_section}</option>

                                                                    ))
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Joining Date</label>
                                                            <div>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    name='JoiningDate'
                                                                    onChange={(e) => handle(e)}
                                                                    value={dataStudent.JoiningDate}
                                                                    style={{ fontWeight: 'bold' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Admission Number</label>
                                                            <div>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name='Admission_number'
                                                                    onChange={(e) => handle(e)}
                                                                    value={dataStudent.Admission_number}
                                                                    style={{ fontWeight: 'bold' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Religion</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name='Religion'
                                                                onChange={(e) => handle(e)}
                                                                value={dataStudent.Religion}
                                                                style={{ fontWeight: 'bold' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Mobile Number</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name='Mobile'
                                                                onChange={(e) => handle(e)}
                                                                value={dataStudent.Mobile}
                                                                style={{ fontWeight: 'bold' }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>Blood Type</label>
                                                            <select className="form-control select" name='BloodType' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                                <option>Select blood type</option>
                                                                <option value="A+">A+</option>
                                                                <option value="A-">A-</option>
                                                                <option value="B+">B+</option>
                                                                <option value="B-">B-</option>
                                                                <option value="AB+">AB+</option>
                                                                <option value="AB-">AB-</option>
                                                                <option value="O+">O+</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mt-5">
                                                        <button type="submit" className="btn btn-success btn-lg">Submit</button> <br /><br /><br /><br />
                                                    </div> 
                                            
                                                </div>
                                            </form>



                                            <form onSubmit={(e) => handleSubmitDataParent(e)} encType="multipart/form-data" id="formStudent">
                                                <div className="row">

                                                    <div className="col-12">
                                                        <h5 className="form-title"><span>Parent Information</span></h5>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="row">

                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Person</label>
                                                                        <select className="form-control" name='Person' id='Person' style={{ fontWeight: 'bold' }} onChange={(e) => handleParent(e)}>
                                                                            <option value={dataParent.Person}>Select Person</option>
                                                                            <option value="Father">Father</option>
                                                                            <option value="Mother">Mother</option>
                                                                            <option value="Guardian">Guardian</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Civility</label>
                                                                        <select className="form-control" name='Civility' id='Civility' style={{ fontWeight: 'bold' }} onChange={(e) => handleParent(e)}>
                                                                            <option value={dataParent.Civility}>Select Civility</option>
                                                                            <option value="Mister">Mister</option>
                                                                            <option value="Madam">Madam</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>First Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='FirstName'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.FirstName}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Last Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='LastName'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.LastName}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Email</label>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            name='Email'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Email}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Email 2</label>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            name='Email_2'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Email_2}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Phone</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Phone'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Phone}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Other Phone</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Other_phone'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Other_phone}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>WhatApp Phone</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='WhatA_phone'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.WhatA_phone}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Work Phone</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Work_phone'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Work_phone}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Home Phone</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Home_phone'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Home_phone}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Home Adress</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Home_adress'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Home_adress}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Postal Code</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Postal_code'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Postal_code}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Country</label>
                                                                        <select name="Country" id="Country" onChange={(e) => handleParent(e)} className='form-control'>
                                                                            <option value={dataParent.Country}>{dataParent.Country}</option>
                                                                            <option value="Albania">Albania</option>
                                                                            <option value="Antarctica">Antarctica</option>
                                                                            <option value="Algeria">Algeria</option>
                                                                            <option value="American Samoa">American Samoa</option>
                                                                            <option value="Andorra">Andorra</option>
                                                                            <option value="Angola">Angola</option>
                                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                                            <option value="Argentina">Argentina</option>
                                                                            <option value="Australia">Australia</option>
                                                                            <option value="Austria">Austria</option>
                                                                            <option value="Bahamas">Bahamas</option>
                                                                            <option value="Bahrain">Bahrain</option>
                                                                            <option value="Bangladesh">Bangladesh</option>
                                                                            <option value="Armenia">Armenia</option>
                                                                            <option value="Barbados">Barbados</option>
                                                                            <option value="Belgium">Belgium</option>
                                                                            <option value="Bermuda">Bermuda</option>
                                                                            <option value="Bhutan">Bhutan</option>
                                                                            <option value="Bolivia">Bolivia</option>
                                                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                                            <option value="Botswana">Botswana</option>
                                                                            <option value="Bouvet Island">Bouvet Island</option>
                                                                            <option value="Brazil">Brazil</option>
                                                                            <option value="Belize">Belize</option>
                                                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                                            <option value="British Virgin Islands">British Virgin Islands</option>
                                                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                                            <option value="Bulgaria">Bulgaria</option>
                                                                            <option value="Myanmar">Myanmar</option>
                                                                            <option value="Burundi">Burundi</option>
                                                                            <option value="Belarus">Belarus</option>
                                                                            <option value="Cambodia">Cambodia</option>
                                                                            <option value="Cameroon">Cameroon</option>
                                                                            <option value="Canada">Canada</option>
                                                                            <option value="Cape Verde">Cape Verde</option>
                                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                                            <option value="Central African">Central African</option>
                                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                                            <option value="Chad">Chad</option>
                                                                            <option value="Chile">Chile</option>
                                                                            <option value="China">China</option>
                                                                            <option value="Taiwan">Taiwan</option>
                                                                            <option value="Christmas Island">Christmas Island</option>
                                                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                                            <option value="Colombia">Colombia</option>
                                                                            <option value="Comoros">Comoros</option>
                                                                            <option value="Mayotte">Mayotte</option>
                                                                            <option value="Republic of the Congo">Republic of the Congo</option>
                                                                            <option value="The Democratic Republic Of The Congo">The Democratic Republic Of The Congo</option>
                                                                            <option value="Cook Islands">Cook Islands</option>
                                                                            <option value="Costa Rica">Costa Rica</option>
                                                                            <option value="Croatia">Croatia</option>
                                                                            <option value="Cuba">Cuba</option>
                                                                            <option value="Cyprus">Cyprus</option>
                                                                            <option value="Czech Republic">Czech Republic</option>
                                                                            <option value="Benin">Benin</option>
                                                                            <option value="Denmark">Denmark</option>
                                                                            <option value="Dominica">Dominica</option>
                                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                                            <option value="Ecuador">Ecuador</option>
                                                                            <option value="El Salvador">El Salvador</option>
                                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                                            <option value="Ethiopia">Ethiopia</option>
                                                                            <option value="Eritrea">Eritrea</option>
                                                                            <option value="Estonia">Estonia</option>
                                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                                            <option value="Falkland Islands">Falkland Islands</option>
                                                                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                                            <option value="Fiji">Fiji</option>
                                                                            <option value="Finland">Finland</option>
                                                                            <option value="Åland Islands">Åland Islands</option>
                                                                            <option value="France">France</option>
                                                                            <option value="French Guiana">French Guiana</option>
                                                                            <option value="French Polynesia">French Polynesia</option>
                                                                            <option value="French Southern Territories">French Southern Territories</option>
                                                                            <option value="Djibouti">Djibouti</option>
                                                                            <option value="Gabon">Gabon</option>
                                                                            <option value="Georgia">Georgia</option>
                                                                            <option value="Gambia">Gambia</option>
                                                                            <option value="Occupied Palestinian Territory">Occupied Palestinian Territory</option>
                                                                            <option value="Germany">Germany</option>
                                                                            <option value="Ghana">Ghana</option>
                                                                            <option value="Gibraltar">Gibraltar</option>
                                                                            <option value="Kiribati">Kiribati</option>
                                                                            <option value="Greece">Greece</option>
                                                                            <option value="Greenland">Greenland</option>
                                                                            <option value="Grenada">Grenada</option>
                                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                                            <option value="Guam">Guam</option>
                                                                            <option value="Guatemala">Guatemala</option>
                                                                            <option value="Guinea">Guinea</option>
                                                                            <option value="Guyana">Guyana</option>
                                                                            <option value="Haiti">Haiti</option>
                                                                            <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                                                                            <option value="Vatican City State">Vatican City State</option>
                                                                            <option value="Honduras">Honduras</option>
                                                                            <option value="Hong Kong">Hong Kong</option>
                                                                            <option value="Hungary">Hungary</option>
                                                                            <option value="Iceland">Iceland</option>
                                                                            <option value="India">India</option>
                                                                            <option value="Indonesia">Indonesia</option>
                                                                            <option value="Islamic Republic of Iran">Islamic Republic of Iran</option>
                                                                            <option value="Iraq">Iraq</option>
                                                                            <option value="Ireland">Ireland</option>
                                                                            <option value="Israel">Israel</option>
                                                                            <option value="Italy">Italy</option>
                                                                            <option value="Ivory Coast">Ivory Coast</option>
                                                                            <option value="Jamaica">Jamaica</option>
                                                                            <option value="Japan">Japan</option>
                                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                                            <option value="Jordan">Jordan</option>
                                                                            <option value="Kenya">Kenya</option>
                                                                            <option value="Democratic People's Republic of Korea">Democratic People's Republic of Korea</option>
                                                                            <option value="Republic of Korea">Republic of Korea</option>
                                                                            <option value="Kuwait">Kuwait</option>
                                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                                            <option value="Lebanon">Lebanon</option>
                                                                            <option value="Lesotho">Lesotho</option>
                                                                            <option value="Latvia">Latvia</option>
                                                                            <option value="Liberia">Liberia</option>
                                                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                                            <option value="Lithuania">Lithuania</option>
                                                                            <option value="Luxembourg">Luxembourg</option>
                                                                            <option value="Macao">Macao</option>
                                                                            <option value="Madagascar">Madagascar</option>
                                                                            <option value="Malawi">Malawi</option>
                                                                            <option value="Malaysia">Malaysia</option>
                                                                            <option value="Maldives">Maldives</option>
                                                                            <option value="Mali">Mali</option>
                                                                            <option value="Malta">Malta</option>
                                                                            <option value="Martinique">Martinique</option>
                                                                            <option value="Mauritania">Mauritania</option>
                                                                            <option value="Mauritius">Mauritius</option>
                                                                            <option value="Mexico">Mexico</option>
                                                                            <option value="Monaco">Monaco</option>
                                                                            <option value="Mongolia">Mongolia</option>
                                                                            <option value="Republic of Moldova">Republic of Moldova</option>
                                                                            <option value="Montserrat">Montserrat</option>
                                                                            <option value="Morocco">Morocco</option>
                                                                            <option value="Mozambique">Mozambique</option>
                                                                            <option value="Oman">Oman</option>
                                                                            <option value="Namibia">Namibia</option>
                                                                            <option value="Nauru">Nauru</option>
                                                                            <option value="Nepal">Nepal</option>
                                                                            <option value="Netherlands">Netherlands</option>
                                                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                                            <option value="Aruba">Aruba</option>
                                                                            <option value="New Caledonia">New Caledonia</option>
                                                                            <option value="Vanuatu">Vanuatu</option>
                                                                            <option value="New Zealand">New Zealand</option>
                                                                            <option value="Nicaragua">Nicaragua</option>
                                                                            <option value="Niger">Niger</option>
                                                                            <option value="Nigeria">Nigeria</option>
                                                                            <option value="Niue">Niue</option>
                                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                                            <option value="Norway">Norway</option>
                                                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                                            <option value="Federated States of Micronesia">Federated States of Micronesia</option>
                                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                                            <option value="Palau">Palau</option>
                                                                            <option value="Pakistan">Pakistan</option>
                                                                            <option value="Panama">Panama</option>
                                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                                            <option value="Paraguay">Paraguay</option>
                                                                            <option value="Peru">Peru</option>
                                                                            <option value="Philippines">Philippines</option>
                                                                            <option value="Pitcairn">Pitcairn</option>
                                                                            <option value="Poland">Poland</option>
                                                                            <option value="Portugal">Portugal</option>
                                                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                                            <option value="Timor-Leste">Timor-Leste</option>
                                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                                            <option value="Qatar">Qatar</option>
                                                                            <option value="Réunion">Réunion</option>
                                                                            <option value="Romania">Romania</option>
                                                                            <option value="Russian Federation">Russian Federation</option>
                                                                            <option value="Rwanda">Rwanda</option>
                                                                            <option value="Saint Helena">Saint Helena</option>
                                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                                            <option value="Anguilla">Anguilla</option>
                                                                            <option value="Saint Lucia">Saint Lucia</option>
                                                                            <option value="Saint-Pierre and Miquelon">Saint-Pierre and Miquelon</option>
                                                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                                            <option value="San Marino">San Marino</option>
                                                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                                            <option value="Senegal">Senegal</option>
                                                                            <option value="Seychelles">Seychelles</option>
                                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                                            <option value="Singapore">Singapore</option>
                                                                            <option value="Slovakia">Slovakia</option>
                                                                            <option value="Vietnam">Vietnam</option>
                                                                            <option value="Slovenia">Slovenia</option>
                                                                            <option value="Somalia">Somalia</option>
                                                                            <option value="South Africa">South Africa</option>
                                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                                            <option value="Spain">Spain</option>
                                                                            <option value="Western Sahara">Western Sahara</option>
                                                                            <option value="Sudan">Sudan</option>
                                                                            <option value="Suriname">Suriname</option>
                                                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                                            <option value="Swaziland">Swaziland</option>
                                                                            <option value="Sweden">Sweden</option>
                                                                            <option value="Switzerland">Switzerland</option>
                                                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                                            <option value="Tajikistan">Tajikistan</option>
                                                                            <option value="Thailand">Thailand</option>
                                                                            <option value="Togo">Togo</option>
                                                                            <option value="Tokelau">Tokelau</option>
                                                                            <option value="Tonga">Tonga</option>
                                                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                                            <option value="Tunisia">Tunisia</option>
                                                                            <option value="Turkey">Turkey</option>
                                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                                            <option value="Tuvalu">Tuvalu</option>
                                                                            <option value="Uganda">Uganda</option>
                                                                            <option value="Ukraine">Ukraine</option>
                                                                            <option value="The Former Yugoslav Republic of Macedonia">The Former Yugoslav Republic of Macedonia</option>
                                                                            <option value="Egypt">Egypt</option>
                                                                            <option value="United Kingdom">United Kingdom</option>
                                                                            <option value="Isle of Man">Isle of Man</option>
                                                                            <option value="United Republic Of Tanzania">United Republic Of Tanzania</option>
                                                                            <option value="United States">United States</option>
                                                                            <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                                            <option value="Uruguay">Uruguay</option>
                                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                                            <option value="Venezuela">Venezuela</option>
                                                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                                            <option value="Samoa">Samoa</option>
                                                                            <option value="Yemen">Yemen</option>
                                                                            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                                                                            <option value="Zambia">Zambia</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Nationality</label>
                                                                        <select name="Nationality" id="Nationality" onChange={(e) => handleParent(e)} className='form-control'>
                                                                            <option value={dataParent.Nationality}>{dataParent.Nationality}</option>
                                                                            <option value="Afghan">Afghan</option>
                                                                            <option value="Albanian">Albanian</option>
                                                                            <option value="Algerian">Algerian</option>
                                                                            <option value="American">American</option>
                                                                            <option value="Andorran">Andorran</option>
                                                                            <option value="Angolan">Angolan</option>
                                                                            <option value="Argentinian">Argentinian</option>
                                                                            <option value="Armenian">Armenian</option>
                                                                            <option value="Australian">Australian</option>
                                                                            <option value="Austrian">Austrian</option>
                                                                            <option value="Azerbaijani">Azerbaijani</option>
                                                                            <option value="Bahamian">Bahamian</option>
                                                                            <option value="Bangladeshi">Bangladeshi</option>
                                                                            <option value="Barbadian">Barbadian</option>
                                                                            <option value="Belgian">Belgian</option>
                                                                            <option value="Belorussian">Belorussian</option>
                                                                            <option value="Beninese">Beninese</option>
                                                                            <option value="Bhutanese">Bhutanese</option>
                                                                            <option value="Bolivian">Bolivian</option>
                                                                            <option value="Bosnian">Bosnian</option>
                                                                            <option value="Brazilian">Brazilian</option>
                                                                            <option value="British">British</option>
                                                                            <option value="Briton">Briton</option>
                                                                            <option value="Bruneian">Bruneian</option>
                                                                            <option value="Bulgarian">Bulgarian</option>
                                                                            <option value="Burkinabe">Burkinabe</option>
                                                                            <option value="Burmese">Burmese</option>
                                                                            <option value="Burundian">Burundian</option>
                                                                            <option value="Cambodian">Cambodian</option>
                                                                            <option value="Cameroonian">Cameroonian</option>
                                                                            <option value="Canadian">Canadian</option>
                                                                            <option value="Central African">Central African</option>
                                                                            <option value="Chadian">Chadian</option>
                                                                            <option value="Chilean">Chilean</option>
                                                                            <option value="Chinese">Chinese</option>
                                                                            <option value="Colombian">Colombian</option>
                                                                            <option value="Congolese">Congolese</option>
                                                                            <option value="Congolese">Congolese</option>
                                                                            <option value="Croatian">Croatian</option>
                                                                            <option value="Cuban">Cuban</option>
                                                                            <option value="Cypriot">Cypriot</option>
                                                                            <option value="Czech">Czech</option>
                                                                            <option value="Dane">Dane</option>
                                                                            <option value="Dominican">Dominican</option>
                                                                            <option value="Dutchman">Dutchman</option>
                                                                            <option value="Ecuadorean">Ecuadorean</option>
                                                                            <option value="Egyptian">Egyptian</option>
                                                                            <option value="Englishman">Englishman</option>
                                                                            <option value="Eritrean">Eritrean</option>
                                                                            <option value="Estonian">Estonian</option>
                                                                            <option value="Ethiopian">Ethiopian</option>
                                                                            <option value="Fijian">Fijian</option>
                                                                            <option value="Filipino">Filipino</option>
                                                                            <option value="Finn">Finn</option>
                                                                            <option value="Frenchman">Frenchman</option>
                                                                            <option value="Gabonese">Gabonese</option>
                                                                            <option value="Gambian">Gambian</option>
                                                                            <option value="Georgian">Georgian</option>
                                                                            <option value="German">German</option>
                                                                            <option value="Ghanaian">Ghanaian</option>
                                                                            <option value="Greek">Greek</option>
                                                                            <option value="Grenadian">Grenadian</option>
                                                                            <option value="Guatemalan">Guatemalan</option>
                                                                            <option value="Guinean">Guinean</option>
                                                                            <option value="Guyanese">Guyanese</option>
                                                                            <option value="Haitian">Haitian</option>
                                                                            <option value="Honduran">Honduran</option>
                                                                            <option value="Hungarian">Hungarian</option>
                                                                            <option value="Icelander">Icelander</option>
                                                                            <option value="Indian">Indian</option>
                                                                            <option value="Indonesian">Indonesian</option>
                                                                            <option value="Iranian">Iranian</option>
                                                                            <option value="Iraqi">Iraqi</option>
                                                                            <option value="Irishman">Irishman</option>
                                                                            <option value="Israeli">Israeli</option>
                                                                            <option value="Italian">Italian</option>
                                                                            <option value="Ivorian">Ivorian</option>
                                                                            <option value="Jamaican">Jamaican</option>
                                                                            <option value="Japanese">Japanese</option>
                                                                            <option value="Jordanian">Jordanian</option>
                                                                            <option value="Kazakh">Kazakh</option>
                                                                            <option value="Kenyan">Kenyan</option>
                                                                            <option value="Korean">Korean</option>
                                                                            <option value="Kuwaiti">Kuwaiti</option>
                                                                            <option value="Laotian">Laotian</option>
                                                                            <option value="Latvian">Latvian</option>
                                                                            <option value="Lebanese">Lebanese</option>
                                                                            <option value="Liberian">Liberian</option>
                                                                            <option value="Libyan">Libyan</option>
                                                                            <option value="Liechtensteiner">Liechtensteiner</option>
                                                                            <option value="Lithuanian">Lithuanian</option>
                                                                            <option value="Luxembourger">Luxembourger</option>
                                                                            <option value="Macedonian">Macedonian</option>
                                                                            <option value="Madagascan">Madagascan</option>
                                                                            <option value="Malawian">Malawian</option>
                                                                            <option value="Malaysian">Malaysian</option>
                                                                            <option value="Maldivian">Maldivian</option>
                                                                            <option value="Malian">Malian</option>
                                                                            <option value="Maltese">Maltese</option>
                                                                            <option value="Mauritanian">Mauritanian</option>
                                                                            <option value="Mauritian">Mauritian</option>
                                                                            <option value="Mexican">Mexican</option>
                                                                            <option value="Moldovan">Moldovan</option>
                                                                            <option value="Monacan">Monacan</option>
                                                                            <option value="Mongolian">Mongolian</option>
                                                                            <option value="Montenegrin">Montenegrin</option>
                                                                            <option value="Moroccan">Moroccan</option>
                                                                            <option value="Mozambican">Mozambican</option>
                                                                            <option value="Namibian">Namibian</option>
                                                                            <option value="Nepalese">Nepalese</option>
                                                                            <option value="Nicaraguan">Nicaraguan</option>
                                                                            <option value="Nigerian">Nigerian</option>
                                                                            <option value="Nigerien">Nigerien</option>
                                                                            <option value="Norwegian">Norwegian</option>
                                                                            <option value="Pakistani">Pakistani</option>
                                                                            <option value="Panamanian">Panamanian</option>
                                                                            <option value="Paraguayan">Paraguayan</option>
                                                                            <option value="Peruvian">Peruvian</option>
                                                                            <option value="Pole">Pole</option>
                                                                            <option value="Portuguese">Portuguese</option>
                                                                            <option value="Qatari">Qatari</option>
                                                                            <option value="Romanian">Romanian</option>
                                                                            <option value="Russian">Russian</option>
                                                                            <option value="Rwandan">Rwandan</option>
                                                                            <option value="Salvadorean">Salvadorean</option>
                                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                                            <option value="Saudi Arabian">Saudi Arabian</option>
                                                                            <option value="Scot">Scot</option>
                                                                            <option value="Senegalese">Senegalese</option>
                                                                            <option value="Serbian">Serbian</option>
                                                                            <option value="Sierra Leonean">Sierra Leonean</option>
                                                                            <option value="Singaporean">Singaporean</option>
                                                                            <option value="Slovak">Slovak</option>
                                                                            <option value="Slovenian">Slovenian</option>
                                                                            <option value="Somali">Somali</option>
                                                                            <option value="South African">South African</option>
                                                                            <option value="Spaniard">Spaniard</option>
                                                                            <option value="SriLankan">SriLankan</option>
                                                                            <option value="Sudanese">Sudanese</option>
                                                                            <option value="Surinamese">Surinamese</option>
                                                                            <option value="Swazi">Swazi</option>
                                                                            <option value="Swede">Swede</option>
                                                                            <option value="Swiss">Swiss</option>
                                                                            <option value="Syrian">Syrian</option>
                                                                            <option value="Tadzhik">Tadzhik</option>
                                                                            <option value="Taiwanese">Taiwanese</option>
                                                                            <option value="Tanzanian">Tanzanian</option>
                                                                            <option value="Thai">Thai</option>
                                                                            <option value="Togolese">Togolese</option>
                                                                            <option value="Trinidadian">Trinidadian</option>
                                                                            <option value="Tunisian">Tunisian</option>
                                                                            <option value="Turk">Turk</option>
                                                                            <option value="Ugandan">Ugandan</option>
                                                                            <option value="Ukrainian">Ukrainian</option>
                                                                            <option value="Uruguayan">Uruguayan</option>
                                                                            <option value="Uzbek">Uzbek</option>
                                                                            <option value="Venezuelan">Venezuelan</option>
                                                                            <option value="Vietnamese">Vietnamese</option>
                                                                            <option value="Welshman">Welshman</option>
                                                                            <option value="Yemeni">Yemeni</option>
                                                                            <option value="Yugoslav">Yugoslav</option>
                                                                            <option value="Zambian">Zambian</option>
                                                                            <option value="Zimbabwean">Zimbabwean</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>City</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='City'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.City}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Message Language</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Sms_lang'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Sms_lang}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Occupation</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name='Occupation'
                                                                            onChange={(e) => handleParent(e)}
                                                                            value={dataParent.Occupation}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Present Address</label>
                                                                        <input className="form-control" name='PresentAddress' style={{ fontWeight: 'bold' }} onChange={(e) => handleParent(e)} value={dataParent.PresentAddress}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Enterprise Address</label>
                                                                        <input className="form-control" name='EnterpriseAddress' style={{ fontWeight: 'bold' }} onChange={(e) => handleParent(e)} value={dataParent.EnterpriseAddress}/>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Responsible of school fees</label>
                                                                        <select className="form-control select" name='ResponsibleFees' style={{ fontWeight: 'bold' }} onChange={(e) => handleParent(e)}>
                                                                            <option value="No">No</option>
                                                                            <option value="Yes">Yes</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mt-5">
                                                        <button type="submit" className="btn btn-success btn-lg">Submit</button>
                                                    </div>

                                                </div>
                                            </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StudentAdd;