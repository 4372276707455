import React, { useEffect, useState } from "react";
import Menu from "../../components/Menus";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import services from "../../services/services";
import Swal from "sweetalert2";


function ServicesParameters() {

    ///////////////////////////////////////////////////////////////////////////// Services Parameters /////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////// Cars Brands /////////////////////////////////////////

    /** Activation modal d'insertion Cars Brands */
    const [showModalCarsBrand, setShowModalCarsBrand] = useState(false)

    function ShowModalBrand() {
        setShowModalCarsBrand(true); //open modal

    }
    const handleCloseModalCarsBrand = () => setShowModalCarsBrand(false) ///close modal
    /** Activation modal d'insertion Cars Brands */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataBrand, setBrand] = useState({
        brand: '',
    })

    const handleCarsBrand = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataBrand, [name]: value }
        setBrand(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitCarsBrand(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('brand', dataBrand.brand);

        services.InsertCarsBrand(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalCarsBrand();
                getallCarsBrand();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallCarsBrand()
        getCarsBrandOpen()
    }, [])


    const [dataCarsBrandOpen, setDataCarsBrandOpen] = useState([]);

    function getCarsBrandOpen() {
        services.get_CarsBrand_Open()
            .then((response) => {

                setDataCarsBrandOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataCarsBrand, setDataCarsBrand] = useState([]);

    function getallCarsBrand() {
        services.getCarsBrand()
            .then((response) => {

                setDataCarsBrand(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsCarsBrand = [
        {
            name: "Brand",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.brand} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateBrand(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteCarsBrand(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteCarsBrand(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Cars Brands",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteCarsBrand(id)
                    .then(response => {
                        console.log(response.data);
                        getallCarsBrand()
                        Swal.fire(
                            'Data Cars Brands has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditCarsBrand, setShowEditModalCarsBrand] = useState(false)

    function ShowModalUpdateBrand(id) {
        setShowEditModalCarsBrand(true);
        dataBrandEdit(id)

    }
    const handleEditCloseModalCarsBrand = () => setShowEditModalCarsBrand(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditBrand, setDataEditBrand] = useState([])

    function dataBrandEdit(id) {
        services.get_CarsBrand_by(id)
            .then((response) => {

                setDataEditBrand(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateCarsBrand(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Brand', id);
        bodyFormData.append('brand', dataBrand.brand);

        services.updateCarsBrand(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallCarsBrand()
                handleEditCloseModalCarsBrand()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Cars Brands //////////////////////////////////////////



    ///////////////////////////////////////////////////////////////////////////// Cars /////////////////////////////////////////

    /** Activation modal d'insertion Cars */
    const [showModalCars, setShowModalCars] = useState(false)

    function ShowModalCar() {
        setShowModalCars(true); //open modal

    }
    const handleCloseModalCars = () => setShowModalCars(false) ///close modal
    /** Activation modal d'insertion Cars */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataCar, setCar] = useState({
        matricule:'',
        id_brand: '',
        model: '',
        type: '',
        capacity: '',
        driver: '',
        assistant: '',
    })

    const handleCars = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataCar, [name]: value }
        setCar(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitCars(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('matricule', dataCar.matricule);
        bodyFormData.append('brand', dataCar.id_brand);
        bodyFormData.append('model', dataCar.model);
        bodyFormData.append('type', dataCar.type);
        bodyFormData.append('capacity', dataCar.capacity);
        bodyFormData.append('driver', dataCar.driver);
        bodyFormData.append('assistant', dataCar.assistant);
        

        services.InsertCars(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalCars();
                getallCars();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallCars()
    }, [])


    const [dataCars, setDataCars] = useState([]);

    function getallCars() {
        services.getCars()
            .then((response) => {

                setDataCars(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsCars = [

        {
            name: "Matricule",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.matricule} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Brand",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.brand} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Model",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.model} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Type",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.type} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Capacity",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.capacity} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Driver",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.driver} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Assistant",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.assistant} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateCar(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteCars(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteCars(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Cars",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteCars(id)
                    .then(response => {
                        console.log(response.data);
                        getallCars()
                        Swal.fire(
                            'Data Cars has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditCars, setShowEditModalCars] = useState(false)

    function ShowModalUpdateCar(id) {
        setShowEditModalCars(true);
        dataCarEdit(id)

    }
    const handleEditCloseModalCars = () => setShowEditModalCars(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditCar, setDataEditCar] = useState([])

    function dataCarEdit(id) {
        services.get_Cars_by(id)
            .then((response) => {

                setDataEditCar(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateCars(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Car', id);
        bodyFormData.append('matricule', dataCar.matricule);
        bodyFormData.append('model', dataCar.model);
        bodyFormData.append('type', dataCar.type);
        bodyFormData.append('capacity', dataCar.capacity);
        bodyFormData.append('driver', dataCar.driver);
        bodyFormData.append('assistant', dataCar.assistant);

        services.updateCars(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallCars()
                handleEditCloseModalCars()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Cars //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Trips /////////////////////////////////////////

    /** Activation modal d'insertion Trips */
    const [showModalTrips, setShowModalTrips] = useState(false)

    function ShowModalTrip() {
        setShowModalTrips(true); //open modal

    }
    const handleCloseModalTrips = () => setShowModalTrips(false) ///close modal
    /** Activation modal d'insertion Trips */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataTrip, setTrip] = useState({
        libelle: '',
        description: '',
        one_way: '',
        round_trip: '',
    })

    const handleTrips = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataTrip, [name]: value }
        setTrip(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitTrips(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataTrip.libelle);
        bodyFormData.append('description', dataTrip.description);
        bodyFormData.append('one_way', dataTrip.one_way);
        bodyFormData.append('round_trip', dataTrip.round_trip);

        services.InsertTrips(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalTrips();
                getallTrips();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallTrips()
        getTripsOpen()
    }, [])

    const [dataTripsOpen, setDataTripsOpen] = useState([]);

    function getTripsOpen() {
        services.get_Trips_Open()
            .then((response) => {

                setDataTripsOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataTrips, setDataTrips] = useState([]);

    function getallTrips() {
        services.getTrips()
            .then((response) => {

                setDataTrips(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsTrips = [

        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "One_way",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.one_way} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Round_trip",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.round_trip} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateTrip(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteTrips(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteTrips(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Trips",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteTrips(id)
                    .then(response => {
                        console.log(response.data);
                        getallTrips()
                        Swal.fire(
                            'Data Trips has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditTrips, setShowEditModalTrips] = useState(false)

    function ShowModalUpdateTrip(id) {
        setShowEditModalTrips(true);
        dataTripEdit(id)

    }
    const handleEditCloseModalTrips = () => setShowEditModalTrips(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditTrip, setDataEditTrip] = useState([])

    function dataTripEdit(id) {
        services.get_Trips_by(id)
            .then((response) => {

                setDataEditTrip(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateTrips(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Trip', id);
        bodyFormData.append('libelle', dataTrip.libelle);
        bodyFormData.append('description', dataTrip.description);
        bodyFormData.append('one_way', dataTrip.one_way);
        bodyFormData.append('round_trip', dataTrip.round_trip);

        services.updateTrips(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallTrips()
                handleEditCloseModalTrips()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Trips //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Assign Trips And Cars /////////////////////////////////////////

    /** Activation modal d'insertion Assign Trips And Cars */
    const [showModalAssignTrip, setShowModalAssignTrip] = useState(false)

    function ShowModalAssign() {
        setShowModalAssignTrip(true); //open modal

    }
    const handleCloseModalAssignTrip = () => setShowModalAssignTrip(false) ///close modal
    /** Activation modal d'insertion Assign Trips And Cars */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataAssign, setAssign] = useState({
        id_trip: '',
        pick_up_car: '',
        drop_off_car: '',
    })

    const handleAssignTrip = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataAssign, [name]: value }
        setAssign(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitAssignTrip(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataAssign.id_trip);
        bodyFormData.append('pick_up_car', dataAssign.pick_up_car);
        bodyFormData.append('drop_off_car', dataAssign.drop_off_car);
        

        services.InsertAssignTrip(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalAssignTrip();
                getallAssignTrip();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallAssignTrip()
    }, [])


    const [dataAssignTrip, setDataAssignTrip] = useState([]);

    function getallAssignTrip() {
        services.getAssignTrip()
            .then((response) => {

                setDataAssignTrip(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsAssignTrip = [

        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Pick Up Car",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.pick_up_car} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Drop Off Car",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.drop_off_car} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateAssign(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteAssignTrip(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteAssignTrip(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data AssignTrip",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteAssignTrip(id)
                    .then(response => {
                        console.log(response.data);
                        getallAssignTrip()
                        Swal.fire(
                            'Data AssignTrip has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditAssignTrip, setShowEditModalAssignTrip] = useState(false)

    function ShowModalUpdateAssign(id) {
        setShowEditModalAssignTrip(true);
        dataAssignEdit(id)

    }
    const handleEditCloseModalAssignTrip = () => setShowEditModalAssignTrip(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditAssign, setDataEditAssign] = useState([])

    function dataAssignEdit(id) {
        services.get_AssignTrip_by(id)
            .then((response) => {

                setDataEditAssign(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateAssignTrip(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Assign', id);
        bodyFormData.append('pick_up_car', dataAssign.pick_up_car);
        bodyFormData.append('drop_off_car', dataAssign.drop_off_car);

        services.updateAssignTrip(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallAssignTrip()
                handleEditCloseModalAssignTrip()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Assign Trips And Cars //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Reasons For /////////////////////////////////////////

    /** Activation modal d'insertion Reasons For Absence */
    const [showModalReasons, setShowModalReasons] = useState(false)

    function ShowModalReason() {
        setShowModalReasons(true); //open modal

    }
    const handleCloseModalReasons = () => setShowModalReasons(false) ///close modal
    /** Activation modal d'insertion Reasons For Absence */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataReason, setReason] = useState({
        reasons: '',
    })

    const handleReasons = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataReason, [name]: value }
        setReason(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitReasons(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('reasons', dataReason.reasons);

        services.InsertReasons(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalReasons();
                getallReasons();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallReasons()
    }, [])


    const [dataReasons, setDataReasons] = useState([]);

    function getallReasons() {
        services.getReasons()
            .then((response) => {

                setDataReasons(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsReasons = [
        {
            name: "Reasons",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.reasons} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateReason(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteReasons(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteReasons(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Reasons For absence",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteReasons(id)
                    .then(response => {
                        console.log(response.data);
                        getallReasons()
                        Swal.fire(
                            'Data Reasons For absence has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditReasons, setShowEditModalReasons] = useState(false)

    function ShowModalUpdateReason(id) {
        setShowEditModalReasons(true);
        dataReasonEdit(id)

    }
    const handleEditCloseModalReasons = () => setShowEditModalReasons(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditReason, setDataEditReason] = useState([])

    function dataReasonEdit(id) {
        services.get_Reasons_by(id)
            .then((response) => {

                setDataEditReason(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateReasons(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Reason', id);
        bodyFormData.append('reasons', dataReason.reasons);

        services.updateReasons(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallReasons()
                handleEditCloseModalReasons()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Reasons for //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Coachs /////////////////////////////////////////

    /** Activation modal d'insertion Assign Coachs */
    const [showModalCoachs, setShowModalCoachs] = useState(false)

    function ShowModalCoach() {
        setShowModalCoachs(true); //open modal

    }
    const handleCloseModalCoachs = () => setShowModalCoachs(false) ///close modal
    /** Activation modal d'insertion Assign Coachs */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataCoach, setCoach] = useState({
        last_name: '',
        first_name: '',
        phone: '',
        email: '',
    })

    const handleCoachs = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataCoach, [name]: value }
        setCoach(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitCoachs(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('last_name', dataCoach.last_name);
        bodyFormData.append('first_name', dataCoach.first_name);
        bodyFormData.append('phone', dataCoach.phone);
        bodyFormData.append('email', dataCoach.email);
        

        services.InsertCoachs(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalCoachs();
                getallCoachs();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallCoachs()
        getCoachsOpen()
    }, [])

    const [dataCoachsOpen, setDataCoachsOpen] = useState([]);

    function getCoachsOpen() {
        services.get_Coachs_Open()
            .then((response) => {

                setDataCoachsOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataCoachs, setDataCoachs] = useState([]);

    function getallCoachs() {
        services.getCoachs()
            .then((response) => {

                setDataCoachs(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsCoachs = [

        {
            name: "Last Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.last_name} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "First Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.first_name} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Phone",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.phone} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Email",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.email} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateCoach(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteCoachs(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteCoachs(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Coachs",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteCoachs(id)
                    .then(response => {
                        console.log(response.data);
                        getallCoachs()
                        Swal.fire(
                            'Data Coachs has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditCoachs, setShowEditModalCoachs] = useState(false)

    function ShowModalUpdateCoach(id) {
        setShowEditModalCoachs(true);
        dataCoachEdit(id)

    }
    const handleEditCloseModalCoachs = () => setShowEditModalCoachs(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditCoach, setDataEditCoach] = useState([])

    function dataCoachEdit(id) {
        services.get_Coachs_by(id)
            .then((response) => {

                setDataEditCoach(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateCoachs(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Coach', id);
        bodyFormData.append('last_name', dataCoach.last_name);
        bodyFormData.append('first_name', dataCoach.first_name);
        bodyFormData.append('phone', dataCoach.phone);
        bodyFormData.append('email', dataCoach.email);

        services.updateCoachs(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallCoachs()
                handleEditCloseModalCoachs()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Coachs //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Activities /////////////////////////////////////////

    /** Activation modal d'insertion Activities */
    const [showModalActivitie, setShowModalActivitie] = useState(false)

    function ShowModalActi() {
        setShowModalActivitie(true); //open modal

    }
    const handleCloseModalActivitie = () => setShowModalActivitie(false) ///close modal
    /** Activation modal d'insertion Activities */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataActi, setActi] = useState({
        id_level: '',
        id_coach: '',
        description: '',
        month_price: '',
        days: '',
    })

    const handleActivitie = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataActi, [name]: value }
        setActi(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitActivitie(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataActi.id_level);
        bodyFormData.append('last_name', dataActi.id_coach);
        bodyFormData.append('description', dataActi.description);
        bodyFormData.append('month_price', dataActi.month_price);
        bodyFormData.append('days', dataActi.days);

        services.InsertActivitie(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalActivitie();
                getallActivitie();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallActivitie()
        getFirstlevelOpen()
    }, [])


    const [dataFirstlevelOpen, setDataFirstlevelOpen] = useState([]);

    function getFirstlevelOpen() {
        services.get_Firstlevel_Open()
            .then((response) => {

                setDataFirstlevelOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataActivitie, setDataActivitie] = useState([]);

    function getallActivitie() {
        services.getActivitie()
            .then((response) => {

                setDataActivitie(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsActivitie = [

        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Last Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.last_name} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Month Price",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.month_price} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Days",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.days} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateActi(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteActivitie(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteActivitie(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Activities",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteActivitie(id)
                    .then(response => {
                        console.log(response.data);
                        getallActivitie()
                        Swal.fire(
                            'Data Activities has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditActivitie, setShowEditModalActivitie] = useState(false)

    function ShowModalUpdateActi(id) {
        setShowEditModalActivitie(true);
        dataActiEdit(id)

    }
    const handleEditCloseModalActivitie = () => setShowEditModalActivitie(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditActi, setDataEditActi] = useState([])

    function dataActiEdit(id) {
        services.get_Activitie_by(id)
            .then((response) => {

                setDataEditActi(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateActivitie(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Acti', id);
        bodyFormData.append('description', dataActi.description);
        bodyFormData.append('month_price', dataActi.month_price);
        bodyFormData.append('days', dataActi.days);

        services.updateActivitie(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallActivitie()
                handleEditCloseModalActivitie()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Activities //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Provider /////////////////////////////////////////

    /** Activation modal d'insertion Assign Provinder */
    const [showModalProvider, setShowModalProvider] = useState(false)

    function ShowModalProv() {
        setShowModalProvider(true); //open modal

    }
    const handleCloseModalProvider = () => setShowModalProvider(false) ///close modal
    /** Activation modal d'insertion Assign Provider */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataProv, setProv] = useState({
        all_name: '',
        email: '',
        phone: '',
        adresse: '',
    })

    const handleProvider = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataProv, [name]: value }
        setProv(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitProvider(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('all_name', dataProv.all_name);
        bodyFormData.append('email', dataProv.email);
        bodyFormData.append('phone', dataProv.phone);
        bodyFormData.append('adresse', dataProv.adresse);
        

        services.InsertProvider(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalProvider();
                getallProvider();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallProvider()
    }, [])


    const [dataProvider, setDataProvider] = useState([]);

    function getallProvider() {
        services.getProvider()
            .then((response) => {

                setDataProvider(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsProvider = [

        {
            name: "All Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.all_name} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Email",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.email} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Phone",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.phone} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Adresse",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.adresse} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateProv(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteProvider(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteProvider(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Provider",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteProvider(id)
                    .then(response => {
                        console.log(response.data);
                        getallProvider()
                        Swal.fire(
                            'Data Provider has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditProvider, setShowEditModalProvider] = useState(false)

    function ShowModalUpdateProv(id) {
        setShowEditModalProvider(true);
        dataProvEdit(id)

    }
    const handleEditCloseModalProvider = () => setShowEditModalProvider(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditProv, setDataEditProv] = useState([])

    function dataProvEdit(id) {
        services.get_Provider_by(id)
            .then((response) => {

                setDataEditProv(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateProvider(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Prov', id);
        bodyFormData.append('all_name', dataProv.all_name);
        bodyFormData.append('email', dataProv.email);
        bodyFormData.append('phone', dataProv.phone);
        bodyFormData.append('adresse', dataProv.adresse);

        services.updateProvider(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallProvider()
                handleEditCloseModalProvider()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Provider //////////////////////////////////////////

    
    ///////////////////////////////////////////////////////////////////////////// Monthly /////////////////////////////////////////

    /** Activation modal d'insertion Monthly */
    const [showModalMonthly, setShowModalMonthly] = useState(false)

    function ShowModalMont() {
        setShowModalMonthly(true); //open modal

    }
    const handleCloseModalMonthly = () => setShowModalMonthly(false) ///close modal
    /** Activation modal d'insertion Monthly */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataMont, setMont] = useState({
        id_aca_year: '',
        amount: '',
    })

    const handleMonthly = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataMont, [name]: value }
        setMont(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitMonthly(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataMont.id_aca_year);
        bodyFormData.append('amount', dataMont.amount);

        services.InsertMonthly(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalMonthly();
                getallMonthly();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallMonthly()
        getAcademicOpen()
    }, [])


    const [dataAcademicOpen, setDataAcademicOpen] = useState([]);

    function getAcademicOpen() {
        services.get_Academic_Open()
            .then((response) => {

                setDataAcademicOpen(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataMonthly, setDataMonthly] = useState([]);

    function getallMonthly() {
        services.getMonthly()
            .then((response) => {

                setDataMonthly(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsMonthly = [
        {
            name: "Amount",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.amount} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateMont(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteMonthly(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteMonthly(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Monthly",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteMonthly(id)
                    .then(response => {
                        console.log(response.data);
                        getallMonthly()
                        Swal.fire(
                            'Data Monthly has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE //////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditMonthly, setShowEditModalMonthly] = useState(false)

    function ShowModalUpdateMont(id) {
        setShowEditModalMonthly(true);
        dataMontEdit(id)

    }
    const handleEditCloseModalMonthly = () => setShowEditModalMonthly(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditMont, setDataEditMont] = useState([])

    function dataMontEdit(id) {
        services.get_Monthly_by(id)
            .then((response) => {

                setDataEditMont(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateMonthly(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Mont', id);
        bodyFormData.append('amount', dataMont.amount);

        services.updateMonthly(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallMonthly()
                handleEditCloseModalMonthly()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Monthly //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Subjects /////////////////////////////////////////

    /** Activation modal d'insertion Sof Subjects */
    const [showModalSubjects, setShowModalSubjects] = useState(false)

    function ShowModalSub() {
        setShowModalSubjects(true); //open modal

    }
    const handleCloseModalSubjects = () => setShowModalSubjects(false) ///close modal
    /** Activation modal d'insertion Sof Subjects */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSub, setSub] = useState({
        id_acad_year: '',
        subject: '',
        price: '',
    })

    const handleSubjects = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSub, [name]: value }
        setSub(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitSubjects(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataSub.id_acad_year);
        bodyFormData.append('subject', dataSub.subject);
        bodyFormData.append('price', dataSub.price);

        services.InsertSubjects(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalSubjects();
                getallSubjects();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallSubjects()
        // getAcademicOpen()
    }, [])


    // const [dataAcademicOpen, setDataAcademicOpen] = useState([]);

    // function getAcademicOpen() {
    //     services.get_Academic_Open()
    //         .then((response) => {

    //             setDataAcademicOpen(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    const [dataSubjects, setDataSubjects] = useState([]);

    function getallSubjects() {
        services.getSubjects()
            .then((response) => {

                setDataSubjects(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsSubjects = [

        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Subject",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.subject} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Price",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.price} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to={"#"} onClick={(e) => ShowModalUpdateSub(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSubjects(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteSubjects(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Sof Subjects",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSubjects(id)
                    .then(response => {
                        console.log(response.data);
                        getallSubjects()
                        Swal.fire(
                            'Data Sof Subjects has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditSubjects, setShowEditModalSubjects] = useState(false)

    function ShowModalUpdateSub(id) {
        setShowEditModalSubjects(true);
        dataSubEdit(id)

    }
    const handleEditCloseModalSubjects = () => setShowEditModalSubjects(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditSub, setDataEditSub] = useState([])

    function dataSubEdit(id) {
        services.get_Subjects_by(id)
            .then((response) => {

                setDataEditSub(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateSubjects(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Sub', id);
        bodyFormData.append('subject', dataSub.subject);
        bodyFormData.append('price', dataSub.price);

        services.updateSubjects(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallSubjects()
                handleEditCloseModalSubjects()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    
    ///////////////////////////////////////////////////////////////////////////// Sof Subjects //////////////////////////////////////////



    return (
        <>
            <Menu />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Services Parameters</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Setting</a></li>
                                    <li className="breadcrumb-item active">Services Parameters</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="card bg-white">
                                <div className="card-header">
                                    <h5 className="card-title">Services Parameters</h5>
                                </div>
                                <div className="card-body">
                                    <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                        <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab1" data-bs-toggle="tab">Transport</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab2" data-bs-toggle="tab">Extra activity</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab3" data-bs-toggle="tab">Cantine</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4" data-bs-toggle="tab">Sof Subjects</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="solid-justified-tab1">
                                            <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                                <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab1-1" data-bs-toggle="tab">Cars Brands</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab1-2" data-bs-toggle="tab">Cars</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab1-3" data-bs-toggle="tab">Trips</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab1-4" data-bs-toggle="tab">Assign Trips Cars</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab1-5" data-bs-toggle="tab">Reasons For Absence</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="solid-justified-tab1-1">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalBrand()} ><i className="fas fa-plus"></i> Cars Brands </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsCarsBrand}
                                                                            data={dataCarsBrand}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab1-2">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalCar()} ><i className="fas fa-plus"></i> Cars </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsCars}
                                                                            data={dataCars}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab1-3">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalTrip()} ><i className="fas fa-plus"></i> Trips </button>

                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsTrips}
                                                                            data={dataTrips}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab1-4">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalAssign()} ><i className="fas fa-plus"></i> Assign Trips </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsAssignTrip}
                                                                            data={dataAssignTrip}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab1-5">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalReason()} ><i className="fas fa-plus"></i> Reasons For Absence </button>


                                                                        <DataTable
                                                                            title="DataTable"
                                                                            // customStyles={customStyles}
                                                                            columns={columnsReasons}
                                                                            data={dataReasons}
                                                                            responsive
                                                                            noHeader
                                                                            defaultSortFieldId={1}
                                                                            defaultSortField="id"
                                                                            defaultSortAsc={false}
                                                                            persistTableHead
                                                                            highlightOnHover
                                                                            exportHeaders={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab2">
                                            <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                                <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab2-1" data-bs-toggle="tab">Coachs</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab2-2" data-bs-toggle="tab">Activities</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="solid-justified-tab2-1">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalCoach()} ><i className="fas fa-plus"></i> Coachs </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsCoachs}
                                                                                data={dataCoachs}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab2-2">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalActi()} ><i className="fas fa-plus"></i> Activities </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsActivitie}
                                                                                data={dataActivitie}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab3">
                                             <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                                <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab3-1" data-bs-toggle="tab">Provider</a></li>
                                                <li className="nav-item"><a className="nav-link" href="#solid-justified-tab3-2" data-bs-toggle="tab">Monthly Price</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="solid-justified-tab3-1">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalProv()} ><i className="fas fa-plus"></i> Provider </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsProvider}
                                                                                data={dataProvider}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="solid-justified-tab3-2">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card card-table">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                            <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalMont()} ><i className="fas fa-plus"></i> Monthly Price </button>


                                                                            <DataTable
                                                                                title="DataTable"
                                                                                // customStyles={customStyles}
                                                                                columns={columnsMonthly}
                                                                                data={dataMonthly}
                                                                                responsive
                                                                                noHeader
                                                                                defaultSortFieldId={1}
                                                                                defaultSortField="id"
                                                                                defaultSortAsc={false}
                                                                                persistTableHead
                                                                                highlightOnHover
                                                                                exportHeaders={false}
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab4">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="card card-table">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalSub()} ><i className="fas fa-plus"></i> Sof Subjects </button>

                                                                    <DataTable
                                                                        title="DataTable"
                                                                        // customStyles={customStyles}
                                                                        columns={columnsSubjects}
                                                                        data={dataSubjects}
                                                                        responsive
                                                                        noHeader
                                                                        defaultSortFieldId={1}
                                                                        defaultSortField="id"
                                                                        defaultSortAsc={false}
                                                                        //pagination
                                                                        // selectableRows
                                                                        persistTableHead
                                                                        highlightOnHover
                                                                        exportHeaders={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>

            {/* formulaire Cars Brands */}
            <Modal show={showModalCarsBrand} onHide={handleCloseModalCarsBrand} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Cars Brands Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitCarsBrand(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">


                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Brand</label>
                                    <input type="text" name="brand" onChange={(e) => handleCarsBrand(e)} placeholder="brand..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditCarsBrand} onHide={handleEditCloseModalCarsBrand} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Cars Brands Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateCarsBrand(e, dataEditBrand.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Brand</label>
                                    <input type="text" name="brand" defaultValue={dataEditBrand.brand} onChange={(e) => handleCarsBrand(e)} placeholder="brand..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Cars Brands */}



            {/* formulaire Cars */}
            <Modal show={showModalCars} onHide={handleCloseModalCars} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Cars Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitCars(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Matricule</label>
                                    <input type="text" name="matricule" onChange={(e) => handleCars(e)} placeholder="matricule..." className="form-control" />
                                </div>               
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Card Brand</label>
                                    <select name="id_brand" onChange={(e) => handleCars(e)} id=""  className="form-control">
                                    <option value="">Select brand</option>{
                                    dataCarsBrandOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.brand}</option>
                                          ))
                                          }
                                    </select>
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Model</label>
                                    <input type="text" name="model" onChange={(e) => handleCars(e)} placeholder="model..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Type</label>
                                    <input type="text" name="type" onChange={(e) => handleCars(e)} placeholder="type..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Capacity</label>
                                    <input type="text" name="capacity" onChange={(e) => handleCars(e)} placeholder="capacity..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Driver</label>
                                    <input type="text" name="driver" onChange={(e) => handleCars(e)} placeholder="driver..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Assistant</label>
                                    <input type="text" name="assistant" onChange={(e) => handleCars(e)} placeholder="assistant..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditCars} onHide={handleEditCloseModalCars} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Cars Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateCars(e, dataEditCar.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Model</label>
                                    <input type="text" name="model" defaultValue={dataEditCar.model} onChange={(e) => handleCars(e)} placeholder="model..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Type</label>
                                    <input type="text" name="type" defaultValue={dataEditCar.type} onChange={(e) => handleCars(e)} placeholder="type..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Capacity</label>
                                    <input type="text" name="capacity" defaultValue={dataEditCar.capacity} onChange={(e) => handleCars(e)} placeholder="capacity..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Driver</label>
                                    <input type="text" name="driver" defaultValue={dataEditCar.driver} onChange={(e) => handleCars(e)} placeholder="driver..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Assistant</label>
                                    <input type="text" name="assistant" defaultValue={dataEditCar.assistant} onChange={(e) => handleCars(e)} placeholder="assistant..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Cars */}

            
            {/* formulaire Trips */}
            <Modal show={showModalTrips} onHide={handleCloseModalTrips} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Trips Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitTrips(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
               
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Libelle</label>
                                    <input type="text" name="libelle" onChange={(e) => handleTrips(e)} placeholder="libelle..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleTrips(e)} placeholder="description..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">One_way</label>
                                    <input type="text" name="one_way" onChange={(e) => handleTrips(e)} placeholder="one_way..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Round_trip</label>
                                    <input type="text" name="round_trip" onChange={(e) => handleTrips(e)} placeholder="round_trip..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditTrips} onHide={handleEditCloseModalTrips} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Trips Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateTrips(e, dataEditTrip.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Libelle</label>
                                    <input type="text" name="libelle" defaultValue={dataEditTrip.libelle} onChange={(e) => handleTrips(e)} placeholder="libelle..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditTrip.description} onChange={(e) => handleTrips(e)} placeholder="description..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">One_way</label>
                                    <input type="text" name="one_way" defaultValue={dataEditTrip.one_way} onChange={(e) => handleTrips(e)} placeholder="one_way..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Round_trip</label>
                                    <input type="text" name="round_trip" defaultValue={dataEditTrip.round_trip} onChange={(e) => handleTrips(e)} placeholder="round_trip..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Trips */}


            {/* formulaire Assign Trips And Cars */}
            <Modal show={showModalAssignTrip} onHide={handleCloseModalAssignTrip} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Assign Trips Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitAssignTrip(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Trips</label>
                                    <select name="id_trip" onChange={(e) => handleAssignTrip(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataTripsOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Pick Up Car</label>
                                    <input type="text" name="pick_up_car" onChange={(e) => handleAssignTrip(e)} placeholder="pick_up_car..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Drop Off Car</label>
                                    <input type="text" name="drop_off_car" onChange={(e) => handleAssignTrip(e)} placeholder="drop_off_car..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditAssignTrip} onHide={handleEditCloseModalAssignTrip} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Assign Trips Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateAssignTrip(e, dataEditAssign.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Pick Up Car</label>
                                    <input type="text" name="pick_up_car" defaultValue={dataEditAssign.pick_up_car} onChange={(e) => handleAssignTrip(e)} placeholder="pick_up_car..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Drop Off Car</label>
                                    <input type="text" name="drop_off_car" defaultValue={dataEditAssign.drop_off_car} onChange={(e) => handleAssignTrip(e)} placeholder="drop_off_car..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Assign Trips And Cars */}


            {/* formulaire Reasons For Absence */}
            <Modal show={showModalReasons} onHide={handleCloseModalReasons} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Reasons For Absence Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitReasons(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">


                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Reasons</label>
                                    <input type="text" name="reasons" onChange={(e) => handleReasons(e)} placeholder="reasons..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditReasons} onHide={handleEditCloseModalReasons} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Reasons For Absence Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateReasons(e, dataEditReason.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Reasons</label>
                                    <input type="text" name="reasons" defaultValue={dataEditReason.reasons} onChange={(e) => handleReasons(e)} placeholder="reasons..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Reasons For Absence */}


            {/* formulaire Coachs */}
            <Modal show={showModalCoachs} onHide={handleCloseModalCoachs} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Coachs Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitCoachs(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
               
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Last Name</label>
                                    <input type="text" name="last_name" onChange={(e) => handleCoachs(e)} placeholder="last_name..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">First Name</label>
                                    <input type="text" name="first_name" onChange={(e) => handleCoachs(e)} placeholder="first_name..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Phone</label>
                                    <input type="text" name="phone" onChange={(e) => handleCoachs(e)} placeholder="phone..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Email</label>
                                    <input type="text" name="email" onChange={(e) => handleCoachs(e)} placeholder="email..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditCoachs} onHide={handleEditCloseModalCoachs} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Coachs Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateCoachs(e, dataEditCoach.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Last Name</label>
                                    <input type="text" name="last_name" defaultValue={dataEditCoach.last_name} onChange={(e) => handleCoachs(e)} placeholder="last_name..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">First Name</label>
                                    <input type="text" name="first_name" defaultValue={dataEditCoach.first_name} onChange={(e) => handleCoachs(e)} placeholder="first_name..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Phone</label>
                                    <input type="number" name="phone" defaultValue={dataEditCoach.phone} onChange={(e) => handleCoachs(e)} placeholder="phone..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Email</label>
                                    <input type="text" name="email" defaultValue={dataEditCoach.email} onChange={(e) => handleCoachs(e)} placeholder="email..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Coachs */}


            {/* formulaire Activitie */}
            <Modal show={showModalActivitie} onHide={handleCloseModalActivitie} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Activitie Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitActivitie(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
           
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_level" onChange={(e) => handleActivitie(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Coachs</label>
                                    <select name="id_coach" onChange={(e) => handleActivitie(e)} id=""  className="form-control">
                                    <option value="">Select last_name</option>{
                                    dataCoachsOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.last_name}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">description</label>
                                    <input type="text" name="description" onChange={(e) => handleActivitie(e)} placeholder="description..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Month Price</label>
                                    <input type="date" name="month_price" onChange={(e) => handleActivitie(e)} placeholder="month_price..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Days</label>
                                    <input type="date" name="days" onChange={(e) => handleActivitie(e)} placeholder="days..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditActivitie} onHide={handleEditCloseModalActivitie} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Activitie Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateActivitie(e, dataEditActi.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditActi.description} onChange={(e) => handleActivitie(e)} placeholder="description..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Month Price</label>
                                    <input type="date" name="month_price" defaultValue={dataEditActi.month_price} onChange={(e) => handleActivitie(e)} placeholder="month_price..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Days</label>
                                    <input type="date" name="days" defaultValue={dataEditActi.days} onChange={(e) => handleActivitie(e)} placeholder="days..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Activitie */}


            {/* formulaire Provider */}
            <Modal show={showModalProvider} onHide={handleCloseModalProvider} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Provider Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitProvider(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
               
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">All Name</label>
                                    <input type="text" name="all_name" onChange={(e) => handleProvider(e)} placeholder="all_name..." className="form-control" />
                                </div> 
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Email</label>
                                    <input type="text" name="email" onChange={(e) => handleProvider(e)} placeholder="email..." className="form-control" />
                                </div>               
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Phone</label>
                                    <input type="number" name="phone" onChange={(e) => handleProvider(e)} placeholder="phone..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Adresse</label>
                                    <input type="text" name="adresse" onChange={(e) => handleProvider(e)} placeholder="adresse..." className="form-control" />
                                </div>                
                                

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditProvider} onHide={handleEditCloseModalProvider} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Provider Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateProvider(e, dataEditProv.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">All Name</label>
                                    <input type="text" name="all_name" defaultValue={dataEditProv.all_name} onChange={(e) => handleProvider(e)} placeholder="all_name..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Email</label>
                                    <input type="text" name="email" defaultValue={dataEditProv.email} onChange={(e) => handleProvider(e)} placeholder="email..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Phone</label>
                                    <input type="number" name="phone" defaultValue={dataEditProv.phone} onChange={(e) => handleProvider(e)} placeholder="phone..." className="form-control" />
                                </div>                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Adresse</label>
                                    <input type="text" name="adresse" defaultValue={dataEditProv.adresse} onChange={(e) => handleProvider(e)} placeholder="adresse..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Provider */}


            {/* formulaire Monthly */}
            <Modal show={showModalMonthly} onHide={handleCloseModalMonthly} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Monthly Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitMonthly(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleMonthly(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Amount</label>
                                    <input type="text" name="amount" onChange={(e) => handleMonthly(e)} placeholder="amount..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditMonthly} onHide={handleEditCloseModalMonthly} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Monthly Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateMonthly(e, dataEditMont.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Amount</label>
                                    <input type="text" name="amount" defaultValue={dataEditMont.amount} onChange={(e) => handleMonthly(e)} placeholder="amount..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Monthly */}


            {/* formulaire Sof Subjects */}
            <Modal show={showModalSubjects} onHide={handleCloseModalSubjects} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Sof Subjects Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitSubjects(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_acad_year" onChange={(e) => handleSubjects(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Subject</label>
                                    <input type="text" name="subject" onChange={(e) => handleSubjects(e)} placeholder="subject..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Price</label>
                                    <input type="text" name="price"  onChange={(e) => handleSubjects(e)} placeholder="subject..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditSubjects} onHide={handleEditCloseModalSubjects} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Sof Subjects Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateSubjects(e, dataEditSub.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Subject</label>
                                    <input type="text" name="subject" defaultValue={dataEditSub.subject} onChange={(e) => handleSubjects(e)} placeholder="subject..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Price</label>
                                    <input type="text" name="price" defaultValue={dataEditSub.price} onChange={(e) => handleSubjects(e)} placeholder="subject..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Sof Subjects */}
        </>
    )

}
export default ServicesParameters;