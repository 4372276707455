import Dash from '../components/Dash';
import Menus from '../components/Menus';
import Header from '../components/Header';
import { useEffect } from 'react';

function Dashboard() {

    useEffect(()=>{
        redirect();
    }, [JSON.parse(sessionStorage.getItem('token'))])
    



const redirect = () =>{
    if(!JSON.parse(sessionStorage.getItem('token'))){
        //if (JSON.parse(sessionStorage.getItem('role_us')) === '909') {
                
            window.location.replace('/Login') 
        //}
    }
}

    return(
        <>
            <Header />
            <Menus />
            <Dash />
        </>
    );
}

export default Dashboard;