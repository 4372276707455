import { useState, useEffect } from 'react';

import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link } from 'react-router-dom'

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import services from '../services/services';
import Swal from 'sweetalert2';


function StudentList() {

    const [dataList, setDataList] = useState([])

    useEffect(() => {
        GetAllStudent()
    }, []);

    function GetAllStudent() {
        services.get_data_stud()
            .then((res) => {
                setDataList(res.data)
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const columns = [

        // {
        //     name: "Year",
        //     cell: (rows) => (
        //         <b style={{ color: "black" }}> {rows.academicyear} </b>
        //     ),
        //     sortable: true,
        //     reorder: true
        // },
        {
            name: "Picture",
            cell: (rows) => (
                rows.file === null ? (

                    rows.sexe === 'Male' ? (
                        <div className="table-avatar">
                            <Link to={"/detail-student/" + rows.StudentId} className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src={"assets/img/male.png"} alt="UserImage" /></Link>
                        </div>

                    ) : (
                        <div className="table-avatar">
                            <Link to={"/detail-student/" + rows.StudentId} className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src={"assets/img/female.png"} alt="UserImage" /></Link>
                        </div>

                    )

                ) : (
                    <div className="table-avatar">
                        <Link to={"/detail-student/" + rows.StudentId} className="avatar avatar-sm me-2">
                            <img className="avatar-img rounded-circle" src={"https://scholarsapp.iesaciv.com/" + rows.file} alt="?" />
                        </Link>

                    </div>

                )

            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Full name",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.nom} {rows.prenom} </b>
            ),
            sortable: true,
            reorder: true,
            width: "20rem",                       // added line here
            headerStyle: (selector, id) => {
              return { textAlign: "center" };   // removed partial line here
            },
        },

        // {
        //     name: "Class",
        //     cell: (rows) => (
        //         <b style={{ color: "black" }}> {rows.classroom}</b>
        //     ),
        //     sortable: true,
        //     reorder: true
        // },
        {
            name: "DOB",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.birthday} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Nationality",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.nationality} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Admission Date",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.admission_date} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Admission Number",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.admission_number} </b>
            ),
            sortable: true,
            reorder: true,
            width: "10rem",                       // added line here
            headerStyle: (selector, id) => {
              return { textAlign: "center" };   // removed partial line here
            },
        },
        {
            name: "Academic Year",
            cell: (rows) => (
                <Link to="" className="btn btn-outline-primary btn-sm btn-block me-2">
                        <i className="fas fa-calendar"></i>
                    </Link>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">
                    <Link to={"/detail-student/" + rows.StudentId} className="btn btn-sm bg-primary-light me-2">
                        <i className="fas fa-eye"></i>
                    </Link>

                    <Link to={"/edit-student/" + rows.StudentId} className="btn btn-sm bg-success-light me-2">
                        <i className="fas fa-pen"></i>
                    </Link>
                    <Link to="#" className="btn btn-sm bg-danger-light" onClick={(e) => DeleteStudent(e, rows.id_stud, rows.invoice)}>
                        <i className="fas fa-trash"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },


    ]

    function DeleteStudent(e, id, invoice) {
        Swal.fire({
            title: 'Es-tu sûr?',
            html: "You can't go back! The student will be deleted <br> Vous ne pourrez pas revenir en arrière! l'éléve sera supprimé",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprime-le!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.remove(id, invoice)
                    .then(response => {
                        console.log(response.data);
                        GetAllStudent()
                        Swal.fire(
                            'Supprimé!',
                            'Votre fichier a été supprimé.',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }



    return (
        <>
            <Header />
            <Menus />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Students</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Scholarsapp</Link></li>
                                    <li className="breadcrumb-item active">Students</li>
                                </ul>
                            </div>
                            <div className="col-auto text-end float-end ms-auto">
                                <Link to="#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</Link>
                                <Link to="/Add-Student" className="btn btn-primary"><i className="fas fa-plus"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-table">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTableExtensions
                                            columns={columns}
                                            data={dataList}
                                            export={false}
                                            print={false}
                                        >
                                            <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                // selectableRows
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <footer>
                    <p>Copyright © 2020 Dreamguys.</p>
                </footer> */}

            </div>
        </>
    );
}

export default StudentList;