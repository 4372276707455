import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Menus from "../../components/Menus";
import services from "../../services/services";
import DataTable from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import { Link } from "react-router-dom";


function StudentSubject(){


    /** Activation modal d'insertion Student Section  */
    const [showModalStudentSubject, setShowModalStudentSubject] = useState(false)

    function ShowModalStudeSub() {
        setShowModalStudentSubject(true); //open modal

    }
    const handleCloseModalStudentSubject = () => setShowModalStudentSubject(false) ///close modal
    /** Activation modal d'insertion Student Section  */


    /** variable de recuperation des donner entrant dans les cahmp input */
   

    function ChooseClasse(e) {
        const value = e.target.value;
        window.location.replace("/student-subject/"+value)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */

    // const [dataStudeSub, setStudeSub] = useState({
    //     id_class: '',
    // })

    // const handleStudentSubject = (e) => {
    //     const value = e.target.value;
    //     const name = e.target.name;
    //     const data = { ...dataStudeSub, [name]: value }
    //     setStudeSub(data);
    //     // console.log();

    // }
    

    useEffect(() => {
        getallStudentSubject()
        getallClasseSection()
    },[])


    const [dataClasseOpen, setDataClasseOpen] = useState([]);

    function getallClasseSection() {
        services.get_ClasseSection_Open()
            .then((response) => {

                setDataClasseOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    


    const [dataStudentSubject, setDataStudentSubject] = useState([]);

    function getallStudentSubject() {
        services.getStudentSubjects()
            .then((response) => {

                setDataStudentSubject(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsStudentSubject = [
        {
            name: "Class",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.id_classe+''+rows.id_section} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Students",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.student} <button className="btn btn-info" onClick={(e) => getStudentInfo(rows.student, rows.class_subject)}>
                <i className="fa fa-eye"></i></button></b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Class Subject",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.subject} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Academic Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        
    ]


    const [showModalStudentInfo, setShowModaltStudentInfo] = useState(false)

    function ShowModalStudentInfo() {
        setShowModaltStudentInfo(true); //open modal

    }
    const handleCloseModalStudentInfo = () => setShowModaltStudentInfo(false)

    const [dataStudentInfo, setDataStudentInfo] = useState([]);

    function getStudentInfo(code, subject) {
        ShowModalStudentInfo()

        services.get_StudentInfo(code, subject)
            .then((response) => {

                setDataStudentInfo(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsStudentInfo = [
       
        {
            name: "Student",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.student}</b>
            ),
            sortable: true,
            reorder: true
        },
        // {
        //     name: "Subject",
        //     cell: (rows) => (
        //         <b style={{ color: "black" }}>{rows.subject}</b>
        //     ),
        //     sortable: true,
        //     reorder: true
        // },

    ]

    return(

        <>
            <Header/>
            <Menus/>

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Student Subject</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="">Scholarsapp / Mapping</a></li>
                                    <li className="breadcrumb-item active">Student Subject</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalStudeSub()} ><i className="fas fa-plus"></i> Add Student Subject </button>

                                                <DataTable
                                                    title="DataTable"
                                                    // customStyles={customStyles}
                                                    columns={columnsStudentSubject}
                                                    data={dataStudentSubject}
                                                    responsive
                                                    noHeader
                                                    defaultSortFieldId={1}
                                                    defaultSortField="id"
                                                    defaultSortAsc={false}
                                                    pagination
                                                    persistTableHead
                                                    highlightOnHover
                                                    exportHeaders={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* formulaire Student Subject */}

            <Modal show={showModalStudentSubject} onHide={handleCloseModalStudentSubject} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Choose classes section </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="GET">
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Select Class</label>
                                    <select name="id_class" onChange={(e) => ChooseClasse(e)} id=""  className="form-control">
                                    <option value="">Select Class</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.id_classe}{result.id_section}</option>
                                          ))
                                          }
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </form>
            </Modal>

            {/* formulaire Student Subject */}

            <Modal show={showModalStudentInfo} onHide={handleCloseModalStudentInfo} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>List Info Student</h3>
                    </Modal.Title>
                </Modal.Header>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-12">
                                   
                                        <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                columns={columnsStudentInfo}
                                                data={dataStudentInfo}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                                
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    
            </Modal>

        </>
    )

}
export default StudentSubject;