import React, { useState } from 'react'
import logo from '../../logo-white.png';
import services from '../../services/services';
import Swal from 'sweetalert2';

function Register(){

    const [dataForm, setDataForm] = useState({
        email:'',
        phone:'',
        full_name:'',
        username:'',
        password:'',
        confpassword:'',
    })


    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = {...dataForm, [name]:value}
        setDataForm(data)
        console.log(data)

    }

    const [showbtn, setShowbtn] = useState();

    function Accept(){
        setShowbtn(!showbtn)
    }

    function handleSubmit(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('email', dataForm.email);
        bodyFormData.append('phone', dataForm.phone);
        bodyFormData.append('full_name', dataForm.full_name);
        bodyFormData.append('username', dataForm.username);
        bodyFormData.append('password', dataForm.password);
        bodyFormData.append('confpassword', dataForm.confpassword);

        services.create_auth(bodyFormData).then((response) =>{
            console.log(response.data);
            sessionStorage.setItem('role_us', JSON.stringify( response.data.role))
            sessionStorage.setItem('token', JSON.stringify( response.data.token))
            setDataForm('')
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 5000
            })


            setTimeout(() => {
                window.location.replace('/admission')
              }, 4000);


        }).catch((error) => {
            console.error(error.response);
            Swal.fire({
              position: 'top-center',
              icon: 'error',
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 5000
            })
          });

    }
    
    return (
        <div className="main-wrapper login-body">
        <div className="login-wrapper">
            <div className="container">
                <div className="loginbox">
                    <div className="login-left">
                        <img className="img-fluid" src={logo} alt="Logo" />
                    </div>
                    <div className="login-right">
                        <div className="login-right-wrap">
                            <h1>Register</h1>
                            <p className="account-subtitle">Admission period 2023-2024 <br />Start: 01-04-2023 End: 31-03-2024</p>

                            <form action="/Dashboard">
                                <div className="form-group">
                                    <input className="form-control" type="text" name='email' onChange={(e) => handle(e)} placeholder="Email" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="text" name='phone' onChange={(e) => handle(e)} placeholder="Phone" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="text" name='full_name' onChange={(e) => handle(e)} placeholder="Full name" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="text" name='username' onChange={(e) => handle(e)} placeholder="Username" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="text" name='password' onChange={(e) => handle(e)} placeholder="Password" />
                                </div>

                                <div className="form-group">
                                    <input className="form-control" type="text" name='confpassword' onChange={(e) => handle(e)} placeholder="Confirm password" />
                                </div>
                                <div className="form-group">
                                    <div className="text-center forgotpass"><input type='checkbox' onClick={Accept} />&nbsp;Accept our <a href="https://iesaciv.com/ipage/regulations/terms-conditions" target='_blank'>Terms and Conditions</a></div>

                                </div>

                                <div className="form-group">
                                    {
                                        showbtn && 
                                            <>
                                                <button type="button" onClick={(e) => handleSubmit(e)} className="btn btn-info btn-block btn-lg" style={{color:'#fff', fontWeight:'600'}}>Register</button>
                                            </>
                                    }
                                </div>
                            </form>

                            {/* <div className="login-or">
                                <span className="or-line"></span>
                                <span className="span-or">or</span>
                            </div> */}

                            {/* <div className="social-login">
                                <span>Login with</span>
                                <a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a><a href="#" className="google"><i className="fab fa-google"></i></a>
                            </div> */}

                            <div className="text-center dont-have">Already have an account? <a href="/Login">Login</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  

}

export default Register
