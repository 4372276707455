import React, { useEffect, useState } from 'react';
import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link, useParams } from 'react-router-dom'
import Services from '../services/services';
import Swal from 'sweetalert2';

import iconMale from '../male.png';
import iconFemale from '../female.png';

import { Modal, Button } from 'react-bootstrap';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import axios from 'axios';

function StudentEdit() {
    const { code } = useParams()


    useEffect(() => {
        if (code)
            getStudent(code)
        getStudentPickup1(code, 'pickup1')
        getStudentPickup2(code, 'pickup2')
        getParentFather(code, 'Father')
        getParentMother(code, 'Mother')
        getParentGuardian(code, 'Guardian')

    }, [code]);


    const [dataStud, setDataStud] = useState('')
    function getStudent(code) {

        Services.get(code)
            .then((res) => {
                setDataStud(res.data[0])
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const [dataStudPickup1, setDataStudPickup1] = useState([])

    function getStudentPickup1(code, type) {
        Services.getPickupType(code, type)
            .then((res) => {
                setDataStudPickup1(res.data[0])
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    const [dataStudPickup2, setDataStudPickup2] = useState([])

    function getStudentPickup2(code, type) {
        Services.getPickupType(code, type)
            .then((res) => {
                setDataStudPickup2(res.data[0])
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const [dataParentFather, setDataParentFather] = useState([])

    function getParentFather(code, person) {
        Services.getParentsBy(code, person)
            .then((res) => {
                setDataParentFather(res.data[0])
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const [dataParentMother, setDataParentMother] = useState([])

    function getParentMother(code, person) {
        Services.getParentsBy(code, person)
            .then((res) => {
                setDataParentMother(res.data[0])
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const [dataParentGuardian, setDataParentGuardian] = useState([])

    function getParentGuardian(code, person) {
        Services.getParentsBy(code, person)
            .then((res) => {
                setDataParentGuardian(res.data[0])
                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataStudent, setDataStudent] = useState(

        {
            FirstName: '',
            LastName: '',
            Gender: '',
            DateBirth: '',
            Classroom: '',
            Section: '',
            Religion: '',
            JoiningDate: '',
            Mobile: '',
            bloodtype: '',
            name_pickup1: '',
            contact_pickup1: '',
            name_pickup2: '',
            contact_pickup2: ''
        }
    );


    const [dataFather, setDataFather] = useState({
        FatherName: '',
        FatherOccupation: '',
        FatherEmail: '',
        FatherMobile: '',
        FatherPresentAddress: '',
        FatherPermanentAddress: '',
        ResponsibleFeesFather: ''
    })

    const [dataMother, setDataMother] = useState({
        MotherName: '',
        MotherOccupation: '',
        MotherEmail: '',
        MotherMobile: '',
        MotherPresentAddress: '',
        MotherPermanentAddress: '',
        ResponsibleFeesMother: ''
    })


    const [dataGuardian, setDataGuardian] = useState({
        GuardianName: '',
        GuardianOccupation: '',
        GuardianEmail: '',
        GuardianMobile: '',
        GuardianPresentAddress: '',
        GuardianPermanentAddress: '',
        ResponsibleFeesGuardian: ''
    })



    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const newdata = { ...dataStudent, [name]: value }
        setDataStudent(newdata);
        console.log(newdata)
    }


    function handleFather(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataFather, [name]: value }
        setDataFather(data);
    }

    function handleMother(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataMother, [name]: value }
        setDataMother(data);
    }


    function handleGuardian(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataGuardian, [name]: value }
        setDataGuardian(data);
    }



    const [selectedFile, setFileStudent] = useState(null)

    const handleFileSelect = (event) => {
        setFileStudent(event.target.files[0])
        console.log(event.target.files[0])
    }

    const handlesubmitFile = async (event) => {
        event.preventDefault();

        // Create a FormData object
        const formData = new FormData();

        // Append file to the formData object here
        formData.append('id_stud', code);
        formData.append("selectedFile", selectedFile);

        try {
            // We will send formData object as a data to the API URL here.
            const response = await axios.post("http://127.0.0.1:8000/api/update_file_student", formData, {
                headers: {"Content-Type": "multipart/form-data"}
            }).then((res) => {
                alert("File Uploaded Successfully");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'File Uploaded Successfully',
                    showConfirmButton: false,
                    timer: 5000
                })
                setInterval(() => {
                    window.location.reload();
                   }, 3000);

            }).catch((error) => {
                alert("Error")
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmitData = async (event) => {
        event.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_stud', code);

        bodyFormData.append('firstName', dataStudent.FirstName);
        bodyFormData.append('lastName', dataStudent.LastName);
        bodyFormData.append('gender', dataStudent.Gender);
        bodyFormData.append('dateBirth', dataStudent.DateBirth);
        bodyFormData.append('classroom', dataStudent.Classroom);
        bodyFormData.append('section', dataStudent.Section);
        bodyFormData.append('religion', dataStudent.Religion);
        bodyFormData.append('joiningDate', dataStudent.JoiningDate);
        bodyFormData.append('mobile', dataStudent.Mobile);
        bodyFormData.append('bloodtype', dataStudent.bloodtype);

        bodyFormData.append('FatherName', dataFather.FatherName);
        bodyFormData.append('FatherOccupation', dataFather.FatherOccupation);
        bodyFormData.append('FatherEmail', dataFather.FatherEmail);
        bodyFormData.append('FatherMobile', dataFather.FatherMobile);
        bodyFormData.append('FatherPresentAddress', dataFather.FatherPresentAddress);
        bodyFormData.append('FatherPermanentAddress', dataFather.FatherPermanentAddress);
        bodyFormData.append('ResponsibleFeesFather', dataFather.ResponsibleFeesFather);


        bodyFormData.append('MotherName', dataMother.MotherName);
        bodyFormData.append('MotherOccupation', dataMother.MotherOccupation);
        bodyFormData.append('MotherEmail', dataMother.MotherEmail);
        bodyFormData.append('MotherMobile', dataMother.MotherMobile);
        bodyFormData.append('MotherPresentAddress', dataMother.MotherPresentAddress);
        bodyFormData.append('MotherPermanentAddress', dataMother.MotherPermanentAddress);
        bodyFormData.append('ResponsibleFeesMother', dataMother.ResponsibleFeesMother);

        bodyFormData.append('GuardianName', dataGuardian.GuardianName);
        bodyFormData.append('GuardianOccupation', dataGuardian.GuardianOccupation);
        bodyFormData.append('GuardianEmail', dataGuardian.GuardianEmail);
        bodyFormData.append('GuardianMobile', dataGuardian.GuardianMobile);
        bodyFormData.append('GuardianPresentAddress', dataGuardian.GuardianPresentAddress);
        bodyFormData.append('GuardianPermanentAddress', dataGuardian.GuardianPermanentAddress);
        bodyFormData.append('ResponsibleFeesGuardian', dataGuardian.ResponsibleFeesGuardian);

        bodyFormData.append('name_pickup1', dataStudent.name_pickup1);
        bodyFormData.append('contact_pickup1', dataStudent.contact_pickup1);
        bodyFormData.append('name_pickup2', dataStudent.name_pickup2);
        bodyFormData.append('contact_pickup2', dataStudent.contact_pickup2);


        //    console.log(fileStudent)

        Services.updateStud(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
               setInterval(() => {
                window.location.reload();
               }, 3000);

            })
            .catch((error) => {
                console.log(error.response);
            })


        // const requestOptions = {
        //     method: "POST",
        //     headers: {
        //         'accept': 'multipart/form-data'
        //     },
        //     body: bodyFormData
        // }

        // const response = await fetch("https://scholars-app.iesaciv.com/api/student", requestOptions)
        // await response.json()
        // .then(res => {
        //     console.log(res)
        // })
        // .catch(error => {
        //     console.log(error)
        // })



    }

    // useEffect(() => {
    //     ListeParents()
    // }, [])

    const [showModal, setShowModal] = useState(false);
    
    function ShowModalParents() {
        setShowModal(true);
        ListeParents()

    }
    const handleCloseModal = () => setShowModal(false)



   

    const [listParents, setListParents] = useState('')

    function ListeParents() {

        Services.getListParents()
            .then((response) => {
                setListParents(response.data)
                console.log(response.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }



    const columnsParents = [
        {
            name: "Persone",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Persone} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Name} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Mobile",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.Mobile} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">


                    <button type='button' onClick={() => AddParent(rows.id_parents, code)} className="btn btn-sm btn-lg bg-success-light me-2">
                        <i className="fas fa-plus"></i>
                    </button>

                </div>


            ),
            sortable: true,
            reorder: true
        },
    ]



    const AddParent = (id, code) => {
        Services.InsertParent(id, code)
            .then((response) => {

                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })



                console.log(response.data)

                setTimeout(() => {
                    window.location.reload();
                }, 3000);


            })
            .catch((error) => {
                console.log(error.response);
            })
    }



    function EmptyParents(id, code) {
        Swal.fire({
            title: 'Es-tu sûr?',
            html: "You can't go back! The parent will be deleted <br> Vous ne pourrez pas revenir en arrière! le parent sera supprimé",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprime-le!'
        }).then((result) => {
            if (result.isConfirmed) {
                Services.removeParents(id, code)
                    .then(response => {
                        console.log(response.data);
                        Swal.fire(
                            'Supprimé!',
                            'Votre fichier a été supprimé.',
                            'success'
                        )

                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);

                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }

    return (
        <>
            <Header />
            <Menus />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Edit Students</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Student-List">Students</Link></li>
                                    <li className="breadcrumb-item active">Edit Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className='card-header'>

                                    {(() => {
                                        if (dataStud.StudentImage === null) {

                                            if (dataStud.Gender === 'Male') {
                                                return (
                                                    <img src={iconMale} className="me-3 flex-shrink-0" style={{ width: '150px', height: '150px' }} alt="..." />
                                                )

                                            } else {
                                                return (
                                                    <img src={iconFemale} className="me-3 flex-shrink-0" style={{ width: '150px', height: '150px' }} alt="..." />
                                                )

                                            }

                                        } else {
                                            return(
                                            <img 
                                              className="avatar-img rounded-circle" 
                                              src={"http://localhost/eSchool/storage/app/public/profileStudents/" + dataStud.StudentImage} 
                                              alt="UserImage"
                                              style={{width:'200px'}}
                                               />

                                            )
                                        }
                                    })()}
                                    <form onSubmit={(e) => handlesubmitFile(e)}>
                                        <div className='col-12 col-sm-3 mt-3'>
                                            <input
                                                type='file'
                                                className="form-control"
                                                name="file"
                                                onChange={handleFileSelect}
                                            />
                                            <button type='submit' className='btn btn-info mt-2'>Update photo <i className='fa fa-download'></i> </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={(e) => handleSubmitData(e)} id="formStudent">
                                        <div className="row">
                                            <div className="col-12">
                                                <h5 className="form-title"><span>Student Information</span></h5>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='FirstName'
                                                        onChange={(e) => handle(e)}
                                                        defaultValue={dataStud.FirstName}
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='LastName'
                                                        onChange={(e) => handle(e)}
                                                        defaultValue={dataStud.LastName}
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Gender</label>
                                                    <select className="form-control select" name='Gender' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                        <option defaultValue={dataStud.Gender} >{dataStud.Gender}</option>
                                                        <option defaultValue="Female">Female</option>
                                                        <option defaultValue="Male">Male</option>
                                                        <option defaultValue="Others">Others</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Date of Birth</label>
                                                    <div>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name='DateBirth'
                                                            onChange={(e) => handle(e)}
                                                            defaultValue={dataStud.DateofBirth}
                                                            style={{ fontWeight: 'bold' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Class</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Classroom'
                                                        onChange={(e) => handle(e)}
                                                        defaultValue={dataStud.Class}
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Section</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Section'
                                                        onChange={(e) => handle(e)}
                                                        defaultValue={dataStud.Section}
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Joining Date</label>
                                                    <div>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name='JoiningDate'
                                                            onChange={(e) => handle(e)}
                                                            defaultValue={dataStud.JoiningDate}
                                                            style={{ fontWeight: 'bold' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Religion</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Religion'
                                                        onChange={(e) => handle(e)}
                                                        defaultValue={dataStud.Religion}
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Mobile Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Mobile'
                                                        onChange={(e) => handle(e)}
                                                        defaultValue={dataStud.MobileNumber}
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>Blood type</label>
                                                    <select className="form-control select" name='bloodtype' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                        <option defaultValue={dataStud.Bloodtype}>{dataStud.Bloodtype}</option>
                                                        <option defaultValue="A+">A+</option>
                                                        <option defaultValue="A-">A-</option>
                                                        <option defaultValue="B+">B+</option>
                                                        <option defaultValue="B-">B-</option>
                                                        <option defaultValue="AB+">AB+</option>
                                                        <option defaultValue="AB-">AB-</option>
                                                        <option defaultValue="O+">O+</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <h5 className="form-title"><span>Pickup Child Information</span></h5>
                                            </div>

                                            {(() => {
                                                if (dataStudPickup1) {
                                                    return (
                                                        <>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Name 1</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name='name_pickup1'
                                                                        onChange={(e) => handle(e)}
                                                                        defaultValue={dataStudPickup1.name_pickup}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Contact 1</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name='contact_pickup1'
                                                                        onChange={(e) => handle(e)}
                                                                        defaultValue={dataStudPickup1.contact_pickup}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    />
                                                                </div>
                                                            </div></>
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Name 1</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name='name_pickup1'
                                                                        onChange={(e) => handle(e)}
                                                                        value={dataStudent.name_pickup1}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Contact 1</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name='contact_pickup1'
                                                                        onChange={(e) => handle(e)}
                                                                        value={dataStudent.contact_pickup1}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })()}



                                            {(() => {
                                                if (dataStudPickup2) {
                                                    return (
                                                        <>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Name 2</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name='name_pickup2'
                                                                        onChange={(e) => handle(e)}
                                                                        defaultValue={dataStudPickup2.name_pickup}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Contact 2</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name='contact_pickup2'
                                                                        onChange={(e) => handle(e)}
                                                                        defaultValue={dataStudPickup2.contact_pickup}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    />
                                                                </div>
                                                            </div></>
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Name 2</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name='name_pickup2'
                                                                        onChange={(e) => handle(e)}
                                                                        value={dataStudent.name_pickup2}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Contact 2</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name='contact_pickup2'
                                                                        onChange={(e) => handle(e)}
                                                                        value={dataStudent.contact_pickup2}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })()}



                                            <div className="col-12">
                                                <h5 className="form-title"><span>Parent Information</span></h5>
                                                <h4 className='mb-4'>Add a parent from the database <button type='button' onClick={() => ShowModalParents()} className='btn btn-sm bg-success-light me-2'><i className='fa fa-plus'></i></button> </h4>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">

                                                    {(() => {
                                                        if (dataParentFather) {


                                                            return (
                                                                <div className="col-4">
                                                                    <h5 className='mb-4' style={{ color: 'red' }}>Empty Father <button type='button' onClick={() => EmptyParents(dataParentFather.id_parents, dataParentFather.IdStudents)} className='btn btn-danger btn-sm me-2'><i className='fa fa-trash'></i></button> </h5>

                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Name</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='FatherName'
                                                                                onChange={(e) => handleFather(e)}
                                                                                defaultValue={dataParentFather.Name}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Occupation</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='FatherOccupation'
                                                                                onChange={(e) => handleFather(e)}
                                                                                defaultValue={dataParentFather.Occupation}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Mobile</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='FatherMobile'
                                                                                onChange={(e) => handleFather(e)}
                                                                                defaultValue={dataParentFather.Mobile}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Email</label>
                                                                            <input
                                                                                type="email"
                                                                                className="form-control"
                                                                                name='FatherEmail'
                                                                                onChange={(e) => handleFather(e)}
                                                                                defaultValue={dataParentFather.Email}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Present Address</label>
                                                                            <textarea className="form-control" name='FatherPresentAddress' style={{ fontWeight: 'bold' }} onChange={(e) => handleFather(e)} defaultValue={dataParentFather.PresentAddress}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Permanent Address</label>
                                                                            <textarea className="form-control" name='FatherPermanentAddress' style={{ fontWeight: 'bold' }} onChange={(e) => handleFather(e)} defaultValue={dataParentFather.PermanentAddress}></textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label>Responsible of school fees</label>
                                                                            <select className="form-control select" name='ResponsibleFeesFather' style={{ fontWeight: 'bold' }} onChange={(e) => handleFather(e)}>
                                                                                <option defaultValue={dataParentFather.Responsible_fees}>{dataParentFather.Responsible_fees}</option>
                                                                                <option defaultValue="No">No</option>
                                                                                <option defaultValue="Yes">Yes</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )


                                                        } else {

                                                            return (
                                                                <div className="col-4">

                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Name</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='FatherName'
                                                                                onChange={(e) => handleFather(e)}
                                                                                value={dataFather.FatherName}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Occupation</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='FatherOccupation'
                                                                                onChange={(e) => handleFather(e)}
                                                                                value={dataFather.FatherOccupation}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Mobile</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='FatherMobile'
                                                                                onChange={(e) => handleFather(e)}
                                                                                value={dataFather.FatherMobile}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Email</label>
                                                                            <input
                                                                                type="email"
                                                                                className="form-control"
                                                                                name='FatherEmail'
                                                                                onChange={(e) => handleFather(e)}
                                                                                value={dataFather.FatherEmail}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Present Address</label>
                                                                            <textarea className="form-control" name='FatherPresentAddress' style={{ fontWeight: 'bold' }} onChange={(e) => handleFather(e)} value={dataFather.FatherPresentAddress}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Father's Permanent Address</label>
                                                                            <textarea className="form-control" name='FatherPermanentAddress' style={{ fontWeight: 'bold' }} onChange={(e) => handleFather(e)} value={dataFather.FatherPermanentAddress}></textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label>Responsible of school fees</label>
                                                                            <select className="form-control select" name='ResponsibleFeesFather' style={{ fontWeight: 'bold' }} onChange={(e) => handleFather(e)}>
                                                                                <option value="No">No</option>
                                                                                <option value="Yes">Yes</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }
                                                    })()}









                                                    {(() => {

                                                        if (dataParentMother) {
                                                            return (
                                                                <div className="col-4">
                                                                    <h5 className='mb-4' style={{ color: 'red' }}>Empty Mother <button type='button' onClick={() => EmptyParents(dataParentMother.id_parents, dataParentMother.IdStudents)} className='btn btn-danger btn-sm me-2'><i className='fa fa-trash'></i></button> </h5>

                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Name</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='MotherName'
                                                                                onChange={(e) => handleMother(e)}
                                                                                defaultValue={dataParentMother.Name}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Occupation</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='MotherOccupation'
                                                                                onChange={(e) => handleMother(e)}
                                                                                defaultValue={dataParentMother.Occupation}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Mobile</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='MotherMobile'
                                                                                onChange={(e) => handleMother(e)}
                                                                                defaultValue={dataParentMother.Mobile}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Email</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='MotherEmail'
                                                                                onChange={(e) => handleMother(e)}
                                                                                defaultValue={dataParentMother.Email}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Present Address</label>
                                                                            <textarea className="form-control" name='MotherPresentAddress' onChange={(e) => handleMother(e)} style={{ fontWeight: 'bold' }} value={dataParentMother.PresentAddress}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Permanent Address</label>
                                                                            <textarea className="form-control" name='MotherPermanentAddress' onChange={(e) => handleMother(e)} style={{ fontWeight: 'bold' }} value={dataParentMother.PermanentAddress}></textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label>Responsible of school fees</label>
                                                                            <select className="form-control select" name='ResponsibleFeesMother' style={{ fontWeight: 'bold' }} onChange={(e) => handleMother(e)}>
                                                                                <option defaultValue={dataParentMother.Responsible_fees}>{dataParentMother.Responsible_fees}</option>
                                                                                <option defaultValue="No">No</option>
                                                                                <option defaultValue="Yes">Yes</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )



                                                        } else {
                                                            return (
                                                                <div className="col-4">

                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Name</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='MotherName'
                                                                                onChange={(e) => handleMother(e)}
                                                                                value={dataMother.MotherName}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Occupation</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='MotherOccupation'
                                                                                onChange={(e) => handleMother(e)}
                                                                                value={dataMother.MotherOccupation}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Mobile</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='MotherMobile'
                                                                                onChange={(e) => handleMother(e)}
                                                                                value={dataMother.MotherMobile}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Email</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='MotherEmail'
                                                                                onChange={(e) => handleMother(e)}
                                                                                value={dataMother.MotherEmail}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Present Address</label>
                                                                            <textarea className="form-control" name='MotherPresentAddress' onChange={(e) => handleMother(e)} style={{ fontWeight: 'bold' }} value={dataMother.MotherPresentAddress}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mother's Permanent Address</label>
                                                                            <textarea className="form-control" name='MotherPermanentAddress' onChange={(e) => handleMother(e)} style={{ fontWeight: 'bold' }} value={dataMother.MotherPermanentAddress}></textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label>Responsible of school fees</label>
                                                                            <select className="form-control select" name='ResponsibleFeesMother' style={{ fontWeight: 'bold' }} onChange={(e) => handleMother(e)}>
                                                                                <option value="No">No</option>
                                                                                <option value="Yes">Yes</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }
                                                    })()}







                                                    {(() => {
                                                        if (dataParentGuardian) {

                                                            return (
                                                                <div className="col-4">
                                                                    <h5 className='mb-4' style={{ color: 'red' }}>Empty Guardian <button type='button' onClick={() => EmptyParents(dataParentGuardian.id_parents, dataParentGuardian.IdStudents)} className='btn btn-danger btn-sm me-2'><i className='fa fa-trash'></i></button> </h5>

                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Name</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='GuardianName'
                                                                                onChange={(e) => handleGuardian(e)}
                                                                                defaultValue={dataParentGuardian.Name}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Occupation</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='GuardianOccupation'
                                                                                onChange={(e) => handleGuardian(e)}
                                                                                defaultValue={dataParentGuardian.Occupation}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Mobile</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='GuardianMobile'
                                                                                onChange={(e) => handleGuardian(e)}
                                                                                defaultValue={dataParentGuardian.Mobile}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Email</label>
                                                                            <input
                                                                                type="email"
                                                                                className="form-control"
                                                                                name='GuardianEmail'
                                                                                onChange={(e) => handleGuardian(e)}
                                                                                defaultValue={dataParentGuardian.Email}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Present Address</label>
                                                                            <textarea className="form-control" name='GuardianPresentAddress' onChange={(e) => handleGuardian(e)} style={{ fontWeight: 'bold' }} defaultValue={dataParentGuardian.PresentAddress}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Permanent Address</label>
                                                                            <textarea className="form-control" name='GuardianPermanentAddress' onChange={(e) => handleGuardian(e)} style={{ fontWeight: 'bold' }} defaultValue={dataParentGuardian.PermanentAddress}></textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label>Responsible of school fees</label>
                                                                            <select className="form-control select" name='ResponsibleFeesGuardian' style={{ fontWeight: 'bold' }} onChange={(e) => handleGuardian(e)}>
                                                                                <option defaultValue={dataParentGuardian.Responsible_fees}>{dataParentGuardian.Responsible_fees}</option>
                                                                                <option defaultValue="No">No</option>
                                                                                <option defaultValue="Yes">Yes</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )



                                                        } else {




                                                            return (
                                                                <div className="col-4">

                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Name</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='GuardianName'
                                                                                onChange={(e) => handleGuardian(e)}
                                                                                value={dataGuardian.GuardianName}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Occupation</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='GuardianOccupation'
                                                                                onChange={(e) => handleGuardian(e)}
                                                                                value={dataGuardian.GuardianOccupation}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Mobile</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name='GuardianMobile'
                                                                                onChange={(e) => handleGuardian(e)}
                                                                                value={dataGuardian.GuardianMobile}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Email</label>
                                                                            <input
                                                                                type="email"
                                                                                className="form-control"
                                                                                name='GuardianEmail'
                                                                                onChange={(e) => handleGuardian(e)}
                                                                                value={dataGuardian.GuardianEmail}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Present Address</label>
                                                                            <textarea className="form-control" name='GuardianPresentAddress' style={{ fontWeight: 'bold' }} onChange={(e) => handleGuardian(e)} value={dataGuardian.GuardianPresentAddress}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Guardian's Permanent Address</label>
                                                                            <textarea className="form-control" name='GuardianPermanentAddress' style={{ fontWeight: 'bold' }} onChange={(e) => handleGuardian(e)} value={dataGuardian.GuardianPermanentAddress}></textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group">
                                                                            <label>Responsible of school fees</label>
                                                                            <select className="form-control select" name='ResponsibleFeesGuardian' style={{ fontWeight: 'bold' }} onChange={(e) => handleGuardian(e)}>
                                                                                <option value="No">No</option>
                                                                                <option value="Yes">Yes</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )

                                                        }
                                                    })()}



                                                </div>
                                            </div>


                                            <div className="col-12 mt-5">
                                                <button type="submit" className="btn btn-warning btn-lg">Update Information</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={showModal} onHide={handleCloseModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1 style={{ color: 'orange' }}>List Parents DB </h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <DataTableExtensions
                            columns={columnsParents}
                            data={listParents}
                            export={false}
                            print={false}
                        >
                            <DataTable

                                title="DataTable"
                                responsive
                                noHeader
                                defaultSortFieldId={1}
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                // selectableRows
                                persistTableHead
                                highlightOnHover
                                exportHeaders={false}
                            />
                        </DataTableExtensions>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={handleCloseModal} variant='secondary'>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StudentEdit;