import React, { useEffect, useState } from "react";
import Menus from "../../components/Menus";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import services from "../../services/services";
import Swal from "sweetalert2";


function GlobaParameters() {

    /////////////////////////////////////////////////////////////////////////////MAIN SETTING//////////////////////////////////////////

    /** Activation modal d'insertion main setting */
    const [showModalMainSetting, setShowModalMainSetting] = useState(false)

    function ShowModalMain() {
        setShowModalMainSetting(true); //open modal

    }
    const handleCloseModalMainSetting = () => setShowModalMainSetting(false) ///close modal
    /** Activation modal d'insertion main setting */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataMain, setMain] = useState({
        wording: '',
        description: '',
    })

    const handleMainsetting = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataMain, [name]: value }
        setMain(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitMainSetting(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('wording', dataMain.wording);
        bodyFormData.append('description', dataMain.description);

        services.InsertMainSetting(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                handleCloseModalMainSetting();
                getallMainSetting();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallMainSetting()
    }, [])


    const [dataMainSetting, setDataMainSetting] = useState([]);

    function getallMainSetting() {
        services.getMainSetting()
            .then((response) => {

                setDataMainSetting(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsMainSetting = [
        {
            name: "Wording",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.wording} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdate(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteMainSetting(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteMainSetting(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data main setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteMainSetting(id)
                    .then(response => {
                        console.log(response.data);
                        getallMainSetting()
                        Swal.fire(
                            'Data main setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditMainSetting, setShowEditModalMainSetting] = useState(false)

    function ShowModalUpdate(id) {
        setShowEditModalMainSetting(true);
        dataMainEdit(id)

    }
    const handleEditCloseModalMainSetting = () => setShowEditModalMainSetting(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditMain, setDataEditMain] = useState([])

    function dataMainEdit(id) {
        services.get_main_by(id)
            .then((response) => {

                setDataEditMain(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateMainSetting(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_main', id);
        bodyFormData.append('wording', dataMain.wording);
        bodyFormData.append('description', dataMain.description);

        services.updateMainSetting(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallMainSetting()
                handleEditCloseModalMainSetting()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    /////////////////////////////////////////////////////////////////////////////MAIN SETTING//////////////////////////////////////////



    ///////////////////////////////////////////////////////////////////////////// Countries //////////////////////////////////////////

    /** Activation modal d'insertion conutries */
    const [showModalCountries, setShowModalCountries] = useState(false)

    function ShowModalCount() {
        setShowModalCountries(true); //open modal

    }
    const handleCloseModalCountries = () => setShowModalCountries(false) ///close modal
    /** Activation modal d'insertion conutries */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataCount, setCount] = useState({
        libelle_english: '',
        libelle_french: '',
    })

    const handleCountries = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataCount, [name]: value }
        setCount(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitCountries(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle_english', dataCount.libelle_english);
        bodyFormData.append('libelle_french', dataCount.libelle_french);

        services.InsertCountrie(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallCountries();
                handleCloseModalCountries();


            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallCountries()
    }, [])


    const [dataCountries, setDataCountries] = useState([]);

    function getallCountries() {
        services.getCountrie()
            .then((response) => {

                setDataCountries(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }



    const columnsCountries = [
        {
            name: "libelle_english",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle_english} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "libelle_french",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle_french} </b>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateCount(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteCountries(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteCountries(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Countries",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteCountrie(id)
                    .then(response => {
                        console.log(response.data);
                        getallCountries()
                        Swal.fire(
                            'Data Countries has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditCountries, setShowEditModalCountries] = useState(false)

    function ShowModalUpdateCount(id) {
        setShowEditModalCountries(true);
        dataCountEdit(id)

    }
    const handleEditCloseModalCountries = () => setShowEditModalCountries(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditCount, setDataEditCount] = useState([])

    function dataCountEdit(id) {
        services.get_countrie_by(id)
            .then((response) => {

                setDataEditCount(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateCountries(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_count', id);
        bodyFormData.append('libelle_english', dataCount.libelle_english);
        bodyFormData.append('libelle_french', dataCount.libelle_french);

        services.updateCountrie(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallCountries()
                handleEditCloseModalCountries()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    //////////////////////////////////////////////////////// Countries //////////////////////////////////////////////////


    /////////////////////////////////////////////////////// Notionalities ///////////////////////////////////////////////

    /** Activation modal d'insertion nationalities */
    const [showModalNationalities, setShowModalNationalities] = useState(false)

    function ShowModalNation() {
        setShowModalNationalities(true); //open modal

    }
    const handleCloseModalNation = () => setShowModalNationalities(false) ///close modal
    /** Activation modal d'insertion conutries */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataNation, setNation] = useState({
        libelle: '',
    })

    const handleNation = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataNation, [name]: value }
        setNation(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitNation(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataNation.libelle);

        services.InsertNation(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallNation();
                handleCloseModalNation();


            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallNation()
    }, [])


    const [dataNationalities, setDataNation] = useState([]);

    function getallNation() {
        services.getNation()
            .then((response) => {

                setDataNation(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsNation = [
        {
            name: "libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateNation(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteNation(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteNation(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Nation",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteNation(id)
                    .then(response => {
                        console.log(response.data);
                        getallNation()
                        Swal.fire(
                            'Data Nation has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE //////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditNation, setShowEditModalNation] = useState(false)

    function ShowModalUpdateNation(id) {
        setShowEditModalNation(true);
        dataNationEdit(id)

    }
    const handleEditCloseModalNation = () => setShowEditModalNation(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditNation, setDataEditNation] = useState([])

    function dataNationEdit(id) {
        services.get_Nation_by(id)
            .then((response) => {

                setDataEditNation(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateNation(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_nat', id);
        bodyFormData.append('libelle', dataNation.libelle);

        services.updateNation(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallNation()
                handleEditCloseModalNation()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /////////////////////////////////////////////////////// Notionalities ////////////////////////////////////////

    /////////////////////////////////////////////////////// Users_Groups //////////////////////////////////////////

    /** Activation modal d'insertion Users-Groups */
    const [showModalUsersGroups, setShowModalUsersGroups] = useState(false)

    function ShowModalGroup() {
        setShowModalUsersGroups(true); //open modal

    }
    const handleCloseModalUsersGroups = () => setShowModalUsersGroups(false) ///close modal
    /** Activation modal d'insertion conutries */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataGroup, setGroup] = useState({
        libelle: '',
        description: '',
    })

    const handleUsersGroups = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataGroup, [name]: value }
        setGroup(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitUsersGroups(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataGroup.libelle);
        bodyFormData.append('description', dataGroup.description);


        services.InsertUsersGroups(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallUsersGroups();
                handleCloseModalUsersGroups();


            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallUsersGroups()
    }, [])


    const [dataUsersGroups, setDataUsersGroups] = useState([]);

    function getallUsersGroups() {
        services.getUsersGroups()
            .then((response) => {

                setDataUsersGroups(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }



    const columnsUsersGroups = [
        {
            name: "libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateGroup(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteUsersGroups(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */



    /** Suppression un élement dans la base de donée */

    function DeleteUsersGroups(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data UsersGroups",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteUsersGroups(id)
                    .then(response => {
                        console.log(response.data);
                        getallUsersGroups()
                        Swal.fire(
                            'Data UsersGroups has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditUsersGroups, setShowEditModalUsersGroups] = useState(false)

    function ShowModalUpdateGroup(id) {
        setShowEditModalUsersGroups(true);
        dataGroupEdit(id)

    }
    const handleEditCloseModalUsersGroups = () => setShowEditModalUsersGroups(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditGroup, setDataEditGroup] = useState([])

    function dataGroupEdit(id) {
        services.get_UsersGroups_by(id)
            .then((response) => {

                setDataEditGroup(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */



    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateUsersGroups(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_group', id);
        bodyFormData.append('libelle', dataGroup.libelle);
        bodyFormData.append('description', dataGroup.description);

        services.updateUsersGroups(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallUsersGroups()
                handleEditCloseModalUsersGroups()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    //////////////////////////////////////////////////////// Users Groups //////////////////////////////////////////////////


    /////////////////////////////////////////////////////// Relationship ///////////////////////////////////////////////

    /** Activation modal d'insertion Relationship */
    const [showModalRelation, setShowModalRelation] = useState(false)

    function ShowModalRelation() {
        setShowModalRelation(true); //open modal

    }
    const handleCloseModalRelation = () => setShowModalRelation(false) ///close modal
    /** Activation modal d'insertion conutries */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataRelation, setRelation] = useState({
        libelle: '',
    })

    const handleRelation = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataRelation, [name]: value }
        setRelation(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitRelation(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataRelation.libelle);

        services.InsertRelation(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallRelation();
                handleCloseModalRelation();


            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallRelation()
    }, [])


    const [dataRelationship, setDataRelation] = useState([]);

    function getallRelation() {
        services.getRelation()
            .then((response) => {

                setDataRelation(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const columnsRelation = [
        {
            name: "libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateRelation(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteRelation(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteRelation(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Relationship",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteRelation(id)
                    .then(response => {
                        console.log(response.data);
                        getallRelation()
                        Swal.fire(
                            'Data Relationship has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE //////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditRelation, setShowEditModalRelation] = useState(false)

    function ShowModalUpdateRelation(id) {
        setShowEditModalRelation(true);
        dataRelationEdit(id)

    }
    const handleEditCloseModalRelation = () => setShowEditModalRelation(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditRelation, setDataEditRelation] = useState([])

    function dataRelationEdit(id) {
        services.get_Relation_by(id)
            .then((response) => {

                setDataEditRelation(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateRelation(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_rela', id);
        bodyFormData.append('libelle', dataRelation.libelle);

        services.updateRelation(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallRelation()
                handleEditCloseModalRelation()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /////////////////////////////////////////////////////// Relationship ////////////////////////////////////////



    //////////////////////////////////////////////////////// Jobs Description ////////////////////////////////////

    /** Activation modal d'insertion Jobs Description */
    const [showModalJob, setShowModalJob] = useState(false)

    function ShowModalJob() {
        setShowModalJob(true); //open modal

    }
    const handleCloseModalJob = () => setShowModalJob(false) ///close modal
    /** Activation modal d'insertion conutries */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataJob, setJob] = useState({
        job_english: '',
        job_french: '',
    })

    const handleJob = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataJob, [name]: value }
        setJob(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitJob(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('job_english', dataJob.job_english);
        bodyFormData.append('job_french', dataJob.job_french);

        services.InsertJob(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallJob();
                handleCloseModalJob();


            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallJob()
    }, [])


    const [dataJobs, setDataJob] = useState([]);

    function getallJob() {
        services.getJob()
            .then((response) => {

                setDataJob(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }



    const columnsJob = [
        {
            name: "job_english",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.job_english} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "job_french",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.job_french} </b>
            ),
            sortable: true,
            reorder: true
        },



        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateJob(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteJob(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteJob(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Job",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteJob(id)
                    .then(response => {
                        console.log(response.data);
                        getallJob()
                        Swal.fire(
                            'Data Job has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditJob, setShowEditModalJob] = useState(false)

    function ShowModalUpdateJob(id) {
        setShowEditModalJob(true);
        dataJobEdit(id)

    }
    const handleEditCloseModalJob = () => setShowEditModalJob(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditJob, setDataEditJob] = useState([])

    function dataJobEdit(id) {
        services.get_Job_by(id)
            .then((response) => {

                setDataEditJob(response.data[0])
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateJob(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_job', id);
        bodyFormData.append('job_english', dataJob.job_english);
        bodyFormData.append('job_french', dataJob.job_french);

        services.updateJob(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(response.data)
                getallJob()
                handleEditCloseModalJob()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    //////////////////////////////////////////////////////// Jobs Description //////////////////////////////////////////////////

    return (
        <>
            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Gloabal Parameters</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Setting</a></li>
                                    <li className="breadcrumb-item active">Gloabal Parameters</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="card bg-white">
                                <div className="card-header">
                                    <h5 className="card-title">Gloabal Parameters</h5>
                                </div>
                                <div className="card-body">
                                    <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                        <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab1" data-bs-toggle="tab">Main Settings</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab2" data-bs-toggle="tab">Countries</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab3" data-bs-toggle="tab">Nationalities</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4" data-bs-toggle="tab">Users Groups</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5" data-bs-toggle="tab">Relationship</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab6" data-bs-toggle="tab">Jobs Description</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="solid-justified-tab1">

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalMain()} ><i className="fas fa-plus"></i> Main setting</button>


                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsMainSetting}
                                                                    data={dataMainSetting}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab2">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalCount()} ><i className="fas fa-plus"></i> Countries </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsCountries}
                                                                    data={dataCountries}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab3">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalNation()} ><i className="fas fa-plus"></i> Nationalities </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsNation}
                                                                    data={dataNationalities}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab4">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalGroup()} ><i className="fas fa-plus"></i> Users Group </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsUsersGroups}
                                                                    data={dataUsersGroups}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab5">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalRelation()} ><i className="fas fa-plus"></i> Relationship </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsRelation}
                                                                    data={dataRelationship}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab6">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalJob()} ><i className="fas fa-plus"></i> Jobs Description </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsJob}
                                                                    data={dataJobs}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* formulaire Main Setting */}
            <Modal show={showModalMainSetting} onHide={handleCloseModalMainSetting} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Main Setting Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitMainSetting(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Wording</label>
                                    <input type="text" name="wording" onChange={(e) => handleMainsetting(e)} placeholder="wording..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">description</label>
                                    <input type="text" name="description" onChange={(e) => handleMainsetting(e)} placeholder="description..." className="form-control" />

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditMainSetting} onHide={handleEditCloseModalMainSetting} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Main Setting Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateMainSetting(e, dataEditMain.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Wording</label>
                                    <input type="text" name="wording" defaultValue={dataEditMain.wording} onChange={(e) => handleMainsetting(e)} placeholder="wording..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">description</label>
                                    <input type="text" name="description" defaultValue={dataEditMain.description} onChange={(e) => handleMainsetting(e)} placeholder="description..." className="form-control" />

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Main Setting */}


            {/* formulaire Countries */}
            <Modal show={showModalCountries} onHide={handleCloseModalCountries} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Countrie Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitCountries(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle_english</label>
                                    <input type="text" name="libelle_english" onChange={(e) => handleCountries(e)} placeholder="libelle_english..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle_french</label>
                                    <input type="text" name="libelle_french" onChange={(e) => handleCountries(e)} placeholder="libelle_french..." className="form-control" />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditCountries} onHide={handleEditCloseModalCountries} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Countrie Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateCountries(e, dataEditCount.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle_english</label>
                                    <input type="text" name="libelle_english" defaultValue={dataEditCount.libelle_english} onChange={(e) => handleCountries(e)} placeholder="libelle_english..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle_french</label>
                                    <input type="text" name="libelle_french" defaultValue={dataEditCount.libelle_french} onChange={(e) => handleCountries(e)} placeholder="libelle_french..." className="form-control" />

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Countries */}


            {/* formulaire Nationalities */}
            <Modal show={showModalNationalities} onHide={handleCloseModalNation} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Nationalities Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitNation(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle</label>
                                    <input type="text" name="libelle" onChange={(e) => handleNation(e)} placeholder="libelle..." className="form-control" />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditNation} onHide={handleEditCloseModalNation} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Nationalities Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateNation(e, dataEditNation.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle</label>
                                    <input type="text" name="libelle" defaultValue={dataEditNation.libelle} onChange={(e) => handleNation(e)} placeholder="libelle..." className="form-control" />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Nationalities */}


            {/* formulaire UsersGroup */}
            <Modal show={showModalUsersGroups} onHide={handleCloseModalUsersGroups} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add UsersGroup Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitUsersGroups(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle</label>
                                    <input type="text" name="libelle" onChange={(e) => handleUsersGroups(e)} placeholder="libelle..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">description</label>
                                    <input type="text" name="description" onChange={(e) => handleUsersGroups(e)} placeholder="description..." className="form-control" />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditUsersGroups} onHide={handleEditCloseModalUsersGroups} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update UsersGroup Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateUsersGroups(e, dataEditGroup.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle</label>
                                    <input type="text" name="libelle" defaultValue={dataEditGroup.libelle} onChange={(e) => handleUsersGroups(e)} placeholder="libelle..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">description</label>
                                    <input type="text" name="description" defaultValue={dataEditGroup.description} onChange={(e) => handleUsersGroups(e)} placeholder="description..." className="form-control" />

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire UsersGroup */}


            {/* formulaire Relationship */}
            <Modal show={showModalRelation} onHide={handleCloseModalRelation} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Nationalities Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitRelation(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle</label>
                                    <input type="text" name="libelle" onChange={(e) => handleRelation(e)} placeholder="libelle..." className="form-control" />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditRelation} onHide={handleEditCloseModalRelation} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Relationship Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateRelation(e, dataEditRelation.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">libelle</label>
                                    <input type="text" name="libelle" defaultValue={dataEditRelation.libelle} onChange={(e) => handleRelation(e)} placeholder="libelle..." className="form-control" />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Relationship */}



            {/* formulaire Jobs description */}
            <Modal show={showModalJob} onHide={handleCloseModalJob} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Jobs Description Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitJob(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">job_english</label>
                                    <input type="text" name="job_english" onChange={(e) => handleJob(e)} placeholder="job_english..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">job_french</label>
                                    <input type="text" name="job_french" onChange={(e) => handleJob(e)} placeholder="job_french..." className="form-control" />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditJob} onHide={handleEditCloseModalJob} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Jobs Description Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateJob(e, dataEditJob.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">job_english</label>
                                    <input type="text" name="job_english" defaultValue={dataEditJob.job_english} onChange={(e) => handleJob(e)} placeholder="job_english..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">job_french</label>
                                    <input type="text" name="job_french" defaultValue={dataEditJob.job_french} onChange={(e) => handleJob(e)} placeholder="job_french..." className="form-control" />

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Jobs description */}
        </>
    )
}

export default GlobaParameters;