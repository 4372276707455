import React, { useEffect, useState } from "react";
import Menu from "../../components/Menus";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import services from "../../services/services";
import Swal from "sweetalert2";


function SchoolYear() {


    ///////////////////////////////////////////////////////////////////////////// Academic Year /////////////////////////////////////////

    /** Activation modal d'insertion academic yaer */
    const [showModalAcademic, setShowModalAcademic] = useState(false)

    function ShowModalAcade() {
        setShowModalAcademic(true); //open modal

    }
    const handleCloseModalAcademic = () => setShowModalAcademic(false) ///close modal
    /** Activation modal d'insertion academic yaer */




    /** variable de recuperation des donnees entrant dans les champ input */
    const [dataAcade, setAcade] = useState({
        year: '',
        date_start: '',
        date_end: '',
    })

    const handleAcademic = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataAcade, [name]: value }
        setAcade(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitAcademic(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataAcade.year);
        bodyFormData.append('date_start', dataAcade.date_start);
        bodyFormData.append('date_end', dataAcade.date_end);

        services.InsertAcademic(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalAcademic();
                getallAcademic();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallAcademic()
        getAcademicOpen()
    }, [])


    const [dataAcademic, setDataAcademic] = useState([]);

    function getallAcademic() {
        services.getAcademic()
            .then((response) => {

                setDataAcademic(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const [dataAcademicOpen, setDataAcademicOpen] = useState([]);

    function getAcademicOpen() {
        services.get_Academic_Open()
            .then((response) => {

                setDataAcademicOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const columnsAcademic = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Date Start",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_start} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Date End",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_end} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Etat",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.status === 0) {
                            return (
                                <Link className="me-2">
                                    <i className="fas fa-toggle-off fa-2x" onClick={() => OpenAcaYear(rows.id)} style={{ color: 'red' }}></i>
                                </Link>
                            )
                        } else {
                            return (
                                <Link className="e-2">
                                    <i className="fas fa-toggle-on fa-2x" onClick={() => CloseAcaYear(rows.id)} style={{ color: 'green' }}></i>
                                </Link>
                            )
                        }
                    })()}



                </div>


            ),
            sortable: true,
            reorder: true
        },
        


        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdate(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteAcademic(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Ouverture des etats */

    function OpenAcaYear(id) {
        services.openAcademic(id)
                    .then(response => {
                        // console.log(response.data);
                        getallAcademic()
                        
                    })
                    .catch(e => {
                        // console.log(e);
                    });
        
            
    }


    /** Fermerture des etats */

    function CloseAcaYear(id) {
        services.closeAcademic(id)
                    .then(response => {
                        // console.log(response.data);
                        getallAcademic()
                        
                    })
                    .catch(e => {
                        // console.log(e);
                    });
        
            
    }

    /** Suppression un élement dans la base de donée */

    function DeleteAcademic(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data academic yaer",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteAcademic(id)
                    .then(response => {
                        // console.log(response.data);
                        getallAcademic()
                        Swal.fire(
                            'Data academic yaer has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditAcademic, setShowEditModalAcademic] = useState(false)

    function ShowModalUpdate(id) {
        setShowEditModalAcademic(true);
        dataAcadeEdit(id)

    }
    const handleEditCloseModalAcademic = () => setShowEditModalAcademic(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditAcade, setDataEditAcade] = useState([])

    function dataAcadeEdit(id) {
        services.get_Academic_by(id)
            .then((response) => {

                setDataEditAcade(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateAcademic(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Acade', id);
        bodyFormData.append('year', dataAcade.year);
        bodyFormData.append('date_start', dataAcade.date_start);
        bodyFormData.append('date_end', dataAcade.date_end);

        services.updateAcademic(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallAcademic()
                handleEditCloseModalAcademic()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Academic yaer //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Admission Period /////////////////////////////////////////

    /** Activation modal d'insertion Admission Period */
    const [showModalAdmission, setShowModalAdmission] = useState(false)

    function ShowModalAdmi() {
        setShowModalAdmission(true); //open modal
        getAcademicOpen();

    }
    const handleCloseModalAdmission = () => setShowModalAdmission(false) ///close modal
    /** Activation modal d'insertion Admission Period */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataAdmi, setAdmi] = useState({
        id_aca_year: '',
        date_start: '',
        date_end: '',
    })

    const handleAdmission = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataAdmi, [name]: value }
        setAdmi(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitAdmission(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataAdmi.id_aca_year);
        bodyFormData.append('date_start', dataAdmi.date_start);
        bodyFormData.append('date_end', dataAdmi.date_end);

        services.InsertAdmission(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalAdmission();
                getallAdmission();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallAdmission()
    }, [])


    const [dataAdmission, setDataAdmission] = useState([]);

    function getallAdmission() {
        services.getAdmission()
            .then((response) => {

                setDataAdmission(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsAdmission = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Date Start",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_start} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Date End",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_end} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Etat",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.status === 0) {
                            return (
                                <Link className="me-2">
                                    <i className="fas fa-toggle-off fa-2x" onClick={() => OpenAdmis(rows.id)} style={{ color: 'red' }}></i>
                                </Link>
                            )
                        } else {
                            return (
                                <Link className="e-2">
                                    <i className="fas fa-toggle-on fa-2x" onClick={() => CloseAdmis(rows.id)} style={{ color: 'green' }}></i>
                                </Link>
                            )
                        }
                    })()}



                </div>


            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateAdmi(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteAdmission(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


   /** Ouverture des etats */

    function OpenAdmis(id) {
        services.openAdmission(id)
                    .then(response => {
                        // console.log(response.data);
                        getallAdmission()
                        
                    })
                    .catch(e => {
                        // console.log(e);
                    });
        
            
    }


    /** Fermerture des etats */
    
    function CloseAdmis(id) {
        services.closeAdmission(id)
                    .then(response => {
                        // console.log(response.data);
                        getallAdmission()
                        
                    })
                    .catch(e => {
                        // console.log(e);
                    });
        
            
    }


    /** Suppression un élement dans la base de donée */

    function DeleteAdmission(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Admission period",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteAdmission(id)
                    .then(response => {
                        // console.log(response.data);
                        getallAdmission()
                        Swal.fire(
                            'Data Admission period has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditAdmission, setShowEditModalAdmission] = useState(false)

    function ShowModalUpdateAdmi(id) {
        setShowEditModalAdmission(true);
        dataAdmiEdit(id)

    }
    const handleEditCloseModalAdmission = () => setShowEditModalAdmission(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditAdmi, setDataEditAdmi] = useState([])

    function dataAdmiEdit(id) {
        services.get_Admission_by(id)
            .then((response) => {

                setDataEditAdmi(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateAdmission(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Admi', id);
        bodyFormData.append('date_start', dataAdmi.date_start);
        bodyFormData.append('date_end', dataAdmi.date_end);

        services.updateAdmission(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallAdmission()
                handleEditCloseModalAdmission()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Admission Period //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Registration Period /////////////////////////////////////////

    /** Activation modal d'insertion Registration Period */
    const [showModalRegistration, setShowModalRegistration] = useState(false)

    function ShowModalRegis() {
        setShowModalRegistration(true); //open modal
        getAcademicOpen();

    }
    const handleCloseModalRegistration = () => setShowModalRegistration(false) ///close modal
    /** Activation modal d'insertion Registration Period */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataRegis, setRegis] = useState({
        id_aca_year: '',
        date_start: '',
        date_end: '',
    })

    const handleRegistration = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataRegis, [name]: value }
        setRegis(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitRegistration(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataRegis.id_aca_year);
        bodyFormData.append('date_start', dataRegis.date_start);
        bodyFormData.append('date_end', dataRegis.date_end);

        services.InsertRegistration(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalRegistration();
                getallRegistration();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallRegistration()
    }, [])


    const [dataRegistration, setDataRegistration] = useState([]);

    function getallRegistration() {
        services.getRegistration()
            .then((response) => {

                setDataRegistration(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsRegistration = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Date Start",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_start} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Date End",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_end} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Etat",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.status === 0) {
                            return (
                                <Link className="me-2">
                                    <i className="fas fa-toggle-off fa-2x" onClick={() => OpenRegis(rows.id)} style={{ color: 'red' }}></i>
                                </Link>
                            )
                        } else {
                            return (
                                <Link className="e-2">
                                    <i className="fas fa-toggle-on fa-2x" onClick={() => CloseRegis(rows.id)} style={{ color: 'green' }}></i>
                                </Link>
                            )
                        }
                    })()}



                </div>


            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateRegis(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteRegistration(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


   /** Ouverture des etats */

    function OpenRegis(id) {
        services.openRegistration(id)
                    .then(response => {
                        // console.log(response.data);
                        getallRegistration()
                        
                    })
                    .catch(e => {
                        // console.log(e);
                    });
            
    }


    /** Fermerture des etats */
    
    function CloseRegis(id) {
        services.closeRegistration(id)
                    .then(response => {
                        // console.log(response.data);
                        getallRegistration()
                        
                    })
                    .catch(e => {
                        // console.log(e);
                    });
        
    }


    /** Suppression un élement dans la base de donée */

    function DeleteRegistration(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Registration Period",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteRegistration(id)
                    .then(response => {
                        // console.log(response.data);
                        getallRegistration()
                        Swal.fire(
                            'Data Registration Period has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditRegistration, setShowEditModalRegistration] = useState(false)

    function ShowModalUpdateRegis(id) {
        setShowEditModalRegistration(true);
        dataRegisEdit(id)

    }
    const handleEditCloseModalRegistration = () => setShowEditModalRegistration(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditRegis, setDataEditRegis] = useState([])

    function dataRegisEdit(id) {
        services.get_Registration_by(id)
            .then((response) => {

                setDataEditRegis(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateRegistration(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Regis', id);
        bodyFormData.append('date_start', dataRegis.date_start);
        bodyFormData.append('date_end', dataRegis.date_end);

        services.updateRegistration(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallRegistration()
                handleEditCloseModalRegistration()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Registration Period //////////////////////////////////////////



     ///////////////////////////////////////////////////////////////////////////// First Levels /////////////////////////////////////////

    /** Activation modal d'insertion First Levels */
    const [showModalFirstlevel, setShowModalFirstlevel] = useState(false)

    function ShowModalFirst() {
        setShowModalFirstlevel(true); //open modal

    }
    const handleCloseModalFirstlevel = () => setShowModalFirstlevel(false) ///close modal
    /** Activation modal d'insertion First Levels */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataFirst, setFirst] = useState({
        libelle: '',
        code: '',
    })

    const handleFirstlevel = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataFirst, [name]: value }
        setFirst(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitFirstlevel(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataFirst.libelle);
        bodyFormData.append('code', dataFirst.code);

        services.InsertFirstlevel(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalFirstlevel();
                getallFirstlevel();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallFirstlevel()
        getFirstlevelOpen()
    }, [])


    const [dataFirstlevel, setDataFirstlevel] = useState([]);

    function getallFirstlevel() {
        services.getFirstlevel()
            .then((response) => {

                setDataFirstlevel(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const [dataFirstlevelOpen, setDataFirstlevelOpen] = useState([]);

    function getFirstlevelOpen() {
        services.get_Firstlevel_Open()
            .then((response) => {

                setDataFirstlevelOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsFirstlevel = [
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Code",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateFirst(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteFirstlevel(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteFirstlevel(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data First Levels",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteFirstlevel(id)
                    .then(response => {
                        // console.log(response.data);
                        getallFirstlevel()
                        Swal.fire(
                            'Data First Levels has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */

    const [showModalEditFirstlevel, setShowEditModalFirstlevel] = useState(false)

    function ShowModalUpdateFirst(id) {
        setShowEditModalFirstlevel(true);
        dataFirstEdit(id)

    }
    const handleEditCloseModalFirstlevel = () => setShowEditModalFirstlevel(false)

    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    const [dataEditFirst, setDataEditFirst] = useState([])

    function dataFirstEdit(id) {
        services.get_Firstlevel_by(id)
            .then((response) => {

                setDataEditFirst(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateFirstlevel(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_First', id);
        bodyFormData.append('libelle', dataFirst.libelle);

        services.updateFirstlevel(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallFirstlevel()
                handleEditCloseModalFirstlevel()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// First Levels //////////////////////////////////////////


    // ///////////////////////////////////////////////////////////////////////////// School Years //////////////////////////////////////////

    /** Activation modal d'insertion school year */
    const[showModalSchoolYear, setShowModalShoolYear] = useState(false)

    function ShowModalSchoolYear() {
        setShowModalShoolYear(true);
        
    }
    const handleCloseModalSchoolYear = () => setShowModalShoolYear(false)
    /** Activation modal d'insertion school year */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataSchool, setSchool] = useState({
        id_aca_year: '',
        id_level_des: '',
        date_start: '',
        date_end: '',
    })

    const handleSchoolYear = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataSchool, [name]: value }
        setSchool(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitSchoolYear(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('year', dataSchool.id_aca_year);
        bodyFormData.append('libelle', dataSchool.id_level_des);
        bodyFormData.append('date_start', dataSchool.date_start);
        bodyFormData.append('date_end', dataSchool.date_end);

        services.InsertSchoolYear(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalSchoolYear();
                getallSchoolYear();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallSchoolYear()
    }, [])


    const [dataSchoolYear, setDataSchoolYear] = useState([]);

    function getallSchoolYear() {
        services.getSchoolYear()
            .then((response) => {

                setDataSchoolYear(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsSchoolYear = [
        {
            name: "Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Date Start",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_start} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Date End",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_end} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateSchool(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteSchoolYear(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteSchoolYear(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data School Year",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSchoolYear(id)
                    .then(response => {
                        // console.log(response.data);
                        getallSchoolYear()
                        Swal.fire(
                            'Data School Year has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE //////////////////////////////


    /**Activation du modale pour l'update */
    const [showModalEditSchoolYear, setShowEditModalSchoolYear] = useState(false)

    function ShowModalUpdateSchool(id) {
        setShowEditModalSchoolYear(true);
        dataSchoolEdit(id)

    }
    const handleEditCloseModalSchoolYear = () => setShowEditModalSchoolYear(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditSchool, setDataEdiSchool] = useState([])

    function dataSchoolEdit(id) {
        services.get_SchoolYear_by(id)
            .then((response) => {

                setDataEdiSchool(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateSchoolYear(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_School', id);
        bodyFormData.append('date_start', dataSchool.date_start);
        bodyFormData.append('date_end', dataSchool.date_end);

        services.updateSchoolYear(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallSchoolYear()
                handleEditCloseModalSchoolYear()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// School Years //////////////////////////////////////////


     ///////////////////////////////////////////////////////////////////////////// Term Name /////////////////////////////////////////

    /** Activation modal d'insertion Term Name */
    const [showModalTermName, setShowModalTermName] = useState(false)

    function ShowModalTerm() {
        setShowModalTermName(true); //open modal

    }
    const handleCloseModalTermName = () => setShowModalTermName(false) ///close modal
    /** Activation modal d'insertion Term Name */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataTerm, setTerm] = useState({
        description: '',
        code: '',
    })

    const handleTermName = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataTerm, [name]: value }
        setTerm(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitTermName(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('description', dataTerm.description);
        bodyFormData.append('code', dataTerm.code);

        services.InsertTermName(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalTermName();
                getallTermName();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallTermName()
        getTermNameOpen()
    }, [])


    const [dataTermName, setDataTermName] = useState([]);

    function getallTermName() {
        services.getTermName()
            .then((response) => {

                setDataTermName(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataTermNameOpen, setDataTermNameOpen] = useState([]);

    function getTermNameOpen() {
        services.get_TermName_Open()
            .then((response) => {

                setDataTermNameOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsTermName = [
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Code",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateTerm(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteTermName(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteTermName(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Term Name",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteTermName(id)
                    .then(response => {
                        // console.log(response.data);
                        getallTermName()
                        Swal.fire(
                            'Data Term Name has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */

    const [showModalEditTermName, setShowEditModalTermName] = useState(false)

    function ShowModalUpdateTerm(id) {
        setShowEditModalTermName(true);
        dataTermEdit(id)

    }
    const handleEditCloseModalTermName = () => setShowEditModalTermName(false)

    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    const [dataEditTerm, setDataEditTerm] = useState([])

    function dataTermEdit(id) {
        services.get_TermName_by(id)
            .then((response) => {

                setDataEditTerm(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateTermName(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Term', id);
        bodyFormData.append('description', dataTerm.description);

        services.updateTermName(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallTermName()
                handleEditCloseModalTermName()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Term Name //////////////////////////////////////////



    ///////////////////////////////////////////////////////////////////////////// Event Name /////////////////////////////////////////

    /** Activation modal d'insertion Event Name */
    const [showModalEventName, setShowModalEventName] = useState(false)

    function ShowModalEvent() {
        setShowModalEventName(true); //open modal

    }
    const handleCloseModalEventName = () => setShowModalEventName(false) ///close modal
    /** Activation modal d'insertion Event Name */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataEvent, setEvent] = useState({
        libelle: '',
        description: '',
    })

    const handleEventName = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataEvent, [name]: value }
        setEvent(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitEventName(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataEvent.libelle);
        bodyFormData.append('description', dataEvent.description);

        services.InsertEventName(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalEventName();
                getallEventName();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallEventName()
    }, [])


    const [dataEventName, setDataEventName] = useState([]);

    function getallEventName() {
        services.getEventName()
            .then((response) => {

                setDataEventName(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsEventName = [
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateEvent(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteEventName(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteEventName(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Event Name",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteEventName(id)
                    .then(response => {
                        // console.log(response.data);
                        getallEventName()
                        Swal.fire(
                            'Data Event Name has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE//////////////////////////////


    /**Activation du modale pour l'update */

    const [showModalEditEventName, setShowEditModalEventName] = useState(false)

    function ShowModalUpdateEvent(id) {
        setShowEditModalEventName(true);
        dataEventEdit(id)

    }
    const handleEditCloseModalEventName = () => setShowEditModalEventName(false)

    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    const [dataEditEvent, setDataEditEvent] = useState([])

    function dataEventEdit(id) {
        services.get_EventName_by(id)
            .then((response) => {

                setDataEditEvent(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateEventName(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Event', id);
        bodyFormData.append('libelle', dataEvent.libelle);
        bodyFormData.append('description', dataEvent.description);

        services.updateEventName(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallEventName()
                handleEditCloseModalEventName()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */
    ///////////////////////////////////////////////////////////////////////////// Event Name //////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////// Term Management /////////////////////////////////////////

    /** Activation modal d'insertion Term Management */
    const [showModalManagement, setShowModalManagement] = useState(false)

    function ShowModalManag() {
        getManagementOpen()
        setShowModalManagement(true); //open modal

    }
    const handleCloseModalManagement = () => setShowModalManagement(false) ///close modal
    /** Activation modal d'insertion Term Management */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataManag, setManag] = useState({
        id_level: '',
        id_term: '',
        start_term: '',
        end_term: '',
        nemb_opened_days: '',
    })

    const handleManagement = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataManag, [name]: value }
        setManag(data);
        // console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des données dans la base de donnée */
    function handleSubmitManagement(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataManag.id_level);
        bodyFormData.append('code', dataManag.id_term);
        bodyFormData.append('start_term', dataManag.start_term);
        bodyFormData.append('end_term', dataManag.end_term);
        // bodyFormData.append('nemb_opened_days', dataManag.nemb_opened_days);

        services.InsertManagement(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalManagement();
                getallManagement();

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getallManagement()
        getManagementOpen()
    }, [])


    function ChooseTermManagement(e) {
        const value = e.target.value;
        window.location.replace("/add-program-event/"+value)
    }


    const [dataManagementOpen, setDataManagementOpen] = useState([]);

    function getManagementOpen() {
        services.get_Management_Open()
            .then((response) => {

                setDataManagementOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    const [dataManagement, setDataManagement] = useState([]);

    function getallManagement() {
        services.getManagement()
            .then((response) => {

                setDataManagement(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsManagement = [
        {
            name: "Libelle",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Term",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Start Term",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.start_term} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "End Term",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.end_term} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Nemb Opened Days",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.nemb_opened_days} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Acedemic Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Etat",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.status === 0) {
                            return (
                                <Link className="me-2">
                                    <i className="fas fa-toggle-off fa-2x" onClick={() => OpenManag(rows.id)} style={{ color: 'red' }}></i>
                                </Link>
                            )
                        } else {
                            return (
                                <Link className="e-2">
                                    <i className="fas fa-toggle-on fa-2x" onClick={() => CloseManag(rows.id)} style={{ color: 'green' }}></i>
                                </Link>
                            )
                        }
                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateManag(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteManagement(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */



    /** Ouverture des etats */

    function OpenManag(id) {
        services.openManagement(id)
                    .then(response => {
                        // console.log(response.data);
                        getallManagement()
                        
                    })
                    .catch(e => {
                        // console.log(e);
                    });
            
    }


    /** Fermerture des etats */
    
    function CloseManag(id) {
        services.closeManagement(id)
                    .then(response => {
                        // console.log(response.data);
                        getallManagement()
                        
                    })
                    .catch(e => {
                        // console.log(e);
                    });
        
    }


    /** Suppression un élement dans la base de donée */

    function DeleteManagement(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Term Management",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteManagement(id)
                    .then(response => {
                        // console.log(response.data);
                        getallManagement()
                        Swal.fire(
                            'Data Term Management has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */

    ////////////////////////////////////// UPDATE //////////////////////////////


    /**Activation du modale pour l'update */

    const [showModalEditManagement, setShowEditModalManagement] = useState(false)

    function ShowModalUpdateManag(id) {
        setShowEditModalManagement(true);
        dataManagEdit(id)

    }
    const handleEditCloseModalManagement = () => setShowEditModalManagement(false)

    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    const [dataEditManag, setDataEditManag] = useState([])

    function dataManagEdit(id) {
        services.get_Management_by(id)
            .then((response) => {

                setDataEditManag(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateManagement(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Manag', id);
        bodyFormData.append('start_term', dataManag.start_term);
        bodyFormData.append('end_term', dataManag.end_term);
        // bodyFormData.append('nemb_opened_days', dataManag.nemb_opened_days);

        services.updateManagement(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallManagement()
                handleEditCloseModalManagement()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */


    /** Activation modal d'insertion Term Management */
    const [showModalTermManagement, setShowModalTermManagement] = useState(false)

    function ShowModalTermManag() {
        setShowModalTermManagement(true); //open modal

    }
    const handleCloseModalTermManagement = () => setShowModalTermManagement(false) ///close modal
    /** Activation modal d'insertion Term Management */
    ///////////////////////////////////////////////////////////////////////////// Term Management //////////////////////////////////////////


   


    // useEffect(() => {
    //     getallProgramEvent()
    // }, [])

    return (
        <>
            <Menu />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">School Year</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Setting</a></li>
                                    <li className="breadcrumb-item active">School Year</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="card bg-white">
                                <div className="card-header">
                                    <h5 className="card-title">School Year</h5>
                                </div>
                                <div className="card-body">
                                    <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                        <li className="nav-item"><a className="nav-link active" href="#solid-justified-tab1" data-bs-toggle="tab">Academic Year</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab2" data-bs-toggle="tab">Admission Period</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab3" data-bs-toggle="tab">Registration Period</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab4" data-bs-toggle="tab">First Levels</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab5" data-bs-toggle="tab">School Years</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab6" data-bs-toggle="tab">Term Name</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab7" data-bs-toggle="tab">Event Name</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#solid-justified-tab8" data-bs-toggle="tab">Term Management</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="solid-justified-tab1">

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalAcade()} ><i className="fas fa-plus"></i> Academic Yaer </button>


                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsAcademic}
                                                                    data={dataAcademic}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane" id="solid-justified-tab2">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalAdmi()} ><i className="fas fa-plus"></i> Admission Period </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsAdmission}
                                                                    data={dataAdmission}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab3">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalRegis()} ><i className="fas fa-plus"></i> Registration Period </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsRegistration}
                                                                    data={dataRegistration}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab4">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalFirst()} ><i className="fas fa-plus"></i> First level </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsFirstlevel}
                                                                    data={dataFirstlevel}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab5">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalSchoolYear()} ><i className="fas fa-plus"></i> School Year </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsSchoolYear}
                                                                    data={dataSchoolYear}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab6">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalTerm()} ><i className="fas fa-plus"></i> Term Name </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsTermName}
                                                                    data={dataTermName}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab7">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalEvent()} ><i className="fas fa-plus"></i> Event Name </button>

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsEventName}
                                                                    data={dataEventName}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="solid-justified-tab8">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card card-table">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <button type="button" className="btn btn-info mb-3 me-3" onClick={() => ShowModalManag()} ><i className="fas fa-plus"></i> Term Management </button>                                                               
                                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalTermManag()} ><i className="fas fa-plus"></i> Add/VIEW Pragram Event </button>                                                              

                                                                <DataTable
                                                                    title="DataTable"
                                                                    // customStyles={customStyles}
                                                                    columns={columnsManagement}
                                                                    data={dataManagement}
                                                                    responsive
                                                                    noHeader
                                                                    defaultSortFieldId={1}
                                                                    defaultSortField="id"
                                                                    defaultSortAsc={false}
                                                                    //pagination
                                                                    // selectableRows
                                                                    persistTableHead
                                                                    highlightOnHover
                                                                    exportHeaders={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            
            <Modal show={showModalTermManagement} onHide={handleCloseModalTermManagement} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Choose classes section </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="GET">
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Select Term Magement</label>
                                    <select name="id_class" onChange={(e) => ChooseTermManagement(e)} id=""  className="form-control">
                                    <option value="">Select Term Magement</option>{
                                    dataManagementOpen.map((result) => (
                                         <option key={result.id} value={result.id_level}>{result.id_level}</option>
                                          ))
                                          }
                                    </select>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </form>
            </Modal>


             {/* formulaire Academic Year */}
             <Modal show={showModalAcademic} onHide={handleCloseModalAcademic} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Academic Year Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitAcademic(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">year</label>
                                    <input type="text" name="year" onChange={(e) => handleAcademic(e)} placeholder="year..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_start</label>
                                    <input type="date" name="date_start" onChange={(e) => handleAcademic(e)} placeholder="date_start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_end</label>
                                    <input type="date" name="date_end" onChange={(e) => handleAcademic(e)} placeholder="date_end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditAcademic} onHide={handleEditCloseModalAcademic} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Academic Year Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateAcademic(e, dataEditAcade.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">year</label>
                                    <input type="text" name="year" defaultValue={dataEditAcade.year} onChange={(e) => handleAcademic(e)} placeholder="year..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_start</label>
                                    <input type="date" name="date_start" defaultValue={dataEditAcade.date_start} onChange={(e) => handleAcademic(e)} placeholder="date start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_end</label>
                                    <input type="date" name="date_end" defaultValue={dataEditAcade.date_end} onChange={(e) => handleAcademic(e)} placeholder="date end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Academic Year */}


            {/* formulaire Admission Period */}
            <Modal show={showModalAdmission} onHide={handleCloseModalAdmission} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Admission Period Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitAdmission(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleAdmission(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_start</label>
                                    <input type="date" name="date_start" onChange={(e) => handleAdmission(e)} placeholder="date_start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_end</label>
                                    <input type="date" name="date_end" onChange={(e) => handleAdmission(e)} placeholder="date_end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditAdmission} onHide={handleEditCloseModalAdmission} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Admission Period Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateAdmission(e, dataEditAdmi.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                {/* <div className="col-12 col-sm-6">
                                    <label htmlFor="">year</label>
                                    <input type="text" name="year" defaultValue={dataEditAdmi.year} onChange={(e) => handleAdmission(e)} placeholder="year..." className="form-control" />
                                </div> */}
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_start</label>
                                    <input type="date" name="date_start" defaultValue={dataEditAdmi.date_start} onChange={(e) => handleAdmission(e)} placeholder="date_start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_end</label>
                                    <input type="date" name="date_end" defaultValue={dataEditAdmi.date_end} onChange={(e) => handleAdmission(e)} placeholder="date_end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Admission Period */}


            {/* formulaire Registration Period */}
            <Modal show={showModalRegistration} onHide={handleCloseModalRegistration} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Registration Period Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitRegistration(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleRegistration(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_start</label>
                                    <input type="date" name="date_start" onChange={(e) => handleRegistration(e)} placeholder="date start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_end</label>
                                    <input type="date" name="date_end" onChange={(e) => handleRegistration(e)} placeholder="date end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditRegistration} onHide={handleEditCloseModalRegistration} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Registration Period Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateRegistration(e, dataEditRegis.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_start</label>
                                    <input type="date" name="date_start" defaultValue={dataEditRegis.date_start} onChange={(e) => handleRegistration(e)} placeholder="date start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">date_end</label>
                                    <input type="date" name="date_end" defaultValue={dataEditRegis.date_end} onChange={(e) => handleRegistration(e)} placeholder="date end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Registration Period */}



            {/* formulaire First Level */}
            <Modal show={showModalFirstlevel} onHide={handleCloseModalFirstlevel} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add First level Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitFirstlevel(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Libelle</label>
                                    <input type="text" name="libelle" onChange={(e) => handleFirstlevel(e)} placeholder="libelle..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditFirstlevel} onHide={handleEditCloseModalFirstlevel} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update First level Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateFirstlevel(e, dataEditFirst.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Libelle</label>
                                    <input type="text" name="libelle" defaultValue={dataEditFirst.libelle} onChange={(e) => handleFirstlevel(e)} placeholder="libelle..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire First Level */}


             {/* formulaire School Years */}
             <Modal show={showModalSchoolYear} onHide={handleCloseModalSchoolYear} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add School Years Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitSchoolYear(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Academic Year</label>
                                    <select name="id_aca_year" onChange={(e) => handleSchoolYear(e)} id=""  className="form-control">
                                    <option value="">Select year</option>{
                                    dataAcademicOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.year}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_level_des" onChange={(e) => handleSchoolYear(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.code}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Date Start</label>
                                    <input type="date" name="date_start" onChange={(e) => handleSchoolYear(e)} placeholder="date_start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Date End</label>
                                    <input type="date" name="date_end" onChange={(e) => handleSchoolYear(e)} placeholder="date_end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditSchoolYear} onHide={handleEditCloseModalSchoolYear} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update School Years Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateSchoolYear(e, dataEditSchool.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Date Start</label>
                                    <input type="date" name="date_start" defaultValue={dataEditSchool.date_start} onChange={(e) => handleSchoolYear(e)} placeholder="date_start..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Date End</label>
                                    <input type="date" name="date_end" defaultValue={dataEditSchool.date_end} onChange={(e) => handleSchoolYear(e)} placeholder="date_end..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire School Years */}


            {/* formulaire Term Name */}
            <Modal show={showModalTermName} onHide={handleCloseModalTermName} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Term Name Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitTermName(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleTermName(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditTermName} onHide={handleEditCloseModalTermName} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Term Name Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateTermName(e, dataEditTerm.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditTerm.description} onChange={(e) => handleTermName(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Term Name */}


            {/* formulaire Event Name */}
             <Modal show={showModalEventName} onHide={handleCloseModalEventName} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Event Name Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitEventName(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Libelle</label>
                                    <input type="text" name="libelle" onChange={(e) => handleEventName(e)} placeholder="libelle..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" onChange={(e) => handleEventName(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditEventName} onHide={handleEditCloseModalEventName} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Event Name Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateEventName(e, dataEditEvent.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Libelle</label>
                                    <input type="text" name="libelle" defaultValue={dataEditEvent.libelle} onChange={(e) => handleEventName(e)} placeholder="libelle..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Description</label>
                                    <input type="text" name="description" defaultValue={dataEditEvent.description} onChange={(e) => handleEventName(e)} placeholder="description..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Event Name */}


            {/* formulaire Term Management */}
             <Modal show={showModalManagement} onHide={handleCloseModalManagement} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Term Management Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitManagement(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                 <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select First Level</label>
                                    <select name="id_level" onChange={(e) => handleManagement(e)} id=""  className="form-control">
                                    <option value="">Select libelle</option>{
                                    dataFirstlevelOpen.map((result) => (
                                         <option key={result.id} value={result.libelle}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Term Name</label>
                                    <select name="id_term" onChange={(e) => handleManagement(e)} id=""  className="form-control">
                                    <option value="">Select description</option>{
                                    dataTermNameOpen.map((result) => (
                                         <option key={result.id} value={result.description}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Start Date</label>
                                    <input type="date" name="start_term" onChange={(e) => handleManagement(e)} placeholder="start_term..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">End Date</label>
                                    <input type="date" name="end_term" onChange={(e) => handleManagement(e)} placeholder="end_term..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditManagement} onHide={handleEditCloseModalManagement} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Term Management Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateManagement(e, dataEditManag.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Start Date</label>
                                    <input type="date" name="start_term" defaultValue={dataEditManag.start_term} onChange={(e) => handleManagement(e)} placeholder="start_term..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">End Date</label>
                                    <input type="date" name="end_term" defaultValue={dataEditManag.end_term} onChange={(e) => handleManagement(e)} placeholder="end_term..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* formulaire Term Management */}

        </>
    )

}
export default SchoolYear;