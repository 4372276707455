import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {  Link, useParams } from 'react-router-dom'
import Header from "../../components/Header";
import Menus from "../../components/Menus";
import { Button, Modal } from "react-bootstrap";
import services from "../../services/services";
import Swal from "sweetalert2";


function ClassesSubject() {
    const{term} = useParams()

    /** Activation modal d'insertion Classe Subject  */
    // const [showModalClasseSubject, setShowModalClasseSubject] = useState(false)

    const [showCoreModal, setShowCoreModal] = useState(false);
    const [showSelectiveModal, setShowSelectiveModal] = useState(false);
    const [showBreakModal, setShowBreakModal] = useState(false);
    
    function ShowModalCore(e) {
        getSubjectOpen()
        getClasseSectionOpen()
        setShowCoreModal(true); //open modal
       

    }

    function ShowModalSelect(e) {
        getSubjectOpen()
        getClasseSectionOpen()
        setShowSelectiveModal(true); //open modal
        

    }

    function ShowModalBreak(e) {
        getSubjectOpen()
        getClasseSectionOpen()
        setShowBreakModal(true); //open modal
       

    }


    const handleCloseModalCore = () => setShowCoreModal(false) ///close modal
    const handleCloseModalSelective = () => setShowSelectiveModal(false) ///close modal
    const handleCloseModalBreak = () => setShowBreakModal(false) ///close modal
    /** Activation modal d'insertion Classe Subject  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataClasSub, setClasSub] = useState({
        type: '',
        id_class_section: '',
        
    })

    const [dataCoresubject, setCoresubject] = useState({
        type_subject: [],
    })

    const handleTypesubject = (e) => {
        const { value, checked } = e.target;
        const { type_subject } = dataCoresubject;

        if (checked) {
            setCoresubject({
                type_subject: [...type_subject, value],
            });
        }
        else {
            setCoresubject({
                type_subject: "",
            });
        }
    }

    const handleClasseSubject = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataClasSub, [name]: value }
        setClasSub(data);
        console.log(data);

    }

    

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitClasseSubject(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('term', term);
        bodyFormData.append('type', dataClasSub.type);
        bodyFormData.append('subject', dataCoresubject.type_subject);
        bodyFormData.append('id_class', dataClasSub.id_class_section);

        services.InsertClasseSubject(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallClasseSubject();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    useEffect(() => {
        getSubjectOpen()
        getClasseSectionOpen()
        getallClasseSubject()
    }, [])

    


    const [dataSubjectOpen, setDataSubjectOpen] = useState([]);

    function getSubjectOpen() {
        services.get_Subject_Open()
            .then((response) => {

                setDataSubjectOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataClasseSectionOpen, setDataClasseSectionOpen] = useState([]);

    function getClasseSectionOpen() {
        services.get_ClasseSection_Open()
            .then((response) => {
                setDataClasseSectionOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    
    const [dataClasseSubject, setDataClasseSubject] = useState([]);

    function getallClasseSubject() {
        services.getClasseSubject()
            .then((response) => {

                setDataClasseSubject(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsClasseSubject = [
        {
            name: "Term",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.term} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Type",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.type} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Class Section",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.id_classe}{rows.id_section}</b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Subject",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.subject} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Academic Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year}</b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                    
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> 
                                <Link to={"#"} onClick={(e) => ShowModalUpdateClasSub(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>*/}
                                <Link to="#" onClick={(e) => DeleteClasseSubject(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]

    /** Suppression un élement dans la base de donée */
    function DeleteClasseSubject(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Class Subject setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteClasseSubject(id)
                    .then(response => {
                        // console.log(response.data);
                        getallClasseSubject()
                        Swal.fire(
                            'Data Items Class Subject has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    /** Suppression un élement dans la base de donée */
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return(
        <>
        
        <Menus />
        <Header />
         {term}


         <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Class Subject {term}</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Mapping</a></li>
                                    <li className="breadcrumb-item active">Class Subject</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <button type="button" style={{'color':'#fff' }} className="btn btn-info mb-3 me-3" onClick={(e) => ShowModalCore(e)} ><i className="fas fa-plus"></i> Add Class Core Subject </button>
                                                <button type="button"  style={{'color':'#fff' }} className="btn btn-warning mb-3 me-3" onClick={(e) => ShowModalSelect(e)} ><i className="fas fa-plus"></i> Add Class Selective Subject </button>
                                                <button type="button"  style={{'color':'#000' }} className="btn btn-primary mb-3" onClick={(e) => ShowModalBreak(e)} ><i className="fas fa-plus"></i> Add Class Break </button>

                                                <DataTable
                                                    title="DataTable"
                                                    // customStyles={customStyles}
                                                    columns={columnsClasseSubject}
                                                    data={dataClasseSubject}
                                                    responsive
                   N                                 noHeader
                                                    defaultSortFieldId={1}
                                                    defaultSortField="id"
                                                    defaultSortAsc={false}
                                                    pagination
                                                    persistTableHead
                                                    highlightOnHover
                                                    exportHeaders={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            {/* formulaire Classe Subject */}

            <Modal show={showCoreModal} onHide={handleCloseModalCore} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Class Subject </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitClasseSubject(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Term</label>
                                        <input type="text" value={term} readOnly className="form-control"  />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select className="form-control" name='type' id='type' style={{ fontWeight: 'bold' }} onChange={(e) => handleClasseSubject(e)}>
                                            <option value="">Select Type</option>
                                            <option value="Core">Core</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class section</label>
                                    <select name="id_class_section" onChange={(e) => handleClasseSubject(e)} id=""  className="form-control">
                                    <option value="">Select class Section</option>{
                                    dataClasseSectionOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.id_classe}{result.id_section}</option>
                                          ))
                                          }
                                    </select>
                                </div>

                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Select Core Subject</label>{
                                        dataSubjectOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="type_subject" 
                                                id="type_subject"  
                                                className="form-check-input"  
                                                onChange={(e) => handleTypesubject(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.subject}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>


            <Modal show={showSelectiveModal} onHide={handleCloseModalSelective} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Class Subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitClasseSubject(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Term</label>
                                        <input type="text" value={term} readOnly className="form-control"  />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select className="form-control" name='type' id='type' style={{ fontWeight: 'bold' }} onChange={(e) => handleClasseSubject(e)}>
                                            <option value="">Select Type</option>
                                            <option value="Selctive">Selctive</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class section</label>
                                    <select name="id_class_section" onChange={(e) => handleClasseSubject(e)} id=""  className="form-control">
                                    <option value="">Select class Section</option>{
                                    dataClasseSectionOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.id_classe}{result.id_section}</option>
                                          ))
                                          }
                                    </select>
                                </div> 
                                 
                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Select Selective Subject</label>{
                                        dataSubjectOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="type_subject" 
                                                id="type_subject"  
                                                className="form-check-input"  
                                                onChange={(e) => handleTypesubject(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.subject}</label>
                                            </div>
                                            ))
                                        }
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>


            <Modal show={showBreakModal} onHide={handleCloseModalBreak} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Class Subject Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitClasseSubject(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Term</label>
                                        <input type="text" value={term} readOnly className="form-control"  />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select className="form-control" name='type' id='type' style={{ fontWeight: 'bold' }} onChange={(e) => handleClasseSubject(e)}>
                                            <option value="">Select Type</option>
                                            <option value="Break">Break</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class section</label>
                                    <select name="id_class_section" onChange={(e) => handleClasseSubject(e)} id=""  className="form-control">
                                    <option value="">Select class Section</option>{
                                    dataClasseSectionOpen.map((result) => (
                                         <option key={result.id} value={result.id}>{result.id_classe}{result.id_section}</option>
                                          ))
                                          }
                                    </select>
                                </div>

                                <div className="col-12 col-sm-4">
                                    <label htmlFor="">Select Break</label>{
                                        dataSubjectOpen.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="type_subject" 
                                                id="type_subject"  
                                                className="form-check-input"  
                                                onChange={(e) => handleTypesubject(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.subject}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

           


        </>

    )
}
export default ClassesSubject;