import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import services from '../services/services';
import './overflow.css';

const mySidebar = {
    // width: '100%',
    // height: '100%',
    // overflow: 'scroll'
    width: '100%',
    fontWeight: 'bold',
    color: '#6f6f6f',
    fontSize: '16px'
};

const scrollbar = {
    /*background-color: lightblue;*/
      width: '100%',
      height: '800px',
     overflow: 'scroll',
    //  boxShadow: 'inset 0 0 5px grey', 
    borderRadius: '10px',
    // background: 'red',
    overflowY: 'auto',
    overflowX: 'hidden',
    }

function Menus() {

    useEffect(() => {
        getallTermName()
        // getallClasse()
    }, [])


    const [dataTermName, setDataTermName] = useState([]);
    
    function getallTermName() {
        services.getTermName()
            .then((response) => {
    
                setDataTermName(response.data)
                console.log(response.data);
    
            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    // const [dataClasse, setDataClasse] = useState([]);
    
    // function getallClasse() {
    //     services.getClasse()
    //         .then((response) => {
    
    //             setDataClasse(response.data)
    //             console.log(response.data);
    
    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }
    
    return (
        <div className="sidebar" id="sidebar">

            <Sidebar style={mySidebar}>
                <Menu>

                    {/* <li className="menu-title">
                        <span>Main Menu</span>
                    </li> */}
                    <SubMenu label="DASHBOARD" icon={<i className="fas fa-user-graduate"></i>}>
                        <MenuItem component={<Link to="/Dashboard" />}>  Admin Dashboard </MenuItem>
                        <MenuItem component={<Link to="/Teacher-Dashboard" />}> Teacher Dashboard </MenuItem>
                        <MenuItem component={<Link to="/Student-Dashboard" />}> Student Dashboard </MenuItem>
                    </SubMenu>

                    {/* <li className="menu-title">
                        <span>Management</span>
                    </li> */}

                    <SubMenu label="SCHOLARSAPP" icon={<i className="fas fa-house-laptop"></i>}>
                        <div style={scrollbar} id="styleScrollbar">
                        
                        <SubMenu label="Users Access" icon={<i className="fas fa-lock"></i>}>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Parents </MenuItem>
                            <MenuItem component={<Link to="/staffs-access" />}><i className="fas fa-angle-right"></i> Staff </MenuItem>
                        </SubMenu>



                        <SubMenu label="Setting" icon={<i className="fas fa-cogs"></i>}>
                            <MenuItem component={<Link to="/school-year" />}><i className="fas fa-angle-right"></i> School Years </MenuItem>
                            <MenuItem component={<Link to="/global-parameters" />}><i className="fas fa-angle-right"></i> Global Parameters </MenuItem>
                            <MenuItem component={<Link to="/services-parameters" />}><i className="fas fa-angle-right"></i> Services Parameters </MenuItem>
                            <MenuItem component={<Link to="/academic-parameters" />}><i className="fas fa-angle-right"></i> Academic Parameters </MenuItem>
                            <MenuItem component={<Link to="/finances-parameters" />}><i className="fas fa-angle-right"></i> Finances Parameters </MenuItem>
                        </SubMenu>




                        <SubMenu label="Mapping" icon={<i className="fas fa-map"></i>}>
                            <MenuItem component={<Link to="/classes-section" />}><i className="fas fa-angle-right"></i> Classes - section </MenuItem>

                                <SubMenu label="Classes - subject">
                            
                                    {
                                    dataTermName.map((result) => (
                                        <MenuItem key={result.id} component={<Link to={"/classes-subject/"+result.description}/>}>{result.description}</MenuItem>
                                        ))
                                    }
                                </SubMenu>

                            <SubMenu label="Teacher - subject">
                           
                                {
                                dataTermName.map((result) => (
                                    <MenuItem key={result.id} component={<Link to={"/teacher-subject/"+result.description}/>}>{result.description}</MenuItem>
                                    ))
                                }
                           </SubMenu>
                            <MenuItem component={<Link to="/student-section" />}><i className="fas fa-angle-right"></i> Student - Section </MenuItem>
                            <MenuItem component={<Link to="/class-student-subject" />}><i className="fas fa-angle-right"></i> Student - Subject </MenuItem>
                            <MenuItem component={<Link to="/student-authorities" />}><i className="fas fa-angle-right"></i> School - authorities </MenuItem>

                        </SubMenu>


                        <SubMenu label="Academic"  icon={<i className="fas fa-house-flag"></i>}>
                           
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> School Calendar </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Syllabus </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Lesson planning </MenuItem>

                            <SubMenu label="Timetable">
                                <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i>  Courses </MenuItem>
                                <SubMenu label="Exams">
                           
                                    {
                                    dataTermName.map((result) => (
                                        <MenuItem key={result.id} component={<Link to="/" />}>{result.description}</MenuItem>
                                        ))
                                    }
                                </SubMenu>

                                <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Teachers </MenuItem>
                            </SubMenu>


                            <SubMenu label="Test Schedule">
                           
                                {
                                dataTermName.map((result) => (
                                    <MenuItem key={result.id} component={<Link to="/" />}><i className="fas fa-angle-right"></i> {result.description}</MenuItem>
                                    ))
                                }
                           </SubMenu>

                           <SubMenu label="Exam Schedule">
                           
                                {
                                dataTermName.map((result) => (
                                    <MenuItem key={result.id} component={<Link to="/" />}><i className="fas fa-angle-right"></i>  {result.description}</MenuItem>
                                    ))
                                }
                           </SubMenu>

                           <SubMenu label="Behaviour Report">
                           
                                {
                                dataTermName.map((result) => (
                                    <MenuItem key={result.id} component={<Link to="/" />}><i className="fas fa-angle-right"></i>  {result.description}</MenuItem>
                                    ))
                                }
                           </SubMenu>

                           <SubMenu label="Report Card">
                           
                                        <SubMenu label="Primary & High School">
                                        
                                        {
                                        dataTermName.map((result) => (
                                            <MenuItem key={result.id} component={<Link to="/" />}><i className="fas fa-angle-right"></i>  {result.description}</MenuItem>
                                            ))
                                        }
                                    </SubMenu>
                                    <SubMenu label="Nursery">
                                        
                                        {
                                        dataTermName.map((result) => (
                                            <MenuItem key={result.id} component={<Link to="/" />}><i className="fas fa-angle-right"></i>  {result.description}</MenuItem>
                                            ))
                                        }
                                    </SubMenu>
                           </SubMenu>

                           <SubMenu label="Broadsheet">
                           
                                {
                                dataTermName.map((result) => (
                                    <MenuItem key={result.id} component={<Link to="/" />}><i className="fas fa-angle-right"></i>  {result.description}</MenuItem>
                                    ))
                                }
                           </SubMenu>

                           <SubMenu label="General Annual">
                           
                                {
                                dataTermName.map((result) => (
                                    <MenuItem key={result.id} component={<Link to="/" />}><i className="fas fa-angle-right"></i>  {result.description}</MenuItem>
                                    ))
                                }
                           </SubMenu>

                           <SubMenu label="Statistics">
                           
                                {
                                dataTermName.map((result) => (
                                    <MenuItem key={result.id} component={<Link to="/" />}><i className="fas fa-angle-right"></i>  {result.description}</MenuItem>
                                    ))
                                }
                           </SubMenu>
                                          
                        </SubMenu>


                        <SubMenu label="Registration" icon={<i className="fas fa-registered"></i>}>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Admission </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Re-registration </MenuItem>
                        </SubMenu> 

                        <SubMenu label="Finance" icon={<i className="fas fa-coins"></i>}>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i>  Invoices </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i>  Instalment Plan </MenuItem>

                            <SubMenu label="Receipts">
                                <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> New </MenuItem>
                                <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> List </MenuItem>
                                <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Responsible of school fees </MenuItem>
                            </SubMenu>  
                        </SubMenu>    

                         <SubMenu label="Stock Management" icon={<i className="fas fa-cart-shopping"></i>}>

                            <SubMenu label="FEV - Free Exit Voucher">
                                <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> New </MenuItem>
                                <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> List </MenuItem>
                            </SubMenu>  
                        </SubMenu>    

                        <SubMenu label="Students" icon={<i className="fas fa-user-graduate"></i>}>
                            <MenuItem component={<Link to="/student-active" />}><i className="fas fa-angle-right"></i> Active </MenuItem>
                            <MenuItem component={<Link to="/student-inactive" />}><i className="fas fa-angle-right"></i> Inactive </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Absentees </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> ID cards </MenuItem>
                            <MenuItem component={<Link to="/Add-Student" />}><i className="fas fa-angle-right"></i> Add </MenuItem>
                        </SubMenu>

                        <SubMenu label="Staff" icon={<i className="fas fa-chalkboard-teacher"></i>}>
                            <MenuItem component={<Link to="/staff-active" />}><i className="fas fa-angle-right"></i> Active </MenuItem>
                            <MenuItem><i className="fas fa-angle-right"></i> Inactive </MenuItem>
                            <MenuItem><i className="fas fa-angle-right"></i> Contrat </MenuItem>
                        </SubMenu>

                        <MenuItem icon={<i className="fas fa-user-secret"></i>}> Parents </MenuItem>

                        <SubMenu label="Communication" icon={<i className="fas fa-comments"></i>}>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Information files </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Geberal new </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Message </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Birthdays </MenuItem>
                        </SubMenu>

                        <SubMenu label="Utilities management" icon={<i className="fas fa-bus"></i>}>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Canteen  </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Extra activities </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Transport </MenuItem>
                        </SubMenu>

                        <SubMenu label="Gallery" icon={<i className="fas fa-image"></i>}>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Picture  </MenuItem>
                            <MenuItem component={<Link to="/" />}><i className="fas fa-angle-right"></i> Video </MenuItem>
                        </SubMenu>

                        
                        </div>
                    </SubMenu>




                </Menu>
            </Sidebar>
        </div>


    );
}

export default Menus;
