import React from "react";
import Menus from "../components/Menus";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Services from "../services/services";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';


function InvoiceList() {

    useEffect(() => {
        data_invoice()
        list_invoice()
    }, [])

    const [nbrInvoice, setNbrInvoice] = useState([])
    const [sumInvoice, setSumInvoice] = useState([])

    const [nbrPaid, setNbrPaid] = useState([])
    const [sumPaid, setSumPaid] = useState([])

    const [nbreamining, setNbreamining] = useState([])
    const [sumReamining, setSumeamining] = useState([])

    function data_invoice() {
        Services.DataInvoices()
            .then((response) => {

                setNbrInvoice(response.data.all_invoice)
                setSumInvoice(response.data.sum_invoice)

                setNbrPaid(response.data.all_paid)
                setSumPaid(response.data.sum_paid)

                setNbreamining(response.data.all_reamining)
                setSumeamining(response.data.sum_reamining)

                console.log(response.data)

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [list, setList] = useState([])

    function list_invoice() {
        Services.ListInvoices()
            .then((response) => {

                setList(response.data)

                console.log(response.data)

            })
            .catch((error) => {
                console.log(error)
            })
    }


    const columnsList = [
        {
            name: "Invoice ID",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code_student} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Created on",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.JoiningDate} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Invoice to",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.FirstName} {rows.LastName}  </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Amount",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.amount} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Paid",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.paid} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Reamining",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.reaminig} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Status",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.reaminig !== 0) {
                            return (
                                <>
                                     <span class="badge bg-danger">Overdue</span>

                                </>
                            )
                        } else {
                            return (
                                <>
                                   
                                    <span class="badge bg-success-light">Paid</span>
                                </>
                            )
                        }


                    })()}
                </div>
            ),
            sortable: true,
            reorder: true
        },






        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">

                    <Link to={"/View-invoice/"+rows.invoice} className="btn btn-sm bg-info-light me-3">
                        <i className="fas fa-eye"></i>
                    </Link>

                    <Link to="#" className="btn btn-sm bg-warning-light me-3">
                        <i className="fas fa-edit"></i>
                    </Link>

                    <Link to="#" className="btn btn-sm bg-danger me-3">
                        <i className="fas fa-power-off"></i>
                    </Link>

                    <Link to="#" className="btn btn-sm bg-danger-light me-3">
                        <i className="fas fa-trash"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },
    ]

    return (
        <>
            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Invoices</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/Dashboard"}>Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Invoices</li>
                                </ul>
                            </div>
                            <div className="col-auto">
                                <Link to={"/Invoice-List"} className="invoices-links active">
                                    <i className="fas fa-list fa-2x"></i>
                                </Link>
                                <Link to="invoice-grid.html" className="invoices-links">
                                    <i className="fas fa-square fa-2x"></i>
                                </Link>
                            </div>
                        </div>
                    </div>


                    <div className="card report-card">
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="app-listing">
                                        <li>
                                            <div className="multipleSelection">
                                                <div className="">
                                                    <p className="mb-2" style={{ color: '#1B2559', fontWeight: '500' }}>
                                                        <i className="fas fa-calendar me-1 select-icon" style={{ color: '#18aefa', width: '20px', height: '20px' }}></i>
                                                        Starting date</p>
                                                    <p>
                                                        <input type="date" name="" className="form-control" />
                                                    </p>
                                                </div>

                                            </div>
                                        </li>
                                        <li>

                                        </li>
                                        <li>
                                            <div className="multipleSelection">
                                                <div className="">
                                                    <p className="mb-2" style={{ color: '#1B2559', fontWeight: '500' }}>
                                                        <i className="fas fa-calendar me-1 select-icon" style={{ color: '#18aefa', width: '20px', height: '20px' }}></i>
                                                        Closing  date</p>
                                                    <p>
                                                        <input type="date" name="" className="form-control" />
                                                    </p>
                                                </div>

                                            </div>
                                        </li>
                                        <li>

                                        </li>
                                        <li>
                                            <div className="report-btn mt-3">
                                                <Link to="#" className="btn">
                                                    <img src="assets/img/icons/invoices-icon5.png" alt="" className="me-2" /> Generate report
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card invoices-tabs-card border-0">
                        <div className="card-body card-body pt-0 pb-0">
                            <div className="invoices-main-tabs">
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-md-8">
                                        <div className="invoices-tabs">
                                            <ul>
                                                <li><Link to="invoices.html" className="active">All Invoice</Link></li>
                                                <li><Link to="invoices-paid.html">Paid</Link></li>
                                                <li><Link to="invoices-overdue.html">Overdue</Link></li>
                                                <li><Link to="invoices-draft.html">Draft</Link></li>
                                                <li><Link to="invoices-recurring.html">Recurring</Link></li>
                                                <li><Link to="invoices-cancelled.html">Cancelled</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="invoices-settings-btn">
                                            <Link to={"/Invoice-settings"} className="invoices-settings-icon">
                                                <i className="fas fa-cog fa-2x"></i>
                                            </Link>
                                            {/* <Link to="add-invoice.html" className="btn">
                                                <i className="fas fa-plus-circle me-2"></i> New Invoice
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card inovices-card">
                                <div className="card-body">
                                    <div className="inovices-widget-header">
                                        <span className="inovices-widget-icon">
                                            <img src="assets/img/icons/invoices-icon1.svg" alt="" />
                                        </span>
                                        <div className="inovices-dash-count">
                                            <div className="inovices-amount">{sumInvoice} XOF</div>
                                        </div>
                                    </div>
                                    <p className="inovices-all">All Invoices <span style={{ fontSize: '14px' }} ><b>{nbrInvoice}</b></span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card inovices-card">
                                <div className="card-body">
                                    <div className="inovices-widget-header">
                                        <span className="inovices-widget-icon">
                                            <img src="assets/img/icons/invoices-icon2.svg" alt="" />
                                        </span>
                                        <div className="inovices-dash-count">
                                            <div className="inovices-amount">{sumPaid} XOF</div>
                                        </div>
                                    </div>
                                    <p className="inovices-all">Paid Invoices <span style={{ fontSize: '14px' }} ><b>{nbrPaid}</b></span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card inovices-card">
                                <div className="card-body">
                                    <div className="inovices-widget-header">
                                        <span className="inovices-widget-icon">
                                            <img src="assets/img/icons/invoices-icon3.svg" alt="" />
                                        </span>
                                        <div className="inovices-dash-count">
                                            <div className="inovices-amount">{sumReamining} XOF</div>
                                        </div>
                                    </div>
                                    <p className="inovices-all">Unpaid Invoices <span style={{ fontSize: '14px' }} ><b>{nbreamining}</b></span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card inovices-card">
                                <div className="card-body">
                                    <div className="inovices-widget-header">
                                        <span className="inovices-widget-icon">
                                            <img src="assets/img/icons/invoices-icon4.svg" alt="" />
                                        </span>
                                        <div className="inovices-dash-count">
                                            <div className="inovices-amount">$8,8,797</div>
                                        </div>
                                    </div>
                                    <p className="inovices-all">Cancelled Invoices <span>80</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-table">
                                <div className="card-body">
                                    <div className="table-responsive">

                                        <DataTableExtensions
                                            columns={columnsList}
                                            data={list}
                                            export={false}
                                            print={false}
                                        >
                                            <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                // selectableRows
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                        </DataTableExtensions>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default InvoiceList;