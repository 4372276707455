import React from "react";
import Menus from "../components/Menus";
import Header from "../components/Header";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import Services from "../services/services";

function DetailModel() {

    const { code } = useParams()

    useEffect(() => {
        DetailModelInvoice(code)
    }, [code])

    const [dataDetail, setDataDetail] = useState([])

    function DetailModelInvoice(code) {
        Services.GetModelBy(code)
            .then((res) => {
                setDataDetail(res.data)
                console.log(res.data)


            })
            .catch((error) => {
                console.log(error)
            })

    }


    const columnsDetail = [
        {
            name: "Type Invoice",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.type_invoice} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Article",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Category",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.category} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Price",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.price} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Quantity",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.qty} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Amount",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.amount} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Discount",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.discount} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Class",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.classes} </b>
            ),
            sortable: true,
            reorder: true
        },




        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">


                    <Link to="#" className="btn btn-sm bg-danger-light me-3">
                        <i className="fas fa-trash"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },
    ]


    return (
        <>
            <Menus />
            <Header />


            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="page-title">Detail Model Invoice  </h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/Simple-invoice"}>Simple Invoice  </Link>
                                    </li>
                                    <li className="breadcrumb-item active">General Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-table">
                                <div className="card-body">

                                    <div className="table-responsive mt-5">
                                        <DataTable
                                            title="DataTable"
                                            columns={columnsDetail}
                                            data={dataDetail}
                                            // customStyles={customStyles}
                                            responsive
                                            noHeader
                                            defaultSortFieldId={1}
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            // selectableRows
                                            persistTableHead
                                            highlightOnHover
                                            exportHeaders={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DetailModel;