import axios from '../apiRest/api';

const get_data_parent = (id) => {
    return axios.get(`/get_data_parent/${id}`);
}


const get_data_parent_by_id = (id) => {
    return axios.get(`/get_data_parent_by_id/${id}`);
}


const delete_data_parent_by_id = (codeP, codeS) => {
    return axios.get(`/delete_data_parent_by_id/${codeP}/${codeS}`);
}


const get_all_data_parent = (person) =>{
    return axios.get(`/get_all_data_parent/${person}`);
}

const insert_upload_file_stud = (data) =>{
    return axios.post('/insert_upload_file_stud',data);
}


const get_file_admissions_student = (codeS) => {
    return axios.get(`/get_file_admissions_student/${codeS}`);
}


const services2 = {
    get_data_parent,
    get_data_parent_by_id,
    delete_data_parent_by_id,
    get_all_data_parent,
    insert_upload_file_stud,
    get_file_admissions_student,
}

export default services2;