import React, { useState } from 'react';
import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link, useParams } from 'react-router-dom'
import services from '../services/services';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';


function StaffAdd() {

    // const {id_satff} = useParams()


    const [data, setData] = useState({
        id_staff:'',
        id_usergroup:'',
        username:'',
        password:'',

    })

    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const newdata = { ...data, [name]: value }
        setData(newdata);
        // console.log(newdata)
    }


    function handleSubmitData(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_staff', idStaffSelect);
        bodyFormData.append('id_usergroup', data.id_usergroup);
        bodyFormData.append('username', data.username);
        bodyFormData.append('password', data.password);

        services.InsertUserAccess(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            })
            .catch((error) => {
                // console.log(error.response);
            })

    }


    // const [dataUserAccess, setDataUserAccess] = useState([]);

    // function getallUserAccess() {
    //     services.getUserAccess()
    //         .then((response) => {

    //             setDataUserAccess(response.data)
    //             console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }

    

    useEffect(() => {
        getallStaff()
        getUsersGroupsOpen()
        // getStaffsOpen()
        // getallUserAccess()
    }, [])


    const [dataStaff, setDataStaff] = useState([]);

    function getallStaff() {
        services.getStaff()
            .then((response) => {

                setDataStaff(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    

    // const [dataStaffsOpen, setDataStaffsOpen] = useState([]);

    // function getStaffsOpen() {
    //     services.get_Staffs_Open()
    //         .then((response) => {

    //             setDataStaffsOpen(response.data)
    //             // console.log(response.data);

    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //         })
    // }


    const [dataUsersGroupsOpen, setDataUsersGroupsOpen] = useState([]);

    function getUsersGroupsOpen() {
        services.get_UsersGroups_Open()
            .then((response) => {

                setDataUsersGroupsOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    // if(dataStaff.status === 1){
    
    const columnsStaff = [
        
        {
            name: "Photo",
            cell: (rows) => (
                // <b style={{ color: "black" }}> <img src='public/staff_photo' alt={rows.photo}/></b>
                <img src={"http://127.0.0.1:8000/staff_photo/"+ rows.photo} width="50" height="60" alt="?" />

            ),
            sortable: true,
            reorder: true
        },
        {
            name: "First Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.firstname} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Last Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.lastname} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Civility",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.civility} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Job Description",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.job_descrip} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Email",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.email} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Etat",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.status === 0) {
                            return (
                                <Link className="me-2">
                                    <i className="fas fa-toggle-off fa-2x" onClick={() => OpenStaff(rows.id)} style={{ color: 'red' }}></i>
                                </Link>
                            )
                        } else {
                            return (
                                <Link className="e-2">
                                    <i className="fas fa-toggle-on fa-2x" onClick={() => Closestaff(rows.id)} style={{ color: 'green' }}></i>
                                </Link>
                            )
                        }
                    })()}



                </div>


            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Create Action",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        if (rows.status === 0) {
                            return (
                                <>
                                    <Link to={"#"}  className="btn btn-sm me-2">
                                    <button type="button" className="btn btn-info" onClick={() => showModalAdmin(rows.id)} ><i className="fas fa-cog"></i> </button>
                                        
                                    </Link>
                                </>
                            )
                        }
        
                    })()}
        
                </div>
        
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateStaff(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                {/* <Link to="#" onClick={(e) => DeleteStaff(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link> */}
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    // }

    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataStaf, setStaf] = useState({
        It_teacher:'',
        Council_mem:'',
        Civility:'',
        FirstName:'',
        LastName:'',
        Email:'',
        Sms_phone:'',
        Phone_2:'',
        Departemnt:'',
        Job_descrip:'',
        Enter_date:'',
        Contract:'',
        Supervisor:'',
        Main_lang:'',
    })

    const handleStaffs = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...dataStaf, [name]: value }
        setStaf(data);
        console.log(data)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    function OpenStaff(id) {
        services.openStaff(id)
                    .then(response => {
                        console.log(response.data);
                        getallStaff()
                        // getallUserAccess()
                        
                    })
                    .catch(e => {
                        console.log(e);
                    }); 
    }


    /** Fermerture des etats */
    
    function Closestaff(id) {
        services.closeStaff(id)
                    .then(response => {
                        console.log(response.data);
                        getallStaff()
                        // getallUserAccess()
                        
                    })
                    .catch(e => {
                        console.log(e);
                    });    
    }


    /** Affichage des donnée enregistré */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditStaffs, setShowEditModalStaffs] = useState(false)

    function ShowModalUpdateStaff(id) {
        setShowEditModalStaffs(true);
        dataStaffsEdit(id)

    }
    const handleEditCloseModalStaffs = () => setShowEditModalStaffs(false)
    /**Activation du modale pour l'update */

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditStaff, setDataEditStaff] = useState([])

    function dataStaffsEdit(id) {
        services.get_Staffs_by(id)
            .then((response) => {

                setDataEditStaff(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */


    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateStaffs(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_Staf', id);
        bodyFormData.append('Photo', dataPhoto.Photo);
        bodyFormData.append('It_teacher', dataStaf.It_teacher);
        bodyFormData.append('Council_mem', dataStaf.Council_mem);
        bodyFormData.append('Civility', dataStaf.Civility);
        bodyFormData.append('FirstName', dataStaf.FirstName);
        bodyFormData.append('LastName', dataStaf.LastName);
        bodyFormData.append('Email', dataStaf.Email);
        bodyFormData.append('Sms_phone', dataStaf.Sms_phone);
        bodyFormData.append('Phone_2', dataStaf.Phone_2);
        bodyFormData.append('Departemnt', dataStaf.Departemnt);
        bodyFormData.append('Job_descrip', dataStaf.Job_descrip);
        bodyFormData.append('Enter_date', dataStaf.Enter_date);
        bodyFormData.append('Contract', dataStaf.Contract);
        bodyFormData.append('Supervisor', dataStaf.Supervisor);
        bodyFormData.append('Main_lang', dataStaf.Main_lang);
        bodyFormData.append('Signature', datasignature.Signature);

        services.updateStaffs(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleEditCloseModalStaffs()
                getallStaff()
                // getallUserAccess()

            })
            .catch((error) => {
                console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [idStaffSelect, setIdStaffSelect] = useState()

     /** Activation modal d'insertion Term Management */
     const [showModalAdminStaff, setShowModalAdminStaff] = useState(false)

     function showModalAdmin(id) {
         setShowModalAdminStaff(true); //open modal
         setIdStaffSelect(id)
     }
     const handleCloseModalAdminStaff = () => setShowModalAdminStaff(false) ///close modal
     /** Activation modal d'insertion Term Management */


     function ChooseAdminStaff(e) {
        const value = e.target.value;
        window.location.replace("/users-staff/"+value)
    }


    const sess_Photo = JSON.parse(sessionStorage.getItem('Photo')) 


    const [dataPhoto, setDataPhoto] = useState({
        Photo : ''
    })
    
    function handleFilephoto(e) {
        const name = e.target.name;
        const value = e.target.files[0];
        const data = { ...dataPhoto, [name]: value }
        setDataPhoto(data)
        console.log(data)
    
    }


    const sess_signature = JSON.parse(sessionStorage.getItem('Signature')) 


    const [datasignature, setDatasignature] = useState({
        Signature : ''
    })
    
    function handleFileignature(e) {
        const name = e.target.name;
        const value = e.target.files[0];
        const data = { ...datasignature, [name]: value }
        setDatasignature(data)
        console.log(data)
    
    }


    return (
        <>
            <Header />
            <Menus />


            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Staff</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp</a></li>
                                    <li className="breadcrumb-item active">Users Access</li>
                                </ul>
                            </div>
                        </div>
                        <div className=" text-end float-end ms-auto">
                            {/* <Link to="#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</Link> */}
                            <Link to="/Add-Staff" className="btn btn-primary"><i className="fas fa-plus"> Add Staff</i></Link>

                        </div>
                    </div>
                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {/* <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalAdmin()} ><i className="fas fa-plus"></i> Add/VIEW Pragram Event </button> */}
                                                
                                                <DataTableExtensions
                                                    columns={columnsStaff}
                                                    data={dataStaff}
                                                    export={false}
                                                    print={false}
                                                >
                                                    <DataTable
                                                        title="DataTable"
                                                        // customStyles={customStyles}
                                                        responsive
                                                        noHeader
                                                        defaultSortFieldId={1}
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        persistTableHead
                                                        highlightOnHover
                                                        exportHeaders={false}
                                                    />
                                                </DataTableExtensions>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={showModalAdminStaff} onHide={handleCloseModalAdminStaff} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Choose classes section</h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="GET" onSubmit={(e) => handleSubmitData(e,)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold'}}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-12">
                                    <label htmlFor="">Role</label>
                                    <select name="id_usergroup" id="" onChange={(e) => handle(e)} className="form-control">
                                    <option value="">Select role</option>{
                                    dataUsersGroupsOpen.map((result) => (
                                         <option key={result.id} value={result.libelle}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='username'
                                            onChange={(e) => handle(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name='password'
                                            onChange={(e) => handle(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button Button type="submit" variant='warning'>
                            submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>


            <Modal show={showModalEditStaffs} onHide={handleEditCloseModalStaffs} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update Teacher </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateStaffs(e, dataEditStaff.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Photo <code>(png, jpg, jpeg)</code></label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name='Photo'
                                            onChange={(e) => handleFilephoto(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                        <img src={'http://127.0.0.1:8000/'+sess_Photo} width="50" alt='' />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>It Teacher</label>
                                        <select className="form-control" name='It_teacher' id='It_teacher' style={{ fontWeight: 'bold' }} defaultValue={dataEditStaff.it_teacher} onChange={(e) => handleStaffs(e)}>
                                            <option value={data.it_teacher}>Select It_teacher</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Council Member</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='Council_mem'
                                            defaultValue={dataEditStaff.council_mem} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Civility</label>
                                        <select className="form-control" name='Civility' id='Civility' style={{ fontWeight: 'bold' }} defaultValue={dataEditStaff.civility} onChange={(e) => handleStaffs(e)}>
                                            <option >Select Civility</option>
                                            <option value="Mister">Mister</option>
                                            <option value="Madam">Madam</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='FirstName'
                                            defaultValue={dataEditStaff.firstname} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='LastName'
                                            defaultValue={dataEditStaff.lastname} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name='Email'
                                            defaultValue={dataEditStaff.email} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>SMS Phone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='Sms_phone'
                                            defaultValue={dataEditStaff.sms_phone} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Phone 2</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='Phone_2'
                                            defaultValue={dataEditStaff.phone_2} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Departements</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='Departemnt'
                                            defaultValue={dataEditStaff.departemnt} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Job Description</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='Job_descrip'
                                            defaultValue={dataEditStaff.job_descrip} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Enter date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            name='Enter_date'
                                            defaultValue={dataEditStaff.enter_date} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Contract</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='Contract'
                                            defaultValue={dataEditStaff.contract} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Supervisor</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name='Supervisor'
                                            defaultValue={dataEditStaff.supervisor} onChange={(e) => handleStaffs(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Main Language</label>
                                        <select className="form-control" name='Main_lang' id='Main_lang' style={{ fontWeight: 'bold' }} defaultValue={dataEditStaff.main_lang} onChange={(e) => handleStaffs(e)}>
                                            <option >Select Main Language</option>
                                            <option value="English">English</option>
                                            <option value="French">Madam</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Signature <code>(png, jpg, jpeg)</code></label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name='Signature'
                                            onChange={(e) => handleFileignature(e)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                        <img src={'http://127.0.0.1:8000/'+sess_signature} width="50" alt='' />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button Button type="submit" variant='warning'>
                            update
                        </Button>
                   </Modal.Footer>
                </form>
            </Modal > 
           
        </>
    )


}

export default StaffAdd;