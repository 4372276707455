import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Header from '../../components/Header'
import { Link, useParams } from 'react-router-dom'
import services from '../../services/services'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions';
import NavAdmission from './NavAdmission'
import { Button, Modal } from 'react-bootstrap'
import services2 from '../../services/services2'
import { Next } from 'react-bootstrap/esm/PageItem'

function Admission() {

    const { code } = useParams();


    useEffect(() => {
        
      
        GetStep2()
        GetStep3()
        GetStep4()
        GetStep6()

        // chooseClass();
        redirect();
        getAuth();

      

    }, [code]);

  



    function GetStep2() {
        services.get_data_stud_admission_step2(code)
            .then((res) => {
                sessionStorage.setItem('Emergency_Name1', JSON.stringify(res.data[0].name_1))
                sessionStorage.setItem('Emergency_Contact1', JSON.stringify(res.data[0].contact_1))
                sessionStorage.setItem('emergency_relation1', JSON.stringify(res.data[0].relation_1))
                sessionStorage.setItem('Emergency_Name2', JSON.stringify(res.data[0].name_2))
                sessionStorage.setItem('Emergency_Contact2', JSON.stringify(res.data[0].contact_2))
                sessionStorage.setItem('emergency_relation2', JSON.stringify(res.data[0].relation_2))


                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    function GetStep3() {
        services.get_data_stud_admission_step3(code)
            .then((res) => {
              
               sessionStorage.setItem('BloodGroup', JSON.stringify(res.data[0].BloodGroup))
               sessionStorage.setItem('DoctorName', JSON.stringify(res.data[0].DoctorName)) 
               sessionStorage.setItem('DoctorContact', JSON.stringify(res.data[0].DoctorContact))
               sessionStorage.setItem('any_recommandation', JSON.stringify(res.data[0].any_recommandation))
               sessionStorage.setItem('any_medecine', JSON.stringify(res.data[0].any_medecine))
               sessionStorage.setItem('Drug1', JSON.stringify(res.data[0].Drug1))
               sessionStorage.setItem('Drug2', JSON.stringify(res.data[0].Drug2))
               sessionStorage.setItem('Drug3', JSON.stringify(res.data[0].Drug3))
               sessionStorage.setItem('Drug4', JSON.stringify(res.data[0].Drug4))
               sessionStorage.setItem('Drug5', JSON.stringify(res.data[0].Drug5))
               sessionStorage.setItem('Drug6', JSON.stringify(res.data[0].Drug6))
               sessionStorage.setItem('Drug7', JSON.stringify(res.data[0].Drug7))
               sessionStorage.setItem('Drug8', JSON.stringify(res.data[0].Drug8))
               sessionStorage.setItem('presence_of_any', JSON.stringify(res.data[0].presence_of_any))
               sessionStorage.setItem('Alimentary', JSON.stringify(res.data[0].Alimentary))
               sessionStorage.setItem('Related_to_insects', JSON.stringify(res.data[0].Related_to_insects))
               sessionStorage.setItem('Drug_related', JSON.stringify(res.data[0].Drug_related))
               sessionStorage.setItem('Other_allergy', JSON.stringify(res.data[0].Other_allergy))
               sessionStorage.setItem('Does_the_allergy', JSON.stringify(res.data[0].Does_the_allergy))
               sessionStorage.setItem('Allergy_reaction', JSON.stringify(res.data[0].Allergy_reaction))
               sessionStorage.setItem('Other_medical', JSON.stringify(res.data[0].Other_medical))
                sessionStorage.setItem('Other_medical_justification', JSON.stringify(res.data[0].Other_medical_justification))
               sessionStorage.setItem('Learning_difficulties', JSON.stringify(res.data[0].Learning_difficulties))
                sessionStorage.setItem('Learning_difficulties_justification', JSON.stringify(res.data[0].Learning_difficulties_justification))

                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    

    function GetStep4() {
        services.get_data_stud_admission_step4(code)
            .then((res) => {
              
               sessionStorage.setItem('Pickup_Name1', JSON.stringify(res.data[0].name1))
               sessionStorage.setItem('Pickup_Contact1', JSON.stringify(res.data[0].contact1)) 
               sessionStorage.setItem('Pickup_Relation1', JSON.stringify(res.data[0].relation1))
               sessionStorage.setItem('Pickup_photo1', JSON.stringify(res.data[0].photo1))

               sessionStorage.setItem('Pickup_Name2', JSON.stringify(res.data[0].name2))
               sessionStorage.setItem('Pickup_Contact2', JSON.stringify(res.data[0].contact2)) 
               sessionStorage.setItem('Pickup_Relation2', JSON.stringify(res.data[0].relation2))
               sessionStorage.setItem('Pickup_photo2', JSON.stringify(res.data[0].photo2))
               

                console.log(res.data)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }


    // const [classes, setClasses] = useState([])

 
    // function chooseClass() {
        

    //     if (!JSON.parse(sessionStorage.getItem('class_choose'))) {

    //         Swal.fire({
    //             title: 'Select the class to proceed to registration.',
    //             input: 'select',
    //             inputOptions: {
    //                 'G1': 'G1',
    //                 'G2': 'G2'
    //             },
    //             inputPlaceholder: 'Select class',
    //             showCancelButton: true,
    //             inputValidator: (value) => {
    //                 YesClasses()
    //                 setClasses(value)
    //                 sessionStorage.setItem('class_choose', JSON.stringify(value))
    //                 console.log(value)
    //             }
    //         })

    //     }


    // }
    

    function getAuth() {
        
        services.get_auth(JSON.parse(sessionStorage.getItem('token')))
        .then((res) =>{
            sessionStorage.setItem('id_user', JSON.stringify(res.data[0].id))
            // console.log(res.data)
        });
    }

    const redirect = () => {
        if (!JSON.parse(sessionStorage.getItem('token'))) {
            window.location.replace('/Login')
        }
    }

    

    const [showStep, setShowStep] = useState();
    const [showStep2, setShowStep2] = useState();
    const [showStep3, setShowStep3] = useState();
    const [showStep4, setShowStep4] = useState();
    const [showStep5, setShowStep5] = useState();
    const [showStep6, setShowStep6] = useState();
    const [showStep7, setShowStep7] = useState();

    function YesClasses() {
        setShowStep(!showStep)
    }

    function Step1() {
        setShowStep(!showStep)
        setShowStep2(!showStep2)
    }
  

    function Step2() {
            setShowStep(!showStep)
            setShowStep2(!showStep2)
            
        
    }

    function Step3() {
        setShowStep2(!showStep2)
        setShowStep3(!showStep3) 
    }

    function Step4() {
        setShowStep3(!showStep3)
        setShowStep4(!showStep4)
    }

    function Step5() {
        setShowStep4(!showStep4)
        setShowStep5(!showStep5)
    }

    function PreviewStep2() {
        setShowStep2(!showStep2)
        setShowStep3(!showStep3)
        setDatamsg("")
    }


    function PreviewStep3() {
        setShowStep3(!showStep3)
        setShowStep4(!showStep4)
        setDatamsg("")

    }


    function PreviewStep4() {
        setShowStep4(!showStep4)
        setShowStep5(!showStep5)
        setDatamsg("")

    }

    function PreviewStep5() {
        setShowStep5(!showStep5)
        setShowStep6(!showStep6)
        setDatamsg("")

    }

    function PreviewStep6() {
        setShowStep6(!showStep6)
        setShowStep7(!showStep7)
        setDatamsg("")

    }

    function NextStep6() {
        setShowStep5(!showStep5)
        setShowStep6(!showStep6)

    }

    function NextStep7() {
        setShowStep6(!showStep6)
        setShowStep7(!showStep7)
    }


    const [dataStudent, setDataStudent] = useState({
        FirstName: '',
        LastName: '',
        BirthCity: '',
        DateBirth: '',
        Gender: '',
        BirthCountry: '',
        nationality: '',

        Emergency_FirstName1: '',
        Emergency_LastName1: '',
        emergency_relation1: '',
        Emergency_FirstName2: '',
        Emergency_LastName2: '',
        emergency_relation2: '',
    })

    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem('class_choose'))) {
            setShowStep(!showStep)
        }
    }, [])

    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;

        const data = {...dataStudent, [name]: value }
        setDataStudent(data)
        sessionStorage.setItem(name, JSON.stringify(value))
    }

    const [dataPhoto, setDataPhoto] = useState({
        file: '',
    })

    function handleFile(e) {
        const name = e.target.name;
        const value = e.target.files[0];
        const data = { ...dataPhoto, [name]: value }
        setDataPhoto(data)
        console.log(data)
    
      }


      

      const sees_class = JSON.parse(sessionStorage.getItem('class_choose'))

      const sessFirstName = JSON.parse(sessionStorage.getItem('FirstName'))
      const sessLastName = JSON.parse(sessionStorage.getItem('LastName'))
      const sessGender = JSON.parse(sessionStorage.getItem('Gender'))
      const sessnationality = JSON.parse(sessionStorage.getItem('nationality'))
      const sessDateBirth = JSON.parse(sessionStorage.getItem('DateBirth'))
      const sessBirthCity = JSON.parse(sessionStorage.getItem('BirthCity'))
      const sessBirthCountry = JSON.parse(sessionStorage.getItem('BirthCountry'))

      const sess_Emergency_Name1 = JSON.parse(sessionStorage.getItem('Emergency_Name1'))
      const sess_Emergency_Contact1 = JSON.parse(sessionStorage.getItem('Emergency_Contact1'))
      const sess_emergency_relation1 = JSON.parse(sessionStorage.getItem('emergency_relation1')) 
      const sess_Emergency_Name2 = JSON.parse(sessionStorage.getItem('Emergency_Name2'))
      const sess_Emergency_Contact2 = JSON.parse(sessionStorage.getItem('Emergency_Contact2'))
      const sess_emergency_relation2 = JSON.parse(sessionStorage.getItem('emergency_relation2'))
      
      const sess_id_user = JSON.parse(sessionStorage.getItem('id_user'))
    //   const sess_code_stud = JSON.parse(sessionStorage.getItem('code_stud'))

      const [msg, setDatamsg] = useState('')

      function handleSubmitInfosStudent(e) {
            e.preventDefault();

            const bodyData = new FormData();

            bodyData.append('classroom', sees_class)
            bodyData.append('id_user', sess_id_user)
            bodyData.append('FirstName', sessFirstName)
            bodyData.append('LastName', sessLastName)
            bodyData.append('Gender', sessGender)
            bodyData.append('nationality', sessnationality)
            bodyData.append('DateBirth', sessDateBirth)
            bodyData.append('BirthCity', sessBirthCity)
            bodyData.append('BirthCountry', sessBirthCountry)
            // bodyData.append('file', dataPhoto.file)

            services.insert_info_student(bodyData)
            .then((result) =>{
                setDatamsg(result.data.message);
                sessionStorage.setItem('code_stud', JSON.stringify(result.data.code_stud))
                // console.log(result.data)
                Step2();
            }).catch((error)=>{
                console.error(error.response)
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error.response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                  })
            })
      }



      function handleSubmitEmergency(e) {
        e.preventDefault();

        const bodyData = new FormData();

        bodyData.append('Emergency_Name1', sess_Emergency_Name1)
        bodyData.append('Emergency_Contact1', sess_Emergency_Contact1)
        bodyData.append('emergency_relation1', sess_emergency_relation1)
        bodyData.append('Emergency_Name2', sess_Emergency_Name2)
        bodyData.append('Emergency_Contact2', sess_Emergency_Contact2)
        bodyData.append('emergency_relation2', sess_emergency_relation2)
        if (code === '0') {
            
            bodyData.append('code_student',  JSON.parse(sessionStorage.getItem('code_stud')))
        }else{
            bodyData.append('code_student', code)

        }

        services.insert_info_emergency(bodyData)
        .then((result) =>{
            setDatamsg(result.data.message);
            // console.log(result.data)
            Step3();
        }).catch((error)=>{
            console.error(error.response)
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 5000
              })
        })
  }




  const [anyReconn, setAnyrecomm] = useState()
  
  function handleAnyRecommandation(e) {
      setAnyrecomm(!anyReconn)

      const name = e.target.name;
        const value = e.target.value;

        const data = {...dataStudent, [name]: value }
        setDataStudent(data)
        sessionStorage.setItem(name, JSON.stringify(value))

    }
    
  const [anyMedecine, setAnyMedecine] = useState()
  function handleAnymedecine(e) {
    setAnyMedecine(!anyMedecine)

    const name = e.target.name;
    const value = e.target.value;

    const data = {...dataStudent, [name]: value }
    setDataStudent(data)
    sessionStorage.setItem(name, JSON.stringify(value))

  }
  

  const [medication, setMedication] = useState()
  function handleMedication(e) {
    setMedication(!medication)

    const name = e.target.name;
    const value = e.target.value;

    const data = {...dataStudent, [name]: value }
    setDataStudent(data)
    sessionStorage.setItem(name, JSON.stringify(value))
  }

  
  const [presenceallergy, setPresenceallergy] = useState()
  function handlePresenceallergy(e) {
    setPresenceallergy(!presenceallergy)

    const name = e.target.name;
    const value = e.target.value;

    const data = {...dataStudent, [name]: value }
    setDataStudent(data)
    sessionStorage.setItem(name, JSON.stringify(value))
  }

  

  const [doesallergy, setDoesallergy] = useState()
  function handleDoesallergy(e) {
    setDoesallergy(!doesallergy)

    const name = e.target.name;
    const value = e.target.value;

    const data = {...dataStudent, [name]: value }
    setDataStudent(data)
    sessionStorage.setItem(name, JSON.stringify(value))
  }




  const sess_BloodGroup = JSON.parse(sessionStorage.getItem('BloodGroup'))
  const sess_DoctorName = JSON.parse(sessionStorage.getItem('DoctorName'))
  const sess_DoctorContact = JSON.parse(sessionStorage.getItem('DoctorContact')) 
  const sess_any_recommandation = JSON.parse(sessionStorage.getItem('any_recommandation'))
  const sess_any_medecine = JSON.parse(sessionStorage.getItem('any_medecine'))
  const sess_medication_must = JSON.parse(sessionStorage.getItem('medication_must'))
  const sess_Drug1 = JSON.parse(sessionStorage.getItem('Drug1'))
  const sess_Drug2 = JSON.parse(sessionStorage.getItem('Drug2'))
  const sess_Drug3 = JSON.parse(sessionStorage.getItem('Drug3'))
  const sess_Drug4 = JSON.parse(sessionStorage.getItem('Drug4'))
  const sess_Drug5 = JSON.parse(sessionStorage.getItem('Drug5'))
  const sess_Drug6 = JSON.parse(sessionStorage.getItem('Drug6'))
  const sess_Drug7 = JSON.parse(sessionStorage.getItem('Drug7'))
  const sess_Drug8 = JSON.parse(sessionStorage.getItem('Drug8'))
  const sess_presence_of_any = JSON.parse(sessionStorage.getItem('presence_of_any'))
  const sess_Alimentary = JSON.parse(sessionStorage.getItem('Alimentary'))
  const sess_Related_to_insects = JSON.parse(sessionStorage.getItem('Related_to_insects'))
  const sess_Drug_related = JSON.parse(sessionStorage.getItem('Drug_related'))
  const sess_Other_allergy = JSON.parse(sessionStorage.getItem('Other_allergy'))
  const sess_Allergy_reaction = JSON.parse(sessionStorage.getItem('Allergy_reaction'))
  const sess_Other_medical = JSON.parse(sessionStorage.getItem('Other_medical'))
  const sess_Other_medical_justification = JSON.parse(sessionStorage.getItem('Other_medical_justification'))
  const sess_Learning_difficulties = JSON.parse(sessionStorage.getItem('Learning_difficulties'))
  const sess_Learning_difficulties_justification = JSON.parse(sessionStorage.getItem('Learning_difficulties_justification'))
  const sess_Does_the_allergy = JSON.parse(sessionStorage.getItem('Does_the_allergy'))

 useEffect(() =>{
    if(sess_any_recommandation === 'Yes'){

        setAnyrecomm(!anyReconn)
    }

    if(sess_any_recommandation === 'Yes'){

        setAnyMedecine(!anyMedecine)
    }
    
    if(sess_medication_must === 'Yes'){

        setMedication(!medication)
    }

    if(sess_presence_of_any === 'Yes'){

        setPresenceallergy(!presenceallergy)
    }

    if(sess_Does_the_allergy === 'Yes'){
        setDoesallergy(!doesallergy)
    }
    
    
 }, [])


 

 const [dataJustificationMedical, setDataJustificationMedical] = useState({
    JustificationMedical : ''
})

function handleJustificationMedical(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataJustificationMedical, [name]: value }
    setDataJustificationMedical(data)
    console.log(data)

  }



  const [dataJustificationLearning, setDataJustificationLearning] = useState({
    JustificationLearning : ''
})

function handleJustificationLearning(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataJustificationLearning, [name]: value }
    setDataJustificationLearning(data)
    console.log(data)

  }

 
 function handleSubmitMedical(e) {
    e.preventDefault();

    const bodyData = new FormData();

    bodyData.append('BloodGroup', sess_BloodGroup)
    bodyData.append('DoctorName', sess_DoctorName)
    bodyData.append('DoctorContact', sess_DoctorContact)
    bodyData.append('any_recommandation', sess_any_recommandation)
    bodyData.append('any_medecine', sess_any_medecine)
    bodyData.append('Drug1', sess_Drug1)
    bodyData.append('Drug2', sess_Drug2)
    bodyData.append('Drug3', sess_Drug3)
    bodyData.append('Drug4', sess_Drug4)
    bodyData.append('Drug5', sess_Drug5)
    bodyData.append('Drug6', sess_Drug6)
    bodyData.append('Drug7', sess_Drug7)
    bodyData.append('Drug8', sess_Drug8)
    bodyData.append('presence_of_any', sess_presence_of_any)
    bodyData.append('Alimentary', sess_Alimentary)
    bodyData.append('Related_to_insects', sess_Related_to_insects)
    bodyData.append('Drug_related', sess_Drug_related)
    bodyData.append('Other_allergy', sess_Other_allergy)
    bodyData.append('Does_the_allergy', sess_Does_the_allergy)
    bodyData.append('Allergy_reaction', sess_Allergy_reaction)
    bodyData.append('Other_medical', sess_Other_medical)
    bodyData.append('Other_medical_justification', dataJustificationMedical.JustificationMedical)
    bodyData.append('Learning_difficulties', sess_Learning_difficulties)
    bodyData.append('Learning_difficulties_justification', dataJustificationLearning.JustificationLearning)
    if (code === '0') {
        
        bodyData.append('code_student',  JSON.parse(sessionStorage.getItem('code_stud')))
    }else{
        bodyData.append('code_student', code)

    }

    services.insert_info_medical(bodyData)
    .then((result) =>{
        setDatamsg(result.data.message);
        // console.log(result.data)
        Step4();
    }).catch((error)=>{
        console.error(error.response)
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
    })
}


const [dataPhotoPickup1, setDataPhotoPickup1] = useState({
    Pickup_photo1 : ''
})

function handleFilePickup1(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataPhotoPickup1, [name]: value }
    setDataPhotoPickup1(data)
    console.log(data)

}


  const [dataPhotoPickup2, setDataPhotoPickup2] = useState({
    Pickup_photo2 : ''
})

function handleFilePickup2(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataPhotoPickup2, [name]: value }
    setDataPhotoPickup2(data)
    console.log(data)

  }

const sess_Pickup_Name1 = JSON.parse(sessionStorage.getItem('Pickup_Name1'))
const sess_Pickup_Contact1 = JSON.parse(sessionStorage.getItem('Pickup_Contact1'))
const sess_Pickup_Relation1 = JSON.parse(sessionStorage.getItem('Pickup_Relation1'))  
const sess_Pickup_Photo1 = JSON.parse(sessionStorage.getItem('Pickup_photo1')) 

const sess_Pickup_Name2 = JSON.parse(sessionStorage.getItem('Pickup_Name2'))
const sess_Pickup_Contact2 = JSON.parse(sessionStorage.getItem('Pickup_Contact2'))
const sess_Pickup_Relation2 = JSON.parse(sessionStorage.getItem('Pickup_Relation2')) 
const sess_Pickup_Photo2 = JSON.parse(sessionStorage.getItem('Pickup_photo2')) 



function handleSubmitPickupChild(e) {
    e.preventDefault();

    const bodyData = new FormData(); 

    bodyData.append('Pickup_Name1', sess_Pickup_Name1)
    bodyData.append('Pickup_Contact1', sess_Pickup_Contact1)
    bodyData.append('Pickup_Relation1', sess_Pickup_Relation1)
    bodyData.append('Pickup_Name2', sess_Pickup_Name2)
    bodyData.append('Pickup_Contact2', sess_Pickup_Contact2)
    bodyData.append('Pickup_Relation2', sess_Pickup_Relation2)
    bodyData.append('Pickup_photo1', dataPhotoPickup1.Pickup_photo1) 
    bodyData.append('Pickup_photo2', dataPhotoPickup2.Pickup_photo2) 
    if (code === '0') { 
        
        bodyData.append('code_student',  JSON.parse(sessionStorage.getItem('code_stud')))
    }else{
        bodyData.append('code_student', code)

    }

    services.insert_pickup_chlid(bodyData)
    .then((result) =>{
        setDatamsg(result.data.message); 
        console.log(result.data)
        Step5();
        list_parent(JSON.parse(sessionStorage.getItem('id_user')))

    }).catch((error)=>{
        console.error(error.response)
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
    })
}



const [showModalStep5, setShowModalStep5] = useState(false)
const [parent, setParent] = useState('')

function openModal(p) {
    setShowModalStep5(true);
    setParent(p)
    setParentNoExist()
    setParentExist()
}

const closeModal = () => setShowModalStep5(false);


const [parentExist, setParentExist] = useState();
const [parentNoExist, setParentNoExist] = useState();

function Parentregistered(e) {

    
    const name = e.target.name;
    const value = e.target.value;
    if (value === "Yes") {
        setParentExist(!parentExist)
        setParentNoExist()
    }

    if (value === "No") {
        setParentNoExist(!parentNoExist)
        setParentExist()

        sessionStorage.removeItem('parent_registered_Father');
      sessionStorage.removeItem('civility_Father');
       sessionStorage.removeItem('last_name_Father');
        sessionStorage.removeItem('first_name_Father');
        sessionStorage.removeItem('email_1_Father');
        sessionStorage.removeItem('email_2_Father');
         sessionStorage.removeItem('phone_main_Father');
        sessionStorage.removeItem('other_phone_Father');
        sessionStorage.removeItem('whatsapp_phone_Father');
         sessionStorage.removeItem('work_phone_Father');
         sessionStorage.removeItem('home_phone_Father');
         sessionStorage.removeItem('home_addres_Father');
        sessionStorage.removeItem('postal_code_Father');
        sessionStorage.removeItem('country_Father');
        sessionStorage.removeItem('nationality_Father');
        sessionStorage.removeItem('sms_lang_Father');
         sessionStorage.removeItem('city_Father');
         sessionStorage.removeItem('occupation_Father');
         sessionStorage.removeItem('enterprise_Father');
         sessionStorage.removeItem('enterprise_addres_Father');
        sessionStorage.removeItem('responsible_Father');



        sessionStorage.removeItem('parent_registered_Mother');
      sessionStorage.removeItem('civility_Mother');
       sessionStorage.removeItem('last_name_Mother');
        sessionStorage.removeItem('first_name_Mother');
        sessionStorage.removeItem('email_1_Mother');
        sessionStorage.removeItem('email_2_Mother');
         sessionStorage.removeItem('phone_main_Mother');
        sessionStorage.removeItem('other_phone_Mother');
        sessionStorage.removeItem('whatsapp_phone_Mother');
         sessionStorage.removeItem('work_phone_Mother');
         sessionStorage.removeItem('home_phone_Mother');
         sessionStorage.removeItem('home_addres_Mother');
        sessionStorage.removeItem('postal_code_Mother'); 
        sessionStorage.removeItem('country_Mother');
        sessionStorage.removeItem('nationality_Mother');
        sessionStorage.removeItem('sms_lang_Mother');
         sessionStorage.removeItem('city_Mother');
         sessionStorage.removeItem('occupation_Mother');
         sessionStorage.removeItem('enterprise_Mother');
         sessionStorage.removeItem('enterprise_addres_Mother');
        sessionStorage.removeItem('responsible_Mother');


        sessionStorage.removeItem('parent_registered_Guardian');
        sessionStorage.removeItem('civility_Guardian');
         sessionStorage.removeItem('last_name_Guardian');
          sessionStorage.removeItem('first_name_Guardian');
          sessionStorage.removeItem('email_1_Guardian');
          sessionStorage.removeItem('email_2_Guardian');
           sessionStorage.removeItem('phone_main_Guardian');
          sessionStorage.removeItem('other_phone_Guardian');
          sessionStorage.removeItem('whatsapp_phone_Guardian');
           sessionStorage.removeItem('work_phone_Guardian');
           sessionStorage.removeItem('home_phone_Guardian');
           sessionStorage.removeItem('home_addres_Guardian');
          sessionStorage.removeItem('postal_code_Guardian');
          sessionStorage.removeItem('country_Guardian');
          sessionStorage.removeItem('nationality_Guardian');
          sessionStorage.removeItem('sms_lang_Guardian');
           sessionStorage.removeItem('city_Guardian');
           sessionStorage.removeItem('occupation_Guardian');
           sessionStorage.removeItem('enterprise_Guardian');
           sessionStorage.removeItem('enterprise_addres_Guardian');
          sessionStorage.removeItem('responsible_Guardian');
        
    }

    const data = {...dataStudent, [name]: value }
    setDataStudent(data)
    sessionStorage.setItem(name, JSON.stringify(value))
}


const [dataSearch, setDataSearch] = useState([])
const [searchTerm, setSearchTerm] = useState("")

useEffect(() =>{
    services2.get_all_data_parent(parent).then((res) =>{ 
        console.log(res.data);
        setDataSearch(res.data);
    }).catch((err)=>{
        console.log(err)
    })
}, [parent])
const handleSearchBar = (e) => {
   let value = e.target.value;
   value.length > 2 && setSearchTerm(value);
}

function ChooseParent(params) {
    
}

// console.log(searchTerm)


        const civility_father = JSON.parse(sessionStorage.getItem('civility_Father'));
        const last_name_father = JSON.parse(sessionStorage.getItem('last_name_Father'));
        const first_name_father = JSON.parse(sessionStorage.getItem('first_name_Father'));
        const email_1_father = JSON.parse(sessionStorage.getItem('email_1_Father'));
        const email_2_father = JSON.parse(sessionStorage.getItem('email_2_Father'));
        const phone_main_father = JSON.parse(sessionStorage.getItem('phone_main_Father'));
        const other_phone_father = JSON.parse(sessionStorage.getItem('other_phone_Father'));
        const whatsapp_phone_father = JSON.parse(sessionStorage.getItem('whatsapp_phone_Father'));
        const work_phone_father = JSON.parse(sessionStorage.getItem('work_phone_Father'));
        const home_phone_father = JSON.parse(sessionStorage.getItem('home_phone_Father'));
        const home_addres_father = JSON.parse(sessionStorage.getItem('home_addres_Father'));
        const postal_code_father = JSON.parse(sessionStorage.getItem('postal_code_Father'));
        const country_father = JSON.parse(sessionStorage.getItem('country_Father'));
        const nationality_father = JSON.parse(sessionStorage.getItem('nationality_Father'));
        const city_father = JSON.parse(sessionStorage.getItem('city_Father'));
        const occupation_father = JSON.parse(sessionStorage.getItem('occupation_Father'));
        const sms_lang_father = JSON.parse(sessionStorage.getItem('sms_lang_Father'));
        const enterprise_father = JSON.parse(sessionStorage.getItem('enterprise_Father'));
        const enterprise_addres_father = JSON.parse(sessionStorage.getItem('enterprise_addres_Father'));
        const responsible_Father = JSON.parse(sessionStorage.getItem('responsible_Father'));



        const civility_mother = JSON.parse(sessionStorage.getItem('civility_Mother'));
        const last_name_mother = JSON.parse(sessionStorage.getItem('last_name_Mother'));
        const first_name_mother = JSON.parse(sessionStorage.getItem('first_name_Mother'));
        const email_1_mother = JSON.parse(sessionStorage.getItem('email_1_Mother'));
        const email_2_mother = JSON.parse(sessionStorage.getItem('email_2_Mother'));
        const phone_main_mother = JSON.parse(sessionStorage.getItem('phone_main_Mother'));
        const other_phone_mother = JSON.parse(sessionStorage.getItem('other_phone_Mother'));
        const whatsapp_phone_mother = JSON.parse(sessionStorage.getItem('whatsapp_phone_Mother'));
        const work_phone_mother = JSON.parse(sessionStorage.getItem('work_phone_Mother'));
        const home_phone_mother = JSON.parse(sessionStorage.getItem('home_phone_Mother'));
        const home_addres_mother = JSON.parse(sessionStorage.getItem('home_addres_Mother'));
        const postal_code_mother = JSON.parse(sessionStorage.getItem('postal_code_Mother'));
        const country_mother = JSON.parse(sessionStorage.getItem('country_Mother'));
        const nationality_mother = JSON.parse(sessionStorage.getItem('nationality_Mother'));
        const city_mother = JSON.parse(sessionStorage.getItem('city_Mother'));
        const occupation_mother = JSON.parse(sessionStorage.getItem('occupation_Mother'));
        const sms_lang_mother = JSON.parse(sessionStorage.getItem('sms_lang_Mother'));
        const enterprise_mother = JSON.parse(sessionStorage.getItem('enterprise_Mother'));
        const enterprise_addres_mother = JSON.parse(sessionStorage.getItem('enterprise_addres_Mother'));
        const responsible_mother = JSON.parse(sessionStorage.getItem('responsible_Mother'));



        const civility_guardian = JSON.parse(sessionStorage.getItem('civility_Guardian'));
        const last_name_guardian = JSON.parse(sessionStorage.getItem('last_name_Guardian'));
        const first_name_guardian = JSON.parse(sessionStorage.getItem('first_name_Guardian'));
        const email_1_guardian = JSON.parse(sessionStorage.getItem('email_1_Guardian'));
        const email_2_guardian = JSON.parse(sessionStorage.getItem('email_2_Guardian'));
        const phone_main_guardian = JSON.parse(sessionStorage.getItem('phone_main_Guardian'));
        const other_phone_guardian = JSON.parse(sessionStorage.getItem('other_phone_Guardian'));
        const whatsapp_phone_guardian = JSON.parse(sessionStorage.getItem('whatsapp_phone_Guardian'));
        const work_phone_guardian = JSON.parse(sessionStorage.getItem('work_phone_Guardian'));
        const home_phone_guardian = JSON.parse(sessionStorage.getItem('home_phone_Guardian'));
        const home_addres_guardian = JSON.parse(sessionStorage.getItem('home_addres_Guardian'));
        const postal_code_guardian = JSON.parse(sessionStorage.getItem('postal_code_Guardian'));
        const country_guardian = JSON.parse(sessionStorage.getItem('country_Guardian'));
        const nationality_guardian = JSON.parse(sessionStorage.getItem('nationality_Guardian'));
        const city_guardian = JSON.parse(sessionStorage.getItem('city_Guardian'));
        const occupation_guardian = JSON.parse(sessionStorage.getItem('occupation_Guardian'));
        const sms_lang_guardian = JSON.parse(sessionStorage.getItem('sms_lang_Guardian'));
        const enterprise_guardian = JSON.parse(sessionStorage.getItem('enterprise_Guardian'));
        const enterprise_addres_guardian = JSON.parse(sessionStorage.getItem('enterprise_addres_Guardian'));
        const responsible_guardian = JSON.parse(sessionStorage.getItem('responsible_Guardian'));


function handleSubmitParentInfo(e) {
        e.preventDefault();

        const bodyForm = new FormData();

        if (parent === 'Father') {
            bodyForm.append('person', parent);
            bodyForm.append('civility', civility_father);
            bodyForm.append('last_name', last_name_father);
            bodyForm.append('first_name_', first_name_father);
            bodyForm.append('email_1', email_1_father);
            bodyForm.append('email_2', email_2_father);
            bodyForm.append('phone_main', phone_main_father);
            bodyForm.append('other_phone', other_phone_father);
            bodyForm.append('whatsapp_phone', whatsapp_phone_father);
            bodyForm.append('work_phone', work_phone_father);
            bodyForm.append('home_phone', home_phone_father);
            bodyForm.append('home_addres', home_addres_father);
            bodyForm.append('postal_code', postal_code_father);
            bodyForm.append('country', country_father);
            bodyForm.append('nationality', nationality_father);
            bodyForm.append('city', city_father);
            bodyForm.append('occupation', occupation_father);
            bodyForm.append('sms_lang', sms_lang_father);
            bodyForm.append('enterprise', enterprise_father);
            bodyForm.append('enterprise_addres', enterprise_addres_father);
            bodyForm.append('responsible', responsible_Father);
        }

        if (parent === 'Mother') {
            bodyForm.append('person', parent);
            bodyForm.append('civility', civility_mother);
            bodyForm.append('last_name', last_name_mother);
            bodyForm.append('first_name_', first_name_mother);
            bodyForm.append('email_1', email_1_mother);
            bodyForm.append('email_2', email_2_mother);
            bodyForm.append('phone_main', phone_main_mother);
            bodyForm.append('other_phone', other_phone_mother);
            bodyForm.append('whatsapp_phone', whatsapp_phone_mother);
            bodyForm.append('work_phone', work_phone_mother);
            bodyForm.append('home_phone', home_phone_mother);
            bodyForm.append('home_addres', home_addres_mother);
            bodyForm.append('postal_code', postal_code_mother);
            bodyForm.append('country', country_mother);
            bodyForm.append('nationality', nationality_mother);
            bodyForm.append('city', city_mother);
            bodyForm.append('occupation', occupation_mother);
            bodyForm.append('sms_lang', sms_lang_mother);
            bodyForm.append('enterprise', enterprise_mother);
            bodyForm.append('enterprise_addres', enterprise_addres_mother);
            bodyForm.append('responsible', responsible_mother);
        }

        if (parent === 'Guardian') {
            bodyForm.append('person', parent);
            bodyForm.append('civility', civility_guardian);
            bodyForm.append('last_name', last_name_guardian);
            bodyForm.append('first_name_', first_name_guardian);
            bodyForm.append('email_1', email_1_guardian);
            bodyForm.append('email_2', email_2_guardian);
            bodyForm.append('phone_main', phone_main_guardian);
            bodyForm.append('other_phone', other_phone_guardian);
            bodyForm.append('whatsapp_phone', whatsapp_phone_guardian);
            bodyForm.append('work_phone', work_phone_guardian);
            bodyForm.append('home_phone', home_phone_guardian);
            bodyForm.append('home_addres', home_addres_guardian);
            bodyForm.append('postal_code', postal_code_guardian);
            bodyForm.append('country', country_guardian);
            bodyForm.append('nationality', nationality_guardian);
            bodyForm.append('city', city_guardian);
            bodyForm.append('occupation', occupation_guardian);
            bodyForm.append('sms_lang', sms_lang_guardian);
            bodyForm.append('enterprise', enterprise_guardian);
            bodyForm.append('enterprise_addres', enterprise_addres_guardian);
            bodyForm.append('responsible', responsible_guardian);
        }

        bodyForm.append('id_user',  JSON.parse(sessionStorage.getItem('id_user')))

        if (code === '0') { 
        
            bodyForm.append('code_student',  JSON.parse(sessionStorage.getItem('code_stud')))
        }else{
            bodyForm.append('code_student', code)
    
        }

        services.insert_parent_detail(bodyForm)
        .then((res) => {
             setDatamsg(res.data.message); 
            console.log(res.data)
            // Step5();
            closeModal();
            list_parent(JSON.parse(sessionStorage.getItem('id_user')))
            closeModalUpdateStep5();
            
        }).catch((err) =>{
            console.log(err)
            Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
        })


        

}

const [dataParents, setDataParents] = useState();
// const id_user = JSON.parse(sessionStorage.getItem('id_user'));

// useEffect(() =>{
//     list_parent(id_user)
// }, [id_user])

function list_parent(id_user) {
    
    services2.get_data_parent(id_user).then((res) =>{
        console.log(res.data);
        setDataParents(res.data)

    }).catch((error)=>{
        console.log(error)
    })
}


const columns = [

    {
        name: "Person",
        cell: (rows) => (
            <b style={{ color: "black" }}>{rows.person} </b>
        ),
        sortable: true,
        reorder: true,
        // width: "20rem",                       // added line here
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    },

    {
        name: "Civility",
        cell: (rows) => (
            <b style={{ color: "black" }}>{rows.civility}</b>
        ),
        sortable: true,
        reorder: true,
        // width: "20rem",                       // added line here
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    },  
    
    {
        name: "First name",
        cell: (rows) => (
            <b style={{ color: "black" }}>{rows.firstname} </b>
        ),
        sortable: true,
        reorder: true,
        // width: "20rem",                       // added line here
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
    },

    
    
    {
        name: "Last name",
        cell: (rows) => (
            <b style={{ color: "black" }}>{rows.lastname} </b>
        ),
        sortable: true,
        reorder: true,
    },
    {
        name: "Email",
        cell: (rows) => (
            <b style={{ color: "black" }}>{rows.email_1} <br /> {rows.email_2}</b>
        ),
        sortable: true,
        reorder: true,
        width: "20rem",  

    },

    {
        name: "Phone main",
        cell: (rows) => (
            <b style={{ color: "black" }}>{rows.phone_main} </b>
        ),
        sortable: true,
        reorder: true
    },

    
    
   

    
  

    {
        name: "Action",
        cell: (rows) => (
            <div className="actions"> 
                
                <Link to="#" className="btn btn-sm bg-danger-light me-5" onClick={(e) => ShowEditParent(e, rows.person, rows.id)}>
                    <i className="fas fa-edit"></i>
                </Link>
                {/* <Link to="#" className="btn btn-sm bg-danger " onClick={(e) => DeleteParent(e, rows.code_parent, code)}>
                    <i className="fas fa-trash"></i>
                </Link> */}


                
            </div>


        ),
        sortable: true,
        reorder: true
    },


];


const [showModalUpdateStep5, setShowModalUpdateStep5] = useState()
const [dataUpdateStep5, setDataUpdateStep5] = useState([])

function ShowEditParent(e, person, id) { 
    setParent(person)
    
        services2.get_data_parent_by_id(id).then((res) =>{
            console.log(res.data);
            setShowModalUpdateStep5(true);
            setDataUpdateStep5(res.data);
          
            sessionStorage.setItem('person_'+res.data[0].person, JSON.stringify(res.data[0].person))
            sessionStorage.setItem('civility_'+res.data[0].person, JSON.stringify(res.data[0].civility))
            sessionStorage.setItem('last_name_'+res.data[0].person, JSON.stringify(res.data[0].lastname))
            sessionStorage.setItem('first_name_'+res.data[0].person, JSON.stringify(res.data[0].firstname))
            sessionStorage.setItem('email_1_'+res.data[0].person, JSON.stringify(res.data[0].email_1))
            sessionStorage.setItem('email_2_'+res.data[0].person, JSON.stringify(res.data[0].email_2))
            sessionStorage.setItem('phone_main_'+res.data[0].person, JSON.stringify(res.data[0].phone_main))
            sessionStorage.setItem('other_phone_'+res.data[0].person, JSON.stringify(res.data[0].other_phone))
            sessionStorage.setItem('whatsapp_phone_'+res.data[0].person, JSON.stringify(res.data[0].whatsapp_phone))
            sessionStorage.setItem('work_phone_'+res.data[0].person, JSON.stringify(res.data[0].work_phone))
            sessionStorage.setItem('home_phone_'+res.data[0].person, JSON.stringify(res.data[0].home_phone))
            sessionStorage.setItem('home_addres_'+res.data[0].person, JSON.stringify(res.data[0].home_addres))
            sessionStorage.setItem('postal_code_'+res.data[0].person, JSON.stringify(res.data[0].postal_code))
            sessionStorage.setItem('country_'+res.data[0].person, JSON.stringify(res.data[0].country))
            sessionStorage.setItem('nationality_'+res.data[0].person, JSON.stringify(res.data[0].nationality))
            sessionStorage.setItem('city_'+res.data[0].person, JSON.stringify(res.data[0].city))
            sessionStorage.setItem('occupation_'+res.data[0].person, JSON.stringify(res.data[0].occupation))
            sessionStorage.setItem('sms_lang_'+res.data[0].person, JSON.stringify(res.data[0].sms_lang))
            sessionStorage.setItem('enterprise_'+res.data[0].person, JSON.stringify(res.data[0].enterprise))
            sessionStorage.setItem('enterprise_addres_'+res.data[0].person, JSON.stringify(res.data[0].enterprise_addres))
            sessionStorage.setItem('responsible_'+res.data[0].person, JSON.stringify(res.data[0].responsible))
    
        }).catch((error)=>{
            console.log(error)
        })
    
   
}

const closeModalUpdateStep5 = () => setShowModalUpdateStep5(false);

function DeleteParent(e, codeP, codeS) {
        Swal.fire({
            title: 'Are you sure?',
            html: "the parent will be permanently deleted",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services2.delete_data_parent_by_id(codeP, codeS) 
                    .then(response => {
                       
                        list_parent(JSON.parse(sessionStorage.getItem('id_user')))
                        Swal.fire(
                            'Parent has been deleted',
                            'success'
                        )
                    })
                    .catch(err => {
                        console.log(err);
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            timer: 5000
                          })
                        
                    });

            }
        })
}


const [dataCertificatePassport, setDataCertificatePassport] = useState({
    CertificatePassport : ''
})

function handleCertificatePassport(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataCertificatePassport, [name]: value }
    setDataCertificatePassport(data)
    console.log(data)

  }
  


  const [dataAcademicReports, setDataAcademicReports] = useState({
    AcademicReports : ''
})

function handleAcademicReports(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataAcademicReports, [name]: value }
    setDataAcademicReports(data)
    console.log(data)

  }





  const [dataCertificateExamsResults, setDataCertificateExamsResults] = useState({
    CertificateExamsResults : ''
})

function handleCertificateExamsResults(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataCertificateExamsResults, [name]: value }
    setDataCertificateExamsResults(data)
    console.log(data)

  }



  const [dataVaccinationRecords, setDataVaccinationRecords] = useState({
    VaccinationRecords : ''
})

function handleVaccinationRecords(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataVaccinationRecords, [name]: value }
    setDataVaccinationRecords(data)
    console.log(data)

  }




  function handleSubmitFile(e) {
    e.preventDefault();

    const dataBody = new FormData();

    dataBody.append('CertificatePassport', dataCertificatePassport.CertificatePassport)
    dataBody.append('AcademicReports', dataAcademicReports.AcademicReports)
    dataBody.append('CertificateExamsResults', dataCertificateExamsResults.CertificateExamsResults)
    dataBody.append('VaccinationRecords', dataVaccinationRecords.VaccinationRecords)
    if (code === '0') { 
        
        dataBody.append('code_student',  JSON.parse(sessionStorage.getItem('code_stud')))
    }else{
        dataBody.append('code_student', code)

    }


    services2.insert_upload_file_stud(dataBody)
    .then((res)=>{
        setDatamsg(res.data.message); 
        NextStep7();
        GetStep6(JSON.parse(sessionStorage.getItem('code_stud'))) 

    }).catch((err)=>{
         console.log(err)
            Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 5000
            })
    })
  }
const [file1, setFile1] = useState()
const [file2, setFile2] = useState()
const [file3, setFile3] = useState()
const [file4, setFile4] = useState()

  useEffect(() =>{
    GetStep6() 
  }, [code])

  function GetStep6() {
    services2.get_file_admissions_student(code)
    .then((res)=>{
        setFile1(res.data[0].CertificatePassport)
        setFile2(res.data[0].AcademicReports)
        setFile3(res.data[0].CertificateExamsResults)
        setFile4(res.data[0].VaccinationRecords)

        console.log(res.data)

    }).catch(err => console.log(err))
  }



  const  backTable = (e) =>{
    sessionStorage.removeItem('class_choose');
    sessionStorage.removeItem('FirstName');
    sessionStorage.removeItem('LastName');
    sessionStorage.removeItem('Gender');
    sessionStorage.removeItem('nationality');
    sessionStorage.removeItem('DateBirth');
    sessionStorage.removeItem('BirthCity');
    sessionStorage.removeItem('BirthCountry');
    sessionStorage.removeItem('Emergency_Name1');
    sessionStorage.removeItem('Emergency_Contact1');
    sessionStorage.removeItem('emergency_relation1');
    sessionStorage.removeItem('Emergency_Name2');
    sessionStorage.removeItem('Emergency_Contact2');
    sessionStorage.removeItem('emergency_relation2');
    sessionStorage.removeItem('code_stud');

      sessionStorage.removeItem('BloodGroup');
      sessionStorage.removeItem('DoctorName');
      sessionStorage.removeItem('DoctorContact');
      sessionStorage.removeItem('any_recommandation');
      sessionStorage.removeItem('any_medecine');
      sessionStorage.removeItem('medication_must');
      sessionStorage.removeItem('Drug1');
      sessionStorage.removeItem('Drug2');
      sessionStorage.removeItem('Drug3');
      sessionStorage.removeItem('Drug4');
      sessionStorage.removeItem('Drug5');
      sessionStorage.removeItem('Drug6');
      sessionStorage.removeItem('Drug7');
      sessionStorage.removeItem('Drug8');
      sessionStorage.removeItem('presence_of_any');
      sessionStorage.removeItem('Alimentary');
      sessionStorage.removeItem('Related_to_insects');
      sessionStorage.removeItem('Drug_related');
      sessionStorage.removeItem('Other_allergy');
      sessionStorage.removeItem('Allergy_reaction');
      sessionStorage.removeItem('Other_medical');
      sessionStorage.removeItem('Other_medical_justification');
      sessionStorage.removeItem('Learning_difficulties');
      sessionStorage.removeItem('Learning_difficulties_justification');
      sessionStorage.removeItem('Does_the_allergy');


      sessionStorage.removeItem('Pickup_Name1');
      sessionStorage.removeItem('Pickup_Contact1');
      sessionStorage.removeItem('Pickup_Relation1');
      sessionStorage.removeItem('Pickup_Name2');
      sessionStorage.removeItem('Pickup_Contact2');
      sessionStorage.removeItem('Pickup_Relation2');
      sessionStorage.removeItem('Pickup_photo2');
      sessionStorage.removeItem('Pickup_photo1');


      sessionStorage.removeItem('parent_registered_Father');
      sessionStorage.removeItem('civility_Father');
       sessionStorage.removeItem('last_name_Father');
        sessionStorage.removeItem('first_name_Father');
        sessionStorage.removeItem('email_1_Father');
        sessionStorage.removeItem('email_2_Father');
         sessionStorage.removeItem('phone_main_Father');
        sessionStorage.removeItem('other_phone_Father');
        sessionStorage.removeItem('whatsapp_phone_Father');
         sessionStorage.removeItem('work_phone_Father');
         sessionStorage.removeItem('home_phone_Father');
         sessionStorage.removeItem('home_addres_Father');
        sessionStorage.removeItem('postal_code_Father');
        sessionStorage.removeItem('country_Father');
        sessionStorage.removeItem('nationality_Father');
        sessionStorage.removeItem('sms_lang_Father');
         sessionStorage.removeItem('city_Father');
         sessionStorage.removeItem('occupation_Father');
         sessionStorage.removeItem('enterprise_Father');
         sessionStorage.removeItem('enterprise_addres_Father');
        sessionStorage.removeItem('responsible_Father');



        sessionStorage.removeItem('parent_registered_Mother');
      sessionStorage.removeItem('civility_Mother');
       sessionStorage.removeItem('last_name_Mother');
        sessionStorage.removeItem('first_name_Mother');
        sessionStorage.removeItem('email_1_Mother');
        sessionStorage.removeItem('email_2_Mother');
         sessionStorage.removeItem('phone_main_Mother');
        sessionStorage.removeItem('other_phone_Mother');
        sessionStorage.removeItem('whatsapp_phone_Mother');
         sessionStorage.removeItem('work_phone_Mother');
         sessionStorage.removeItem('home_phone_Mother');
         sessionStorage.removeItem('home_addres_Mother');
        sessionStorage.removeItem('postal_code_Mother');
        sessionStorage.removeItem('country_Mother');
        sessionStorage.removeItem('nationality_Mother');
        sessionStorage.removeItem('sms_lang_Mother');
         sessionStorage.removeItem('city_Mother');
         sessionStorage.removeItem('occupation_Mother');
         sessionStorage.removeItem('enterprise_Mother');
         sessionStorage.removeItem('enterprise_addres_Mother');
        sessionStorage.removeItem('responsible_Mother');


        sessionStorage.removeItem('parent_registered_Guardian');
        sessionStorage.removeItem('civility_Guardian');
         sessionStorage.removeItem('last_name_Guardian');
          sessionStorage.removeItem('first_name_Guardian');
          sessionStorage.removeItem('email_1_Guardian');
          sessionStorage.removeItem('email_2_Guardian');
           sessionStorage.removeItem('phone_main_Guardian');
          sessionStorage.removeItem('other_phone_Guardian');
          sessionStorage.removeItem('whatsapp_phone_Guardian');
           sessionStorage.removeItem('work_phone_Guardian');
           sessionStorage.removeItem('home_phone_Guardian');
           sessionStorage.removeItem('home_addres_Guardian');
          sessionStorage.removeItem('postal_code_Guardian');
          sessionStorage.removeItem('country_Guardian');
          sessionStorage.removeItem('nationality_Guardian');
          sessionStorage.removeItem('sms_lang_Guardian');
           sessionStorage.removeItem('city_Guardian');
           sessionStorage.removeItem('occupation_Guardian');
           sessionStorage.removeItem('enterprise_Guardian');
           sessionStorage.removeItem('enterprise_addres_Guardian');
          sessionStorage.removeItem('responsible_Guardian');

  
    window.location.replace("/admission-table");

  }

    return (
        <>
            <NavAdmission />
            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Admission process</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="#" onClick={(e) => backTable(e)}>Admission tabs</Link></li>
                                    <li className="breadcrumb-item active">Add Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                    <h5 style={{color:'green'}} >{msg}</h5>
                        <div className="col-sm-12">
                            {
                                showStep &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <h5 className="form-title"><span>Step 1 Student Information</span></h5>
                                                
                                            </div>

                                            <div className="col-12 col-sm-2">
                                                <div className="form-group">
                                                    <label>Gender <code>(*)</code></label>
                                                    <select className="form-control select" name='Gender' id='Gender' style={{ fontWeight: 'bold' }} onChange={(e) => handle(e)}>
                                                    <option value={sessGender}>{sessGender}</option>
                                                        
                                                        <option value="Female">Female</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>First Name <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='FirstName'
                                                        id='FirstName'
                                                        onChange={(e) => handle(e)}
                                                        value={sessFirstName}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='First name'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Last Name <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='LastName'
                                                        id='LastName'
                                                        onChange={(e) => handle(e)}
                                                        value={sessLastName}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Last name'
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-2">
                                                <div className="form-group">
                                                    <label>Date of Birth <code>(*)</code></label>
                                                    <div>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name='DateBirth'
                                                            id='DateBirth'
                                                            onChange={(e) => handle(e)}
                                                            value={sessDateBirth}
                                                            style={{ fontWeight: 'bold' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Birth City <code>(*)</code></label>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name='BirthCity'
                                                            id='BirthCity'
                                                            onChange={(e) => handle(e)}
                                                            value={sessBirthCity}
                                                            style={{ fontWeight: 'bold' }}
                                                            placeholder='Birth City'
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Birth Country <code>(*)</code></label>
                                                    <div>
                                                        <select name="BirthCountry" id="BirthCountry"  onChange={(e) => handle(e)} className='form-control'>
                                                            <option value={sessBirthCountry}>{sessBirthCountry}</option>
                                                            <option value="">Birth Country</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Antarctica">Antarctica</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="American Samoa">American Samoa</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh">Bangladesh</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Bermuda">Bermuda</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Bouvet Island">Bouvet Island</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="British Virgin Islands">British Virgin Islands</option>
                                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Cape Verde">Cape Verde</option>
                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                            <option value="Central African">Central African</option>
                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Taiwan">Taiwan</option>
                                                            <option value="Christmas Island">Christmas Island</option>
                                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Mayotte">Mayotte</option>
                                                            <option value="Republic of the Congo">Republic of the Congo</option>
                                                            <option value="The Democratic Republic Of The Congo">The Democratic Republic Of The Congo</option>
                                                            <option value="Cook Islands">Cook Islands</option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Croatia">Croatia</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czech Republic">Czech Republic</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                            <option value="Falkland Islands">Falkland Islands</option>
                                                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="Åland Islands">Åland Islands</option>
                                                            <option value="France">France</option>
                                                            <option value="French Guiana">French Guiana</option>
                                                            <option value="French Polynesia">French Polynesia</option>
                                                            <option value="French Southern Territories">French Southern Territories</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Occupied Palestinian Territory">Occupied Palestinian Territory</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Gibraltar">Gibraltar</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Greenland">Greenland</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                            <option value="Guam">Guam</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                                                            <option value="Vatican City State">Vatican City State</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hong Kong">Hong Kong</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="India">India</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Islamic Republic of Iran">Islamic Republic of Iran</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Ivory Coast">Ivory Coast</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Democratic People's Republic of Korea">Democratic People's Republic of Korea</option>
                                                            <option value="Republic of Korea">Republic of Korea</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                            <option value="Lebanon">Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Macao">Macao</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Martinique">Martinique</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Republic of Moldova">Republic of Moldova</option>
                                                            <option value="Montserrat">Montserrat</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Namibia">Namibia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherlands">Netherlands</option>
                                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option value="Aruba">Aruba</option>
                                                            <option value="New Caledonia">New Caledonia</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="Niue">Niue</option>
                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                            <option value="Federated States of Micronesia">Federated States of Micronesia</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Palau">Palau</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Philippines">Philippines</option>
                                                            <option value="Pitcairn">Pitcairn</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                            <option value="Timor-Leste">Timor-Leste</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Réunion">Réunion</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russian Federation">Russian Federation</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="Saint Helena">Saint Helena</option>
                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                            <option value="Anguilla">Anguilla</option>
                                                            <option value="Saint Lucia">Saint Lucia</option>
                                                            <option value="Saint-Pierre and Miquelon">Saint-Pierre and Miquelon</option>
                                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia">Slovakia</option>
                                                            <option value="Vietnam">Vietnam</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                            <option value="Spain">Spain</option>
                                                            <option value="Western Sahara">Western Sahara</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                            <option value="Swaziland">Swaziland</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tokelau">Tokelau</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="The Former Yugoslav Republic of Macedonia">The Former Yugoslav Republic of Macedonia</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="Isle of Man">Isle of Man</option>
                                                            <option value="United Republic Of Tanzania">United Republic Of Tanzania</option>
                                                            <option value="United States">United States</option>
                                                            <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Uruguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                                                            <option value="Zambia">Zambia</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Nationality <code>(*)</code></label>
                                                    <div>
                                                        <select name="nationality" id="nationality"  onChange={(e) => handle(e)} className='form-control'>
                                                            <option value={sessnationality}>{sessnationality}</option>
                                                            <option value="Afghan">Afghan</option>
                                                            <option value="Albanian">Albanian</option>
                                                            <option value="Algerian">Algerian</option>
                                                            <option value="American">American</option>
                                                            <option value="Andorran">Andorran</option>
                                                            <option value="Angolan">Angolan</option>
                                                            <option value="Argentinian">Argentinian</option>
                                                            <option value="Armenian">Armenian</option>
                                                            <option value="Australian">Australian</option>
                                                            <option value="Austrian">Austrian</option>
                                                            <option value="Azerbaijani">Azerbaijani</option>
                                                            <option value="Bahamian">Bahamian</option>
                                                            <option value="Bangladeshi">Bangladeshi</option>
                                                            <option value="Barbadian">Barbadian</option>
                                                            <option value="Belgian">Belgian</option>
                                                            <option value="Belorussian">Belorussian</option>
                                                            <option value="Beninese">Beninese</option>
                                                            <option value="Bhutanese">Bhutanese</option>
                                                            <option value="Bolivian">Bolivian</option>
                                                            <option value="Bosnian">Bosnian</option>
                                                            <option value="Brazilian">Brazilian</option>
                                                            <option value="British">British</option>
                                                            <option value="Briton">Briton</option>
                                                            <option value="Bruneian">Bruneian</option>
                                                            <option value="Bulgarian">Bulgarian</option>
                                                            <option value="Burkinabe">Burkinabe</option>
                                                            <option value="Burmese">Burmese</option>
                                                            <option value="Burundian">Burundian</option>
                                                            <option value="Cambodian">Cambodian</option>
                                                            <option value="Cameroonian">Cameroonian</option>
                                                            <option value="Canadian">Canadian</option>
                                                            <option value="Central African">Central African</option>
                                                            <option value="Chadian">Chadian</option>
                                                            <option value="Chilean">Chilean</option>
                                                            <option value="Chinese">Chinese</option>
                                                            <option value="Colombian">Colombian</option>
                                                            <option value="Congolese">Congolese</option>
                                                            <option value="Congolese">Congolese</option>
                                                            <option value="Croatian">Croatian</option>
                                                            <option value="Cuban">Cuban</option>
                                                            <option value="Cypriot">Cypriot</option>
                                                            <option value="Czech">Czech</option>
                                                            <option value="Dane">Dane</option>
                                                            <option value="Dominican">Dominican</option>
                                                            <option value="Dutchman">Dutchman</option>
                                                            <option value="Ecuadorean">Ecuadorean</option>
                                                            <option value="Egyptian">Egyptian</option>
                                                            <option value="Englishman">Englishman</option>
                                                            <option value="Eritrean">Eritrean</option>
                                                            <option value="Estonian">Estonian</option>
                                                            <option value="Ethiopian">Ethiopian</option>
                                                            <option value="Fijian">Fijian</option>
                                                            <option value="Filipino">Filipino</option>
                                                            <option value="Finn">Finn</option>
                                                            <option value="Frenchman">Frenchman</option>
                                                            <option value="Gabonese">Gabonese</option>
                                                            <option value="Gambian">Gambian</option>
                                                            <option value="Georgian">Georgian</option>
                                                            <option value="German">German</option>
                                                            <option value="Ghanaian">Ghanaian</option>
                                                            <option value="Greek">Greek</option>
                                                            <option value="Grenadian">Grenadian</option>
                                                            <option value="Guatemalan">Guatemalan</option>
                                                            <option value="Guinean">Guinean</option>
                                                            <option value="Guyanese">Guyanese</option>
                                                            <option value="Haitian">Haitian</option>
                                                            <option value="Honduran">Honduran</option>
                                                            <option value="Hungarian">Hungarian</option>
                                                            <option value="Icelander">Icelander</option>
                                                            <option value="Indian">Indian</option>
                                                            <option value="Indonesian">Indonesian</option>
                                                            <option value="Iranian">Iranian</option>
                                                            <option value="Iraqi">Iraqi</option>
                                                            <option value="Irishman">Irishman</option>
                                                            <option value="Israeli">Israeli</option>
                                                            <option value="Italian">Italian</option>
                                                            <option value="Ivorian">Ivorian</option>
                                                            <option value="Jamaican">Jamaican</option>
                                                            <option value="Japanese">Japanese</option>
                                                            <option value="Jordanian">Jordanian</option>
                                                            <option value="Kazakh">Kazakh</option>
                                                            <option value="Kenyan">Kenyan</option>
                                                            <option value="Korean">Korean</option>
                                                            <option value="Kuwaiti">Kuwaiti</option>
                                                            <option value="Laotian">Laotian</option>
                                                            <option value="Latvian">Latvian</option>
                                                            <option value="Lebanese">Lebanese</option>
                                                            <option value="Liberian">Liberian</option>
                                                            <option value="Libyan">Libyan</option>
                                                            <option value="Liechtensteiner">Liechtensteiner</option>
                                                            <option value="Lithuanian">Lithuanian</option>
                                                            <option value="Luxembourger">Luxembourger</option>
                                                            <option value="Macedonian">Macedonian</option>
                                                            <option value="Madagascan">Madagascan</option>
                                                            <option value="Malawian">Malawian</option>
                                                            <option value="Malaysian">Malaysian</option>
                                                            <option value="Maldivian">Maldivian</option>
                                                            <option value="Malian">Malian</option>
                                                            <option value="Maltese">Maltese</option>
                                                            <option value="Mauritanian">Mauritanian</option>
                                                            <option value="Mauritian">Mauritian</option>
                                                            <option value="Mexican">Mexican</option>
                                                            <option value="Moldovan">Moldovan</option>
                                                            <option value="Monacan">Monacan</option>
                                                            <option value="Mongolian">Mongolian</option>
                                                            <option value="Montenegrin">Montenegrin</option>
                                                            <option value="Moroccan">Moroccan</option>
                                                            <option value="Mozambican">Mozambican</option>
                                                            <option value="Namibian">Namibian</option>
                                                            <option value="Nepalese">Nepalese</option>
                                                            <option value="Nicaraguan">Nicaraguan</option>
                                                            <option value="Nigerian">Nigerian</option>
                                                            <option value="Nigerien">Nigerien</option>
                                                            <option value="Norwegian">Norwegian</option>
                                                            <option value="Pakistani">Pakistani</option>
                                                            <option value="Panamanian">Panamanian</option>
                                                            <option value="Paraguayan">Paraguayan</option>
                                                            <option value="Peruvian">Peruvian</option>
                                                            <option value="Pole">Pole</option>
                                                            <option value="Portuguese">Portuguese</option>
                                                            <option value="Qatari">Qatari</option>
                                                            <option value="Romanian">Romanian</option>
                                                            <option value="Russian">Russian</option>
                                                            <option value="Rwandan">Rwandan</option>
                                                            <option value="Salvadorean">Salvadorean</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Saudi Arabian">Saudi Arabian</option>
                                                            <option value="Scot">Scot</option>
                                                            <option value="Senegalese">Senegalese</option>
                                                            <option value="Serbian">Serbian</option>
                                                            <option value="Sierra Leonean">Sierra Leonean</option>
                                                            <option value="Singaporean">Singaporean</option>
                                                            <option value="Slovak">Slovak</option>
                                                            <option value="Slovenian">Slovenian</option>
                                                            <option value="Somali">Somali</option>
                                                            <option value="South African">South African</option>
                                                            <option value="Spaniard">Spaniard</option>
                                                            <option value="SriLankan">SriLankan</option>
                                                            <option value="Sudanese">Sudanese</option>
                                                            <option value="Surinamese">Surinamese</option>
                                                            <option value="Swazi">Swazi</option>
                                                            <option value="Swede">Swede</option>
                                                            <option value="Swiss">Swiss</option>
                                                            <option value="Syrian">Syrian</option>
                                                            <option value="Tadzhik">Tadzhik</option>
                                                            <option value="Taiwanese">Taiwanese</option>
                                                            <option value="Tanzanian">Tanzanian</option>
                                                            <option value="Thai">Thai</option>
                                                            <option value="Togolese">Togolese</option>
                                                            <option value="Trinidadian">Trinidadian</option>
                                                            <option value="Tunisian">Tunisian</option>
                                                            <option value="Turk">Turk</option>
                                                            <option value="Ugandan">Ugandan</option>
                                                            <option value="Ukrainian">Ukrainian</option>
                                                            <option value="Uruguayan">Uruguayan</option>
                                                            <option value="Uzbek">Uzbek</option>
                                                            <option value="Venezuelan">Venezuelan</option>
                                                            <option value="Vietnamese">Vietnamese</option>
                                                            <option value="Welshman">Welshman</option>
                                                            <option value="Yemeni">Yemeni</option>
                                                            <option value="Yugoslav">Yugoslav</option>
                                                            <option value="Zambian">Zambian</option>
                                                            <option value="Zimbabwean">Zimbabwean</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>ID photo <code>(*) (png, jpg, jpeg, 413x531 pixel)</code></label>
                                                    <div>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name='file'
                                                            onChange={(e) => handleFile(e)}
                                                            style={{ fontWeight: 'bold' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}


                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <div className="col-12">
                                            <button type="submit" style={{ float: 'right' }} onClick={(e) => handleSubmitInfosStudent(e)} className="btn btn-success btn-lg">Next</button>
                                        </div>
                                    </div>
                                </div>
                            }




                            {
                                showStep2 &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <h5 className="form-title"><span>Step 2 least one contact for emergency cases</span></h5>
                                                

                                            </div>


                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Emergency Name 1 <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Emergency_Name1'
                                                        onChange={(e) => handle(e)}
                                                        value={sess_Emergency_Name1}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Emergency Name'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Emergency Contact 1 <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Emergency_Contact1'
                                                        onChange={(e) => handle(e)}
                                                        value={sess_Emergency_Contact1}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Emergency Contact'
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Emergency Relation 1 <code>(*)</code></label>
                                                    <div>
                                                        <select name="emergency_relation1" id="emergency_relation1" onChange={(e) => handle(e)} className="form-control">
                                                            <option value={sess_emergency_relation1}>{sess_emergency_relation1}</option>
                                                            <option value="">Choose a relationship</option>
                                                            <option value="Mother">Mother</option>
                                                            <option value="Friend">Friend</option>
                                                            <option value="Guardian">Guardian</option>
                                                            <option value="Uncle">Uncle</option>
                                                            <option value="Sister">Sister</option>
                                                            <option value="Brother">Brother</option>
                                                            <option value="Aunty">Aunty</option>
                                                            <option value="Grandmother">Grandmother</option>
                                                            <option value="Grandfather">Grandfather</option>
                                                            <option value="Niece">Niece</option>
                                                            <option value="Other">Other</option>
                                                            <option value="Family">Family</option>
                                                            <option value="Father">Father</option>
                                                            <option value="Wife">Wife</option>
                                                            <option value="Husband">Husband</option>
                                                            <option value="Colleague">Colleague</option>
                                                            <option value="Driver">Driver</option>
                                                            <option value="Nanny">Nanny</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>




                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Emergency Name 2 <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Emergency_Name2'
                                                        onChange={(e) => handle(e)}
                                                        value={sess_Emergency_Name2}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Emergency Name'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Emergency Contact 2 <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Emergency_Contact2'
                                                        onChange={(e) => handle(e)}
                                                        value={sess_Emergency_Contact2}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Emergency Contact'
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Emergency Relation 2 <code>(*)</code></label>
                                                    <div>
                                                        <select name="emergency_relation2" id="emergency_relation2" onChange={(e) => handle(e)} className="form-control">
                                                            <option value={sess_emergency_relation2}>{sess_emergency_relation2}</option>
                                                            <option value="">Choose a relationship</option>
                                                            <option value="Mother">Mother</option>
                                                            <option value="Friend">Friend</option>
                                                            <option value="Guardian">Guardian</option>
                                                            <option value="Uncle">Uncle</option>
                                                            <option value="Sister">Sister</option>
                                                            <option value="Brother">Brother</option>
                                                            <option value="Aunty">Aunty</option>
                                                            <option value="Grandmother">Grandmother</option>
                                                            <option value="Grandfather">Grandfather</option>
                                                            <option value="Niece">Niece</option>
                                                            <option value="Other">Other</option>
                                                            <option value="Family">Family</option>
                                                            <option value="Father">Father</option>
                                                            <option value="Wife">Wife</option>
                                                            <option value="Husband">Husband</option>
                                                            <option value="Colleague">Colleague</option>
                                                            <option value="Driver">Driver</option>
                                                            <option value="Nanny">Nanny</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>





                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <div className="col-12">
                                            <button type="submit" style={{ float: 'left' }} onClick={Step1} className="btn btn-primary btn-lg">Preview</button>
                                            <button type="submit" style={{ float: 'right' }} onClick={(e) => handleSubmitEmergency(e)} className="btn btn-success btn-lg">Next</button>
                                        </div>
                                    </div>
                                </div>
                            }



                            {
                                showStep3 &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <h5 className="form-title"><span>Step 3 provide at least the blood group</span></h5>
                                            </div>


                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Blood Group<code>(*)</code></label> 
                                                    
                                                        <select name="BloodGroup" className="form-control" id="Blood_group" onChange={(e) => handle(e)}>
                                                            <option value={sess_BloodGroup}>{sess_BloodGroup}</option>
                                                            <option value="A+">A+</option>
                                                            <option value="B+">B+</option>
                                                            <option value="O+">O+</option>
                                                            <option value="AB+">AB+</option>
                                                            <option value="B-">B-</option>
                                                            <option value="O-">O-</option>
                                                            <option value="A-">A-</option>
                                                        </select>
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Doctor Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='DoctorName'
                                                        onChange={(e) => handle(e)}
                                                        value={sess_DoctorName}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder=''
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Doctor Contact</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='DoctorContact'
                                                        onChange={(e) => handle(e)}
                                                        value={sess_DoctorContact}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder=''
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Any recommandation, concerns or needs?</label>
                                                    <select name="any_recommandation" id="" className='form-control'  onChange={(e) => handleAnyRecommandation(e)}>
                                                        <option value={sess_any_recommandation}>{sess_any_recommandation}</option>
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </select>
                                                </div>
                                            </div>
 
                                            <div className='col-12 col-md-12 mt-4'>
                                                <div className='row'>
                                                    
                                                { 
                                                    
                                                    anyReconn && (
                                                <div className='col-12 col-md-12'>
                                                    <div className='row'>
                                                     
                                                    <div className="col-12 col-sm-3">
                                                        <div className="form-group">
                                                            <label>Any medecine for specific health conditions ?</label>
                                                            <select name="any_medecine" id="" className='form-control'  onChange={(e) => handleAnymedecine(e)}>
                                                            <option value={sess_any_medecine}>{sess_any_medecine}</option>
                                                            <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {
                                                    anyMedecine && (
                                                        <div className="col-12 col-sm-3">
                                                            <div className="form-group">
                                                                <label>Medication must be given or available at school ?</label>
                                                                <select name="medication_must" id="" className='form-control'  onChange={(e) => handleMedication(e)}>
                                                                <option value={sess_medication_must}>{sess_medication_must}</option>
                                                                <option value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div> 
                                                    )
                                                    }


                                                    {
                                                        medication && (

                                                     
                                                    <div className='col-12 col-md-12 mt-4'>
                                                        <div className='row'>

                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug 1</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug1'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug1}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug 2</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug2'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug2}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug 3</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug3'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug3}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug 4</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug4'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug4}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug 5</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug5'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug5}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug 6</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug6'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug6}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug 7</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug7'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug7}
                                                                    style={{ fontWeight: 'bold' }} 
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug 8</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug8'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug8}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        </div>
                                                    </div>

                                                        )
                                                    }


                                                    <div className="col-12 col-sm-3">
                                                        <div className="form-group">
                                                            <label>Presence of any allergy ?</label>
                                                            <select name="presence_of_any" id="" className='form-control'  onChange={(e) => handlePresenceallergy(e)}>
                                                            <option value={sess_presence_of_any}>{sess_presence_of_any}</option>
                                                                <option value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div> 


                                                    {
                                                        presenceallergy && (

                                                      <> 
                                                    <div className='col-12 col-md-12 mt-4'>
                                                        <div className='row'>

                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Alimentary</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Alimentary'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Alimentary}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Related to insects</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Related_to_insects'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Related_to_insects}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Drug related</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Drug_related'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Drug_related}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-2">
                                                            <div className="form-group">
                                                                <label>Other allergy</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name='Other_allergy'
                                                                    onChange={(e) => handle(e)}
                                                                    value={sess_Other_allergy}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder=''
                                                                />
                                                            </div>
                                                        </div>


                                                        </div>
                                                    </div>
                                                       
                                                       
                                                    <div className="col-12 col-sm-3 mt-3">
                                                        <div className="form-group">
                                                            <label>Does the allergy require a reaction ?</label>
                                                            <select name="Does_the_allergy" id="" className='form-control'  onChange={(e) => handleDoesallergy(e)}>
                                                            <option value={sess_Does_the_allergy}>{sess_Does_the_allergy}</option>
                                                            <option value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div> 
                                                       </>     
                                                        )
                                                        }
 
                                                    {
                                                        doesallergy && (

                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="">Allergy reaction</label>
                                                        <textarea name="Allergy_reaction" id="" cols="30" rows="10" className='form-control'  onChange={(e) => handle(e)} placeholder='Allergy reaction'>{sess_Allergy_reaction}</textarea>
                                                    </div>
                                                        )
                                                    }


                                                <div className="col-12 col-sm-12 mt-3">
                                                    <div className="row">

                                                        <div className="col-12 col-sm-6">
                                                            <label htmlFor="">Other medical information</label>
                                                            <textarea name="Other_medical" id="" cols="30" rows="10" className='form-control' onChange={(e) => handle(e)}  placeholder='Other medical information'>{sess_Other_medical}</textarea>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <div className="form-group">
                                                                <label>justification <code>(pdf, png, jpg)</code></label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name='JustificationMedical'
                                                                    onChange={(e) => handleJustificationMedical(e)}
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder='Blood Group'
                                                                />
                                                            </div>
                                                            <img src={'http://127.0.0.1:8000/'+sess_Other_medical_justification} width="50" alt='' />
                                                        </div>

                                                        <div className="col-12 col-sm-6">
                                                            <label htmlFor="">Learning difficulties</label>
                                                            <textarea name="Learning_difficulties" id="" cols="30" rows="10" onChange={(e) => handle(e)} className='form-control' placeholder='Learning difficulties'>{sess_Learning_difficulties}</textarea>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <div className="form-group">
                                                                <label>justification <code>(pdf, png, jpg)</code></label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name='JustificationLearning'
                                                                    onChange={(e) => handleJustificationLearning(e)} 
                                                                    style={{ fontWeight: 'bold' }}
                                                                    placeholder='Blood Group'
                                                                />
                                                            </div>
                                                            <img src={'http://127.0.0.1:8000/'+sess_Learning_difficulties_justification} width="50" alt='' /> 
                                                        </div>

                                                   </div>
                                                </div>





                                                   </div> 
                                                </div> 
                                            )
                                            }         




                                                </div>
                                            </div>
                                           


                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <div className="col-12">
                                            <button type="submit" style={{ float: 'left' }} onClick={PreviewStep2} className="btn btn-primary btn-lg">Preview</button>
                                            <button type="submit" style={{ float: 'right' }} onClick={(e) => handleSubmitMedical(e)} className="btn btn-success btn-lg">Next</button>
                                        </div>
                                    </div>
                                </div>
                            }


                            {
                                showStep4 &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <h5 className="form-title"><span>Step 4 Child pickup Contact</span></h5>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Pickup Name 1 <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Pickup_Name1'
                                                        onChange={(e) => handle(e)}
                                                       value={sess_Pickup_Name1}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Name'
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Pickup Contact 1 <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Pickup_Contact1'
                                                        onChange={(e) => handle(e)}
                                                       value={sess_Pickup_Contact1}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Contact'
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Pickup Relation 1 <code>(*)</code></label>
                                                   
                                                    <select name="Pickup_Relation1" id="Pickup_Relation1" onChange={(e) => handle(e)} className="form-control">
                                                            <option value={sess_Pickup_Relation1}>{sess_Pickup_Relation1}</option>
                                                            <option value="">Choose a relationship</option>
                                                            <option value="Mother">Mother</option>
                                                            <option value="Friend">Friend</option>
                                                            <option value="Guardian">Guardian</option>
                                                            <option value="Uncle">Uncle</option>
                                                            <option value="Sister">Sister</option>
                                                            <option value="Brother">Brother</option>
                                                            <option value="Aunty">Aunty</option>
                                                            <option value="Grandmother">Grandmother</option>
                                                            <option value="Grandfather">Grandfather</option>
                                                            <option value="Niece">Niece</option>
                                                            <option value="Other">Other</option>
                                                            <option value="Family">Family</option>
                                                            <option value="Father">Father</option>
                                                            <option value="Wife">Wife</option>
                                                            <option value="Husband">Husband</option>
                                                            <option value="Colleague">Colleague</option>
                                                            <option value="Driver">Driver</option>
                                                            <option value="Nanny">Nanny</option>

                                                        </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Pickup Photo 1 <code>(png, jpg, jpeg)</code></label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name='Pickup_photo1'
                                                        onChange={(e) => handleFilePickup1(e)}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Relation'
                                                    />
                                                     <img src={'http://127.0.0.1:8000/'+sess_Pickup_Photo1} width="50" alt='' /> 
                                                </div>
                                            </div>





                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Pickup Name 2 <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Pickup_Name2'
                                                        onChange={(e) => handle(e)}
                                                        value={sess_Pickup_Name2}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Name'
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Pickup Contact 2 <code>(*)</code></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name='Pickup_Contact2'
                                                        onChange={(e) => handle(e)}
                                                        value={sess_Pickup_Contact2}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Contact'
                                                    />
                                                    
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Pickup Relation 2 <code>(*)</code></label>
                                                    
                                                        <select name="Pickup_Relation2" id="Pickup_Relation2" onChange={(e) => handle(e)} className="form-control">
                                                            <option value={sess_Pickup_Relation2}>{sess_Pickup_Relation2}</option>
                                                            <option value="">Choose a relationship</option>
                                                            <option value="Mother">Mother</option>
                                                            <option value="Friend">Friend</option>
                                                            <option value="Guardian">Guardian</option>
                                                            <option value="Uncle">Uncle</option>
                                                            <option value="Sister">Sister</option>
                                                            <option value="Brother">Brother</option>
                                                            <option value="Aunty">Aunty</option>
                                                            <option value="Grandmother">Grandmother</option>
                                                            <option value="Grandfather">Grandfather</option>
                                                            <option value="Niece">Niece</option>
                                                            <option value="Other">Other</option>
                                                            <option value="Family">Family</option>
                                                            <option value="Father">Father</option>
                                                            <option value="Wife">Wife</option>
                                                            <option value="Husband">Husband</option>
                                                            <option value="Colleague">Colleague</option>
                                                            <option value="Driver">Driver</option>
                                                            <option value="Nanny">Nanny</option> 

                                                        </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Pickup Photo 2 <code>(png, jpg, jpeg)</code></label> 
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name='Pickup_photo2'
                                                        onChange={(e) => handleFilePickup2(e)}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Relation'
                                                    />
                                                     <img src={'http://127.0.0.1:8000/'+sess_Pickup_Photo2} width="50" alt='' /> 
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <div className="col-12">
                                            <button type="submit" style={{ float: 'left' }} onClick={PreviewStep3} className="btn btn-primary btn-lg">Preview</button>
                                            <button type="submit" style={{ float: 'right' }} onClick={(e) => handleSubmitPickupChild(e)}  className="btn btn-success btn-lg">Next</button>
                                        </div>
                                    </div>
                                </div>
                            }


                            {
                                showStep5 &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <h5 className="form-title"><span>Step 5 Parents details</span></h5>
                                            </div>

                                                <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0 mb-5">
                                                    <button type="button" onClick={(e) => openModal('Father')} className="btn btn-block btn-outline-dark active">Father</button>
                                                </div>

                                                <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0 mb-5">
                                                    <button type="button" onClick={(e) => openModal('Mother')} className="btn btn-block btn-outline-dark active">Mother</button>
                                                </div>

                                                <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0 mb-5">
                                                    <button type="button" onClick={(e) => openModal('Guardian')} className="btn btn-block btn-outline-dark active">Guardian</button>
                                                </div>

                                                <div className="row mt-5">
                                                    <div className='col-12 col-sm-12 col-md-12'>
                                                <div className="table-responsive">
                                       
                                                    <DataTable
                                                        title="DataTable"
                                                        // customStyles={customStyles}
                                                        columns={columns}
                                                        data={dataParents}
                                                        responsive
                                                        noHeader
                                                        defaultSortFieldId={1}
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        // selectableRows
                                                        persistTableHead
                                                        highlightOnHover
                                                        exportHeaders={false}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                        <div className="col-12">
                                            <button type="submit" style={{ float: 'left' }} onClick={PreviewStep4} className="btn btn-primary btn-lg">Preview</button>
                                            <button type="submit" style={{ float: 'right' }} onClick={(e) => NextStep6(e)}  className="btn btn-success btn-lg">Next</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            }



                            {
                                showStep6 &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <h5 className="form-title"><span>Step 6 upload File</span></h5>
                                            </div>

                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Photocopy of birth certificate/Passport <code>(*)(Format: pdf,png,jpg,jpeg)</code></label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name='CertificatePassport'
                                                        onChange={(e) => handleCertificatePassport(e)}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Relation'
                                                    />
                                                     <img src={'http://127.0.0.1:8000/file_admission/'+file1} width="50" alt='' /> 
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Previous Academic Reports <code>(*)(Format: pdf,png,jpg,jpeg)</code></label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name='AcademicReports'
                                                        onChange={(e) => handleAcademicReports(e)}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Relation'
                                                    />
                                                     <img src={'http://127.0.0.1:8000/file_admission/'+file2} width="50" alt='' /> 
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Transfer Certificate/Exams Results <code>(*)(Format: pdf,png,jpg,jpeg)</code></label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name='CertificateExamsResults'
                                                        onChange={(e) => handleCertificateExamsResults(e)} 
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Relation'
                                                    />
                                                     <img src={'http://127.0.0.1:8000/file_admission/'+file3} width="50" alt='' /> 
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Updated Vaccination Records <code>(*)(Format: pdf,png,jpg,jpeg)</code></label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name='VaccinationRecords'
                                                        onChange={(e) => handleVaccinationRecords(e)}
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder='Pickup Relation'
                                                    />
                                                     <img src={'http://127.0.0.1:8000/file_admission/'+file4} width="50" alt='' /> 
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="col-12">
                                            <button type="submit" style={{ float: 'left' }} onClick={PreviewStep5} className="btn btn-primary btn-lg">Preview</button>
                                            <button type="submit" style={{ float: 'right' }} onClick={(e) => handleSubmitFile(e)}  className="btn btn-success btn-lg">Next</button>
                                        </div>
                                    </div>
                                </div>
                            }
 

                                {
                                showStep7 &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <h5 className="form-title"><span>Step 7 </span></h5> 
                                            </div>

                                           <div className="col-12">
                                            
                                                    <p>
                                                    <h2>COMMITMENTS</h2>
                                                    In accordance with the<a href="https://scholarsapp.iesaciv.com/assets/loi/loi-2013-546-30-juillet-2013-transactions-electroniques.pdf" target="_blank" download style={{color: "red"}}> law  N&#186; 2013-546 of 30-July-2013</a> referring to electronic transactions, all digital signatures has equal legal value as handwritten signatures. As such, the digital signatories abide by commitments herein after contracted</p>

                                                    <h2>RULES AND REGULATIONS</h2>
                                                    <p>1. Pupils are expected to wear the prescribed school uniform and to be clean and tidy at all times to and from school. Any modification to the uniform is not allowed.</p>
                                                    <p>2. Pupils must be courteous and respectful to everyone.</p>
                                                    <p>3. Pupils must be on their best behaviour even outside school.</p>
                                                    <p>4. School does not accept bullying of any kind by any pupils.</p>
                                                    <p>5. Pupils should complete their homework neatly and hand it in punctually.</p>
                                                    <p>6. Pupils must be punctual and regular for school and school activities.</p>
                                                    <p>7. Absence from classes, exams and other school activities must be covered by a medical certificate or a letter of excuse from the parent/guardian.</p>
                                                    <p>8. Pupils must keep their classrooms and school premises clean.</p>
                                                    <p>9. Pupils must move quietly, briskly and in an orderly manner.</p>
                                                    <p>10. Pupils must handle school equipment and property with care.</p> 
                                                    <p>11. Pupils are not allowed to bring mobile phones or any form of electronic gadgets at school. if found on pupils, these gadgets will be confiscated and parents/guardians will be asked to claim these items from the office.</p>

                                                    <p>12. Pupils are not allowed to bring jewels, drugs and any kind of dangerous tools (knives, sharp objects...).</p>

                                                    <p>13. Make up must not be worn to school by pupils. Coloured nail polish and nail decorations and extensions are not permitted.</p>
                                                    <p>14. Tinting, bleaching, colouring and any fashionable hairstyling are not allowed.</p>
                                                    <p>15. Students should not wear any form of cosmetics, nor should there be body piercings, or unnatural body markings made with inks, paints, etc.</p>
                                                    <p>16. Any student who violates the school rules and regulations is liable to face disciplinary action.</p>

                                                    <p>Download the complete Rules and Regulations <a href="https://scholarsapp.iesaciv.com/assets/pdf/Rules & Regulations.pdf" download style={{color: "red"}}>here</a></p>

                                                    
                                                    <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" name="aggree_one" className="custom-control-input" id="aggree_one" value="0" />
                                                    <label className="custom-control-label" for="defaultChecked2" ><span style={{color: "#00284c"}}>&nbsp;I agree, J'approuve <b style={{color: "red"}}>(*)</b></span> <i style={{color: "#d70000"}} id="req_one"></i></label>
                                                    </div>

                                                    <h2>HOME SCHOOL AGREEMENT</h2>
                                                    <p>As a parent, I will:</p>

                                                    <p>1. Take and active interest in all aspects of my child\'s school life;</p>

                                                    <p>2. See that my child attends school regulary, on time and properly equipped;</p>

                                                    <p>3. Support my child\'s participation in all school events and projects;</p>

                                                    <p>4. Communicate to school all relevant information which may affect my child's work or behaviour ;</p>

                                                    <p>5. Notify the school if, for any reason, my child cannot attend;</p>

                                                    <p>6. Encourage my child to follow the school's behaviour policy and support associated action taken by the school ;</p>
                                                    <p>7. Support the school's policy on homework, provide suitable facilities at home, and encourage my child to make the required effort ;</p>

                                                    <p>8. Sign home assignments and class tests regulary;</p>

                                                    <p>9. Replace all school property damaged by my child including payment of labour charges when applicable</p>

                                                    <p>Respect fee's payment deadlines</p>

                                                    <p>Do my best to attend parents' evenings and other meetings at which my presence requested.</p>


                                                    <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" name="aggree_two" className="custom-control-input" id="aggree_two" value="0" />
                                                    <label className="custom-control-label" for="defaultChecked2" ><span style={{color: "#00284c"}}>&nbsp;I agree, J'approuve <b style={{color: "red"}}>(*)</b></span> <i style={{color: "#d70000"}} id="req_two"></i></label>
                                                    </div>

                                                    <h2>SCHOOL FEES POLICIES</h2>
                                                    <p>1. Fees once paid are not refundable.</p>

                                                    <p>2. Payment should be made by cash/ Bank Transfer/Cheque (Cheque should be in the favour of “International English School of Abidjan or IESA”)</p>

                                                    <p>3. In case of a bounced cheque, penalty of 20% will be charged.</p>

                                                    <p>4. For all cash payments, please provide the relevant amount of tax:</p>

                                                    <table className="table">
                                                    <thead>
                                                        <tr>
                                                        <th>AMOUNT PAID (FCFA)</th>
                                                        <th>TAX FEE (FCFA)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <td>5,001—100,000</td>
                                                        <td>100</td>
                                                        </tr>
                                                        <td>100,001—500,000</td>
                                                        <td>500</td>
                                                        <tr>
                                                        <td>500,001—1,000,000</td>
                                                        <td>1, 000</td>
                                                        </tr>
                                                        <tr>
                                                        <td>1,000,001—5,000,000</td>
                                                        <td>2, 000</td>
                                                        </tr>
                                                        <tr>
                                                        <td>More than 5,000,000</td>
                                                        <td>5, 000</td>
                                                        </tr>
                                                    </tbody>
                                                    </table>



                                                    <p>5. Tuition Fees are paid in full or in instalments according to the payment plan agreed with the school. If paid in installments, 50% of the tuition has to be paid before the child can start school.</p>

                                                    <p>6. Transportation, Canteen and Extra activities fees are fixed, irrespective of working days per school term.</p>

                                                    <p>7. The payment per term of Transportation and Canteen fees is equal to the total sum of the months in the term.</p>

                                                    <p>8. The payment of Transportation, Canteen and Extra activities fees for one term has to be made at time subscription of these services and before the beginning of each term for all renewal. In case of non-payment the service will be discontinued.</p>

                                                    <p>9. Parent must necessary respect payment deadlines.</p>

                                                    <p>10. Upon non-payment of tuition fees, a reminder letter, SMS or email will be sent. If no payment is received within 5 days, penalty of 10% will be charged and we'll refuse the pupil entry to the school premises.</p>

                                                    <p>11. Further non-payment within completion of 15 days from the 1st due date will put the child off the class roll.</p>

                                                    <p>12. All school fees and terms and conditions mentioned above are valid at the time of admission. However, the school reserves the right to make changes to the fees structure, terms & conditions and/or ancillary from time to time.</p>



                                                    <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" name="aggree_three" className="custom-control-input" id="aggree_three" value="0" />
                                                    <label className="custom-control-label" for="defaultChecked2" ><span style={{color: "#00284c"}}>&nbsp;I agree, J'approuve <b style={{color: "red"}}>(*)</b></span><i style={{color: "#d70000"}}  id="req_three"></i></label>
                                                    </div>

                                                    <h2>COMMITMENT OF PARTY RESPONSIBLE OF SCHOOL FEES</h2>
                                                    <p>1. I understand and accept my financial obligation (school fees invoice(s) and all other fees) due to IESA.</p>

                                                    <p>2. I agree to abide by the dates listed in the payments plan.</p>

                                                    <p>3. I understand that I am responsible for enrolling for IESA's School Fees Management system and remaining in the IESA program for the entire year if I am paying on the six-month plan.</p>

                                                    <p>4. I understand that if my child (ren) school fees account is more than 5 days in arrears, my child (ren) will be sent home until fee payments clearance is obtained.</p>

                                                    <p>5. I understand that if my child (ren) leaves in the middle of any month, I am responsible for the entire month's payment.</p>

                                                    <p>6. Iunderstandthatfailuretomeetmyfinancialobligationcouldexposemetorecoveryprocedures that may lead to prosecution.</p>

                                                    <p>7. I understand that IESA has the right to seek legal action for collection of school fees.</p>

                                                    <p>The party responsible for school fees will be responsible for all costs of collection, including court expenses and attorney fees.</p>

                                                    <p>8. I understand that this commitment is in addition to IESA's Fee Policy.</p>

                                                    <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" name="aggree_for" className="custom-control-input" id="aggree_for" value="0" />
                                                    <label className="custom-control-label" for="defaultChecked2"><span style={{color: "#00284c"}}>&nbsp;I agree, J'approuve <b style={{color: "red"}}>(*)</b></span> <i style={{color: "#d70000"}} id="req_for"></i></label>
                                                    </div>


                                                    <h2>PARENTS DECLARATION</h2>

                                                    <p>We have read all the rules, agreements and policies aforementioned.</p>
                                                    <p>We declare that if our son/daughter/ward is granted admission, we shall abide by all the present rules, agreements and policies of the school as also those framed from time to time.</p>
                                                    <p>We hereby authorize the school authorities to give first aid to /get medically treated our child/ward in case of any necessity.</p>
                                                    <p>We understand that the School may obtain process and hold personal information about our child, including sensitive information, such as medical details, and we consent to this for the purposes of assessment and in order to safeguard and promote the welfare of the child.</p>

                                                    
                                           </div>


                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="col-12">
                                            <button type="submit" style={{ float: 'left' }} onClick={PreviewStep6} className="btn btn-primary btn-lg">Preview</button>
                                            {/* <button type="submit" style={{ float: 'right' }} onClick={(e) => NextStep7(e)}  className="btn btn-success btn-lg">Next</button> */}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
           
            </div>

        

            <Modal show={showModalStep5} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>{parent}</h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="GET">
                    <Modal.Body>

                                <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                                <div className="row">

                                    <div className="col-12 col-sm-8 mb-3">
                                        <label htmlFor="">The child already has a {parent} registered in our database</label>
                                        <select name={"parent_registered_"+parent} onChange={(e)=> Parentregistered(e)} className='form-control' id="">
                                            <option value="">Select Yes or No</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    </div>

                            {
                                parentExist &&
                             <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group"> 
                                            <code>Enter Last name</code>
                                            <input type="text" className="form-control" name='Search' onChange={(e)=> handleSearchBar(e)}
                                            // value={}
                                            style={{ fontWeight: 'bold' }}
                                            placeholder='Search Parent'
                                            />

                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <div className="form-group"> 
                                            {
                                                dataSearch.filter((val) => {
                                                    return val.lastname.toLowerCase().includes(searchTerm.toLowerCase());
                                                }).map((val)=>{
                                                    return(

                                                        <div className='__result__search'>
                                                            <table className='table table-bordered'>
                                                                <tr>
                                                                    <td style={{textAlign:'center'}}>{val.firstname}</td>
                                                                    <td style={{textAlign:'center'}}> {val.lastname}</td>
                                                                    <td style={{textAlign:'center'}}> {val.phone_main}</td>
                                                                    <td style={{textAlign:'center'}}><button className='btn btn-sm bg-success-light' onClick={(e) => ChooseParent(val.id)}>Choose</button></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    )
                                                })
                                            }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                        }
                                            {
                                                parentNoExist && 

                                <div className="row mt-5"> 

                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Civil Title <code>(*)</code></label>
                                        <select name={"civility_"+parent} onChange={(e)=> handle(e)} className='form-control' id="">
                                            <option value={JSON.parse(sessionStorage.getItem('civility_'+parent))}>{JSON.parse(sessionStorage.getItem('civility_'+parent))}</option>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Ms">Ms</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="">Last name <code>(*)</code></label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('last_name_'+parent))} name={'last_name_'+parent} onChange={(e)=> handle(e)} />
                                    </div>

                                    <div className="col-12 col-sm-5 mb-3">
                                        <label htmlFor="">First name <code>(*)</code></label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('first_name_'+parent))} name={'first_name_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="">Email 1 <code>(*)</code></label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('email_1_'+parent))} name={'email_1_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="">Email 2 </label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('email_2_'+parent))} name={'email_2_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="">Phone (Main) <code>(*)</code></label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('phone_main_'+parent))} name={'phone_main_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Other Phone</label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('other_phone_'+parent))} name={'other_phone_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Whatsapp Phone</label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('whatsapp_phone_'+parent))} name={'whatsapp_phone_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Work phone</label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('work_phone_'+parent))} name={'work_phone_'+parent} onChange={(e)=> handle(e)} />
                                    </div>

                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Home phone</label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('home_phone_'+parent))} name={'home_phone_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Home Addres</label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('home_addres_'+parent))} name={'home_addres_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Postal code</label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('postal_code_'+parent))} name={'postal_code_'+parent} onChange={(e)=> handle(e)} />
                                    </div>

                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">City <code>(*)</code></label>
                                        <input type="text" className="form-control" value={JSON.parse(sessionStorage.getItem('city_'+parent))} name={'city_'+parent} onChange={(e)=> handle(e)} />
                                    </div>

                                    <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Country <code>(*)</code></label>
                                                    <div>
                                                        <select name={"country_"+parent} id="country"  onChange={(e) => handle(e)} className='form-control'>
                                                            <option value={JSON.parse(sessionStorage.getItem('country_'+parent))}>{JSON.parse(sessionStorage.getItem('country_'+parent))}</option>
                                                            <option value="">Country</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Antarctica">Antarctica</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="American Samoa">American Samoa</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh">Bangladesh</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Bermuda">Bermuda</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Bouvet Island">Bouvet Island</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="British Virgin Islands">British Virgin Islands</option>
                                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Cape Verde">Cape Verde</option>
                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                            <option value="Central African">Central African</option>
                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Taiwan">Taiwan</option>
                                                            <option value="Christmas Island">Christmas Island</option>
                                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Mayotte">Mayotte</option>
                                                            <option value="Republic of the Congo">Republic of the Congo</option>
                                                            <option value="The Democratic Republic Of The Congo">The Democratic Republic Of The Congo</option>
                                                            <option value="Cook Islands">Cook Islands</option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Croatia">Croatia</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czech Republic">Czech Republic</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                            <option value="Falkland Islands">Falkland Islands</option>
                                                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="Åland Islands">Åland Islands</option>
                                                            <option value="France">France</option>
                                                            <option value="French Guiana">French Guiana</option>
                                                            <option value="French Polynesia">French Polynesia</option>
                                                            <option value="French Southern Territories">French Southern Territories</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Occupied Palestinian Territory">Occupied Palestinian Territory</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Gibraltar">Gibraltar</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Greenland">Greenland</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                            <option value="Guam">Guam</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                                                            <option value="Vatican City State">Vatican City State</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hong Kong">Hong Kong</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="India">India</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Islamic Republic of Iran">Islamic Republic of Iran</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Ivory Coast">Ivory Coast</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Democratic People's Republic of Korea">Democratic People's Republic of Korea</option>
                                                            <option value="Republic of Korea">Republic of Korea</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                            <option value="Lebanon">Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Macao">Macao</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Martinique">Martinique</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Republic of Moldova">Republic of Moldova</option>
                                                            <option value="Montserrat">Montserrat</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Namibia">Namibia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherlands">Netherlands</option>
                                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option value="Aruba">Aruba</option>
                                                            <option value="New Caledonia">New Caledonia</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="Niue">Niue</option>
                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                            <option value="Federated States of Micronesia">Federated States of Micronesia</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Palau">Palau</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Philippines">Philippines</option>
                                                            <option value="Pitcairn">Pitcairn</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                            <option value="Timor-Leste">Timor-Leste</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Réunion">Réunion</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russian Federation">Russian Federation</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="Saint Helena">Saint Helena</option>
                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                            <option value="Anguilla">Anguilla</option>
                                                            <option value="Saint Lucia">Saint Lucia</option>
                                                            <option value="Saint-Pierre and Miquelon">Saint-Pierre and Miquelon</option>
                                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia">Slovakia</option>
                                                            <option value="Vietnam">Vietnam</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                            <option value="Spain">Spain</option>
                                                            <option value="Western Sahara">Western Sahara</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                            <option value="Swaziland">Swaziland</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tokelau">Tokelau</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="The Former Yugoslav Republic of Macedonia">The Former Yugoslav Republic of Macedonia</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="Isle of Man">Isle of Man</option>
                                                            <option value="United Republic Of Tanzania">United Republic Of Tanzania</option>
                                                            <option value="United States">United States</option>
                                                            <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Uruguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                                                            <option value="Zambia">Zambia</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Nationality <code>(*)</code></label>
                                                    <div>
                                                        <select name={"nationality_"+parent} id="nationality"  onChange={(e) => handle(e)} className='form-control'>
                                                        <option value={JSON.parse(sessionStorage.getItem('nationality_'+parent))}>{JSON.parse(sessionStorage.getItem('nationality_'+parent))}</option>
                                                            <option value="">Nationality</option>
                                                            <option value="Afghan">Afghan</option>
                                                            <option value="Albanian">Albanian</option>
                                                            <option value="Algerian">Algerian</option>
                                                            <option value="American">American</option>
                                                            <option value="Andorran">Andorran</option>
                                                            <option value="Angolan">Angolan</option>
                                                            <option value="Argentinian">Argentinian</option>
                                                            <option value="Armenian">Armenian</option>
                                                            <option value="Australian">Australian</option>
                                                            <option value="Austrian">Austrian</option>
                                                            <option value="Azerbaijani">Azerbaijani</option>
                                                            <option value="Bahamian">Bahamian</option>
                                                            <option value="Bangladeshi">Bangladeshi</option>
                                                            <option value="Barbadian">Barbadian</option>
                                                            <option value="Belgian">Belgian</option>
                                                            <option value="Belorussian">Belorussian</option>
                                                            <option value="Beninese">Beninese</option>
                                                            <option value="Bhutanese">Bhutanese</option>
                                                            <option value="Bolivian">Bolivian</option>
                                                            <option value="Bosnian">Bosnian</option>
                                                            <option value="Brazilian">Brazilian</option>
                                                            <option value="British">British</option>
                                                            <option value="Briton">Briton</option>
                                                            <option value="Bruneian">Bruneian</option>
                                                            <option value="Bulgarian">Bulgarian</option>
                                                            <option value="Burkinabe">Burkinabe</option>
                                                            <option value="Burmese">Burmese</option>
                                                            <option value="Burundian">Burundian</option>
                                                            <option value="Cambodian">Cambodian</option>
                                                            <option value="Cameroonian">Cameroonian</option>
                                                            <option value="Canadian">Canadian</option>
                                                            <option value="Central African">Central African</option>
                                                            <option value="Chadian">Chadian</option>
                                                            <option value="Chilean">Chilean</option>
                                                            <option value="Chinese">Chinese</option>
                                                            <option value="Colombian">Colombian</option>
                                                            <option value="Congolese">Congolese</option>
                                                            <option value="Congolese">Congolese</option>
                                                            <option value="Croatian">Croatian</option>
                                                            <option value="Cuban">Cuban</option>
                                                            <option value="Cypriot">Cypriot</option>
                                                            <option value="Czech">Czech</option>
                                                            <option value="Dane">Dane</option>
                                                            <option value="Dominican">Dominican</option>
                                                            <option value="Dutchman">Dutchman</option>
                                                            <option value="Ecuadorean">Ecuadorean</option>
                                                            <option value="Egyptian">Egyptian</option>
                                                            <option value="Englishman">Englishman</option>
                                                            <option value="Eritrean">Eritrean</option>
                                                            <option value="Estonian">Estonian</option>
                                                            <option value="Ethiopian">Ethiopian</option>
                                                            <option value="Fijian">Fijian</option>
                                                            <option value="Filipino">Filipino</option>
                                                            <option value="Finn">Finn</option>
                                                            <option value="Frenchman">Frenchman</option>
                                                            <option value="Gabonese">Gabonese</option>
                                                            <option value="Gambian">Gambian</option>
                                                            <option value="Georgian">Georgian</option>
                                                            <option value="German">German</option>
                                                            <option value="Ghanaian">Ghanaian</option>
                                                            <option value="Greek">Greek</option>
                                                            <option value="Grenadian">Grenadian</option>
                                                            <option value="Guatemalan">Guatemalan</option>
                                                            <option value="Guinean">Guinean</option>
                                                            <option value="Guyanese">Guyanese</option>
                                                            <option value="Haitian">Haitian</option>
                                                            <option value="Honduran">Honduran</option>
                                                            <option value="Hungarian">Hungarian</option>
                                                            <option value="Icelander">Icelander</option>
                                                            <option value="Indian">Indian</option>
                                                            <option value="Indonesian">Indonesian</option>
                                                            <option value="Iranian">Iranian</option>
                                                            <option value="Iraqi">Iraqi</option>
                                                            <option value="Irishman">Irishman</option>
                                                            <option value="Israeli">Israeli</option>
                                                            <option value="Italian">Italian</option>
                                                            <option value="Ivorian">Ivorian</option>
                                                            <option value="Jamaican">Jamaican</option>
                                                            <option value="Japanese">Japanese</option>
                                                            <option value="Jordanian">Jordanian</option>
                                                            <option value="Kazakh">Kazakh</option>
                                                            <option value="Kenyan">Kenyan</option>
                                                            <option value="Korean">Korean</option>
                                                            <option value="Kuwaiti">Kuwaiti</option>
                                                            <option value="Laotian">Laotian</option>
                                                            <option value="Latvian">Latvian</option>
                                                            <option value="Lebanese">Lebanese</option>
                                                            <option value="Liberian">Liberian</option>
                                                            <option value="Libyan">Libyan</option>
                                                            <option value="Liechtensteiner">Liechtensteiner</option>
                                                            <option value="Lithuanian">Lithuanian</option>
                                                            <option value="Luxembourger">Luxembourger</option>
                                                            <option value="Macedonian">Macedonian</option>
                                                            <option value="Madagascan">Madagascan</option>
                                                            <option value="Malawian">Malawian</option>
                                                            <option value="Malaysian">Malaysian</option>
                                                            <option value="Maldivian">Maldivian</option>
                                                            <option value="Malian">Malian</option>
                                                            <option value="Maltese">Maltese</option>
                                                            <option value="Mauritanian">Mauritanian</option>
                                                            <option value="Mauritian">Mauritian</option>
                                                            <option value="Mexican">Mexican</option>
                                                            <option value="Moldovan">Moldovan</option>
                                                            <option value="Monacan">Monacan</option>
                                                            <option value="Mongolian">Mongolian</option>
                                                            <option value="Montenegrin">Montenegrin</option>
                                                            <option value="Moroccan">Moroccan</option>
                                                            <option value="Mozambican">Mozambican</option>
                                                            <option value="Namibian">Namibian</option>
                                                            <option value="Nepalese">Nepalese</option>
                                                            <option value="Nicaraguan">Nicaraguan</option>
                                                            <option value="Nigerian">Nigerian</option>
                                                            <option value="Nigerien">Nigerien</option>
                                                            <option value="Norwegian">Norwegian</option>
                                                            <option value="Pakistani">Pakistani</option>
                                                            <option value="Panamanian">Panamanian</option>
                                                            <option value="Paraguayan">Paraguayan</option>
                                                            <option value="Peruvian">Peruvian</option>
                                                            <option value="Pole">Pole</option>
                                                            <option value="Portuguese">Portuguese</option>
                                                            <option value="Qatari">Qatari</option>
                                                            <option value="Romanian">Romanian</option>
                                                            <option value="Russian">Russian</option>
                                                            <option value="Rwandan">Rwandan</option>
                                                            <option value="Salvadorean">Salvadorean</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Saudi Arabian">Saudi Arabian</option>
                                                            <option value="Scot">Scot</option>
                                                            <option value="Senegalese">Senegalese</option>
                                                            <option value="Serbian">Serbian</option>
                                                            <option value="Sierra Leonean">Sierra Leonean</option>
                                                            <option value="Singaporean">Singaporean</option>
                                                            <option value="Slovak">Slovak</option>
                                                            <option value="Slovenian">Slovenian</option>
                                                            <option value="Somali">Somali</option>
                                                            <option value="South African">South African</option>
                                                            <option value="Spaniard">Spaniard</option>
                                                            <option value="SriLankan">SriLankan</option>
                                                            <option value="Sudanese">Sudanese</option>
                                                            <option value="Surinamese">Surinamese</option>
                                                            <option value="Swazi">Swazi</option>
                                                            <option value="Swede">Swede</option>
                                                            <option value="Swiss">Swiss</option>
                                                            <option value="Syrian">Syrian</option>
                                                            <option value="Tadzhik">Tadzhik</option>
                                                            <option value="Taiwanese">Taiwanese</option>
                                                            <option value="Tanzanian">Tanzanian</option>
                                                            <option value="Thai">Thai</option>
                                                            <option value="Togolese">Togolese</option>
                                                            <option value="Trinidadian">Trinidadian</option>
                                                            <option value="Tunisian">Tunisian</option>
                                                            <option value="Turk">Turk</option>
                                                            <option value="Ugandan">Ugandan</option>
                                                            <option value="Ukrainian">Ukrainian</option>
                                                            <option value="Uruguayan">Uruguayan</option>
                                                            <option value="Uzbek">Uzbek</option>
                                                            <option value="Venezuelan">Venezuelan</option>
                                                            <option value="Vietnamese">Vietnamese</option>
                                                            <option value="Welshman">Welshman</option>
                                                            <option value="Yemeni">Yemeni</option>
                                                            <option value="Yugoslav">Yugoslav</option>
                                                            <option value="Zambian">Zambian</option>
                                                            <option value="Zimbabwean">Zimbabwean</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">SMS/Email Language <code>(*)</code></label>
                                                <select name={"sms_lang_"+parent} onChange={(e)=> handle(e)} className='form-control' id="">
                                                <option value={JSON.parse(sessionStorage.getItem('sms_lang_'+parent))}>{JSON.parse(sessionStorage.getItem('sms_lang_'+parent))}</option>
                                                    <option value="French">French</option>
                                                    <option value="English">English</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">Occupation</label>
                                                <input type="text" className="form-control"  value={JSON.parse(sessionStorage.getItem('occupation_'+parent))} name={'occupation_'+parent} onChange={(e)=> handle(e)} />
                                            </div>

                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">Enterprise</label>
                                                <input type="text" className="form-control"  value={JSON.parse(sessionStorage.getItem('enterprise_'+parent))} name={'enterprise_'+parent} onChange={(e)=> handle(e)} />
                                            </div>

                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">Enterprise adress</label>
                                                <input type="text" className="form-control"  value={JSON.parse(sessionStorage.getItem('enterprise_addres_'+parent))} name={'enterprise_addres_'+parent} onChange={(e)=> handle(e)} />
                                            </div>

                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">Responsible of school feese <code>(*)</code></label>
                                                <select name={"responsible_"+parent} onChange={(e)=> handle(e)} className='form-control' id="">
                                                <option value={JSON.parse(sessionStorage.getItem('responsible_'+parent))}>{JSON.parse(sessionStorage.getItem('responsible_'+parent))}</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                </div>
                                }
                            </div>

                    </Modal.Body>
                    <Modal.Footer>

                    {
                    parentNoExist &&         
                        <Button type="button" onClick={(e) => handleSubmitParentInfo(e)} variant='primary'>
                            Submit
                        </Button>
                    }
                    </Modal.Footer>
                </form>
            </Modal>



             <Modal show={showModalUpdateStep5} onHide={closeModalUpdateStep5} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update infos {parent}</h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="GET">
                    <Modal.Body>

                                <div className="row">

                                  {
                                    dataUpdateStep5.map((result) => (

                                   

                                <div className="row mt-5"> 
                                                {result.code_parent}
                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Civil Title <code>(*)</code></label>
                                        <select name={"civility_"+parent} onChange={(e)=> handle(e)} className='form-control' id="">
                                            <option value={result.civility}>{result.civility}</option>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Ms">Ms</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="">Last name <code>(*)</code></label>
                                        <input type="text" className="form-control" defaultValue={result.lastname} name={'last_name_'+parent} onChange={(e)=> handle(e)} />
                                    </div>

                                    <div className="col-12 col-sm-5 mb-3">
                                        <label htmlFor="">First name <code>(*)</code></label>
                                        <input type="text" className="form-control" defaultValue={result.firstname} name={'first_name_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="">Email 1 <code>(*)</code></label>
                                        <input type="text" className="form-control" defaultValue={result.email_1} name={'email_1_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="">Email 2 </label>
                                        <input type="text" className="form-control" defaultValue={result.email_2} name={'email_2_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="">Phone (Main) <code>(*)</code></label>
                                        <input type="text" className="form-control" defaultValue={result.phone_main} name={'phone_main_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Other Phone</label>
                                        <input type="text" className="form-control" defaultValue={result.other_phone} name={'other_phone_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Whatsapp Phone</label>
                                        <input type="text" className="form-control" defaultValue={result.whatsapp_phone} name={'whatsapp_phone_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Work phone</label>
                                        <input type="text" className="form-control" defaultValue={result.work_phone} name={'work_phone_'+parent} onChange={(e)=> handle(e)} />
                                    </div>

                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Home phone</label>
                                        <input type="text" className="form-control" defaultValue={result.home_phone} name={'home_phone_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Home Addres</label>
                                        <input type="text" className="form-control" defaultValue={result.home_addres} name={'home_addres_'+parent} onChange={(e)=> handle(e)} />
                                    </div>


                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">Postal code</label>
                                        <input type="text" className="form-control" defaultValue={result.postal_code} name={'postal_code_'+parent} onChange={(e)=> handle(e)} />
                                    </div>

                                    <div className="col-12 col-sm-3 mb-3">
                                        <label htmlFor="">City <code>(*)</code></label>
                                        <input type="text" className="form-control" defaultValue={result.city} name={'city_'+parent} onChange={(e)=> handle(e)} />
                                    </div>

                                    <div className="col-12 col-sm-3">
                                                <div className="form-group">
                                                    <label>Country <code>(*)</code></label>
                                                    <div>
                                                        <select name={"country_"+parent} id="country"  onChange={(e) => handle(e)} className='form-control'>
                                                            <option defaultValue={result.country}>{result.country}</option>
                                                            <option defaultValue="">Country</option>
                                                            <option defaultValue="Albania">Albania</option>
                                                            <option defaultValue="Antarctica">Antarctica</option>
                                                            <option defaultValue="Algeria">Algeria</option>
                                                            <option defaultValue="American Samoa">American Samoa</option>
                                                            <option defaultValue="Andorra">Andorra</option>
                                                            <option defaultValue="Angola">Angola</option>
                                                            <option defaultValue="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option defaultValue="Azerbaijan">Azerbaijan</option>
                                                            <option defaultValue="Argentina">Argentina</option>
                                                            <option defaultValue="Australia">Australia</option>
                                                            <option defaultValue="Austria">Austria</option>
                                                            <option defaultValue="Bahamas">Bahamas</option>
                                                            <option defaultValue="Bahrain">Bahrain</option>
                                                            <option defaultValue="Bangladesh">Bangladesh</option>
                                                            <option defaultValue="Armenia">Armenia</option>
                                                            <option defaultValue="Barbados">Barbados</option>
                                                            <option defaultValue="Belgium">Belgium</option>
                                                            <option defaultValue="Bermuda">Bermuda</option>
                                                            <option defaultValue="Bhutan">Bhutan</option>
                                                            <option defaultValue="Bolivia">Bolivia</option>
                                                            <option defaultValue="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                            <option defaultValue="Botswana">Botswana</option>
                                                            <option defaultValue="Bouvet Island">Bouvet Island</option>
                                                            <option defaultValue="Brazil">Brazil</option>
                                                            <option defaultValue="Belize">Belize</option>
                                                            <option defaultValue="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option defaultValue="Solomon Islands">Solomon Islands</option>
                                                            <option defaultValue="British Virgin Islands">British Virgin Islands</option>
                                                            <option defaultValue="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option defaultValue="Bulgaria">Bulgaria</option>
                                                            <option defaultValue="Myanmar">Myanmar</option>
                                                            <option defaultValue="Burundi">Burundi</option>
                                                            <option defaultValue="Belarus">Belarus</option>
                                                            <option defaultValue="Cambodia">Cambodia</option>
                                                            <option defaultValue="Cameroon">Cameroon</option>
                                                            <option defaultValue="Canada">Canada</option>
                                                            <option defaultValue="Cape Verde">Cape Verde</option>
                                                            <option defaultValue="Cayman Islands">Cayman Islands</option>
                                                            <option defaultValue="Central African">Central African</option>
                                                            <option defaultValue="Sri Lanka">Sri Lanka</option>
                                                            <option defaultValue="Chad">Chad</option>
                                                            <option defaultValue="Chile">Chile</option>
                                                            <option defaultValue="China">China</option>
                                                            <option defaultValue="Taiwan">Taiwan</option>
                                                            <option defaultValue="Christmas Island">Christmas Island</option>
                                                            <option defaultValue="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                            <option defaultValue="Colombia">Colombia</option>
                                                            <option defaultValue="Comoros">Comoros</option>
                                                            <option defaultValue="Mayotte">Mayotte</option>
                                                            <option defaultValue="Republic of the Congo">Republic of the Congo</option>
                                                            <option defaultValue="The Democratic Republic Of The Congo">The Democratic Republic Of The Congo</option>
                                                            <option defaultValue="Cook Islands">Cook Islands</option>
                                                            <option defaultValue="Costa Rica">Costa Rica</option>
                                                            <option defaultValue="Croatia">Croatia</option>
                                                            <option defaultValue="Cuba">Cuba</option>
                                                            <option defaultValue="Cyprus">Cyprus</option>
                                                            <option defaultValue="Czech Republic">Czech Republic</option>
                                                            <option defaultValue="Benin">Benin</option>
                                                            <option defaultValue="Denmark">Denmark</option>
                                                            <option defaultValue="Dominica">Dominica</option>
                                                            <option defaultValue="Dominican Republic">Dominican Republic</option>
                                                            <option defaultValue="Ecuador">Ecuador</option>
                                                            <option defaultValue="El Salvador">El Salvador</option>
                                                            <option defaultValue="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option defaultValue="Ethiopia">Ethiopia</option>
                                                            <option defaultValue="Eritrea">Eritrea</option>
                                                            <option defaultValue="Estonia">Estonia</option>
                                                            <option defaultValue="Faroe Islands">Faroe Islands</option>
                                                            <option defaultValue="Falkland Islands">Falkland Islands</option>
                                                            <option defaultValue="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                            <option defaultValue="Fiji">Fiji</option>
                                                            <option defaultValue="Finland">Finland</option>
                                                            <option defaultValue="Åland Islands">Åland Islands</option>
                                                            <option defaultValue="France">France</option>
                                                            <option defaultValue="French Guiana">French Guiana</option>
                                                            <option defaultValue="French Polynesia">French Polynesia</option>
                                                            <option defaultValue="French Southern Territories">French Southern Territories</option>
                                                            <option defaultValue="Djibouti">Djibouti</option>
                                                            <option defaultValue="Gabon">Gabon</option>
                                                            <option defaultValue="Georgia">Georgia</option>
                                                            <option defaultValue="Gambia">Gambia</option>
                                                            <option defaultValue="Occupied Palestinian Territory">Occupied Palestinian Territory</option>
                                                            <option defaultValue="Germany">Germany</option>
                                                            <option defaultValue="Ghana">Ghana</option>
                                                            <option defaultValue="Gibraltar">Gibraltar</option>
                                                            <option defaultValue="Kiribati">Kiribati</option>
                                                            <option defaultValue="Greece">Greece</option>
                                                            <option defaultValue="Greenland">Greenland</option>
                                                            <option defaultValue="Grenada">Grenada</option>
                                                            <option defaultValue="Guadeloupe">Guadeloupe</option>
                                                            <option defaultValue="Guam">Guam</option>
                                                            <option defaultValue="Guatemala">Guatemala</option>
                                                            <option defaultValue="Guinea">Guinea</option>
                                                            <option defaultValue="Guyana">Guyana</option>
                                                            <option defaultValue="Haiti">Haiti</option>
                                                            <option defaultValue="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                                                            <option defaultValue="Vatican City State">Vatican City State</option>
                                                            <option defaultValue="Honduras">Honduras</option>
                                                            <option defaultValue="Hong Kong">Hong Kong</option>
                                                            <option defaultValue="Hungary">Hungary</option>
                                                            <option defaultValue="Iceland">Iceland</option>
                                                            <option defaultValue="India">India</option>
                                                            <option defaultValue="Indonesia">Indonesia</option>
                                                            <option defaultValue="Islamic Republic of Iran">Islamic Republic of Iran</option>
                                                            <option defaultValue="Iraq">Iraq</option>
                                                            <option defaultValue="Ireland">Ireland</option>
                                                            <option defaultValue="Israel">Israel</option>
                                                            <option defaultValue="Italy">Italy</option>
                                                            <option defaultValue="Ivory Coast">Ivory Coast</option>
                                                            <option defaultValue="Jamaica">Jamaica</option>
                                                            <option defaultValue="Japan">Japan</option>
                                                            <option defaultValue="Kazakhstan">Kazakhstan</option>
                                                            <option defaultValue="Jordan">Jordan</option>
                                                            <option defaultValue="Kenya">Kenya</option>
                                                            <option defaultValue="Democratic People's Republic of Korea">Democratic People's Republic of Korea</option>
                                                            <option defaultValue="Republic of Korea">Republic of Korea</option>
                                                            <option defaultValue="Kuwait">Kuwait</option>
                                                            <option defaultValue="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option defaultValue="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                            <option defaultValue="Lebanon">Lebanon</option>
                                                            <option defaultValue="Lesotho">Lesotho</option>
                                                            <option defaultValue="Latvia">Latvia</option>
                                                            <option defaultValue="Liberia">Liberia</option>
                                                            <option defaultValue="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option defaultValue="Liechtenstein">Liechtenstein</option>
                                                            <option defaultValue="Lithuania">Lithuania</option>
                                                            <option defaultValue="Luxembourg">Luxembourg</option>
                                                            <option defaultValue="Macao">Macao</option>
                                                            <option defaultValue="Madagascar">Madagascar</option>
                                                            <option defaultValue="Malawi">Malawi</option>
                                                            <option defaultValue="Malaysia">Malaysia</option>
                                                            <option defaultValue="Maldives">Maldives</option>
                                                            <option defaultValue="Mali">Mali</option>
                                                            <option defaultValue="Malta">Malta</option>
                                                            <option defaultValue="Martinique">Martinique</option>
                                                            <option defaultValue="Mauritania">Mauritania</option>
                                                            <option defaultValue="Mauritius">Mauritius</option>
                                                            <option defaultValue="Mexico">Mexico</option>
                                                            <option defaultValue="Monaco">Monaco</option>
                                                            <option defaultValue="Mongolia">Mongolia</option>
                                                            <option defaultValue="Republic of Moldova">Republic of Moldova</option>
                                                            <option defaultValue="Montserrat">Montserrat</option>
                                                            <option defaultValue="Morocco">Morocco</option>
                                                            <option defaultValue="Mozambique">Mozambique</option>
                                                            <option defaultValue="Oman">Oman</option>
                                                            <option defaultValue="Namibia">Namibia</option>
                                                            <option defaultValue="Nauru">Nauru</option>
                                                            <option defaultValue="Nepal">Nepal</option>
                                                            <option defaultValue="Netherlands">Netherlands</option>
                                                            <option defaultValue="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option defaultValue="Aruba">Aruba</option>
                                                            <option defaultValue="New Caledonia">New Caledonia</option>
                                                            <option defaultValue="Vanuatu">Vanuatu</option>
                                                            <option defaultValue="New Zealand">New Zealand</option>
                                                            <option defaultValue="Nicaragua">Nicaragua</option>
                                                            <option defaultValue="Niger">Niger</option>
                                                            <option defaultValue="Nigeria">Nigeria</option>
                                                            <option defaultValue="Niue">Niue</option>
                                                            <option defaultValue="Norfolk Island">Norfolk Island</option>
                                                            <option defaultValue="Norway">Norway</option>
                                                            <option defaultValue="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option defaultValue="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                            <option defaultValue="Federated States of Micronesia">Federated States of Micronesia</option>
                                                            <option defaultValue="Marshall Islands">Marshall Islands</option>
                                                            <option defaultValue="Palau">Palau</option>
                                                            <option defaultValue="Pakistan">Pakistan</option>
                                                            <option defaultValue="Panama">Panama</option>
                                                            <option defaultValue="Papua New Guinea">Papua New Guinea</option>
                                                            <option defaultValue="Paraguay">Paraguay</option>
                                                            <option defaultValue="Peru">Peru</option>
                                                            <option defaultValue="Philippines">Philippines</option>
                                                            <option defaultValue="Pitcairn">Pitcairn</option>
                                                            <option defaultValue="Poland">Poland</option>
                                                            <option defaultValue="Portugal">Portugal</option>
                                                            <option defaultValue="Guinea-Bissau">Guinea-Bissau</option>
                                                            <option defaultValue="Timor-Leste">Timor-Leste</option>
                                                            <option defaultValue="Puerto Rico">Puerto Rico</option>
                                                            <option defaultValue="Qatar">Qatar</option>
                                                            <option defaultValue="Réunion">Réunion</option>
                                                            <option defaultValue="Romania">Romania</option>
                                                            <option defaultValue="Russian Federation">Russian Federation</option>
                                                            <option defaultValue="Rwanda">Rwanda</option>
                                                            <option defaultValue="Saint Helena">Saint Helena</option>
                                                            <option defaultValue="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                            <option defaultValue="Anguilla">Anguilla</option>
                                                            <option defaultValue="Saint Lucia">Saint Lucia</option>
                                                            <option defaultValue="Saint-Pierre and Miquelon">Saint-Pierre and Miquelon</option>
                                                            <option defaultValue="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                            <option defaultValue="San Marino">San Marino</option>
                                                            <option defaultValue="Sao Tome and Principe">Sao Tome and Principe</option>
                                                            <option defaultValue="Saudi Arabia">Saudi Arabia</option>
                                                            <option defaultValue="Senegal">Senegal</option>
                                                            <option defaultValue="Seychelles">Seychelles</option>
                                                            <option defaultValue="Sierra Leone">Sierra Leone</option>
                                                            <option defaultValue="Singapore">Singapore</option>
                                                            <option defaultValue="Slovakia">Slovakia</option>
                                                            <option defaultValue="Vietnam">Vietnam</option>
                                                            <option defaultValue="Slovenia">Slovenia</option>
                                                            <option defaultValue="Somalia">Somalia</option>
                                                            <option defaultValue="South Africa">South Africa</option>
                                                            <option defaultValue="Zimbabwe">Zimbabwe</option>
                                                            <option defaultValue="Spain">Spain</option>
                                                            <option defaultValue="Western Sahara">Western Sahara</option>
                                                            <option defaultValue="Sudan">Sudan</option>
                                                            <option defaultValue="Suriname">Suriname</option>
                                                            <option defaultValue="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                            <option defaultValue="Swaziland">Swaziland</option>
                                                            <option defaultValue="Sweden">Sweden</option>
                                                            <option defaultValue="Switzerland">Switzerland</option>
                                                            <option defaultValue="Syrian Arab Republic">Syrian Arab Republic</option>
                                                            <option defaultValue="Tajikistan">Tajikistan</option>
                                                            <option defaultValue="Thailand">Thailand</option>
                                                            <option defaultValue="Togo">Togo</option>
                                                            <option defaultValue="Tokelau">Tokelau</option>
                                                            <option defaultValue="Tonga">Tonga</option>
                                                            <option defaultValue="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option defaultValue="United Arab Emirates">United Arab Emirates</option>
                                                            <option defaultValue="Tunisia">Tunisia</option>
                                                            <option defaultValue="Turkey">Turkey</option>
                                                            <option defaultValue="Turkmenistan">Turkmenistan</option>
                                                            <option defaultValue="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                            <option defaultValue="Tuvalu">Tuvalu</option>
                                                            <option defaultValue="Uganda">Uganda</option>
                                                            <option defaultValue="Ukraine">Ukraine</option>
                                                            <option defaultValue="The Former Yugoslav Republic of Macedonia">The Former Yugoslav Republic of Macedonia</option>
                                                            <option defaultValue="Egypt">Egypt</option>
                                                            <option defaultValue="United Kingdom">United Kingdom</option>
                                                            <option defaultValue="Isle of Man">Isle of Man</option>
                                                            <option defaultValue="United Republic Of Tanzania">United Republic Of Tanzania</option>
                                                            <option defaultValue="United States">United States</option>
                                                            <option defaultValue="U.S. Virgin Islands">U.S. Virgin Islands</option>
                                                            <option defaultValue="Burkina Faso">Burkina Faso</option>
                                                            <option defaultValue="Uruguay">Uruguay</option>
                                                            <option defaultValue="Uzbekistan">Uzbekistan</option>
                                                            <option defaultValue="Venezuela">Venezuela</option>
                                                            <option defaultValue="Wallis and Futuna">Wallis and Futuna</option>
                                                            <option defaultValue="Samoa">Samoa</option>
                                                            <option defaultValue="Yemen">Yemen</option>
                                                            <option defaultValue="Serbia and Montenegro">Serbia and Montenegro</option>
                                                            <option defaultValue="Zambia">Zambia</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12 col-sm-4">
                                                <div className="form-group">
                                                    <label>Nationality <code>(*)</code></label>
                                                    <div>
                                                        <select name={"nationality_"+parent} id="nationality"  onChange={(e) => handle(e)} className='form-control'>
                                                        <option defaultValue={result.nationality}>{result.nationality}</option>
                                                            <option defaultValue="">Nationality</option>
                                                            <option defaultValue="Afghan">Afghan</option>
                                                            <option defaultValue="Albanian">Albanian</option>
                                                            <option defaultValue="Algerian">Algerian</option>
                                                            <option defaultValue="American">American</option>
                                                            <option defaultValue="Andorran">Andorran</option>
                                                            <option defaultValue="Angolan">Angolan</option>
                                                            <option defaultValue="Argentinian">Argentinian</option>
                                                            <option defaultValue="Armenian">Armenian</option>
                                                            <option defaultValue="Australian">Australian</option>
                                                            <option defaultValue="Austrian">Austrian</option>
                                                            <option defaultValue="Azerbaijani">Azerbaijani</option>
                                                            <option defaultValue="Bahamian">Bahamian</option>
                                                            <option defaultValue="Bangladeshi">Bangladeshi</option>
                                                            <option defaultValue="Barbadian">Barbadian</option>
                                                            <option defaultValue="Belgian">Belgian</option>
                                                            <option defaultValue="Belorussian">Belorussian</option>
                                                            <option defaultValue="Beninese">Beninese</option>
                                                            <option defaultValue="Bhutanese">Bhutanese</option>
                                                            <option defaultValue="Bolivian">Bolivian</option>
                                                            <option defaultValue="Bosnian">Bosnian</option>
                                                            <option defaultValue="Brazilian">Brazilian</option>
                                                            <option defaultValue="British">British</option>
                                                            <option defaultValue="Briton">Briton</option>
                                                            <option defaultValue="Bruneian">Bruneian</option>
                                                            <option defaultValue="Bulgarian">Bulgarian</option>
                                                            <option defaultValue="Burkinabe">Burkinabe</option>
                                                            <option defaultValue="Burmese">Burmese</option>
                                                            <option defaultValue="Burundian">Burundian</option>
                                                            <option defaultValue="Cambodian">Cambodian</option>
                                                            <option defaultValue="Cameroonian">Cameroonian</option>
                                                            <option defaultValue="Canadian">Canadian</option>
                                                            <option defaultValue="Central African">Central African</option>
                                                            <option defaultValue="Chadian">Chadian</option>
                                                            <option defaultValue="Chilean">Chilean</option>
                                                            <option defaultValue="Chinese">Chinese</option>
                                                            <option defaultValue="Colombian">Colombian</option>
                                                            <option defaultValue="Congolese">Congolese</option>
                                                            <option defaultValue="Congolese">Congolese</option>
                                                            <option defaultValue="Croatian">Croatian</option>
                                                            <option defaultValue="Cuban">Cuban</option>
                                                            <option defaultValue="Cypriot">Cypriot</option>
                                                            <option defaultValue="Czech">Czech</option>
                                                            <option defaultValue="Dane">Dane</option>
                                                            <option defaultValue="Dominican">Dominican</option>
                                                            <option defaultValue="Dutchman">Dutchman</option>
                                                            <option defaultValue="Ecuadorean">Ecuadorean</option>
                                                            <option defaultValue="Egyptian">Egyptian</option>
                                                            <option defaultValue="Englishman">Englishman</option>
                                                            <option defaultValue="Eritrean">Eritrean</option>
                                                            <option defaultValue="Estonian">Estonian</option>
                                                            <option defaultValue="Ethiopian">Ethiopian</option>
                                                            <option defaultValue="Fijian">Fijian</option>
                                                            <option defaultValue="Filipino">Filipino</option>
                                                            <option defaultValue="Finn">Finn</option>
                                                            <option defaultValue="Frenchman">Frenchman</option>
                                                            <option defaultValue="Gabonese">Gabonese</option>
                                                            <option defaultValue="Gambian">Gambian</option>
                                                            <option defaultValue="Georgian">Georgian</option>
                                                            <option defaultValue="German">German</option>
                                                            <option defaultValue="Ghanaian">Ghanaian</option>
                                                            <option defaultValue="Greek">Greek</option>
                                                            <option defaultValue="Grenadian">Grenadian</option>
                                                            <option defaultValue="Guatemalan">Guatemalan</option>
                                                            <option defaultValue="Guinean">Guinean</option>
                                                            <option defaultValue="Guyanese">Guyanese</option>
                                                            <option defaultValue="Haitian">Haitian</option>
                                                            <option defaultValue="Honduran">Honduran</option>
                                                            <option defaultValue="Hungarian">Hungarian</option>
                                                            <option defaultValue="Icelander">Icelander</option>
                                                            <option defaultValue="Indian">Indian</option>
                                                            <option defaultValue="Indonesian">Indonesian</option>
                                                            <option defaultValue="Iranian">Iranian</option>
                                                            <option defaultValue="Iraqi">Iraqi</option>
                                                            <option defaultValue="Irishman">Irishman</option>
                                                            <option defaultValue="Israeli">Israeli</option>
                                                            <option defaultValue="Italian">Italian</option>
                                                            <option defaultValue="Ivorian">Ivorian</option>
                                                            <option defaultValue="Jamaican">Jamaican</option>
                                                            <option defaultValue="Japanese">Japanese</option>
                                                            <option defaultValue="Jordanian">Jordanian</option>
                                                            <option defaultValue="Kazakh">Kazakh</option>
                                                            <option defaultValue="Kenyan">Kenyan</option>
                                                            <option defaultValue="Korean">Korean</option>
                                                            <option defaultValue="Kuwaiti">Kuwaiti</option>
                                                            <option defaultValue="Laotian">Laotian</option>
                                                            <option defaultValue="Latvian">Latvian</option>
                                                            <option defaultValue="Lebanese">Lebanese</option>
                                                            <option defaultValue="Liberian">Liberian</option>
                                                            <option defaultValue="Libyan">Libyan</option>
                                                            <option defaultValue="Liechtensteiner">Liechtensteiner</option>
                                                            <option defaultValue="Lithuanian">Lithuanian</option>
                                                            <option defaultValue="Luxembourger">Luxembourger</option>
                                                            <option defaultValue="Macedonian">Macedonian</option>
                                                            <option defaultValue="Madagascan">Madagascan</option>
                                                            <option defaultValue="Malawian">Malawian</option>
                                                            <option defaultValue="Malaysian">Malaysian</option>
                                                            <option defaultValue="Maldivian">Maldivian</option>
                                                            <option defaultValue="Malian">Malian</option>
                                                            <option defaultValue="Maltese">Maltese</option>
                                                            <option defaultValue="Mauritanian">Mauritanian</option>
                                                            <option defaultValue="Mauritian">Mauritian</option>
                                                            <option defaultValue="Mexican">Mexican</option>
                                                            <option defaultValue="Moldovan">Moldovan</option>
                                                            <option defaultValue="Monacan">Monacan</option>
                                                            <option defaultValue="Mongolian">Mongolian</option>
                                                            <option defaultValue="Montenegrin">Montenegrin</option>
                                                            <option defaultValue="Moroccan">Moroccan</option>
                                                            <option defaultValue="Mozambican">Mozambican</option>
                                                            <option defaultValue="Namibian">Namibian</option>
                                                            <option defaultValue="Nepalese">Nepalese</option>
                                                            <option defaultValue="Nicaraguan">Nicaraguan</option>
                                                            <option defaultValue="Nigerian">Nigerian</option>
                                                            <option defaultValue="Nigerien">Nigerien</option>
                                                            <option defaultValue="Norwegian">Norwegian</option>
                                                            <option defaultValue="Pakistani">Pakistani</option>
                                                            <option defaultValue="Panamanian">Panamanian</option>
                                                            <option defaultValue="Paraguayan">Paraguayan</option>
                                                            <option defaultValue="Peruvian">Peruvian</option>
                                                            <option defaultValue="Pole">Pole</option>
                                                            <option defaultValue="Portuguese">Portuguese</option>
                                                            <option defaultValue="Qatari">Qatari</option>
                                                            <option defaultValue="Romanian">Romanian</option>
                                                            <option defaultValue="Russian">Russian</option>
                                                            <option defaultValue="Rwandan">Rwandan</option>
                                                            <option defaultValue="Salvadorean">Salvadorean</option>
                                                            <option defaultValue="Saudi Arabia">Saudi Arabia</option>
                                                            <option defaultValue="Saudi Arabian">Saudi Arabian</option>
                                                            <option defaultValue="Scot">Scot</option>
                                                            <option defaultValue="Senegalese">Senegalese</option>
                                                            <option defaultValue="Serbian">Serbian</option>
                                                            <option defaultValue="Sierra Leonean">Sierra Leonean</option>
                                                            <option defaultValue="Singaporean">Singaporean</option>
                                                            <option defaultValue="Slovak">Slovak</option>
                                                            <option defaultValue="Slovenian">Slovenian</option>
                                                            <option defaultValue="Somali">Somali</option>
                                                            <option defaultValue="South African">South African</option>
                                                            <option defaultValue="Spaniard">Spaniard</option>
                                                            <option defaultValue="SriLankan">SriLankan</option>
                                                            <option defaultValue="Sudanese">Sudanese</option>
                                                            <option defaultValue="Surinamese">Surinamese</option>
                                                            <option defaultValue="Swazi">Swazi</option>
                                                            <option defaultValue="Swede">Swede</option>
                                                            <option defaultValue="Swiss">Swiss</option>
                                                            <option defaultValue="Syrian">Syrian</option>
                                                            <option defaultValue="Tadzhik">Tadzhik</option>
                                                            <option defaultValue="Taiwanese">Taiwanese</option>
                                                            <option defaultValue="Tanzanian">Tanzanian</option>
                                                            <option defaultValue="Thai">Thai</option>
                                                            <option defaultValue="Togolese">Togolese</option>
                                                            <option defaultValue="Trinidadian">Trinidadian</option>
                                                            <option defaultValue="Tunisian">Tunisian</option>
                                                            <option defaultValue="Turk">Turk</option>
                                                            <option defaultValue="Ugandan">Ugandan</option>
                                                            <option defaultValue="Ukrainian">Ukrainian</option>
                                                            <option defaultValue="Uruguayan">Uruguayan</option>
                                                            <option defaultValue="Uzbek">Uzbek</option>
                                                            <option defaultValue="Venezuelan">Venezuelan</option>
                                                            <option defaultValue="Vietnamese">Vietnamese</option>
                                                            <option defaultValue="Welshman">Welshman</option>
                                                            <option defaultValue="Yemeni">Yemeni</option>
                                                            <option defaultValue="Yugoslav">Yugoslav</option>
                                                            <option defaultValue="Zambian">Zambian</option>
                                                            <option defaultValue="Zimbabwean">Zimbabwean</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">SMS/Email Language <code>(*)</code></label>
                                                <select name={"sms_lang_"+parent} onChange={(e)=> handle(e)} className='form-control' id="">
                                                <option defaultValue={result.sms_lang}>{result.sms_lang}</option>
                                                    <option defaultValue="French">French</option>
                                                    <option defaultValue="English">English</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">Occupation</label>
                                                <input type="text" className="form-control"  defaultValue={result.occupation} name={'occupation_'+parent} onChange={(e)=> handle(e)} />
                                            </div>

                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">Enterprise</label>
                                                <input type="text" className="form-control"  defaultValue={result.enterprise} name={'enterprise_'+parent} onChange={(e)=> handle(e)} />
                                            </div>

                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">Enterprise adress</label>
                                                <input type="text" className="form-control"  defaultValue={result.enterprise_addres} name={'enterprise_addres_'+parent} onChange={(e)=> handle(e)} />
                                            </div>

                                            <div className="col-12 col-sm-4 mb-3">
                                                <label htmlFor="">Responsible of school feese <code>(*)</code></label>
                                                <select name={"responsible_"+parent} onChange={(e)=> handle(e)} className='form-control' id="">
                                                <option defaultValue={result.responsible}>{result.responsible}</option>
                                                    <option defaultValue="Yes">Yes</option>
                                                    <option defaultValue="No">No</option>
                                                </select>
                                            </div>
                                </div>
                                 ))
                                }
                            </div>

                    </Modal.Body>
                    <Modal.Footer>


                        <Button type="button" onClick={(e) => handleSubmitParentInfo(e)} variant='primary'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>                   
        </>
    )
}

export default Admission
