import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Menus from "../../components/Menus";
import services from "../../services/services";
import DataTable from "react-data-table-component";
import { Button, Modal, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";


function StudentSubject(){

    const { id_class } = useParams()

    useEffect(() => {
        getallStudentSectionByClass(id_class)
        getSubjectByIDCLass(id_class)
        getallStudentSubject()
    },[id_class])

    const [dataStudentSection, setDataStudentSection] = useState([]);

    function getallStudentSectionByClass(id) {
        services.get_StudentSection_by_id_class(id)
            .then((response) => {

                setDataStudentSection(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataSubject, setDataSubject] = useState([]);

    function getSubjectByIDCLass(id) {
        services.getSubject(id)
            .then((response) => {

                setDataSubject(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }



    /** Activation modal de la liste des classe subject  */
    const [showModalSubject, setShowModaltSubject] = useState(false)

    function ShowModalSubject() {
        setShowModaltSubject(true); //open modal

    }
    const handleCloseModalSubject = () => setShowModaltSubject(false) ///close modal
    /** Activation modal de la liste des classe subject  */




    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataStudeSub, setStudeSub] = useState({
        id_class: '',
        id_student: '',
    })

    const handleStudentSubject = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataStudeSub, [name]: value }
        setStudeSub(data);
        // console.log(data);

    }

    const [dataStudsubject, setStudsubject] = useState({
        id_class_subject: [],
    })

    const handleStudsubject = (e) => {
        const { value, checked } = e.target;
        const { id_class_subject } = dataStudsubject;

        if (checked) {
            setStudsubject({
                id_class_subject: [...id_class_subject, value],
            });
        }
        else {
            setStudsubject({
                id_class_subject: "",
            });
        }
    }


    const [showInputcheck, setShowInputcheck] = useState(false)

    function check() {
        setShowInputcheck(!showInputcheck)
    }

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitStudentSubject(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_class', id_class);
        bodyFormData.append('id_student', dataStudeSub.id_student);
        bodyFormData.append('id_class_subject', dataStudsubject.id_class_subject);

        services.InsertStudentSubject(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalSubject();
                // getallStudentSubject();
                getallStudentSectionByClass(id_class)

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    
    /** fonction d'insertion des donné dans la base de donnée */

    useEffect(() => {
        getAcademicOpen()
        getallStudentSubject()
    },[])


    const [dataAcademicOpen, setDataAcademicOpen] = useState([]);

    function getAcademicOpen() {
        services.get_Academic_Open()
            .then((response) => {

                setDataAcademicOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    

    const [showModalSubjectAssigned, setShowModaltSubjectAssigned] = useState(false)

    function ShowModalSubjectAssigned() {
        setShowModaltSubjectAssigned(true); //open modal

    }
    const handleCloseModalSubjectAssigned = () => setShowModaltSubjectAssigned(false)

    const [dataSubjectAsigned, setDataSubjectAsigned] = useState([]);

    function getStudentSubjectAsigned(code, id_class) {
        ShowModalSubjectAssigned()

        services.get_StudentSubjectAssigned(code, id_class)
            .then((response) => {

                setDataSubjectAsigned(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [dataStudentSubject, setDataStudentSubject] = useState([]);

    function getallStudentSubject() {
        services.getStudentSubjects()
            .then((response) => {

                setDataStudentSubject(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const columnsSubjectAsignedt = [
       
        {
            name: "Subject",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.subject}</b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>
                                <Link to="#" onClick={(e) => DeleteSubjectAssigned(rows.id, rows.code_academic, id_class)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )

                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
       
       
    ]

    function DeleteSubjectAssigned(id, code_academic, idclass) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data assigned subject",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteSubjectAssigned(id)
                    .then(response => {
                        // console.log(response.data);
                        getStudentSubjectAsigned(code_academic, idclass)
                        Swal.fire(
                            'Data assigned subject has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    function DeleteAllSubjectAssigned(code_academic, idclass) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data all assigned subject",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteAllSubjectAssigned(code_academic, idclass)
                    .then(response => {
                        // console.log(response.data);
                        getallStudentSubject()
                        getStudentSubjectAsigned(code_academic, idclass)
                        Swal.fire(
                            'Data all assigned subject has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }

    return(

        <>
            <Header/>
            <Menus/>

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Assign Class subject</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/class-student-subject">Student Subject</a></li>
                                    <li className="breadcrumb-item active">Assigned</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Class</th>
                                                                    <th>Student</th>
                                                                    <th>Subject Assigned</th>
                                                                    <th>Academic Year</th>
                                                                    <th>Assign</th>
                                                                    <th>Delete All subject</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {dataStudentSection.map((result) => (
                                                                <tr  key={result.id}>
                                                                    <td>
                                                                        <input 
                                                                        type="checkbox" 
                                                                        name="id_student" 
                                                                        id="id_student"  
                                                                        className="form-check-input"  
                                                                        onChange={(e) => handleStudentSubject(e)}
                                                                        onClick={check}
                                                                        value={result.code_academic}
                                                                        />
                                                                    </td>
                                                                    <td>{result.id_classe+result.id_section}</td>
                                                                    <td>{result.prenom+' '+result.nom}</td>

                                                                    <td>
                                                                    <button className="btn btn-info" onClick={(e) => getStudentSubjectAsigned(result.code_academic, result.id_class_section)}>
                                                                        <i className="fa fa-eye"></i></button>
                                                                    </td>

                                                                    {
                                                                    dataAcademicOpen.map((result) => (
                                                                    <td key={result.id}>
                                                                        {result.year}
                                                                    </td>
                                                                    ))
                                                                    }
                                                                    
                                                                    <td>
                                                                        {
                                                                            showInputcheck &&
                                                                            <>
                                                                                <button className="btn btn-primary" onClick={(e) => ShowModalSubject(e)}>Subject</button>
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            showInputcheck &&
                                                                            <>
                                                                                <button onClick={(e) => DeleteAllSubjectAssigned(result.code_academic, result.id_class_section)} className="btn btn-sm bg-danger-light">
                                                                                    Delete-All
                                                                                </button>
                                                                            </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            }
                                                               
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
               
            </div>

            {/* formulaire Student Subject */}

            <Modal show={showModalSubject} onHide={handleCloseModalSubject} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Classes subject</h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitStudentSubject(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Subject</label>{
                                        dataSubject.map((result) => (
                                            <div className="form-check" key={result.id}>
                                            <input 
                                                type="checkbox" 
                                                name="id_class_subject" 
                                                id="id_class_subject"  
                                                className="form-check-input"  
                                                onChange={(e) => handleStudsubject(e)}
                                                value={result.id}
                                                />
                                            <label className="form-check-label">{result.subject}</label>
                                            </div>
                                            ))
                                        }
                                </div>

                                

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalSubjectAssigned} onHide={handleCloseModalSubjectAssigned} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>List Subject Assigned</h3>
                    </Modal.Title>
                </Modal.Header>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-12">
                                   
                                        <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                columns={columnsSubjectAsignedt}
                                                data={dataSubjectAsigned}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                                
                                </div>
                                
                                
                                

                            </div>
                        </div>

                    </Modal.Body>
                    
            </Modal>

            {/* formulaire Student Subject */}

        </>
    )

}
export default StudentSubject;