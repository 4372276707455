import axios from "axios";

export default axios.create({
    // baseURL: "http://127.0.0.1:8000/api",
    baseURL: "https://back-scholarsappv2.iesaciv.com/api",
    headers: {
        'Content-Type': 'multipart/form-data',
        // "Content-type": "application/json",
        // "Accept": "application/json",
        'X-CSRF-TOKEN': "token.content",
        'Access-Control-Allow-Origin': '*',
    
      }
});