import DataTable from "react-data-table-component";
import Header from "../../components/Header";
import Menus from "../../components/Menus";
import services from "../../services/services";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";


function TeacherSubject() {

     ///////////////////////////////////////////////////////////////////////////// Classe Section //////////////////////////////////////////

    /** Activation modal d'insertion Classe Section  */
    const [showModalClasseSection, setShowModalClasseSection] = useState(false)

    function ShowModalClasSec() {
        getSectionOpen()
        getClasseOpen()
        setShowModalClasseSection(true); //open modal

    }
    const handleCloseModalClasseSection = () => setShowModalClasseSection(false) ///close modal
    /** Activation modal d'insertion Classe Section  */


    /** variable de recuperation des donner entrant dans les cahmp input */
    const [dataClasSec, setClasSec] = useState({
        id_classe: '',
        number_place: '',
        link_whats: '',
        id_section: '',
    })


    const handleClasseSection = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const data = { ...dataClasSec, [name]: value }
        setClasSec(data);
        console.log(data);

    }

    

    /** variable de recuperation des donner entrant dans les cahmp input */


    /** fonction d'insertion des donné dans la base de donnée */
    function handleSubmitClasseSection(e) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('libelle', dataClasSec.id_classe);
        bodyFormData.append('description', dataClasSec.id_section);
        bodyFormData.append('number_place', dataClasSec.number_place);
        bodyFormData.append('lien', dataClasSec.link_whats);

        services.InsertClasseSection(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                handleCloseModalClasseSection();
                getallClasseSection();

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** fonction d'insertion des donné dans la base de donnée */


    /** Affichage des donnée enregistré */
    useEffect(() => {
        getSectionOpen()
        getallClasseSection()
        getClasseOpen()
    }, [])

    const [dataSectionOpen, setDataSectionOpen] = useState([]);

    function getSectionOpen() {
        services.get_Section_Open()
            .then((response) => {

                setDataSectionOpen(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    const [dataClasseOpen, setDataClasseOpen] = useState([]);

        function getClasseOpen() {
            services.get_Classe_Open()
                .then((response) => {

                    setDataClasseOpen(response.data)
                    // console.log(response.data);

                })
                .catch((error) => {
                    // console.log(error.response);
                })
        }


    const [dataClasseSection, setDataClasseSection] = useState([]);

    function getallClasseSection() {
        services.getClasseSection()
            .then((response) => {

                setDataClasseSection(response.data)
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }


    const columnsClasseSection = [
        {
            name: "Class",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Section",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.description} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Number Of Place",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.number_place} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Link WhatsApp",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.lien} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Academic Year",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.year} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Actions",
            cell: (rows) => (
                <div className="actions">
                    {(() => {
                        return (
                            <>

                                {/* <Link to={"#"} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link> */}
                                <Link to={"#"} onClick={(e) => ShowModalUpdateClasSec(rows.id)} className="btn btn-sm bg-success-light me-2">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                <Link to="#" onClick={(e) => DeleteClasseSection(rows.id)} className="btn btn-sm bg-danger-light">
                                    <i className="fas fa-trash"></i>
                                </Link>
                            </>
                        )



                    })()}

                </div>

            ),
            sortable: true,
            reorder: true
        },
    ]
    /** Affichage des donnée enregistré */


    /** Suppression un élement dans la base de donée */

    function DeleteClasseSection(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "to delete data Items Classe Section setting",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                services.deleteClasseSection(id)
                    .then(response => {
                        // console.log(response.data);
                        getallClasseSection()
                        Swal.fire(
                            'Data Items Classe Section setting has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        // console.log(e);
                    });

            }
        })
    }
    /** Suppression un élement dans la base de donée */

    //////////////////////////////////////// UPDATE//////////////////////////////

    /**Activation du modale pour l'update */
    const [showModalEditClasseSection, setShowEditModalClasseSection] = useState(false)

    function ShowModalUpdateClasSec(id) {
        setShowEditModalClasseSection(true);
        dataClasseSectiondit(id)

    }
    const handleEditCloseModalClasseSection = () => setShowEditModalClasseSection(false)
    /**Activation du modale pour l'update */


    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */
    const [dataEditClasSec, setDataEditClasSec] = useState([])

    function dataClasseSectiondit(id) {
        services.get_ClasseSection_by(id)
            .then((response) => {

                setDataEditClasSec(response.data[0])
                // console.log(response.data);

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }

    /** recuperation de la ligne dans la base de donnée en fonction de l'ID */

    /**function de modification des donnée recuperé en fonction de l'ID */
    function handleUpdateClasseSection(e, id) {
        e.preventDefault();

        const bodyFormData = new FormData();

        bodyFormData.append('id_ClasSec', id);
        bodyFormData.append('libelle', dataClasSec.id_classe);
        bodyFormData.append('description', dataClasSec.id_section);
        bodyFormData.append('number_place', dataClasSec.number_place);
        bodyFormData.append('lien', dataClasSec.link_whats);

        services.updateClasseSection(bodyFormData)
            .then((response) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000
                })
                // console.log(response.data)
                getallClasseSection()
                handleEditCloseModalClasseSection()

            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
    /**function de modification des donnée recuperé en fonction de l'ID */

    ///////////////////////////////////////////////////////////////////////////// ClasseSection //////////////////////////////////////////

    return (
        <>

            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Classe Section</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.html">Scholarsapp / Mapping</a></li>
                                    <li className="breadcrumb-item active">Classe Section</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane">
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <button type="button" className="btn btn-info mb-3" onClick={() => ShowModalClasSec()} ><i className="fas fa-plus"></i> Add Classe Section </button>

                                                <DataTable
                                                    title="DataTable"
                                                    // customStyles={customStyles}
                                                    columns={columnsClasseSection}
                                                    data={dataClasseSection}
                                                    responsive
                                                    noHeader
                                                    defaultSortFieldId={1}
                                                    defaultSortField="id"
                                                    defaultSortAsc={false}
                                                    persistTableHead
                                                    highlightOnHover
                                                    exportHeaders={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* formulaire ClasseSection */}

            <Modal show={showModalClasseSection} onHide={handleCloseModalClasseSection} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Add Classe Section </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleSubmitClasseSection(e)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row"> 
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class</label>
                                    <select name="id_classe" onChange={(e) => handleClasseSection(e)} id=""  className="form-control">
                                    <option value="">Select classe</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.libelle}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                <label htmlFor="">Select Section</label>
                                    <select name="id_section" onChange={(e) => handleClasseSection(e)} id=""  className="form-control">
                                    <option value="">Select Section</option>{
                                    dataSectionOpen.map((result) => (
                                         <option key={result.id} value={result.description}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Number Of place</label>
                                    <input type="number" name="number_place" onChange={(e) => handleClasseSection(e)} className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Link WhatsApp</label>
                                    <input type="text" name="lien" onChange={(e) => handleClasseSection(e)} className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='success'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalEditClasseSection} onHide={handleEditCloseModalClasseSection} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>Update ClasseSection Parameters </h3>
                    </Modal.Title>
                </Modal.Header>
                <form method="POST" onSubmit={(e) => handleUpdateClasseSection(e, dataEditClasSec.id)}>
                    <Modal.Body>

                        <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                            <div className="row">
                                
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Select Class</label>
                                    <select name="id_classe" defaultValue={dataEditClasSec.libelle} onChange={(e) => handleClasseSection(e)} id=""  className="form-control">
                                    <option value="">Select classe</option>{
                                    dataClasseOpen.map((result) => (
                                         <option key={result.id} value={result.libelle}>{result.libelle}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                <label htmlFor="">Select Section</label>
                                    <select name="id_section" defaultValue={dataEditClasSec.description} onChange={(e) => handleClasseSection(e)} id=""  className="form-control">
                                    <option value="">Select Section</option>{
                                    dataSectionOpen.map((result) => (
                                         <option key={result.id} value={result.description}>{result.description}</option>
                                          ))
                                          }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Number Of place</label>
                                    <input type="date" name="number_place" defaultValue={dataEditClasSec.number_place} onChange={(e) => handleClasseSection(e)} placeholder="number_place..." className="form-control" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label htmlFor="">Link WhatsApp</label>
                                    <input type="date" name="lien" defaultValue={dataEditClasSec.lien} onChange={(e) => handleClasseSection(e)} placeholder="lien..." className="form-control" />
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button type="submit" variant='warning'>
                            update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* formulaire ClasseSection */}
        </>

    )
}
export default TeacherSubject;