import React, { useEffect } from "react";
import Menus from '../components/Menus';
import Header from '../components/Header';
import DataTable from 'react-data-table-component';
import { useState } from "react";
import { Modal, Button } from 'react-bootstrap';

import { Link } from "react-router-dom";
import Services from "../services/services";
import Swal from "sweetalert2";

function InvoiceSettings() {

    const [showModalItemGroup, setShowModalItemGroup] = useState(false)
    function ShowModalItemGroups() {
        setShowModalItemGroup(true)
    }
    const HideModalItemGroups = () => setShowModalItemGroup(false)


    const [dataItemsGroups, setDataItemsGroups] = useState({
        name : '',
    })

    function handle(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = {...dataItemsGroups, [name]: value}
        setDataItemsGroups(data)
        console.log(data)

    }


    function handleSubmit(e) {
        e.preventDefault();

       const dataInput = new FormData();

       dataInput.append('libelle', dataItemsGroups.name);

       Services.AddItemsGroup(dataInput)
        .then((response) => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000
            })
            console.log(response.data)
            HideModalItemGroups()
            getItemsGroups()
        })
        .catch((error)=>{
            console.log(error)
        })
        
    }

    useEffect(()=>{
        getItemsGroups()
    }, [])

    const [itemGroup, setItemGroup] = useState([])

    function getItemsGroups() {
        Services.GetItemsGroup()
        .then((response) => {
            setItemGroup(response.data)
            console.log(response.data)
        })
        .catch((error) =>{
            console.log(error)
        })
    }

    const columnsItemsGroup = [
        {
            name: "Name",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.title} </b>
            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">

                    <Link to="#" className="btn btn-sm bg-danger-light" onClick={(e) => DeleteItemsGroup(rows.id_itg)}>
                        <i className="fas fa-trash"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },
    ]

    function DeleteItemsGroup(id) {
        Swal.fire({
            title: 'Are you sure?',
            html: "You can't go back! The <b>Item Group</b> name will be deleted",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Services.removeItemsGroup(id)
                    .then(response => {
                        console.log(response.data);
                        getItemsGroups()
                        Swal.fire(
                            'Section name has been deleted',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }
    return (
        <>
            <Menus />
            <Header />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="page-title">Invoice settings</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/Dashboard"}>Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">General Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-xl-3 col-md-4">
                            <div className="widget settings-menu">
                                <ul>
                                    <li className="nav-item">
                                        <Link to={"/Invoice-settings"} className="nav-link active">
                                            <i className="fa fa-book"></i> <span>Items Groups</span>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to={"/Items-settings"} className="nav-link">
                                            <i className="fa fa-book"></i> <span>Items</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/Simple-invoice"} className="nav-link">
                                            <i className="fas fa-file-invoice"></i> <span>Simple invoice</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/bank-settings"} className="nav-link">
                                            <i className="fas fa-university"></i> <span>Bank Settings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-9 col-md-8">
                            <div className="card invoices-settings-card">
                                <div className="card-header">
                                    <h5 className="card-title">Items Groups</h5>
                                </div>
                                <div className="card-body">
                                    <div className="col-auto text-end float-left ms-auto">
                                        <button type="button" className="btn btn-outline-primary me-2 mt-3" onClick={() => ShowModalItemGroups()}>Add Item Group <i className="fas fa-plus"></i></button>
                                    </div>
                                    <div className="table-responsive mt-5">
                                        <DataTable
                                            title="DataTable"
                                            columns={columnsItemsGroup}
                                            data={itemGroup}
                                            // customStyles={customStyles}
                                            responsive
                                            noHeader
                                            defaultSortFieldId={1}
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            // selectableRows
                                            persistTableHead
                                            highlightOnHover
                                            exportHeaders={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={showModalItemGroup} onHide={HideModalItemGroups} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h3 style={{ color: 'orange' }}>Add Item Group </h3>
                            </Modal.Title>
                        </Modal.Header>
                        <form  className="invoices-settings-form" onSubmit={(e) => handleSubmit(e)}>
                            <Modal.Body>


                               
                                <div className="row align-items-center form-group">
                                    <label htmlFor="email" className="col-sm-3 col-form-label input-label">Group Name</label>
                                    <div className="col-sm-9">
                                        <input type="text" name="name" value={dataItemsGroups.name} onChange={(e) => handle(e)} className="form-control" placeholder="Name..." />
                                    </div>
                                </div>
                               
                               
                               
                               
                               


                            </Modal.Body>
                            <Modal.Footer>
                           

                                <Button type="submit"  className="btn btn-primary-save-bg">
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                </div>
            </div>
        </>
    )
}

export default InvoiceSettings;