import { useState, useEffect } from 'react';

import Menus from '../components/Menus';
import Header from '../components/Header';
import { Link } from 'react-router-dom'

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import Services from '../services/services';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';


function StudentList() {

    const [dataList, setDataList] = useState([])
    const [dataStudent, setDataStudent] = useState([])

    useEffect(() => {
        GetAllParents()
    }, []);

    function GetAllParents() {
        Services.get_data_parent()
            .then((res) => {
                setDataList(res.data)
                console.log(res.data)


            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const [showListStud, setShowListStud] = useState(false);

    function ShowModalStud(e, code) {
        setShowListStud(true);

        Services.get_sudent_by_code_parent(code)
            .then((ress) => {
                setDataStudent(ress.data)
                console.log(ress.data)
                console.log(code)

            })
            .catch((error) => {
                console.log(error.response);
            })
    }

    const closeModalListStud = () => setShowListStud(false);



    const columns = [


        {
            name: "Full name",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.fist_name} {rows.last_name} </b>
            ),
            sortable: true,
            reorder: true,
            width: "20rem",                       // added line here
            headerStyle: (selector, id) => {
                return { textAlign: "center" };   // removed partial line here
            },
        },

        {
            name: "Email",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.email} <br />{rows.email2}</b>
            ),
            sortable: true,
            reorder: true,
            width: "20rem",
        },
        {
            name: "Nationality",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.nationality} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Main Mobile",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.main_mobile} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Whatsapp Phone",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.whatsapp_phone} </b>
            ),
            sortable: true,
            reorder: true,
            width: "10rem",                       // added line here
            headerStyle: (selector, id) => {
                return { textAlign: "center" };   // removed partial line here
            },
        },

        {
            name: "Main Language",
            cell: (rows) => (
                <b style={{ color: "black" }}>{rows.main_language} </b>
            ),
            sortable: true,
            reorder: true,
            width: "10rem",                       // added line here
            headerStyle: (selector, id) => {
                return { textAlign: "center" };   // removed partial line here
            },
        },


        {
            name: "Student",
            cell: (rows) => (
                <div className="actions">
                    
                    <Link to="#" className="btn btn-sm bg-primary-light" onClick={(e) => ShowModalStud(e, rows.code_parent)}>
                        <i className="fas fa-users"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },


        {
            name: "Action",
            cell: (rows) => (
                <div className="actions">
                    <Link to={"/detail-student/" + rows.StudentId} className="btn btn-sm bg-primary-light me-2">
                        <i className="fas fa-eye"></i>
                    </Link>

                    <Link to={"/edit-student/" + rows.StudentId} className="btn btn-sm bg-success-light me-2">
                        <i className="fas fa-pen"></i>
                    </Link>
                    <Link to="#" className="btn btn-sm bg-danger-light" onClick={(e) => DeleteStudent(e, rows.StudentId, rows.invoice)}>
                        <i className="fas fa-trash"></i>
                    </Link>
                </div>


            ),
            sortable: true,
            reorder: true
        },


    ]

    function DeleteStudent(e, id, invoice) {
        Swal.fire({
            title: 'Es-tu sûr?',
            html: "You can't go back! The student will be deleted <br> Vous ne pourrez pas revenir en arrière! l'éléve sera supprimé",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprime-le!'
        }).then((result) => {
            if (result.isConfirmed) {
                Services.remove(id, invoice)
                    .then(response => {
                        console.log(response.data);
                        GetAllParents()
                        Swal.fire(
                            'Supprimé!',
                            'Votre fichier a été supprimé.',
                            'success'
                        )
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        })
    }



    return (
        <>
            <Header />
            <Menus />

            <div className="page-wrapper">
                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Parents</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Parents</li>
                                </ul>
                            </div>
                            <div className="col-auto text-end float-end ms-auto">
                                <Link to="#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</Link>
                                <Link to="/Add-Student" className="btn btn-primary"><i className="fas fa-plus"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-table">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTableExtensions
                                            columns={columns}
                                            data={dataList}
                                            export={false}
                                            print={false}
                                        >
                                            <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                // selectableRows
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <footer>
                    <p>Copyright © 2020 Dreamguys.</p>
                </footer> */}

            </div>

            <Modal show={showListStud} onHide={closeModalListStud} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: 'orange' }}>List student </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-lg-12" style={{ fontWeight: 'bold' }}>
                        <div className="row">

                            {
                                dataStudent.map((result) => (
                                    <div className="col-12 col-md-6 col-lg-4 d-flex">
                                        <div className="card flex-fill bg-white">
                                            <img alt="Card Image" src={"https://scholarsapp.iesaciv.com/" + result.file} className="card-img-top" />
                                                <div className="card-header">
                                                    <h5 className="card-title mb-0">{result.nom} {result.prenom}</h5>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Birthday: <code>{result.birthday}</code></li>
                                                    <li className="list-group-item">Nationality: <code>{result.nationality}</code></li>
                                                    <li className="list-group-item">Admission date: <code>{result.admission_date}</code></li>
                                                    <li className="list-group-item">Admission number: <code>{result.admission_number}</code></li>
                                                </ul>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </>
    );
}

export default StudentList;